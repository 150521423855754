import React from "react";
import { ReactComponent as ChipCross } from "../../../Assets/icons/ChipCross.svg";
import "./style.scss";

export default function getMultiSelectChips(selectedItems, removeChipHandler, readMode) {
  return selectedItems?.map((item, index) => (
    <div key={`${item?.value}_${item?.id}`} className="chip-v2">
      <span className="chip-text-v2">{item?.label}</span>
      {!readMode && (
        <span className="chip-icon-v2">
          <ChipCross onClick={(e) => removeChipHandler(e, item, index)} />
        </span>
      )}
    </div>
  ));
}
