import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalMultiselect from "../ModalMultiselect";
import useDetectOutsideClick from "../../../../../CustomHooks/useDetectOutsideClick";
// import { CancelIcon } from "../../../../../Constants/icons";
import ListRenderer from "./multiselectListRernderer";
import {
  modalTitleRender,
  returnCheckedObj,
  returnLength,
  selectedOptionsFirst,
} from "../../../../../Utils/commonUtils";
import { ReactComponent as SelectIcon } from "../../../../../Assets/icons/ic-select.svg";

// eslint-disable-next-line max-lines-per-function
export default function CustomerInvoice({
  name,
  options,
  handleChange,
  onBlurHandler,
  modalTitle = "",
  values = [],
  parentClass = "col-md-2",
  checkReadOnly = false,
  searchPlaceholder = "customer(s)",
  mandatory = false,
  showCount = false,
  innerClass = "",
  modalClassWrapper = "",
}) {
  const value = values[name];
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setItems(returnCheckedObj(value, options));
  }, [value, options]);

  useEffect(() => {
    setItems(options);
  }, [items, options]);

  const onClickHandler = () => {
    handleChange({
      target: {
        value: selectedItems || [],
        name,
      },
    });
    setOpen(!open);
  };

  const handleClose = () => {
    setSelectedItems(value);
    setOpen(false);
    setItems(returnCheckedObj(value, items));
  };

  useEffect(() => {
    setItems(returnCheckedObj(value, options));
  }, []);

  useDetectOutsideClick(dropdownRef, (e) => {
    e.stopPropagation();
    if (open) {
      handleClose();
      onBlurHandler({ target: { value: value || [], name } });
    }
  });

  const onChangeHandler = (event) => {
    const {
      target: { value: targetValue },
    } = event;
    setSelectedItems(targetValue.filter((item) => item.checked));
  };

  const excludeHandler = (e) => {
    handleChange({
      target: {
        value: e.target.checked,
        name: `${name}Exclude`,
      },
    });
  };

  return (
    <div className={`customer-invoice-wrapper !pr-2.5 ${parentClass}`}>
      <div className={innerClass}>
        <div className="multiseelct_toggler_button mb-[6px]">
          <p className={`${mandatory ? "mandatory" : ""}`}>{modalTitle}</p>
          {showCount && selectedItems?.length > 0 && (
            <p className="!text-[10px] !font-medium bg-[#D8EAFF] !px-[2px] !py-px rounded-[3px] ml-[5px]">
              Selected: {selectedItems?.length}
            </p>
          )}
          <p
            className={`!text-[11px] text-[#0A3CA2] cursor-pointer flex gap-[4px] items-center ml-auto ${
              options.length === 0 ? "disabled" : null
            }`}
            onClick={() => {
              if (options?.length !== 0) {
                handleClickOpen();
              }
            }}
            aria-hidden="true"
          >
            <SelectIcon />
            Select
          </p>
        </div>
        <div>
          <ListRenderer
            options={selectedOptionsFirst(items, open, value, selectedItems)}
            modalState={open}
            value={value}
            selectedItems={selectedItems}
            checkReadOnly={checkReadOnly}
            title={modalTitle}
          />
          {checkReadOnly && (
            <div className="toggler_readyOnly min-h-41 flex justify-center p-[9px] border border-solid border-gray-300 rounded-bl-4 rounded-br-4 border-t-0 mt-[-1px]">
              {selectedItems?.length > 5 && (
                <>
                  <p className="blue_text">{returnLength(selectedItems)}</p>
                  <p
                    className="!text-[11px] text-[#0A3CA2] cursor-pointer"
                    onClick={handleClickOpen}
                    aria-hidden="true"
                  >
                    Select
                  </p>
                </>
              )}
            </div>
          )}
        </div>

        <div id="exclude-checkbox">
          <FormControlLabel
            control={
              <Checkbox
                className="exclude-checkbox"
                name="exclude"
                checked={options?.length > 0 ? values[`${name}Exclude`] : false}
                disableRipple
                onChange={(e) => excludeHandler(e)}
                disabled={!selectedItems?.length || (checkReadOnly && !values[`${name}Exclude`])}
              />
            }
            label="Exclude Selected"
          />
        </div>
      </div>
      <Dialog
        className={`multiselect_component ${
          checkReadOnly ? "read_only_modal" : ""
        } ${modalClassWrapper}`}
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
      >
        <p className="modal-title-multiselect">
          {modalTitleRender(modalTitle, options, selectedItems, checkReadOnly)}
          {/* <CancelIcon onClick={handleClose} /> */}
        </p>
        <div className="multiselect_wrapper">
          <ModalMultiselect
            searchEnabled
            unitEnabled={false}
            options={selectedOptionsFirst(items, open, value, selectedItems)}
            name={name}
            handleChange={onChangeHandler}
            placeholder={`${options.length} ${searchPlaceholder}`}
            selected={selectedItems}
            checkReadOnly={checkReadOnly}
          />
        </div>
        <div className="action_buttons wrapper_padding">
          <Button onClick={handleClose} className="button outline-primary">
            Cancel
          </Button>
          <Button onClick={onClickHandler} className="button primary">
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
