const accountDate = {
  accountStartDate: "",
  accountEndDate: "",
  dashboardStartDates: {},
  dashboardStartDate: "",
};
const initAction = { type: "", payload: {} };

export default function AccountDateReducer(state = accountDate, action = initAction) {
  switch (action.type) {
    case "SAVE_ACCOUNT_DATE":
      return action.payload;
    case "SAVE_DASHBOARD_DATE_LIST":
      return { ...state, dashboardStartDates: action.payload };
    case "SAVE_DASHBOARD_DATE":
      return { ...state, dashboardStartDate: action.payload };
    default:
      return state;
  }
}
