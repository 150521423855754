import React from "react";
import "./style.scss";

function PopupList(props) {
  const { data = [] } = props;

  return (
    <div className="list-popup-wrapper popupList">
      {data.map((item) => (
        <p className="tags">{`${item}`}</p>
      ))}
    </div>
  );
}

export default PopupList;
