/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import "./privatePricing.scss";
import { tableInputTooltip, updateHeadingOnreadOnly } from "./utils";
import { ReactComponent as SelectIcon } from "../../../../Assets/icons/Vector.svg";
import LabelInput from "../../../FormConfig/Form/Components/labelInput";
import { editableTableConfig, handleNewRegion } from "../../../FormConfig";
import RadioButtonGroup from "../../../FormConfig/Form/Components/RadioButtonGroup";
import ToggleInput from "../../PartnerSettings/toggleInput";
import { getCurrencySymbol, noop } from "../../../../Utils/commonUtils";
import { ContentCopy, ResetValueIcon } from "../../../../Constants/icons";
import CloneModal from "../../../CK-Lens/Common/CloneModal";
import ListSelectorNoData from "../../../FormConfig/Form/Components/ListSelector.js/noData";
import Text from "../../../FormConfig/Form/Components/Text";
import PpaInput from "./PrivatePricingAgreements/ppaInput";
// import PpaInput from "./PrivatePricingAgreements/ppaInput";

function EditableTable({
  headers,
  tableData = [],
  heading,
  enableAos = false,
  handleUpcomingRegions = false,
  handleChange = noop,
  name,
  error = {},
  modalTitle,
  showNoData = false,
  value: currentFormValue,
  readOnly = false,
}) {
  const [modalData, setModalData] = useState({});
  const [modalOpenIndex, setModalOpenIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [touched, setTouched] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);

  const [formObj, setFormObj] = useState({
    public: {
      values: [...tableData],
    },
    discount: {
      values: [...tableData],
    },
    configurationType: "public",
    newRegion: false,
  });

  // const [newRegion, setNewRegion] = useState(true);

  const returnInternalList = (data, excludeName) => {
    const obj = {};
    const deleteCurrentList = data.filter((item) => item.childRegions !== excludeName);
    deleteCurrentList.forEach((item) => {
      obj[item.childRegions] = false;
    });
    return obj;
  };

  useEffect(() => {
    const obj = {};
    tableData.forEach((item) => {
      obj[item.childRegions] = returnInternalList(tableData, item.childRegions);
    });

    setFormObj({
      configurationType: "public",
      public: {
        values: [...tableData],
      },
      discount: {
        values: [...tableData],
      },
      newRegionConfig: {},
      newRegion: false,
      aosSize: "",
    });

    setModalData(obj);
  }, []);

  const manipulateTableData = (data = [], formData = []) => {
    const updatedData = [];

    data?.forEach((item) => {
      const regionData = formData?.values?.find(
        (formRegionObj) => item?.childRegions === formRegionObj?.childRegions
      );
      if (regionData) {
        const updatedValue = {};
        headers.forEach((header) => {
          if (Object.keys(regionData).includes(header.value)) {
            updatedValue[header?.value] = regionData?.[header?.value];
          }
        });
        updatedData?.push(updatedValue);
      } else {
        updatedData?.push(item);
      }
    });
    return [...updatedData];
  };

  useEffect(() => {
    const obj = {};
    setToggleLoader(true);
    tableData.forEach((item) => {
      obj[item.childRegions] = returnInternalList(tableData, item.childRegions);
    });
    const updatedFormObj = {
      configurationType: formObj.configurationType,
      public: {
        values: manipulateTableData(tableData, formObj?.public),
      },
      discount: {
        values: manipulateTableData(tableData, formObj?.discount),
      },
      newRegionConfig: {},
      newRegion: false,
      aosSize: "",
    };
    setFormObj({ ...updatedFormObj });
    setModalData(obj);
    if (!readOnly) {
      handleChange({
        target: {
          name,
          value: {
            ...updatedFormObj[updatedFormObj?.configurationType],
            configurationType: updatedFormObj?.configurationType,
            newRegionConfig: updatedFormObj?.newRegionConfig,
            aosSize: updatedFormObj?.aosSize,
            newRegion: formObj?.newRegion || false,
          },
        },
      });
    }
    setTouched(false);
    setTimeout(() => {
      setToggleLoader(false);
    }, 0);
  }, [tableData, headers]);

  const changePrivatePricing = (editName, value, tableIndex) => {
    const updateFormObj = { ...formObj };
    const { configurationType } = formObj;
    updateFormObj[configurationType].values = updateFormObj?.[configurationType]?.values?.map(
      (item, index) => {
        if (index === tableIndex) {
          const updatedItem = { ...item, [editName]: value };
          if (value === "") {
            delete updatedItem[editName];
          }
          return updatedItem;
        }
        return item;
      }
    );
    setTouched(true);
    handleChange({
      target: {
        name,
        value: {
          ...updateFormObj[configurationType],
          configurationType,
          newRegionConfig: updateFormObj.newRegionConfig,
          aosSize: updateFormObj.aosSize,
          newRegion: updateFormObj.newRegion || false,
        },
      },
    });
    setFormObj(updateFormObj);
  };

  const handleClick = (tableIndex) => {
    setModalOpenIndex(tableIndex);
  };

  const handleModalClose = (e) => {
    setModalOpenIndex(e);
  };

  const handleApplyCancel = (e) => {
    const { configurationType } = formObj;

    if (e === "Cancel") {
      setSelectedRows([]);
      handleModalClose(null);
    } else if (e === "Apply Rate" && modalOpenIndex !== null) {
      const sourceData = formObj[configurationType].values[modalOpenIndex];
      const updatedFormData = formObj[configurationType].values.map((item) => {
        if (selectedRows.includes(item.childRegions)) {
          const copiedData = { ...sourceData };
          delete copiedData.childRegions;
          const itemCopy = { ...item };
          const itemKeys = Object.keys(itemCopy);
          itemKeys.forEach((key) => {
            if (key !== "childRegions") {
              if (!Object.prototype.hasOwnProperty.call(copiedData, key)) {
                delete itemCopy[key];
              }
            }
          });

          return {
            ...itemCopy,
            ...copiedData,
          };
        }
        return item;
      });
      const updatedData = { [configurationType]: { values: updatedFormData } };
      setFormObj({ ...formObj, ...updatedData });
      handleChange({
        target: {
          name,
          value: {
            values: [...updatedFormData],
            configurationType: formObj?.configurationType,
            newRegionConfig: formObj.newRegionConfig,
            aosSize: formObj.aosSize,
            newRegion: formObj.newRegion || false,
          },
        },
      });
      setSelectedRows([]);
      handleModalClose(null);
    }
  };

  const onModalChange = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleResetButtonClick = () => {
    setFormObj({
      public: {
        values: [...tableData],
      },
      discount: {
        values: [...tableData],
      },
      configurationType: "public",
    });
    setTouched(false);
  };

  const sign =
    (readOnly ? currentFormValue?.configurationType : formObj.configurationType) === "public"
      ? { sign: getCurrencySymbol(), position: "left" }
      : { sign: "%", position: "right" };

  const renderContent = (header, data, tableIndex, errorData) => {
    const { value, type } = header;
    switch (type) {
      case "table-input":
        return tableInputTooltip(
          header,
          data,
          changePrivatePricing,
          tableIndex,
          sign,
          currentFormValue?.isEditable
        );
      default:
        return (
          <div className="region-row">
            <div>
              <p>{data[value]}</p>
              {touched ? <p className="error">{errorData}</p> : null}
            </div>
            {tableData?.length > 1 && (
              <ContentCopy
                className={
                  Object.keys(formObj?.[formObj?.configurationType]?.values?.[tableIndex])?.length >
                  1
                    ? "active_copy cursor-pointer"
                    : "cursor-auto"
                }
                onClick={() => handleClick(tableIndex)}
              />
            )}
            {modalOpenIndex === tableIndex && (
              <CloneModal
                setSelectedRows={setSelectedRows}
                header={modalTitle}
                data={modalData?.[data[value]]}
                formData={formObj?.values}
                onModalChange={(e) => onModalChange(e)}
                handleApplyCancel={(e) => handleApplyCancel(e)}
                selectedRows={selectedRows}
                handleModalClose={(e) => handleModalClose(e)}
              />
            )}
          </div>
        );
    }
  };

  const returnHeader = (index) => {
    if (index !== 0) {
      return (currentFormValue?.configurationType || formObj.configurationType) === "public"
        ? `(${getCurrencySymbol()})`
        : "(%)";
    }
    return "";
  };

  const [isPublicNotEmpty, isDiscountNotEmpty] = ["public", "discount"].map((field) =>
    formObj[field].values.every((obj) => Object.keys(obj).length === 1)
  );

  const UpcomingRegionsHandler = (event, value) => {
    const updatedForm = { ...formObj };
    if (!updatedForm.newRegionConfig) {
      updatedForm.newRegionConfig = {};
    }
    updatedForm.newRegionConfig[event] = value;
    handleChange({
      target: {
        name,
        value: {
          newRegionConfig: updatedForm.newRegionConfig,
          aosSize: formObj?.aosSize,
          newRegion: updatedForm?.newRegion,
          configurationType: updatedForm?.configurationType,
          ...formObj[formObj.configurationType],
        },
      },
    });
    setTouched(true);
    setFormObj({ ...updatedForm });
  };

  const upcomingRegionToggleHandler = (event) => {
    const {
      target: { name: inputName, value },
    } = event;

    const updatedForm = { ...formObj };
    updatedForm[inputName] = value;

    if (inputName === "newRegion" && value === false) {
      updatedForm.newRegionConfig = {};
    }

    handleChange({
      target: {
        name,
        value: {
          newRegionConfig: formObj?.newRegionConfig || {},
          aosSize: updatedForm?.aosSize,
          newRegion: updatedForm?.newRegion,
          configurationType: updatedForm?.configurationType,
          ...formObj[formObj.configurationType],
        },
      },
    });

    setTouched(true);
    setFormObj({ ...updatedForm });
  };

  const renderTable = (tableHeaders, data, classlist) => (
    <table className={classlist}>
      <thead>
        <tr className="left_aligned_header">
          <th>Region(s)</th>
          <th colSpan={Number(tableHeaders.length - 1)}>Pricing(s)</th>
        </tr>
        <tr>
          {tableHeaders.map((header, index) => (
            <th className="private_pricing_header">
              <p className="cld_pp_header">{`${header.label} ${returnHeader(index)}`}</p>
              {header.secondLabel && (
                <p className="cld_pp_secondary_header">{header.secondLabel}</p>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr className={error?.[item?.childRegions] ? "error" : ""}>
            {tableHeaders.map((header) => (
              <td className="private_pricing_td">
                {renderContent(header, item, index, error?.[item?.childRegions])}
              </td>
            ))}
          </tr>
        ))}
        {handleUpcomingRegions && (
          <tr className="pp_regions_section error">
            <td className="private_pricing_td">
              <div className="flex items-center gap-[12px]">
                <Text
                  text="Upcoming New Regions"
                  label="Enable to handle upcoming region pricing right away, Please provide required values in concerned boxes."
                  className="mr-[12px] mt-[0px]"
                  iconSize="small"
                />
                {!readOnly && (
                  <div>
                    {!toggleLoader ? (
                      <ToggleInput
                        key="newRegion"
                        {...handleNewRegion}
                        value={formObj?.newRegion}
                        leftText=""
                        rightText=""
                        size="small"
                        handleChange={(event) => upcomingRegionToggleHandler(event)}
                      />
                    ) : null}
                  </div>
                )}
              </div>
              {touched ? <p className="error">{error?.newRegionError}</p> : null}
            </td>
            {tableHeaders
              ?.filter((item) => item?.label !== "")
              ?.map((header) => (
                <td className="private_pricing_td">
                  <PpaInput
                    name={header.value}
                    value={
                      readOnly
                        ? currentFormValue?.newRegionConfig?.[header?.value]
                        : formObj?.newRegionConfig?.[header?.value]
                    }
                    disabled={!formObj.newRegion}
                    type="text"
                    onGetData={(event, value) => UpcomingRegionsHandler(event, value)}
                    placeholderText=""
                    className="!w-[100%]"
                    parentClass={`table-radio-input ${
                      formObj?.newRegionConfig?.[header?.value] > 0 ? "have_value" : ""
                    }`}
                    affix={sign?.sign}
                    affixPosition={sign?.position}
                    inputContainerClass="!w-[100%] !max-w-full"
                    inputClass="text-center"
                    commaFormatting
                  />
                </td>
              ))}
          </tr>
        )}
        {enableAos && (
          <tr className="aos-section error">
            <td className="">
              <Text
                text="Average Object Size?"
                label="The AOS is calculated by dividing the total amount of data transferred (in kilobytes) by the number of requests made."
                className="mt-[0px] font-medium"
                iconSize="small"
              />

              {touched ? <p className="error">{error?.aosSize}</p> : null}
            </td>
            <td>
              <LabelInput
                value={readOnly ? currentFormValue?.aosSize : formObj.aosSize}
                className="aos-input"
                placeholderText="Enter Value"
                name="aosSize"
                inputSuffix="KB"
                iconColor="#0A3CA2"
                onChange={(event) => upcomingRegionToggleHandler(event)}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
  return (
    <div className={`page_container editable_table ${readOnly ? "read_only_commitment" : ""}`}>
      <div className="editable_table_header">
        <div className="flex items-center">
          <h2 className="editable_table_heading ">{updateHeadingOnreadOnly(heading, readOnly)}</h2>
          {showNoData && (
            <button
              type="button"
              className="reset_btn"
              disabled={isPublicNotEmpty && isDiscountNotEmpty}
              onClick={handleResetButtonClick}
            >
              <ResetValueIcon />
              <span>Reset</span>
            </button>
          )}
        </div>
        {showNoData && (
          <div>
            <RadioButtonGroup
              {...editableTableConfig}
              value={currentFormValue?.configurationType || formObj?.configurationType}
              handleChange={(e) => {
                const updateFormObj = { ...formObj };
                updateFormObj.configurationType = e?.target?.value;
                setFormObj(updateFormObj);
                handleChange({
                  target: {
                    name,
                    value: {
                      ...updateFormObj?.[e?.target?.value],
                      configurationType: e?.target?.value,
                    },
                  },
                });
              }}
              // disabled={currentFormValue ? !currentFormValue?.isEditable : false}
            />
          </div>
        )}
      </div>
      {showNoData ? (
        <div className="table_container relative">
          {currentFormValue?.headers && currentFormValue?.region
            ? renderTable(currentFormValue?.headers, currentFormValue?.values)
            : renderTable(headers, formObj[formObj?.configurationType]?.values)}
        </div>
      ) : (
        <div className="table_container h-[400px] relative">
          <ListSelectorNoData message="Please select region and pricing type" Icon={SelectIcon} />
        </div>
      )}
    </div>
  );
}

export default EditableTable;
