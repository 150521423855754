import React from "react";
import { FormHelperText } from "@mui/material";
import AddIcon from "../../../Assets/icons/add-icon.svg";
import "./style.scss";
import ActionsDialog from "../../Common/Modal/ActionsDialog";
import { ReactComponent as MakeDefaultModalIcon } from "../../../Assets/icons/makeDefaultModalIcon.svg";
import DialogComponent from "../../Common/Modal/Dialog";
import DeleteUserModal from "../../Common/Modal/DeleteUserModal";
import commonService from "../../../Services/commonService";
import { getToast } from "../../../Utils/commonUtils";

export default function showError(error, errorKey, touched) {
  return (
    error[errorKey] &&
    touched[errorKey] && (
      <FormHelperText
        className="clear-both error_message partner-settings-email"
        error={error[errorKey]}
        size="small"
      >
        {error[errorKey]}
      </FormHelperText>
    )
  );
}

const getCustomerHeaders = () => [
  {
    Header: "Customer Type",
    accessor: "type",
    sortedIcon: true,
    headerClass: "!w-[10%]",
    bodyClass: "!font-medium",
  },
  {
    Header: "Purpose",
    accessor: "description",
    sortedIcon: true,
    align: "Left",
    headerClass: "!w-[10%]",
  },
  {
    Header: "In Use",
    accessor: "inUse",
    headerClass: "!w-[10%]",
    render: (data) => (data?.inUse ? "Yes" : "No"),
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    align: "Right",
    headerClass: "!w-[10%]",
    bodyClass: "",
  },
];
const populateCustomerActions = (apiResponse) =>
  apiResponse.map((item) => ({
    ...item,
    actions: [
      {
        actionType: "delete",
        class: `delete-icon ${
          !item?.inUse ? "" : "!pointer-events-none disabled-customerType-action"
        }`,
      },
    ],
  }));

export const getStatusTable = (apiResponse, onActionClickHandler) => ({
  headers: getCustomerHeaders(),
  data: populateCustomerActions(apiResponse),
  onIconClick: onActionClickHandler,
});

export const buttonForCreateStatus = (opneModal) => [
  {
    label: "Create Customer Type",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
    handleClick: () => {
      opneModal(true);
    },
  },
];

export const createPayload = (data) => {
  const { name, constructOrdering } = data;
  const ordering = constructOrdering.map((item) => item.text);
  const formattedData = { name, ordering };
  return formattedData;
};

export const headerMap = {
  preview: "Preview Template",
  clone: "Clone Template",
  delete: "Delete Template",
  apply: "Apply Template",
  createTemplate: "Create Template",
};

export const makeDefaultSumbitHandler = async (id, setIsUpdating, loadHandler) => {
  setIsUpdating(true);
  try {
    await commonService.UpdateOrderingTemplates({}, id);
    await loadHandler();
    getToast("success", "Template Updated Successfully");
  } catch (error) {
    // do nothing
  } finally {
    setIsUpdating(false);
  }
};

export const getConstructOrderingActionDialog = (modal, setModal, setIsUpdating, loadHandler) => (
  <ActionsDialog
    open={modal?.[modal?.activeModal]?.show}
    data={{
      config: {
        heading: "Change to Default Template?",
        type: "Custom",
        body: `Are you sure you want to change the default template to "${modal?.makeDefault?.row?.name}".`,
        primaryBtnText: "Submit",
        icon: <MakeDefaultModalIcon />,
      },
    }}
    handleClose={() => setModal({ ...modal, makeDefault: { row: {}, show: false } })}
    handleModalSubmit={() =>
      makeDefaultSumbitHandler(modal?.[modal?.activeModal]?.row?.id, setIsUpdating, loadHandler)
    }
    keyname="name"
  />
);

export const getConstructOrderingDialogComponent = (
  modal,
  setModal,
  customerAccountModalBodyHTML,
  dialogComponentParameters,
  hasPermission
) => (
  <DialogComponent
    className={`modalWrapper-customerAaccount ${
      modal?.activeModal === "apply" ? "apply_modal" : ""
    }`}
    id=""
    open={modal?.show}
    handleClose={() => setModal({ ...modal, show: false, activeModal: "" })}
    bodyClass="!border-0"
    header={headerMap[modal.activeModal] || null}
  >
    {modal?.activeModal === "apply"
      ? customerAccountModalBodyHTML(
          modal,
          dialogComponentParameters?.handleApplyChange,
          dialogComponentParameters?.formDataObj,
          setModal,
          dialogComponentParameters?.customerList,
          null,
          null,
          hasPermission
        )
      : customerAccountModalBodyHTML(
          modal,
          null,
          dialogComponentParameters?.formDataObj,
          dialogComponentParameters?.handleCloseModal,
          null,
          dialogComponentParameters?.handleModalData,
          dialogComponentParameters?.handleModalSubmit
        )}
  </DialogComponent>
);

export const handleDelete = async (deletePopup, setIsUpdating, loadHandler) => {
  try {
    setIsUpdating(true);
    await commonService.deleteOrderingTemplates(deletePopup?.row?.id);
    await loadHandler();
    getToast("success", "Template Deleted Successfully");
  } catch (error) {
    // do nothing
  } finally {
    setIsUpdating(false);
  }
};

export const getConstructOrderingDeletePopup = (
  deletePopup,
  setDeletePopup,
  setIsUpdating,
  loadHandler
) =>
  deletePopup?.show && (
    <DeleteUserModal
      open={deletePopup.show}
      data={deletePopup.row}
      handleClose={() => setDeletePopup({ show: false, row: {} })}
      handleDelete={() => handleDelete(deletePopup, setIsUpdating, loadHandler)}
      screen="Template"
      keyname="name"
    />
  );

export const initialMOdalState = {
  activeModal: "",
  makeDefault: { show: false, row: {} },
};

export const initialTableDataState = {
  headers: [],
  data: [],
};
