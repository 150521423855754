import React from "react";
import FusionChart from "../../../../../Common/fusionChart";

function Chart(props) {
  const {
    graph: {
      xAxisName,
      yAxisName,
      type,
      graphData,
      legendPosition,
      showLegend,
      legendRight = false,
      legendCaption = "",
      graphLabel = "",
      showLabels,
      prefix,
      palettecolors,
      tooltip,
      height,
      numberPrefix,
      numVDivLines,
      plotSpacePercent,
      hideScroll = false,
      maxLabelHeight,
      decimals,
      chartBottomMargin,
      legendIconSides,
      divLineDashed,
      divLineColor,
      xaxislinecolor,
      yaxislinecolor,
      labelFontColor,
      labelFontSize,
      rotateLabels,
      yAxisValueFontColor,
      yAxisValueFontSize,
      labelStep,
      numbersuffix,
      legendItemFont,
      anchorRadius,
    },
    show = false,
    showAxisIcons = {
      xAxis: false,
      yAxis: false,
    },
    graphBackgroundColor,
    updatedWidth,
    isLoading = false,
  } = props;
  return !isLoading ? (
    <div className={type || ""}>
      {graphData?.dataset?.length ||
      graphData?.data ||
      (type === "column2d" && graphData?.length) ? (
        <FusionChart
          xAxisName={showAxisIcons?.xAxis ? xAxisName : ""}
          yAxisName={showAxisIcons?.yAxis ? yAxisName : ""}
          graphData={graphData}
          chartType={type}
          legendPosition={legendPosition}
          showLegend={showLegend}
          show={show}
          legendRight={legendRight}
          legendCaption={legendCaption}
          graphLabel={graphLabel}
          legendBorderThickness="700px"
          legendIconSides={legendIconSides}
          drawCustomLegend={1}
          xAxisNameFontBold={showAxisIcons?.xAxisNameFontBold}
          showLabels={showLabels}
          prefix={prefix}
          plotToolText={tooltip}
          palettecolors={palettecolors}
          bgColor={graphBackgroundColor}
          height={height}
          numberPrefix={numberPrefix}
          hideScroll={hideScroll}
          numVDivLines={numVDivLines}
          plotSpacePercent={plotSpacePercent}
          maxLabelHeight={maxLabelHeight}
          decimals={decimals}
          chartBottomMargin={chartBottomMargin}
          divLineDashed={divLineDashed}
          divLineColor={divLineColor}
          xaxislinecolor={xaxislinecolor}
          yaxislinecolor={yaxislinecolor}
          labelFontColor={labelFontColor}
          labelFontSize={labelFontSize}
          rotateLabels={rotateLabels}
          yAxisValueFontColor={yAxisValueFontColor}
          yAxisValueFontSize={yAxisValueFontSize}
          width={updatedWidth}
          labelStep={labelStep}
          numbersuffix={numbersuffix}
          legendItemFont={legendItemFont}
          anchorRadius={anchorRadius}
        />
      ) : (
        <div className="no_data">
          <h5>No Data Available</h5>
        </div>
      )}
    </div>
  ) : null;
}

export default Chart;
