const actions = {
  SidebarListRenderAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  SidebarToggleAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  SaveUserDataAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  ErrorAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  AccountDateAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  DashboardActions(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  BudgetAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  BucketNameAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  ExternalIDAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  LensBucketNameAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  CkAutoEnabledAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  CkAutoEnableRiOpsAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  CkAutoCustomerMetaDataAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  DragAndDropDataAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  SwitchRoleButtonAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  ModifyRoutes(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  DefaultPathAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  MarketPlaceBulkUpload(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  ComputeHourlyAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  FileDownloaderAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  CommentsAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
  TunerFeaturesAction(action, payload) {
    return {
      type: action,
      payload,
    };
  },
};
export default actions;
