import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TooltipPopup from "../../../Common/Tooltip";
import { noop } from "../../../../Utils/commonUtils";

export default function Text({
  text,
  size,
  type,
  hr = false,
  className = "",
  label,
  tooltipOtherStyles = {},
  onClickHandler = noop,
  tooltipPlacement = "bottom",
  iconSize = "small",
  iconColor = "#0A3CA2",
  title = "",
  labelClass,
  whiteTheme,
  tooltipStyle,
  optional,
  disabled,
  additionalElement,
  children,
}) {
  return (
    <>
      {text && (
        <p
          aria-hidden
          onClick={onClickHandler}
          className={`text-field ${size} ${className} ${type} ${labelClass} mt-2.5 ${
            disabled && "disabled"
          }
          `}
          title={title}
        >
          {text}
          {optional && <span className="text-[11px] text-[#898A90]"> (Optional)</span>}
          {children}
          {label && (
            <TooltipPopup
              style={
                whiteTheme
                  ? tooltipStyle
                  : {
                      color: "white",
                      background:
                        "transparent linear-gradient(283deg, #343D57 0%, #656C80 100%) 0% 0% no-repeat padding-box",
                      boxShadow: "-5px 6px 7px #343D572B",
                      borderRadius: "25px",
                      padding: "3px 6px",
                      lineHeight: "1",
                      fontSize: "9px",
                      ...tooltipOtherStyles,
                    }
              }
              placement={tooltipPlacement}
              content={() => label}
              icon={
                <InfoOutlinedIcon
                  style={{ color: iconColor }}
                  className={`${iconSize}`}
                  color="primary"
                />
              }
            />
          )}
        </p>
      )}
      {additionalElement}
      {hr && <hr className=" border-[#E6E6E6]" />}
    </>
  );
}
