import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import Tooltip from "@mui/material/Tooltip";

function SummaryCards(props) {
  const { summaryCardsData = {}, className = "" } = props;

  return (
    <div className={`flex gap-[5px] ${className}`}>
      {Object.keys(summaryCardsData?.count || {})?.map((category) => {
        const subItems = summaryCardsData?.dataList?.[category] || {};
        const actualSubItems = Object.keys(subItems || {});
        const expectedCount = summaryCardsData?.count?.[category] || 0;

        return (
          <div key={category} className="data-transfer-summary-cards">
            <div className="summary-cards-heading">{category}</div>
            <div className="summary-cards-content">
              {Array.from({ length: expectedCount })?.map((_, index) => {
                const subItem = actualSubItems?.[index] || "--";
                const value = subItems?.[subItem]?.value ?? "--";
                const textRef = useRef(null);
                const [showTooltip, setShowTooltip] = useState(false);

                useEffect(() => {
                  if (textRef.current) {
                    const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
                    setShowTooltip(isOverflowing);
                  }
                }, [subItem]);

                return (
                  <div
                    key={_}
                    style={{ width: `calc(100% / ${expectedCount})` }}
                    className={`summary-cards-item-container ${
                      index < expectedCount - 1 ? "divider" : ""
                    }`}
                  >
                    <Tooltip title={showTooltip ? subItem : ""} placement="top" arrow>
                      <div className="summary-cards-item" ref={textRef}>
                        {subItem}
                      </div>
                    </Tooltip>
                    <div className="summary-cards-amount">{value}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SummaryCards;
