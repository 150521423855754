import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function Counter({ onInputChange, currentValue, countName = "" }) {
  return (
    <div className="relative counter-wrapper d-flex">
      <div className="counter-btn subtract">
        <button
          className={`sub-button ${currentValue === 1 ? "disable" : ""}`}
          type="button"
          onClick={() => {
            onInputChange({ name: countName, value: Number(currentValue) - 1 }, "subtract");
          }}
        >
          <RemoveIcon className="input_icon right-0" />
        </button>
      </div>

      <input
        onChange={(e) => onInputChange({ name: countName, value: e.target.value }, "input")}
        value={currentValue}
        className=" text-center !px-[8px] !h-[23px] w-[50px] !mb-[0px]"
        name="cartValue"
        type="number"
      />
      <div className="counter-btn add">
        <button
          className={`add-button ${currentValue > 98 ? "disable" : ""}`}
          type="button"
          onClick={() => {
            onInputChange({ name: countName, value: Number(currentValue) + 1 }, "add");
          }}
        >
          <AddIcon className="input_icon" />
        </button>
      </div>
    </div>
  );
}

export default Counter;
