import commonService from "../Services/commonService";
import { isSignUpEnabled, getEncryptedData } from "./commonUtils";
import { preloadImages } from "./userUtils";
import { updatePartnerFavicon } from "../Components/Login/utils";

function setLoadingTrue(openFor, updateState) {
  if (openFor === "Login" || openFor === "Signup") {
    updateState({ isLoadingLogo: true });
  } else {
    updateState({ isLoadingLogo: true, isLoading: true });
  }
}

function setLoadingFalse(openFor, refreshReCaptchaHandler, updateState) {
  let timer = 0;
  if (openFor === "Login") {
    timer = setInterval(() => {
      refreshReCaptchaHandler();
    }, 100 * 1000);
    updateState({ isLoadingLogo: false, captchaTimer: timer });
  }
}

// eslint-disable-next-line max-statements
async function callAPI(CkAutoEnabledAction, openFor, updateState) {
  const result = await commonService.partnerCreateUser();
  const partnerDisplayName =
    result?.data?.data?.displayName || result?.data?.data?.partnerName || "";
  const SIGNUP_ENABLED = !!isSignUpEnabled(result?.data?.data);
  CkAutoEnabledAction("SIGNUP_ENABLED", SIGNUP_ENABLED);
  if (openFor === "Login" || openFor === "Signup") {
    CkAutoEnabledAction("IS_CK_AUTO_ENABLED", result?.data?.data?.isCkAutoEnabled);
  }
  const partnerLogo = result?.data?.data?.logo;
  if (partnerLogo) {
    await preloadImages(partnerLogo);
  }
  // temporary change for Azure UI
  if (openFor === "Login") {
    localStorage?.setItem(
      "partnerModuleNames",
      getEncryptedData(result?.data?.data?.partnerModuleNames || [])
    );
  }
  const favIcon = {
    data: {
      data: result?.data?.data?.favicon,
    },
  };
  updatePartnerFavicon(favIcon);
  const stateObj = {
    partnerLogo,
    signUpCheck: !!isSignUpEnabled(result?.data?.data),
    partnerDisplayName,
  };
  if (openFor === "Login" || openFor === "Signup") {
    stateObj.htmlContent = result?.data?.data?.htmlContent;
    stateObj.isCkAuto = result?.data?.data?.isCkAutoEnabled;
    stateObj.isTunerEnabled = result?.data?.data?.isTunerEnabled;
    stateObj.signUpEnabled = result?.data?.data?.isSignupEnabled;
    stateObj.isMicrosoftEnabled = result?.data?.data?.partnerModuleNames?.includes("sso");
    stateObj.isLens =
      result?.data?.data?.isLegacyLensEnabled || result?.data?.data?.isAzureInsightsEnabled;
    stateObj.isTunerModuleEnabled = result?.data?.data?.isTunerModuleEnabled;
    stateObj.isGoogleEnabled = result?.data?.data?.partnerModuleNames?.includes("sso-google");
    stateObj.partnerName = result?.data?.data?.partnerName;
  }
  updateState(stateObj);
  return partnerDisplayName;
}

const loginUtil = async (
  CkAutoEnabledAction,
  navigate,
  updateState,
  openFor,
  route,
  refreshReCaptchaHandler
) => {
  let partnerDisplayNameVar = null;
  try {
    if (process.env.REACT_APP_BASE_URL !== window.location.origin) {
      // api call partner logo
      setLoadingTrue(openFor, updateState);
      partnerDisplayNameVar = await callAPI(CkAutoEnabledAction, openFor, updateState);
    } else {
      updatePartnerFavicon();
    }
  } catch (error) {
    if (openFor !== "Singup") {
      updatePartnerFavicon();
    }
    navigate(route);
  } finally {
    setLoadingFalse(openFor, refreshReCaptchaHandler, updateState);
  }
  return partnerDisplayNameVar;
};

export default loginUtil;
