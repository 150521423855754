import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import Form from "../../FormConfig/Form";

import "./style.scss";
import commonService from "../../../Services/commonService";
import { getForgetPassPayload, getWrapperClass } from "../utils";
import withRouter from "../../Common/WithRouter";
import Routes from "../../../Config/Routes";
import Button from "../../FormConfig/Form/Components/Button";
import { getToast } from "../../../Utils/commonUtils";
import PageTitle from "../../Common/PageTitle";
import actions from "../../../Actions";
import PublicFooter from "../../CK-Auto/Common/PublicFooter";
import Loader from "../../Common/Loader";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../../Services/Strapi/urls";
import loginUtil from "../../../Utils/publicPageUtils";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isLoading: false,
      partnerLogo: "",
      isLoadingLogo: false,
      isMailSent: false,
      signUpCheck: props.ckAutoEnabled,
      allRightsFooter: "",
      partnerDisplayName: "",
      strapiDataState: {},
    };
  }

  async componentDidMount() {
    const {
      props: {
        navigate,
        actions: { CkAutoEnabledAction },
      },
    } = this;
    try {
      this.setState({ isLoading: true });
      const response = await Promise.all([
        this.getStrapiData(),
        loginUtil(
          CkAutoEnabledAction,
          navigate,
          this.updateState,
          "ForgotPassword",
          Routes.PAGENOTFOUND,
          this.refreshReCaptchaHandler
        ),
      ]);
      const [strapiData, partnerDisplayNameVar] = response;
      if (partnerDisplayNameVar) {
        this.setState({
          allRightsFooter: `${partnerDisplayNameVar} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`,
        });
      } else {
        this.setState({
          allRightsFooter: `${strapiData?.pageFooter?.pretext} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`,
        });
      }
      this.setState({ strapiDataState: strapiData, isLoading: false, isLoadingLogo: false });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getStrapiData = async () => {
    const data = await fetchStrapiData(strapiUrls.FORGOT_PASSWORD);
    return data;
  };

  updateState = (stateData) => {
    this.setState(stateData);
  };

  handleSubmit = async (values) => {
    try {
      this.setState({ isLoading: true });
      const payload = getForgetPassPayload(values);
      await commonService.handleForgetPassword(payload);
      getToast("success", "A mail has been sent to your email address");
      this.setState({ isLoading: false, isMailSent: true });
    } catch (err) {
      this.setState({ isLoading: false, isMailSent: false });
      // getToast("error", err.response.data.message);
    }
  };

  render() {
    const {
      handleSubmit,
      state: {
        isLoading,
        isError,
        partnerLogo,
        isLoadingLogo,
        isMailSent,
        signUpCheck,
        allRightsFooter,
        partnerDisplayName,
        strapiDataState: { formComponent: { data: { attributes } = {} } = {} } = {},
        strapiDataState: { AfterFormSuccess: [afterFormSuccess] = [] } = {},
        strapiDataState,
      },
    } = this;

    const emailValidationsClone = { ...attributes?.form?.[0] };
    emailValidationsClone.validations = {
      rules: attributes?.form?.[0]?.rules,
      messages: attributes?.form?.[0]?.messages,
    };

    return isLoading ? (
      <Loader />
    ) : (
      <div
        className={`logIn-mainContainer  ${getWrapperClass(
          "login_wrapper_Without_bg",
          signUpCheck
        )}`}
      >
        <div
          className={`login_wrapper public_module ${getWrapperClass(
            "login_Without_bg",
            signUpCheck
          )}`}
        >
          <PageTitle
            pageTitle={strapiDataState?.heading}
            customPartnerDisplayName={partnerDisplayName}
            pageHeader={signUpCheck}
          />
          <div className="logo-container">
            <div className={` ${partnerLogo && " logoWrapper "} logo`}>
              {!isLoadingLogo && (
                <img
                  src={`${
                    partnerLogo ||
                    `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`
                  }`}
                  alt="logo"
                />
              )}
            </div>
          </div>
          <div className="login-form-wrapper">
            <div className={`login_form ${isMailSent && "email-sent"}`}>
              {!isMailSent ? (
                <>
                  <h1>{strapiDataState?.heading}</h1>
                  <p className="text-secondary text-xs"> {strapiDataState?.description}</p>

                  <Form
                    isError={(value) => this.setState({ isError: value })}
                    formConfig={[emailValidationsClone]}
                    handleSubmit={handleSubmit}
                    formValues={{}}
                  >
                    <Button
                      text={attributes?.form?.[1]?.label}
                      className={attributes?.form?.[1]?.className}
                      disabled={isError || isLoading}
                      isLoading={isLoading}
                      type={attributes?.form?.[1]?.type}
                    />
                    <div className="return-signin">
                      <span className="text-secondary font-medium !text-[#7C7C7C]">
                        {strapiDataState?.routelink?.pretext}{" "}
                      </span>
                      <Link to={strapiDataState?.routelink?.href}>
                        {strapiDataState?.routelink?.hypertext}
                      </Link>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <img
                    src={`${strapiBasePath}${afterFormSuccess?.image?.data?.attributes?.url}`}
                    alt="email sent"
                  />
                  <p className="email-sent-info one">{afterFormSuccess?.title}</p>
                  <p className="email-sent-info two">{afterFormSuccess?.description}</p>
                  <Link
                    className="btn-filled login-btn forgot-return-btn"
                    to={afterFormSuccess?.link?.href}
                  >
                    {afterFormSuccess?.link?.hypertext}
                  </Link>
                </>
              )}
            </div>
          </div>
          {!signUpCheck && <div className="footer-text">{allRightsFooter}</div>}
        </div>
        <PublicFooter
          show={signUpCheck}
          leftText={false}
          allRightsFooter={allRightsFooter}
          strapiData={strapiDataState?.publicFooter}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  ckAutoEnabled: state.CkAutoReducer.signupEnabled,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgetPassword));
