const initialState = {
  errorCode: "",
  errorMessage: "",
};
const initAction = { type: "", payload: {} };

export default function ErrorReducer(state = initialState, action = initAction) {
  switch (action.type) {
    case 200:
      return { errorCode: "" };
    case 401:
    case 403:
    case 404:
    case 500:
    case 503:
    case 786:
    case 709:
    case 715:
    case 785:
    case 418:
      return { errorCode: action.payload.status, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
}
