import React from "react";
import { Link } from "react-router-dom";
import { TabV2, TabsV2 } from "../../../Common/TabsV2";
import "./style.scss";

export default function TabWrapper(props) {
  const {
    isLoading,
    tabs,
    tabClicked,
    selected,
    pathName,
    tooltip = [],
    isRoundedCorner = false,
    showTabInUrl = false,
    showMonthYear = false,
    tabKey = "selected",
  } = props;
  const tabList = tabs?.map((item) => item?.label);
  const data = new URLSearchParams(window.location.search);
  const query = `month=${data.get("month")}&year=${data.get("year")}`;

  const getSearchParam = (item) => {
    if (showMonthYear && showTabInUrl) {
      return `${query}&${tabKey}=${item?.label}`;
    }
    if (showMonthYear) {
      return `${query}`;
    }
    if (showTabInUrl) {
      return `${tabKey}=${item?.label}`;
    }
    return "";
  };

  return (
    <TabsV2
      disabled={isLoading}
      isRoundedCorner={isRoundedCorner}
      wrapperClass="cudos-tabs-wrapper"
    >
      {tabs.map((item, index) => (
        <Link
          to={{
            pathname: pathName,
            search: getSearchParam(item),
          }}
        >
          <TabV2
            onClick={(e) => {
              tabClicked(item, index, e);
            }}
            selectedTab={selected}
            tab={item?.label}
            index={index}
            tabs={tabList}
            customClass="!h-[36px] flex items-center"
            tooltip={tooltip?.length ? tooltip?.[index] || "" : ""}
          >
            <div className="flex !h-[36px]!p-[9px 14px] justify-center items-center gap-2">
              <div className="">{item?.label}</div>
            </div>
          </TabV2>
        </Link>
      ))}
    </TabsV2>
  );
}
