import React, { useEffect, useRef, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import EasyCrop from "../EasyCrop";
import { readFile } from "../Crop";
import Text from "./Text";
import { renturnPartnerEmailTooltipStyle } from "../../../../Utils/commonUtils";

// eslint-disable-next-line max-lines-per-function
export default function UploadFile({
  text,
  handleChange,
  name,
  error,
  values,
  width,
  preview,
  disabled,
  manageLogos,
  tooltip,
  logo,
  touched,
  sourceKeyURL = "",
}) {
  const inputElement = useRef();
  const value = values[name];
  const [image, setImage] = useState(null);
  const [source, setSource] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleImageUpload = async (e) => {
    const {
      target: { files = [] },
    } = e;

    if (files?.length) {
      const imageDataUrl = await readFile(files[0]);
      setImage(imageDataUrl);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const renderImage = async () => {
      if (!value?.size && manageLogos) {
        setSource(values[sourceKeyURL]);
      } else if (value instanceof Blob || value instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setSource(e.target.result);
        };
        reader.readAsDataURL(value);
      } else {
        setSource(null);
      }
    };

    renderImage();
  }, [value]);

  const onChange = (event) => {
    const {
      target: { name: targetName, files },
    } = event;

    handleChange({
      target: {
        name: targetName,
        value: files[0],
      },
    });
  };

  const removeFile = () => {
    const { current } = inputElement;
    current.value = "";

    onChange({ target: current });
  };

  // eslint-disable-next-line complexity
  const renderUploadButton = () => (
    <div className="flex items-center gap-x-2">
      <span
        className={`text-[14px] ${width} relative items-center justify-between flex bg-[#F8F8F8] border border-solid border-[#DEDEDE] px-3 py-3 rounded font-medium text-black ${
          (value && "flex") || "hide"
        } ${error && touched && "error_box"} partnerWidth`}
      >
        <span className="Title" title={value?.name}>
          {value?.name}
        </span>
        <span
          className={`${
            manageLogos
              ? "absolute right-[-38px] display_flex w-8 h-8 rounded bg-[#0A3CA2]"
              : "bg-white"
          } ${error && touched && manageLogos && "bg-[red]"}`}
        >
          <MdClear
            // eslint-disable-next-line no-nested-ternary
            style={{ color: manageLogos ? "white" : error ? "red" : "#0A3CA2" }}
            className="cursor-pointer"
            onClick={removeFile}
            fontSize="large"
          />
        </span>
      </span>

      <label
        className={` button flex items-center gap-x-2 rounded ${(!value && "flex") || "hide"} ${
          manageLogos ? "px-4 text-[13px] !font-medium primary" : "small hover-solid !bg-[#F0F6FF]"
        }`}
        htmlFor={name}
      >
        <FiUpload />
        {manageLogos ? `Choose ${logo.header}` : text}
        <input
          ref={inputElement}
          id={name}
          name={name}
          className="hidden"
          onChange={handleImageUpload}
          type="file"
          disabled={disabled}
        />
      </label>

      {!value && !manageLogos && (
        <span className="text-[10px] text-black leading-[140%] max-w-[210px]">
          Max file size is 500KB. Supported file types are .JPEG, .PNG & .SVG.
        </span>
      )}
    </div>
  );

  return (
    <div
      className={`upload-input-wrapper relative mb-[10px] ${disabled && "disabled"} ${
        manageLogos ? "manage-logos" : ""
      }`}
    >
      {manageLogos ? (
        <div className="flex gap-x-5">
          <div className="image-container display_flex">
            {value ? (
              <div
                className={
                  logo.header !== "Logo"
                    ? " !h-[16px] w-[16px] loadingAnimation animating"
                    : "h-[42px] w-[160px] loadingAnimation animating"
                }
              >
                <img
                  name
                  className={`${logo.header !== "Logo" ? "w-4 h-4" : "h-[42px]"} hide`}
                  src={source}
                  alt={logo.header}
                  onLoad={(event) => {
                    event.target.parentNode.classList.remove("animating");
                    event.target.classList.remove("hide");
                  }}
                />
              </div>
            ) : (
              <div className={`image-alt ${logo.header}`}>
                {logo.header === "Logo" && <span>Product / Platform Logo</span>}
              </div>
            )}
          </div>
          <div className="info-container">
            <Text
              label={tooltip.text}
              tooltipPlacement={tooltip.placement}
              text={logo.header}
              iconSize="big"
              type="bold"
              tooltipOtherStyles={renturnPartnerEmailTooltipStyle()}
              className="!mt-1"
            />
            <p className="text-xs mb-1">
              <span className="font-medium text-black">Supported picture format(s) : </span>
              {logo.supported}
            </p>
            <p className="text-xs mb-3">
              <span className="font-medium text-black">Recommended picture size : </span>
              {logo.recommended}
            </p>
            {renderUploadButton()}
          </div>
        </div>
      ) : (
        renderUploadButton()
      )}
      {showModal && (
        <EasyCrop
          open={showModal}
          handleClose={(removeExistingFile = false) => {
            setShowModal(false);
            setImage(null);
            if (removeExistingFile) removeFile();
          }}
          handleUpload={(croppedImage) => {
            const blob = croppedImage;
            if (!croppedImage.name) blob.name = inputElement?.current?.files[0]?.name;

            onChange({
              target: {
                name,
                files: [blob],
              },
            });
          }}
          image={image}
          preview={preview}
          useOriginal={inputElement?.current?.files[0]}
        />
      )}
      {error && touched && (
        <p className={`error_message ${manageLogos && "left-[220px]"}`}>{error}</p>
      )}
    </div>
  );
}
