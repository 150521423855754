import axiosInstance from "./AxiosInstance";

export const get = (url, queryParams, options) =>
  axiosInstance.get(url, { params: queryParams, ...options });

export const post = (url, body, params) => axiosInstance.post(url, body, params);

export const put = (url, body, queryParams) => axiosInstance.put(url, body, queryParams);

export const deleteAPI = (url, body, queryParams) => axiosInstance.delete(url, body, queryParams);

export const patch = (url, body, queryParams) => axiosInstance.patch(url, body, queryParams);
