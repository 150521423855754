const useFilterModalPosition = (event, elementId) => {
  let positionY = 0;
  let positionX = 0;

  const xLocation = event?.pageX;
  const divElement = document.getElementById(elementId);

  if (divElement) {
    const divRect = divElement.getClientRects()[0];
    const divHeight = divRect?.y ?? 0;
    const hiddenHeight = window.innerHeight - divHeight;

    if (hiddenHeight < 290) {
      positionY = divHeight - hiddenHeight + 80;
    } else {
      positionY = divRect?.y;
    }

    const divWidth = xLocation ?? 0;
    const hiddenWidth = window.innerWidth - divWidth;

    if (hiddenWidth < 193) {
      positionX = divWidth - hiddenWidth - 110;
    } else {
      positionX = xLocation;
    }
  }
  return { positionX, positionY };
};

export default useFilterModalPosition;
