import patterns from "../../../../FormConfig/Form/patterns";

export const defaultFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  companyName: "",
};

export const formInputFeilds = [
  {
    label: "First Name",
    name: "firstName",
    value: "Cloud",
    type: "text",
    parentClass: "input-class",
    regex: patterns.firstOrLastNameRegex,
    errorMessage: "Please enter a valid first name",
    disabled: false,
  },
  {
    label: "Last Name",
    name: "lastName",
    value: "Keeper",
    type: "text",
    parentClass: "input-class",
    regex: patterns.firstOrLastNameRegex,
    errorMessage: "Please enter a valid last name",
    disabled: false,
  },
  {
    label: "Company Name",
    name: "companyName",
    value: "CloudKeeper",
    type: "text",
    parentClass: "input-class",
    regex: patterns.domainRegex,
    errorMessage: "Please enter a valid company name",
    disabled: false,
  },
  {
    label: "Email ID",
    name: "email",
    value: "Doe",
    parentClass: "input-class disabled",
    regex: patterns.email,
    errorMessage: "Please enter a valid email id",
    disabled: true,
  },
];
