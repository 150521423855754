import React from "react";
import { Checkbox } from "@mui/material";
import { getCurrencySymbol } from "../../../../Utils/commonUtils";

export const VRIdata = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
    },
    {
      Header: "Payment Option",
      accessor: "paymentTerm",
      sortedIcon: true,
    },
    {
      Header: "Offering Class",
      accessor: "offeringClass",
      sortedIcon: true,
    },
    {
      Header: `Upfront Price(${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
    },
    {
      Header: `Hourly Rate(${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
    },
    {
      Header: `Effective Rate(${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
    },
    {
      Header: "Desired Quantity",
      accessor: "actions",
      icons: true,
    },
  ],
};

export const ec2 = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: "Offering Class",
      accessor: "offeringClass",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: `Upfront Price (${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: `Hourly Price (${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: `Effective Price (${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: "Quantity",
      accessor: "actionsInput",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: "Action",
      accessor: "actions",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-45%)/5)]",
    },
  ],
};

export const RDS = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: "Deployment Option",
      accessor: "deploymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[15%]",
    },
    {
      Header: `Upfront Price (${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: `Hourly Price (${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: `Effective Price (${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: "Quantity",
      accessor: "actionsInput",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-45%)/5)]",
    },
    {
      Header: "Action",
      accessor: "actions",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-45%)/5)]",
    },
  ],
};

export const elastiCache = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: `Upfront Price (${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Hourly Price (${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Effective Price (${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Quantity",
      accessor: "actionsInput",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Action",
      accessor: "actions",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
  ],
};

export const openSearch = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: `Upfront Price (${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Hourly Price (${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Effective Price (${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Quantity",
      accessor: "actionsInput",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Action",
      accessor: "actions",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
  ],
};

export const redshift = {
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
      hideFilters: true,
      headerClass: "w-[20%]",
    },
    {
      Header: `Upfront Price (${getCurrencySymbol()})`,
      accessor: "upfrontPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Hourly Price (${getCurrencySymbol()})`,
      accessor: "hourlyPrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: `Effective Price (${getCurrencySymbol()})`,
      accessor: "effectivePrice",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "text-right",
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Quantity",
      accessor: "actionsInput",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
    {
      Header: "Action",
      accessor: "actions",
      icons: true,
      hideFilters: true,
      headerClass: "w-[calc((100%-40%)/5)]",
    },
  ],
};

export const savingsPlan = (type, getData = []) => {
  if (type === "Compute Savings Plans") {
    return {
      headers: [
        {
          Header: "Term",
          accessor: "term",
          sortedIcon: true,
        },
        {
          Header: "Payment Option",
          accessor: "paymentOption",
          sortedIcon: true,
        },
        {
          Header: `Upfront Price(${getCurrencySymbol()})`,
          accessor: "upfrontPrice",
          sortedIcon: true,
        },
        {
          Header: `Hourly Charges(${getCurrencySymbol()})`,
          accessor: "hourlyCommitments",
          sortedIcon: true,
        },
        {
          Header: `Effective Rate(${getCurrencySymbol()})`,
          accessor: "effectivePrice",
          sortedIcon: true,
        },
        {
          Header: "Upfront Price",
          accessor: "upfrontPrice",
          sortedIcon: true,
        },
        {
          Header: "Monthly Payment",
          accessor: "monthlyPayment",
          sortedIcon: true,
        },
        {
          Header: "Total Cost",
          accessor: "totalCost",
          sortedIcon: true,
        },
      ],
      data: getData,
    };
  }

  return {
    headers: [
      {
        Header: "Term",
        accessor: "term",
        sortedIcon: true,
      },
      {
        Header: "Payment Option",
        accessor: "paymentOption",
        sortedIcon: true,
      },
      {
        Header: `Upfront Price(${getCurrencySymbol()})`,
        accessor: "upfrontPrice",
        sortedIcon: true,
      },
      {
        Header: `Hourly Rate(${getCurrencySymbol()})`,
        accessor: "hourlyPrice",
        sortedIcon: true,
      },
      {
        Header: "Region",
        accessor: "region",
        sortedIcon: true,
      },
      {
        Header: `Effective Rate(${getCurrencySymbol()})`,
        accessor: "effectivePrice",
        sortedIcon: true,
      },
    ],
    data: getData,
  };
};

export const security = () => ({
  headers: [
    {
      Header: "Term",
      accessor: "term",
      sortedIcon: true,
    },
    {
      Header: "Payment Option",
      accessor: "paymentOption",
      sortedIcon: true,
    },
    {
      Header: "Monthly Commitments",
      accessor: "monthlyCommitments",
      sortedIcon: true,
    },
  ],
});

export const VRSearchPayload = {
  region: null,
  paymentOption: null,
  term: null,
  duration: null,
  savingsPlanType: null,
  hourlyCommitments: null,
  rdsProductDescription: null,
  rds: null,
  deploymentOption: null,
  redshift: null,
  openSearch: null,
  elastiCacheEngine: null,
  elastiCache: null,
  ec2: null,
  operatingSystem: null,
  tenancy: null,
  offeringClass: null,
};

const renderCheckboxHeader = (getData, handleChange) => (
  <div className="text-field cppTextField text-left">
    <Checkbox
      name="name"
      id="name"
      checked={
        getData.length === getData.filter((item) => item.checked).length && getData.length !== 0
      }
      onChange={(event) => handleChange(event, getData, "selectAll")}
    />
    <span>
      <label htmlFor="name">Account(s)</label>
    </span>
  </div>
);

const VIEW_CART_RDS_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Multi AZ",
    accessor: "multiAZ",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: `Upfront Price(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },

  {
    Header: `Hourly Price(${getCurrencySymbol()})`,
    accessor: "hourlyPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Quantity",
    accessor: "actionsInput",
    icons: true,
    headerClass: "whitespace-nowrap w-[10%]",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VIEW_CART_ELASTICACHE_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: `Upfront Price(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },

  {
    Header: `Hourly Price(${getCurrencySymbol()})`,
    accessor: "hourlyPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Quantity",
    accessor: "actionsInput",
    icons: true,
    headerClass: "whitespace-nowrap w-[10%]",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VIEW_CART_OPEN_SEARCH_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: `Upfront Price(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },

  {
    Header: `Hourly Price(${getCurrencySymbol()})`,
    accessor: "hourlyPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: "Quantity",
    accessor: "actionsInput",
    icons: true,
    headerClass: "whitespace-nowrap w-[10%]",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VIEW_CART_REDSHIFT_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: `Upfront Price(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },

  {
    Header: `Hourly Price(${getCurrencySymbol()})`,
    accessor: "hourlyPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "text-right",
    hideFilters: true,
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Quantity",
    accessor: "actionsInput",
    icons: true,
    headerClass: "whitespace-nowrap w-[10%]",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VIEW_CART_SAVING_PLAN_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Instance Family",
    accessor: "savingsPlanFamily",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    bodyClass: "!normal-case",
    hideFilters: true,
  },
  {
    Header: `Hourly Rate(${getCurrencySymbol()})`,
    accessor: "hourlyCommitments",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: `Upfront Payment(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: `Monthly Payment(${getCurrencySymbol()})`,
    accessor: "monthlyPayment",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: `Total Cost(${getCurrencySymbol()})`,
    accessor: "totalCost",
    icons: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VIEW_CART_EC2_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Item(s) to order",
    accessor: "item",
    sortedIcon: false,
    headerClass: "whitespace-nowrap",
    bodyClass: "w-90% !normal-case",
    hideFilters: true,
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Offering Class",
    accessor: "offeringClass",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: `Upfront Price(${getCurrencySymbol()})`,
    accessor: "upfrontPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: `Hourly Price(${getCurrencySymbol()})`,
    accessor: "hourlyPrice",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
    bodyClass: "text-right",
  },
  {
    Header: "Start Date (UTC)",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },

  {
    Header: "Quantity",
    accessor: "actionsInput",
    icons: true,
    headerClass: "whitespace-nowrap w-[10%]",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

const VIEW_CART_CSSB_HEADERS = (getData, handleChange) => [
  {
    Header: renderCheckboxHeader(getData, handleChange),
    accessor: "selectedAccount",
    sortedIcon: false,
    bodyClass: "!normal-case",
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: `Monthly Commitments(${getCurrencySymbol()})`,
    accessor: "monthlyCommitments",
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: `Total Cost(${getCurrencySymbol()})`,
    accessor: "totalCostCloudfront",
    icons: false,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Start Date",
    accessor: "calender",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
    headerClass: "whitespace-nowrap",
    hideFilters: true,
  },
];

export const VRICart = (handleChange, onActionClickHandler, getData = [], selectedConfig = {}) => {
  const { service } = selectedConfig;
  let headers = [];
  switch (service?.value) {
    case "rds":
      headers = VIEW_CART_RDS_HEADERS(getData, handleChange);
      break;
    case "elasticache":
      headers = VIEW_CART_ELASTICACHE_HEADERS(getData, handleChange);
      break;
    case "opensearch":
      headers = VIEW_CART_OPEN_SEARCH_HEADERS(getData, handleChange);
      break;
    case "redshift":
      headers = VIEW_CART_REDSHIFT_HEADERS(getData, handleChange);
      break;
    case "savings-plan":
      headers = VIEW_CART_SAVING_PLAN_HEADERS(getData, handleChange);
      break;
    case "ec2":
      headers = VIEW_CART_EC2_HEADERS(getData, handleChange);
      break;
    case "cssb":
      headers = VIEW_CART_CSSB_HEADERS(getData, handleChange);
      break;
    default:
      break;
  }
  return {
    headers,
    onIconClick: onActionClickHandler,
    data: getData,
  };
};

export const servicesList = {
  message: "Request processed successfully.",
  status: 200,
  data: {
    services: [
      {
        label: "EC2",
        value: "ec2",
      },
      {
        label: "RDS",
        value: "rds",
      },
      {
        label: "ElastiCache",
        value: "elasticache",
      },
      {
        label: "OpenSearch",
        value: "opensearch",
      },
      {
        label: "Redshift",
        value: "redshift",
      },
      {
        label: "Savings Plan",
        value: "savings-plan",
      },
      {
        label: "Cloudfront Security Savings Bundle",
        value: "cssb",
      },
    ],
  },
};

export const ServiceEnum = {
  EC2: "ec2",
  RDS: "rds",
  REDSHIFT: "redshift",
  ELASTICACHE: "elasticache",
  OPENSEARCH: "opensearch",
  SAVINGSPLAN: "savings-plan",
};
