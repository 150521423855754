export const headingTexts = {
  "ck-auto": {
    heading: "Sign-up to view your potential savings",
    subHeading: "Quick and easy onboarding in just 5 minutes!",
  },
  default: {
    heading: "Sign up",
    subHeading: "Please enter your details to get started",
  },
};

export const isCkAutoOrLens = (isCkAuto, isLens) => isCkAuto || isLens;

export const platformSpecificEvent = ({ isCkAuto, isLens }) => {
  const events = {
    ckauto: { event: "ckauto_signup", form_id: "form_ckauto_signup" },
    lens: { event: "cklens_signup", form_id: "form_cklens_signup" },
  };

  if (isCkAuto) {
    return events.ckauto;
  }
  if (isLens) {
    return events.lens;
  }
  return null;
};
