/* eslint-disable max-lines-per-function */
import { FormControlLabel, FormHelperText, Radio, Tooltip, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import Text from "./Text";
import selectedIcon from "../../../../Assets/icons/selected.svg";
import { ReactComponent as InfoIcon } from "../../../../Assets/images/ic-info.svg";

const getClassName = ({
  option,
  renderTooltipStructure,
  error,
  touched,
  name,
  value,
  tabsStyle,
  styleCheck,
  options,
  index,
  disabled,
}) =>
  `buttonStyle ${renderTooltipStructure ? "withTooltip" : ""} ${
    error && touched && "error_box"
  } ${name} ${option?.value === value && "selected"} ${tabsStyle ? "tabsStyle" : ""} ${
    styleCheck && options?.[index + 1]?.value === value ? "!border-r-0" : ""
  } ${styleCheck && options?.[index - 1]?.value === value ? "!border-l-0" : ""} ${
    disabled || option?.disabled ? "disabled" : ""
  }`;

export default function RadioButtonGroup({
  handleChange,
  value,
  name,
  error,
  className,
  childClassName,
  options,
  topLabel,
  buttonStyle,
  touched,
  onBlurHandler,
  disabled,
  isEditable = true,
  whiteTheme,
  tooltipStyle,
  tabsStyle,
  renderTooltipStructure,
  infoRequired = false,
  isButtonRequired = false,
  buttonClickHandler,
}) {
  const [optionSelected, setOptionSelected] = useState(value);

  useEffect(() => {
    if (value !== optionSelected) {
      setOptionSelected(value);
    }
  }, [value]);

  const handleSelect = (e, option) => {
    setOptionSelected(option.value);
    handleChange({ target: { value: option.value, name } });
  };

  const renderCustomisedRadioButtons = (option, index) => {
    const styleCheck = tabsStyle && options?.length > 2;
    return (
      <div
        aria-hidden="true"
        onClick={(e) => handleSelect(e, option)}
        className={getClassName({
          option,
          renderTooltipStructure,
          error,
          touched,
          name,
          value,
          tabsStyle,
          styleCheck,
          options,
          index,
          disabled,
        })}
      >
        {option?.label}
        {option?.label === value && name === "invoiceformat" && (
          <img src={selectedIcon} alt="selectedicon" />
        )}
        {renderTooltipStructure && renderTooltipStructure(option?.value)}
      </div>
    );
  };

  return (
    <div className={`radio-button-group ${className}`}>
      {topLabel?.text && (
        <Text
          size={topLabel?.fontSize}
          className={topLabel?.class}
          text={topLabel?.text}
          label={topLabel?.tooltipText}
          tooltipPlacement={topLabel?.tooltipPlacement}
          whiteTheme={whiteTheme}
          tooltipStyle={tooltipStyle}
          disabled={disabled}
        />
      )}
      {topLabel?.description && (
        <Text
          size={topLabel?.descriptionFontSize}
          className={topLabel?.descriptionClass}
          text={topLabel?.description}
          label={topLabel?.descriptionTooltipText}
          tooltipPlacement={topLabel?.descriptionTooltipPlacement}
          whiteTheme={whiteTheme}
          tooltipStyle={tooltipStyle}
        />
      )}

      <div
        className={`option-wrapper ${tabsStyle ? "!gap-0" : ""} ${
          isEditable ? "" : "pointerDisable"
        }`}
      >
        {options?.map((option, index) => (
          <div
            className={`option flex items-center justify-between ${childClassName} ${
              option?.value?.toLowerCase() === optionSelected?.toLowerCase() ? "selected-group" : ""
            }`}
          >
            {buttonStyle ? (
              renderCustomisedRadioButtons(option, index)
            ) : (
              <div>
                <FormControlLabel
                  className={`${option?.value === optionSelected ? "selected" : ""} ${className} ${
                    option.disabled ? "cursor-not-allowed" : ""
                  }`}
                  control={
                    <Radio
                      onBlur={onBlurHandler}
                      name={name}
                      value={value}
                      id={value}
                      onChange={(e) => handleSelect(e, option)}
                      checked={option?.value === optionSelected}
                      disabled={disabled || option.disabled}
                    />
                  }
                  label={option?.label}
                />
                {option?.subLabel?.text && (
                  <div className={option?.subLabel?.class}>{option?.subLabel?.text}</div>
                )}
                {infoRequired && (
                  <Tooltip
                    title={option?.info}
                    placement="top-start"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 400 }}
                  >
                    <span className="infoIcon">
                      {" "}
                      <InfoIcon />{" "}
                    </span>
                  </Tooltip>
                )}
                {isButtonRequired && (
                  <button type="button" onClick={() => buttonClickHandler(index)}>
                    <InfoIcon />
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {error && touched && (
        <FormHelperText className="clear-both" error={error && touched} size="small">
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
