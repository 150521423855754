import React, { useRef, useState } from "react";
import { FormHelperText } from "@mui/material";
import Text from "../../../../FormConfig/Form/Components/Text";
import Button from "../../../../FormConfig/Form/Components/Button";
import editIcon from "../../../../../Assets/icons/pen.svg";
import useDetectOutsideClick from "../../../../../CustomHooks/useDetectOutsideClick";

export default function InvoiceDeliveryType({
  value,
  handleChange,
  error,
  labelText,
  name,
  className,
  options,
  childClassName,
}) {
  const dropdownRef = useRef(null);

  const [selected, setSelected] = useState(value);
  const [collapse, setCollapse] = useState(true);
  const [email, setEmail] = useState("");

  useDetectOutsideClick(dropdownRef, (e) => {
    e.stopPropagation();
    if (!collapse) {
      setCollapse(true);
    }
  });

  const renderEmailDropdown = () => (
    <div className="email-dropdown-wrapper display_flex">
      <div className="flex w-[91%]">
        <input
          type="email"
          id="email"
          name={name}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder=" Enter Email ID"
          className="w-full text-xs text-[#898A90] border border-solid border-[#DDDFE8] h-[32px] px-3 rounded-l focus:border-[#555B6C]"
        />
        <Button
          className="rounded-r !h-[32px] !px-3 !py-2.5"
          size="small"
          variant="primary"
          id="submit"
          disabled={error}
          text="Save"
          type="submit"
          handleClick={() => {
            setCollapse(true);
            handleChange({ target: { value: email, name } });
          }}
        />
      </div>
    </div>
  );

  const handleSelect = (e, option) => {
    if (option.showDropDown) {
      setSelected(option.label);
      setCollapse(!collapse);
    } else {
      setSelected(option.label);
      setCollapse(true);
      handleChange({ target: { value: option.label, name } });
    }
  };

  const renderIcons = (option) => {
    if (selected === option.label) {
      if (email === value) {
        return option.filledIcon;
      }
      return option.selectedIcon;
    }
    return option.icon;
  };

  return (
    <div className={`invoice-delivery-wrapper ${className}`}>
      {labelText && <Text text={labelText} size="x-small" type="primary" />}
      <div className="invoice-delivery">
        {options.map((option) => (
          <div ref={dropdownRef} className={`option py-[5px] ${childClassName}`}>
            <div
              aria-hidden="true"
              onClick={(e) => handleSelect(e, option)}
              className={`display_flex_between buttonStyle ${error && "error_box"} ${
                option.label
              } ${option.label === selected && "selected"} ${email === value && "filled"}`}
            >
              <div className="display_flex_between gap-x-2.5">
                <input
                  type="radio"
                  id={option.label}
                  name={option.label}
                  checked={selected === option.label}
                  value={option.label}
                  className="!m-0"
                />
                <label htmlFor="radio-button">
                  {option.showDropDown && email === value ? value || option.label : option.label}
                </label>
                {email === value && option.showDropDown && <img src={editIcon} alt="idit icon" />}
              </div>
              <img src={renderIcons(option)} alt="selectedicon" />
            </div>
            {!collapse && option.showDropDown && renderEmailDropdown(option.label)}
          </div>
        ))}
        {error && (
          <FormHelperText className="clear-both" error={error} size="small">
            {error}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
