import React from "react";
import Button from "../Button";
import { useCheckboxes } from "./hooks";
import { ReactComponent as FolderIcon } from "../../../../../Assets/icons/ic-folder.svg";
import "./style.scss";

function SelectionTable(props) {
  const { handleChange, name, value, className, columnKeys, renderTooltipDisclaimer } = props;

  const { checkboxes, toggleAllCheckboxes, toggleCheckbox } = useCheckboxes(
    name,
    handleChange,
    columnKeys,
    value?.options
  );

  const allColumnsChecked = (options) =>
    options.every((option) =>
      option?.value === "CSSB"
        ? Object.entries(option?.column).every(([key, val]) =>
            key === "3_Years" ? val === false : val === true
          )
        : Object.values(option?.column).every((val) => val)
    );

  return (
    <div className={`selection-table-wrapper ${className}`}>
      {checkboxes?.length ? (
        <>
          <div className="selection-table">
            <table>
              <thead>
                <tr>
                  <th className="flex items-center gap-[16px]">
                    <span className="text-[#151E33] text-[11px] font-medium">Service</span>
                    <Button
                      text={allColumnsChecked(checkboxes) ? "Clear All" : "Select All"}
                      className="select-button relative !h-auto text-[#333] w-[fit-content] p-0 m-0 !text-[10px] !font-normal !text-[#0A3CA2]"
                      handleClick={toggleAllCheckboxes}
                    />
                  </th>
                  {Object.entries(columnKeys).map(([columnKey]) => (
                    <th
                      className="column-head text-[11px] font-medium border-l-[1px] border-dashed border-[#DDDFE8]"
                      key={columnKey}
                    >
                      {columnKey.replace("_", " ")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {checkboxes &&
                  checkboxes.map((option) => (
                    <tr key={option?.value}>
                      <td>{option?.label}</td>
                      {Object.entries(columnKeys).map(([columnKey]) => (
                        <td
                          className="border-l-[1px] border-dashed border-[#DDDFE8] min-w-[60px]"
                          key={columnKey}
                        >
                          <input
                            type="checkbox"
                            disabled={option?.value === "CSSB" && columnKey === "3_Years"}
                            checked={option?.column?.[columnKey]}
                            onChange={() => toggleCheckbox(option?.value, columnKey)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="bottom-disclaimer">{renderTooltipDisclaimer}</div>
        </>
      ) : (
        <div
          className="flex flex-col items-center justify-center !h-[220px] border-[1px] border-solid border-[#dddfe8] rounded-[3px]
        "
        >
          <div className="w-[201px] text-center flex items-center justify-center flex-col">
            <FolderIcon />
            <h4 className="mt-[10px] text-[12px] font-medium text-[#000000]">
              No Services Selected Yet!
            </h4>
            <p className="mt-[7px] leading-[12.89px] text-[11px] text-[#696969]">
              Please Select a service for configuring the tenure.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectionTable;
