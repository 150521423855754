const preparePayload = (data) => {
  const json = JSON.stringify(data);
  return new Blob([json], { type: "application/json" });
};

const fallbackSend = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const resdata = await response.json();
    console.log("Data sent using fetch:", resdata);
  } catch (err) {
    console.error("Error sending data:", err);
  }
};

const sendBeaconWrapper = (url, data, sendBeacon = true) => {
  if (navigator.sendBeacon && sendBeacon) {
    const payload = preparePayload(data);
    const success = navigator.sendBeacon(url, payload);

    if (success) {
      console.log("Data sent using sendBeacon.");
      return;
    }
  }

  try {
    fallbackSend(url, data);
  } catch (error) {
    console.error(error);
  }
};

export default sendBeaconWrapper;
