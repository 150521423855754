import React, { useState } from "react";
import Button from "@mui/material/Button";
import { ReactComponent as PlusIcon } from "../../../../Dashboard/CustomerManagement/CreateCustomer/Credits/CommentTimeline/icons/ic-plus.svg";
import { ReactComponent as MinusIcon } from "../../../../Dashboard/CustomerManagement/CreateCustomer/Credits/CommentTimeline/icons/ic-minus.svg";
import "./style.scss";

function TextClipper({ data = "", wordLimit = 100 }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = data.split(" ");

  const displayedComments = isExpanded ? data : `${words.slice(0, wordLimit).join(" ")}`;

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="text_clipper">
      <article className="info">
        <div className="info-content">
          <p>{displayedComments}</p>
          {words.length > wordLimit && (
            <Button
              onClick={toggleText}
              disableFocusRipple
              disableRipple
              variant="outlined"
              startIcon={isExpanded ? <MinusIcon /> : <PlusIcon />}
            >
              {isExpanded ? "Less" : "More"}
            </Button>
          )}
        </div>
      </article>
    </section>
  );
}

export default TextClipper;
