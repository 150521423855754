import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import React, { useState } from "react";
import { Popover } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../../Assets/images/ic-info.svg";
import commonService from "../../../../Services/commonService";
import "./style.scss";
import Loader from "../../../Common/Loader";

function Journey(props) {
  const { data, infoIcon = false, tableRow, prefix = "", suffix = "" } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [journeyList, setJourneyList] = useState([]);
  const [verticalOrigin, setVerticalOrigin] = useState("bottom");
  const [verticalTransformOrigin, setVerticalTransformOrigin] = useState("top");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const getList = async () => {
    const payload = {
      id: tableRow?.id,
      userEmail: tableRow?.email,
    };
    const response = await commonService.getCustomerJourney(payload);

    const {
      data: {
        data: { journey },
      },
    } = response;

    setJourneyList([...journey]);
    setIsLoading(false);
  };
  const handleClick = (event) => {
    const cursorY = event.clientY;
    const windowHeight = window.innerHeight;
    const bottomThreshold = windowHeight * 0.5;

    if (cursorY >= bottomThreshold) {
      setVerticalOrigin("top");
      setVerticalTransformOrigin("bottom");
    }
    setAnchorEl(event.currentTarget);
    setIsLoading(true);
    getList();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const returnJourney = () =>
    journeyList?.length ? (
      journeyList?.map((item, index) => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color={item?.active ? "success" : "grey"} />
            {index + 1 !== journeyList.length && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent className="!text-[12px]">{item?.action || "- - -"}</TimelineContent>
        </TimelineItem>
      ))
    ) : (
      <div className="no_data">
        <h5>No Data Available</h5>
      </div>
    );

  return (
    <div className="text-wrapper !text-[#000]">
      <div className="flex items-center w-[100%] justify-center">
        <p className="text-center !mr-[0]">
          {data !== undefined ? `${prefix}${data}${suffix}` : "- - -"}{" "}
        </p>
        {infoIcon && (
          <span
            aria-hidden
            aria-describedby={id}
            onClick={(e) => handleClick(e)}
            className="ml-2 cursor-pointer"
          >
            <InfoIcon data-testid="info-icon-testid" className="journey_info" />
          </span>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        className={`journey_popover ${
          verticalTransformOrigin === "bottom" ? "bottom_triangle" : "top_triangle"
        }`}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: verticalOrigin,
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: verticalTransformOrigin,
          horizontal: "center",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {isLoading ? <Loader /> : returnJourney()}
        </Timeline>
      </Popover>
    </div>
  );
}

export default Journey;
