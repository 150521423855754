/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import commonService from "../../../Services/commonService";
import Form from "../../FormConfig/Form";
import { getRegisterPayload, getSignUpContext } from "../../Login/utils";
import Routes from "../../../Config/Routes";
import Button from "../../FormConfig/Form/Components/Button";
import Loader from "../../Common/Loader";
import PageTitle from "../../Common/PageTitle";
import withRouter from "../../Common/WithRouter";
import { getParams } from "..";
import actions from "../../../Actions";
import PublicFooter from "../Common/PublicFooter";
import { getToast, pushEventToGTM } from "../../../Utils/commonUtils";
import { isCkAutoOrLens, platformSpecificEvent } from "./utils";
import "./style.scss";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../../Services/Strapi/urls";
import loginUtil from "../../../Utils/publicPageUtils";
import SsoCta from "../../Common/SsoCta";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateEmailValidations: {},
      isError: false,
      isLoading: false,
      partnerLogo: "",
      isLoadingLogo: false,
      allRightsFooter: "",
      submitValid: false,
      signUpCheck: props.ckAutoEnabled,
      htmlContent: "",
      partnerName: "",
      partnerDisplayName: "",
      // isCkAuto: props.isCkAuto,
      isMicrosoftEnabled: false,
      strapiDataState: {},
      isPageLoading: true,
      isGoogleEnabled: false,
    };
  }

  async componentDidMount() {
    const {
      props: {
        actions: { CkAutoEnabledAction },
      },
    } = this;
    const { stateEmailValidations } = this.state;
    this.setState({ isPageLoading: true });
    const response = await Promise.all([
      this.getStrapiData(),
      loginUtil(
        CkAutoEnabledAction,
        this.navigatePage,
        this.updateState,
        "Signup",
        Routes.LOGIN,
        this.refreshReCaptchaHandler
      ),
    ]);
    const [strapiData, partnerDisplayNameVar] = response;
    if (partnerDisplayNameVar) {
      this.setState({
        allRightsFooter: `${partnerDisplayNameVar} ${new Date().getFullYear()} ${
          strapiData?.pageFooter?.hypertext
        }`,
      });
    } else {
      this.setState({
        allRightsFooter: `${strapiData?.pageFooter?.pretext} ${new Date().getFullYear()} ${
          strapiData?.pageFooter?.hypertext
        }`,
      });
    }
    this.setState({
      strapiDataState: strapiData,
      isPageLoading: false,
      stateEmailValidations: { ...stateEmailValidations, ...strapiData?.form?.[2] },
      isLoadingLogo: false,
    });
  }

  getStrapiData = async () => {
    const data = await fetchStrapiData(strapiUrls.SIGNUP);
    return data;
  };

  navigatePage = (path, searchName = "") => {
    const {
      props: { navigate },
    } = this;
    setTimeout(() => {
      navigate({
        pathname: path,
        search: searchName,
      });
    }, 50);
  };

  updateState = (stateData) => {
    this.setState(stateData);
  };

  handleSubmit = async (values) => {
    const params = getParams("customerId");
    const { partnerName, isCkAuto, isLens } = this.state;
    try {
      this.setState({ isLoading: true, submitValid: false });
      delete localStorage.userData;
      let payload = getRegisterPayload(values);
      if (params) {
        payload.awsCustomerId = params;
      }
      const context = getSignUpContext(partnerName);
      payload = { ...payload, ...context };
      const response = await commonService.registerUser(payload);
      const eventConfig = platformSpecificEvent({ isCkAuto, isLens });
      pushEventToGTM(eventConfig);
      this.setState({ isLoading: false });
      getToast("success", response.data.message);
      const searchName = `?email=${encodeURIComponent(payload?.email)}`;
      this.navigatePage(Routes.RESENDEMAIL, searchName);
    } catch (err) {
      // console.log("err", err);
      this.setState({
        isLoading: false,
        submitValid: true,
      });
    }
  };

  getFormFields = (
    firstNameValidations,
    lastNameValidations,
    stateEmailValidations,
    companyNameValidations
  ) => [firstNameValidations, lastNameValidations, stateEmailValidations, companyNameValidations];

  navigateToLogin = () => {
    const {
      props: { navigate },
    } = this;

    navigate({
      pathname: "/",
    });
  };

  renderLeftContent = (content) =>
    content && (
      <div className="LoginLeftHTML-Wrapper" dangerouslySetInnerHTML={{ __html: content }} />
    );

  getParentClass = (name) => {
    const { signUpCheck } = this.state;
    if (name === "firstName") {
      return `${signUpCheck && "col-md-6 mr1 !w-1/2 w48"}`;
    }
    return `${signUpCheck && "col-md-6 ml1 !w-1/2 w48 !mt-[0]"}`;
  };

  getModuleName = () => {
    const { isCkAuto, isTunerEnabled } = this.state;

    /**
     *  Todo:: change strapi - add ck-tuner in heading text
     *  Strapi is not part of current tuner development
     *  using ck-auto to get the heading text for tuner
     */
    if (isCkAuto || isTunerEnabled) {
      return "ck-auto";
    }
    return "default";
  };

  renderPublicFooter = () => {
    const {
      state: {
        signUpCheck,
        allRightsFooter,
        htmlContent,
        isCkAuto,
        isLens,
        strapiDataState,
        isTunerEnabled,
      },
    } = this;
    return (
      <PublicFooter
        isCkAuto={isCkAutoOrLens(isCkAuto, isLens) || isTunerEnabled}
        show={signUpCheck}
        leftText={!!htmlContent}
        allRightsFooter={allRightsFooter}
        strapiData={strapiDataState?.publicFooter}
      />
    );
  };

  returnAutoFooter = () => {
    const { isCkAuto, isLens, isTunerEnabled } = this.state;
    if (!isCkAutoOrLens(isCkAuto, isLens) && !isTunerEnabled) {
      return this.renderPublicFooter();
    }
    return null;
  };

  returnNotAutoFooter = () => {
    const { isCkAuto, isLens, isTunerEnabled } = this.state;
    if (isCkAutoOrLens(isCkAuto, isLens) || isTunerEnabled) {
      return this.renderPublicFooter();
    }
    return null;
  };

  getWrapperClass = () => {
    const { isCkAuto, isLens, isTunerEnabled } = this.state;
    return `login_wrapper ${
      isCkAutoOrLens(isCkAuto, isLens) || isTunerEnabled ? "ck_auto_module" : ""
    }`;
  };

  getFooter = (isLoadingLogo, htmlContent, signUpCheck, allRightsFooter) =>
    !isLoadingLogo && !signUpCheck && <div className="footer-text">{allRightsFooter}</div>;

  render() {
    const {
      handleSubmit,
      state: {
        stateEmailValidations,
        isError,
        isLoading,
        partnerLogo,
        isLoadingLogo,
        submitValid,
        signUpCheck,
        allRightsFooter,
        htmlContent,
        partnerDisplayName,
        strapiDataState: { formComponent: { data: { attributes } = {} } = {} } = {},
        strapiDataState,
        isPageLoading,
        isMicrosoftEnabled,
        isGoogleEnabled,
      },
      getParentClass,
      getFormFields,
      navigateToLogin,
    } = this;

    const formFields = getFormFields(
      {
        ...attributes?.form?.[0],
        parentClass: getParentClass("firstName"),
        validations: {
          rules: attributes?.form?.[0]?.rules,
          messages: attributes?.form?.[0]?.messages,
        },
      },
      {
        ...attributes?.form?.[1],
        parentClass: getParentClass("lastName"),
        validations: {
          rules: attributes?.form?.[1]?.rules,
          messages: attributes?.form?.[1]?.messages,
        },
      },
      {
        ...attributes?.form?.[2],
        validations: {
          rules: attributes?.form?.[2]?.rules,
          messages: attributes?.form?.[2]?.messages,
        },
        parentClass: `${signUpCheck && "cleaBoth pt-6"}`,
      },
      {
        ...attributes?.form?.[3],
        validations: {
          rules: attributes?.form?.[3]?.rules,
          messages: attributes?.form?.[3]?.messages,
        },
        parentClass: `${signUpCheck && "mt-6"}`,
      }
    );

    const emailValidationsClone = stateEmailValidations;
    emailValidationsClone.showLabel = true;
    const moduleName = this.getModuleName();

    const logoImage = `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`;

    return isPageLoading ? (
      <Loader />
    ) : (
      <div
        className={`Register-mainContainer ${htmlContent && "leftHTML"}
      ${signUpCheck && "whiteLabeledProduct"} ${
          signUpCheck && !htmlContent && "withoutHTMLContent"
        }`}
      >
        <PageTitle
          pageTitle={strapiDataState?.pageTitle}
          customPartnerDisplayName={partnerDisplayName}
          pageHeader={signUpCheck}
        />
        <div className="flex justify-between !w-full signup_wrapper">
          {this.renderLeftContent(htmlContent)}
          <div className={this.getWrapperClass()}>
            <div className="login-form-wrapper">
              <div className="logo-container">
                <div className={` ${partnerLogo && " logoWrapper "} logo`}>
                  {!isLoadingLogo && <img src={`${partnerLogo || logoImage}`} alt="logo" />}
                </div>
              </div>
              {isLoadingLogo ? (
                <Loader popup />
              ) : (
                <div className="login_form">
                  <h1>{strapiDataState?.headingTexts?.[moduleName]?.heading}</h1>
                  {signUpCheck && strapiDataState?.headingTexts?.[moduleName]?.subHeading && (
                    <p className="subHeading">
                      {strapiDataState?.headingTexts?.[moduleName]?.subHeading}
                    </p>
                  )}
                  {submitValid ? (
                    <div className="formErrors">
                      One or more inputs were invalid. Please update & retry
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" flex-1 relative flex flex-wrap w-full ">
                    <Form
                      formConfig={formFields}
                      handleSubmit={handleSubmit}
                      isError={(value) => this.setState({ isError: value })}
                      formValues={{}}
                    >
                      <Button
                        text={attributes?.form?.[4]?.label}
                        className={attributes?.form?.[4]?.className}
                        disabled={isError || isLoading}
                        isLoading={isLoading}
                        type={attributes?.form?.[4]?.type}
                      />
                    </Form>
                    <SsoCta
                      isMicrosoftEnabled={isMicrosoftEnabled}
                      isGoogleEnabled={isGoogleEnabled}
                      text="Sign up"
                      loginBtnEnabled={strapiDataState?.signupEnabled}
                    />
                  </div>
                  <div className="accountRegister">
                    <span>{strapiDataState?.routelink?.pretext} </span>
                    <span className="Link" aria-hidden="true" onClick={() => navigateToLogin()}>
                      {strapiDataState?.routelink?.hypertext}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {this.returnNotAutoFooter()}
            {this.getFooter(isLoadingLogo, htmlContent, signUpCheck, allRightsFooter)}
          </div>
        </div>
        {this.returnAutoFooter()}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  ckAutoEnabled: state.CkAutoReducer.signupEnabled,
  isCkAuto: state.CkAutoReducer.isCkAuto,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
