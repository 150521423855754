import React, { useEffect, useRef } from "react";
import "./style.scss";

function InfiniteScroll(props) {
  const { children, onReachBottom, height } = props;
  const scrollElement = useRef();

  const handleScroll = (event) => {
    const {
      target: { scrollTop, clientHeight, scrollHeight },
    } = event;

    if (scrollTop + clientHeight === scrollHeight && onReachBottom) {
      // User has scrolled to the bottom of the element
      onReachBottom();
    }
  };

  useEffect(() => {
    const { current } = scrollElement;

    current.onscroll = handleScroll;
  }, []);

  return (
    <div className={`infinte-scroll ${height}`} ref={scrollElement}>
      {children}
    </div>
  );
}

export default InfiniteScroll;
