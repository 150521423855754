/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import commonService from "../../../Services/commonService";
import withRouter from "../../Common/WithRouter";
import Routes from "../../../Config/Routes";
import { getToast } from "../../../Utils/commonUtils";
import "./style.scss";
import { updatePartnerFavicon } from "../utils";
import PageTitle from "../../Common/PageTitle";
import VerificationLinkExpired from "../VerificationLinkExpired/VerificationLinkExpired";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls from "../../../Services/Strapi/urls";

function ConfirmUser({ navigate }) {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Please wait...");
  const [catchError, setCatchError] = useState(false);
  const [strapiDataState, setStrapiDataState] = useState({});

  const isCustomer = window.location.pathname.includes(Routes.CONFIRM_CUSTOMER);
  const getPayload = () => {
    const payload = Object.fromEntries([...searchParams]);
    const { confirmation_code: code, user_name: email } = payload;
    return {
      email: email?.toLowerCase(),
      code,
    };
  };

  const getCustomerPayload = () => {
    const payload = Object.fromEntries([...searchParams]);
    const { verification_id } = payload;
    return {
      verificationId: verification_id,
    };
  };

  useEffect(() => {
    const getStrapiData = async () => {
      const data = await fetchStrapiData(strapiUrls.CONFIRM_USER);
      setStrapiDataState({ strapiDataState: data });
    };

    getStrapiData();

    async function confirmAccount(queryString) {
      if (queryString) {
        try {
          /* This check is used to check if this route is coming from confirm customer or confirm user */
          if (isCustomer) {
            await commonService.getCustomerVerfication(queryString);
          } else {
            await commonService.confirmSignUp(queryString);
          }
          setMessage("Sign up successful");
          getToast("success", "Sign up successful");

          navigate(Routes.LOGIN);
        } catch (error) {
          const {
            response: {
              status,
              data: { message: errorMessage },
            },
          } = error;

          if (status === 700) {
            setCatchError(true);
          }
          if (status === 701) {
            getToast("error", "User already verified");
            navigate(Routes.LOGIN);
          }
          if (status === 400) {
            getToast("error", errorMessage);
            navigate(Routes.LOGIN);
          }
        }
      }
    }
    updatePartnerFavicon();
    const queryString = isCustomer ? getCustomerPayload() : getPayload();
    confirmAccount(queryString);
  }, []);

  return (
    <>
      <PageTitle pageTitle={strapiDataState?.pageTitle} />
      <div className="landing-image" />
      <div className="sign-up public-route-wrapper grid h-screen place-items-center bg-[#EFF2F7]">
        {catchError ? <VerificationLinkExpired /> : <h2>{message}</h2>}
      </div>
    </>
  );
}
export default withRouter(ConfirmUser);
