import { getSessionStorageUserData } from "../../Utils/commonUtils";

const KONG_URL = process.env.REACT_APP_KONG_URL;
const STATIC_RESOURCE_URL = process.env.REACT_APP_STATIC_DATA_URL;
const API_BASE_URL = `${KONG_URL}/billdesk`;
const LENS_BASE_URL = `${KONG_URL}/lens`;
const CK_AUTO_BASE_URL = `${KONG_URL}/ckauto`;
const QUOTE_GEN_BASE_URL = `${KONG_URL}/utilpro`;
const CK_MARKETPLACE_BASE_URL = `${KONG_URL}/marketplace`;
const CK_BATCH_PROCESSING = `${KONG_URL}`;
const AZURE_INSIGHTS_BASE_URL = `${KONG_URL}/azure-insights`;
const GCP_INSIGHTS_BASE_URL = `${KONG_URL}/gcp-insights`;
const CK_ANALYTICS_BASE_URL = `${KONG_URL}/profitability`;
const CK_TUNER_BASE_URL = `${API_BASE_URL}/cktuner/cln`;
const CK_TUNER_URL = `${KONG_URL}/tuner`;
const CK_COMMENTS = `${KONG_URL}/comments`;

export const getLensBaseURL = (gcpOverride = false) => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleBaseUrls = {
    lens: LENS_BASE_URL,
    "legacy-lens": LENS_BASE_URL,
    "azure-insights": AZURE_INSIGHTS_BASE_URL,
    "gcp-insights": gcpOverride ? API_BASE_URL : GCP_INSIGHTS_BASE_URL,
  };
  return moduleBaseUrls[moduleName] || LENS_BASE_URL;
};
export default {
  LOGIN: `${API_BASE_URL}/auth/login`,
  LOGOUT: `${API_BASE_URL}/auth/logout`,

  RESET_PASSWORD: `${API_BASE_URL}/auth/resetPassword`,
  FORGET_PASSWORD: `${API_BASE_URL}/auth/forgetPassword`,
  CHANGE_PASSWORD: `${API_BASE_URL}/auth/changePassword`,
  USER_DASHBOARD: `${API_BASE_URL}/dashboard/all/v4`,

  USER_META_DATA_DETAIL: `${API_BASE_URL}/user-meta-data/details`,
  RESENDEMAILTIMER: `${API_BASE_URL}/auth/resendConfirmationEmail/timer`,
  RESENDEMAIL: `${API_BASE_URL}/auth/signup/resendConfirmationEmail`,
  SIGN_UP: `${API_BASE_URL}/auth/signup`,
  CONFIRM_USER: `${API_BASE_URL}/auth/signup/confirm`,
  GET_ALL_USERS: `${API_BASE_URL}/customer/user/all`,
  GET_USER_BY_ID: `${API_BASE_URL}/user`,
  CONFIRM_CUSTOMER: `${API_BASE_URL}/user/customer-verification`,
  USER_VERIFICATION: `${API_BASE_URL}/auth/validate/signup/confirm`,
  MAV_ROLE: `${API_BASE_URL}/mav-auth`,
  DASHBOARD_DATA: {
    MASTER: "",
    PARTNER: `${API_BASE_URL}/partner/get`,
    CUSTOMER: "",
  },
  ROLE: {
    GET_CURRENT_ROLE: `${API_BASE_URL}/role/current`,
    GET_LIST: `${API_BASE_URL}/role/list`,
    GET_PARTNERS_LIST: `${API_BASE_URL}/partner/switch?pageNumber=1&numberOfRecords=2000`,
    GET_SWITCH_ROLE: (params) => `${API_BASE_URL}/role/switch-list?switchAccess=${params}`,
    GET_CUSTOMER_CUSTOMER: (params) =>
      `${API_BASE_URL}/customer/switch/names?pageNumber=1&numberOfRecords=2000&partnerId=${params}`,
    GET_CUSTOMER_FOR_PARTNER: `${API_BASE_URL}/customer/switch?pageNumber=1&numberOfRecords=2000`,
  },

  GET_SWITCH_ROLE_DATA: {
    CLOUDONOMICS: `${API_BASE_URL}/partner/switch`,
    PARTNER: `${API_BASE_URL}/customer/switch/names`,
    CUSTOMER: `${API_BASE_URL}/customer/module/enable/all`,
    CUSTOMER_NAME: `${API_BASE_URL}/customer/switch`,
  },
  GET_PARTNER_CUSTOMER_LIST: `${API_BASE_URL}/customer/all`,
  GET_SWITCH_ROLE_DATA_CUSTOMER: `${API_BASE_URL}/customer/switch/names`,
  SWITCH_ROLE: `${API_BASE_URL}/switch-role`,
  USER_META_DATA: `${API_BASE_URL}/user-meta-data/v2`,
  GET_CURRENT_PARTNER_DATA: `${API_BASE_URL}/partner/get`,
  USER: {
    GET_LIST_CLOUDONOMIC: `${API_BASE_URL}/user/all/cld`,
    GET_LIST: `${API_BASE_URL}/user/all`,
    GET_LIST_V2: `${API_BASE_URL}/user/all/v2`,
    CREATE: `${API_BASE_URL}/user/create`,
    UPDATE: `${API_BASE_URL}/user/update`,
    TOGGLE: `${API_BASE_URL}/user/toggle`,
    REVERIFY: `${API_BASE_URL}/auth/authorized/resendConfirmationEmail`,
    DELETE: `${API_BASE_URL}/user/delete`,
    PROMOTE: `${API_BASE_URL}/user/upgrade-user`,
    GET_CURRENT_USER: `${API_BASE_URL}/user/current`,
    GET_CUSTOMER: `${API_BASE_URL}/user/customer/all`,
  },
  PARTNER: {
    CREATE: `${API_BASE_URL}/partner/save`,
    UPDATE: `${API_BASE_URL}/partner/update`,
    DELETE: `${API_BASE_URL}/partner/delete`,
    FETCH_LOGO: `${API_BASE_URL}/partner/authorized-user/logo`,
    FETCH_FAV_ICON: `${API_BASE_URL}/partner/authorized-user/favicon`,
    S3_BASE_URL: `${API_BASE_URL}/partner/s3-base-url`,
    FETCH_LOGO_LOGIN_PAGE: `${API_BASE_URL}/anonymous/logo`,
    FETCH_FAB_LOGO_LOGIN_PAGE: `${API_BASE_URL}/anonymous/favicon`,
  },

  PAYER: {
    GET_LIST: `${API_BASE_URL}/payer/all`,
    CREATE: `${API_BASE_URL}/payer/save`,
    UPDATE: `${API_BASE_URL}/payer/update`,
    DELETE: `${API_BASE_URL}/payer/delete`,
    EXPORT: `${API_BASE_URL}/payer/export`,
  },
  CUSTOMER: {
    DELETE: `${API_BASE_URL}/customer/delete`,
    AGREEMENT: `${API_BASE_URL}/auto-meta/agreement-signed`,
    DATAREFRESH: `${API_BASE_URL}/customer/refresh`,
    CREATE: `${API_BASE_URL}/customer/save`,
    UPDATE: `${API_BASE_URL}/customer/update`,
    UPDATE_CK_AUTO: `${API_BASE_URL}/customer/update/name`,
    ALL: `${API_BASE_URL}/customer/all`,
    DETAILS: `${API_BASE_URL}/customer/details/all`,
    JOURNEY: `${API_BASE_URL}/customer/user/journey`,
    HEATH_CHECK: `${API_BASE_URL}/customer/health-check`,
    CUSTOMERS_HEALTH_REFRESHED: `${API_BASE_URL}/customer/health-check`,
    DEBOARD: `${API_BASE_URL}/deboard/customer`,
  },
  BILLDESK_GET_NOTIFICATION: `${API_BASE_URL}/customer/reminder`,
  ACCOUNT: {
    ORPHANS: `${API_BASE_URL}/account/orphans`,
    ASSOCIATED: `${API_BASE_URL}/account/all`,
  },
  REPORTS: {
    SETTINGS: {
      SAVE: `${API_BASE_URL}/report/setting/save`,
      GET: `${API_BASE_URL}/report/setting/all`,
      UPDATE: `${API_BASE_URL}/report/setting/update`,
      GENERATE_INVOICE: `${CK_BATCH_PROCESSING}/batch/invoice/export`,
      DELETE: `${API_BASE_URL}/report/setting/delete/`,
    },
  },
  LEGAL_ENTITY: {
    GET: `${API_BASE_URL}/legal-entity/all`,
    GET_NAMES: `${API_BASE_URL}/legal-entity/names/all`,
    CREATE: `${API_BASE_URL}/legal-entity/save`,
    UPDATE: `${API_BASE_URL}/legal-entity/update`,
    BULKUPDATE: `${API_BASE_URL}/legal-entity/bulk-update`,
    GET_EFFECTIVE_DATE: (customerId) => `${API_BASE_URL}/legal-entity/effective-date/${customerId}`,
    GET_BILLING_NAME: (customerId, id) =>
      `${API_BASE_URL}/legal-entity/billing-alias/${customerId}/${id}`,
  },
  CBU: {
    GET: `${API_BASE_URL}/cbu/all`,
    GET_NAMES: `${API_BASE_URL}/cbu/names/all`,
    CREATE: `${API_BASE_URL}/cbu/save`,
    BULKUPDATE: `${API_BASE_URL}/cbu/bulk-update`,
    GET_EFFECTIVE_DATE: (customerId) => `${API_BASE_URL}/cbu/effective-date/${customerId}`,
  },
  GET_CONFIGURATION: `${API_BASE_URL}/reservation-discount/config`,
  DISCOUNT: {
    GET_ON_DEMAND: `${API_BASE_URL}/discounts/template/all`,
  },
  DASHBOARD_GRID: {
    GET_DASHBOARDS: `${API_BASE_URL}/dashboard/all`,
    GET_DASHBOARDS_V2: `${API_BASE_URL}/dashboard/all/v2`,

    GET_GRID: {
      CLOUDONOMICS: `${API_BASE_URL}/dashboard/partner/grid/all`,
      PARTNER: `${API_BASE_URL}/dashboard/customer/grid/all`,
    },
    SAVE_UPDATE_GRID: {
      CLOUDONOMICS: `${API_BASE_URL}/dashboard/partner/update/v2`,
      PARTNER: `${API_BASE_URL}/dashboard/customer/update/v2`,
    },
  },
  // Plan Selection
  PLAN_SELECTION: {
    SERVICES: `${API_BASE_URL}/missed-savings/services/`,
    EXPORT: `${API_BASE_URL}/missed-savings/detail/export`,
    DETAILS: `${API_BASE_URL}/missed-savings/detail/`,
  },
  MODULE_GRID: {
    GET_DASHBOARDS: `${API_BASE_URL}/module/all`,
    GET_ENABLED_DASHBOARDS: `${API_BASE_URL}/module/enabled/all`,
    GET_GRID: {
      CLOUDONOMICS: `${API_BASE_URL}/module/partner/grid/all`,
      PARTNER: `${API_BASE_URL}/module/customer/grid/all`,
    },
    SAVE_UPDATE_GRID: {
      CLOUDONOMICS: `${API_BASE_URL}/module/partner/update/v2`,
      PARTNER: `${API_BASE_URL}/module/customer/update/v2`,
    },
  },
  PARTNER_SETTINGS: {
    GET: `${API_BASE_URL}/email-config/get`,
    CREATE: `${API_BASE_URL}/email-config/create`,
    UPDATE: `${API_BASE_URL}/email-config/update`,
  },
  CUSTOMER_STATUS_SETTINGS: {
    GET: `${API_BASE_URL}/customer-type`,
    CREATE: `${API_BASE_URL}/customer-type`,
    DELETE: `${API_BASE_URL}/customer-type`,
  },
  SUPPORT_CONFIGURATION: {
    GET_ALL: (params) => `${API_BASE_URL}/support/all?customerId=${params.customerId}`,
    BULK_UPDATE: `${API_BASE_URL}/support/bulk-update`,
    UPDATE: `${API_BASE_URL}/support/update`,
    GET_EFFECTIVE_DATE: (customerId) => `${API_BASE_URL}/support/effective-date/${customerId}`,
  },
  Cloudfront_Private_Pricing: {
    // GET_ALL: "https://run.mocky.io/v3/7d7338ba-e7b0-46b3-a8fa-e2c5436fc3db",
    // END_DISCOUNT: "https://run.mocky.io/v3/0fd74eaf-11db-4149-a439-11d8a7f09659",
    // PRICING_ON_ID: "https://run.mocky.io/v3/cc7364f9-8bb7-4894-9779-c75f6ac0edb4",
    // PRICING_ON_ID: "https://be.dev.cloudonomic.net/billdesk/cloudfront-private-pricing/1",
    // END_DISCOUNT: `${API_BASE_URL}/cloudfront-private-pricing`,
    GET_ALL: `${API_BASE_URL}/cloudfront-private-pricing`,
    AWS_REGION: `${API_BASE_URL}/aws/region`,
  },
  PREFERENCES: {
    MAV: {
      GET_ALL_MAV: `${API_BASE_URL}/mav/all`,
      GET_ALL_MAV_NAMES: `${API_BASE_URL}/mav/name/all`,
      GET_PROJECT_COUNT: `${API_BASE_URL}/auto-meta/customer`,
      DELETE_MAV: `${API_BASE_URL}/mav/delete/`,
      GET_MAV_NAMES: `${API_BASE_URL}/user/mav-names`,
      UPDATE_MAV: `${API_BASE_URL}/mav/update`,
      UPDATE_MAV_DEFAULT: `${API_BASE_URL}/mav/update/mav-name`,
      GET_ACCOUNT_ID: `${API_BASE_URL}/mav/account-ids`,
      GET_ACCOUNT_ID_WITH_ACCOUNT_NAME: `${API_BASE_URL}/mav/accountIdsWithAccountName`,
      GET_MAV_USERS: `${API_BASE_URL}/mav/users`,
      SAVE_MAV: `${API_BASE_URL}/mav/save`,
      PUBLISH: `${API_BASE_URL}/tag-report/config/publish`,
      UNPUBLISH: `${API_BASE_URL}/tag-report/config/unpublish`,
      V2: {
        GET_ALL_MAV: `${API_BASE_URL}/mav/all/v2`,
      },
    },
    TAGS: {
      GET_ALL_TAGS: `${API_BASE_URL}/tag-report/config/all`,
      CREATE_TAG: `${API_BASE_URL}/tag-report/config/save`,
      UPDATE_TAG: `${API_BASE_URL}/tag-report/config/update`,
      DELETE: `${API_BASE_URL}/tag-report/config/delete/`,
    },
  },
  CK_LENS: {
    MIN_MAX_DATES: () => `${getLensBaseURL()}/dates/min-and-max`,
    COLOR_CODES: () => `${getLensBaseURL()}/billingSummary/color-legend`,
    BILLING_SUMMARY: {
      TOTAL_COST_BY_SERVICE: () => `${getLensBaseURL()}/billingSummary/totalCostByService`,
      RECOMMENDATION_SUMMARY: () => `${getLensBaseURL()}/billingSummary/recommendationSummary`,
      GET_TABLE_OR_GRAPH: () => `${getLensBaseURL()}/billingSummary/getTableOrGraph`,
      COST_DASHBOARD: () => `${getLensBaseURL()}/billingSummary/cost/dashboard`,
    },
    BILLING_CONSOLE: {
      BILLING_CONSOLE_MONTHLY: () => `${getLensBaseURL()}/billing-console/monthly`,
      BILLING_CONSOLE_V2_MONTHLY_GROUP_BY_REGION_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/monthly/groupByRegionName`,
      BILLING_CONSOLE_V2_MONTHLY_GROUP_BY_PRODUCT_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/monthly/groupByProductName`,
      BILLING_CONSOLE_MONTHLYDOWNLOAD: () =>
        `${getLensBaseURL()}/billing-console/exportMonthlyReport`,
      BILLING_CONSOLE_PREVIOUSMONTH: () => `${getLensBaseURL()}/billing-console/previousMonths`,
      BILLING_CONSOLE_V2_PREVIOUSMONTH_GROUP_BY_REGION_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/previousMonths/groupByRegionName`,
      BILLING_CONSOLE_V2_PREVIOUSMONTH_GROUP_BY_PRODUCT_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/previousMonths/groupByProductName`,
      BILLING_CONSOLE_PREVIOUSMONTHDOWNLOAD: () =>
        `${getLensBaseURL()}/billing-console/exportPreviousMonthReport`,
      BILLING_CONSOLE_DAILY: () => `${getLensBaseURL()}/billing-console/dailyBreakup`,
      BILLING_CONSOLE_V2_DAILY_GROUP_BY_REGION_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/dailyBreakup/groupByRegionName`,
      BILLING_CONSOLE_V2_DAILY_GROUP_BY_PRODUCT_NAME: () =>
        `${getLensBaseURL()}/billing-console/v2/dailyBreakup/groupByProductName`,
      BILLING_CONSOLE_DAILYDOWNLOAD: () =>
        `${getLensBaseURL()}/billing-console/exportDailyBreakupReport`,
      BILLING_CONSOLE_COST: () => `${getLensBaseURL()}/billing-console/cost`,
    },
    UTILIZATION: {
      GET_UTILIZATIONDATA: `${LENS_BASE_URL}/reservation-utilization/getRiUtilizationSummary`,
      GET_UTILIZATION_HOURLYDATA: `${LENS_BASE_URL}/reservation-utilization/getHourlyDetails`,
      GET_UTILIZATION_SP_HOURLYDATA: `${LENS_BASE_URL}/reservation-utilization/getSpHourlyDetails`,
      EXPORT_UTILIZATION: `${LENS_BASE_URL}/reservation-utilization/exportReservationUtilization`,
    },
    RI_COVERAGE: {
      GET_RICOVERAGE: `${LENS_BASE_URL}/ri-coverage/getRiCoverage`,
      GET_RICOVERAGE_HOURLYDATA: `${LENS_BASE_URL}/ri-coverage/getRiCoverageHourlyData`,
      EXPORT: `${LENS_BASE_URL}/ri-coverage/export`,
    },
    ACCOUNT_WISE_BREAKUP: {
      ACCOUNT_WISE_BREAKUP_MONTHLY: () => `${getLensBaseURL()}/account-wise/monthly`,
      ACCOUNT_WISE_BREAKUP_V2_MONTHLY_SUMMARY: () =>
        `${getLensBaseURL()}/account-wise/v2/monthly/summary`,
      ACCOUNT_WISE_BREAKUP_V2_MONTHLY_DETAIL: () =>
        `${getLensBaseURL()}/account-wise/v2/monthly/detail`,
      ACCOUNT_WISE_BREAKUP_MONTHLYDOWNLOAD: () =>
        `${getLensBaseURL()}/account-wise/exportMonthlyReport`,
      ACCOUNT_WISE_BREAKUP_PREVIOUSMONTH: () => `${getLensBaseURL()}/account-wise/previousMonths`,
      ACCOUNT_WISE_BREAKUP_V2_PREVIOUSMONTH_SUMMARY: () =>
        `${getLensBaseURL()}/account-wise/v2/previousMonths/summary`,
      ACCOUNT_WISE_BREAKUP_V2_PREVIOUSMONTH_DETAIL: () =>
        `${getLensBaseURL()}/account-wise/v2/previousMonths/detail`,
      ACCOUNT_WISE_BREAKUP_PREVIOUSMONTHDOWNLOAD: () =>
        `${getLensBaseURL()}/account-wise/exportPreviousMonthReport`,
      ACCOUNT_WISE_BREAKUP_DAILY: () => `${getLensBaseURL()}/account-wise/dailyBreakup`,
      ACCOUNT_WISE_BREAKUP_V2_DAILY_SUMMARY: () =>
        `${getLensBaseURL()}/account-wise/v2/dailyBreakup/summary`,
      ACCOUNT_WISE_BREAKUP_V2_DAILY_DETAIL: () =>
        `${getLensBaseURL()}/account-wise/v2/dailyBreakup/detail`,
      ACCOUNT_WISE_BREAKUP_DAILYDOWNLOAD: () =>
        `${getLensBaseURL()}/account-wise/exportDailyReport`,
    },
    NOTIFICATION: {
      INTEGRATION: {
        EMAIL: {
          GET_ALL: `${API_BASE_URL}/notification-group/email/all`,
          CREATE: `${API_BASE_URL}/notification-group/email/create`,
          UPDATE: `${API_BASE_URL}/notification-group/email/update/`,
          DELETE: `${API_BASE_URL}/notification-group/email/delete/`,
          GET_EMAIL_LIST: `${API_BASE_URL}/notification-group/email/user/all`,
          GET_THRESHOLD_COUNT: `${API_BASE_URL}/notification-group/email/email-threashold`,
        },
        SLACK: {
          GET_ALL: `${API_BASE_URL}/notification-group/slack/all`,
          CREATE: `${API_BASE_URL}/notification-group/slack/create`,
          UPDATE: `${API_BASE_URL}/notification-group/slack/update/`,
          DELETE: `${API_BASE_URL}/notification-group/slack/delete/`,
        },
      },
      BUDGET_ALERTS: {
        GET_ALL: `${API_BASE_URL}/cost-deviation/getAll`,
        DAILY_COST_DEVIATION: {
          CREATE: `${API_BASE_URL}/budget-alert/daily/create`,
          UPDATE: `${API_BASE_URL}/budget-alert/daily/update/`,
          DELETE: `${API_BASE_URL}/budget-alert/daily/delete/`,
        },
        MONTHLY_COST_DEVIATION: {
          CREATE: `${API_BASE_URL}/budget-alert/monthly/create`,
          UPDATE: `${API_BASE_URL}/budget-alert/monthly/update/`,
          DELETE: `${API_BASE_URL}/budget-alert/monthly/delete/`,
        },
        DAILY_SUMMARY_REPORT: {
          GET: `${API_BASE_URL}/daily-alert/get`,
          UPDATE: `${API_BASE_URL}/daily-alert/save`,
        },
        RI_UTILIZATION: {
          GET: `${API_BASE_URL}/ri-utilization-budget-alert/all`,
          CREATE: `${API_BASE_URL}/ri-utilization-budget-alert/create`,
          UPDATE: `${API_BASE_URL}/ri-utilization-budget-alert/update/`,
          DELETE: `${API_BASE_URL}/ri-utilization-budget-alert/delete/`,
        },
        RI_EXPIRY: {
          GET: `${API_BASE_URL}/ri-alert/get`,
          UPDATE: `${API_BASE_URL}/ri-alert/save`,
        },
        COST_TRENDS: {
          GET_COST: () => `${getLensBaseURL()}/cost-trends/`,
          GET_EDIT_DATA: () => `${API_BASE_URL}/cost-deviation/getBudgetAlert`,
          // GET_EDIT_DATA: () => "https://run.mocky.io/v3/3d20b49d-749a-44b5-98ac-c1444e37460e",
          GET_FILTERS_LIST: () => `${getLensBaseURL()}/cost-trends/group-by/`,
          GET_GRAPH_DATA: () => `${getLensBaseURL()}/cost-trends/group-by-cost`,
          GET_GRAPH_DATAV2: () => `${getLensBaseURL()}/cost-trends/getGraphData`,
          GET_FILTERLIST_V2: () => `${getLensBaseURL()}/cost-trends/getFilters`,
          GET_FILTER_VALUES: () => `${getLensBaseURL()}/cost-trends/getFilterValues`,
        },
      },
    },
    RESERVATIONSUMMARY: {
      RESERVATION_SUMMARY_GET: () => `${getLensBaseURL()}/reservation-summary/get`,
      RESERVATION_SUMMARY_DOWNLOAD: () => `${getLensBaseURL()}/reservation-summary/export`,
    },
    TAG_REPORT: {
      TAG_LIST: () => `${getLensBaseURL()}/tag/report/get/tag-name`,
      TAG_LIST_MONTHLY_DATA: () => `${getLensBaseURL()}/tag/report/get`,
      TAG_LIST_MONTHLY_DATA_EXPANDALL: () => `${getLensBaseURL()}/tag/report/get/detail-data`,
      TAG_LIST_DOWNLONLOAD: () => `${getLensBaseURL()}/tag/report/export`,
    },
    COST_BREAKUP: {
      STORAGE: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/storage/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/storage/export`,
        GET_COST_EXPORT_ID: () => `${getLensBaseURL()}/cost-breakup/storage/export/v2`,
      },
      INSTANCE_COST: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/instance/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/instance/export`,
        GET_COST_EXPORT_ID: () => `${getLensBaseURL()}/cost-breakup/instance/export/v2`,
        GET_POLL_STATUS: (id) => `${LENS_BASE_URL}/pool/export?id=${id}`,
      },
      SECURITY: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/security/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/security/export`,
      },
      MIGRATION_TRANSFER: {
        GET_DATA: `${LENS_BASE_URL}/cost-breakup/migrationAndTransfer/getData`,
        EXPORT: `${LENS_BASE_URL}/cost-breakup/migrationAndTransfer/export`,
      },
      CONTAINERS: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/containers/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/containers/export`,
      },
      SERVERLESS: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/serverless/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/serverless/export`,
      },
      LOGGING_MONITORING: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/loggingAndMonitoring/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/loggingAndMonitoring/export`,
      },
      DATABASE: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/database/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/database/export`,
      },
      NETWORK_CONTENT_DELIEVERY: {
        GET_DATA: () => `${getLensBaseURL()}/cost-breakup/networkingAndContentDelivery/getData`,
        EXPORT: () => `${getLensBaseURL()}/cost-breakup/networkingAndContentDelivery/export`,
      },
      ANALYTICS: {
        GET_DATA: `${LENS_BASE_URL}/cost-breakup/analytics/getData`,
        EXPORT: `${LENS_BASE_URL}/cost-breakup/analytics/export`,
      },
      MANAGEMENT_GOVERNANCE: {
        GET_DATA: `${LENS_BASE_URL}/cost-breakup/managementAndGovernance/getData`,
        EXPORT: `${LENS_BASE_URL}/cost-breakup/managementAndGovernance/export`,
      },
      MEDIA_SERVICES: {
        GET_DATA: `${LENS_BASE_URL}/cost-breakup/media/getData`,
        EXPORT: `${LENS_BASE_URL}/cost-breakup/media/export`,
      },
      DATA_TRANSFER: {
        GET_DATA: `${LENS_BASE_URL}/cost-breakup/dataTransfer/getData`,
        GET_DATA_SUMMARY: `${LENS_BASE_URL}/cost-breakup/dataTransfer/summary`,
        EXPORT: `${LENS_BASE_URL}/cost-breakup/dataTransfer/export`,
      },
    },
    COSTEXPLORER: {
      GROUP_BY_VALUES: () => `${getLensBaseURL()}/cost-explorer/getGroupByValues`,
      GROUP_BY_TAGNAME_VALUES: () =>
        `${getLensBaseURL()}/cost-explorer/getTagFilterValues?tagName=TAGS`,
      FILTERS_VALUES: () => `${getLensBaseURL()}/cost-explorer/getFilters`,
      TABLE_GRAPHDATA: () => `${getLensBaseURL()}/cost-explorer/getCostExplorerChartTable`,
      TABLE_EXPORT: () => `${getLensBaseURL()}/cost-explorer/export`,
      TAGS_LIST: () => `${getLensBaseURL()}/cost-explorer/getTagFilterValues?tagName=`,
      COSTEXPLORER_REPORT: (id) => `${getLensBaseURL(true)}/filter-report${id ? `/${id}` : ""}`,
    },
    SAVED_REPORTS_LISTING: (type, selected) =>
      `${getLensBaseURL(true)}/filter-report/all/${type}${selected ? `?selected=${selected}` : ""}`,
    SAVED_REPORTS_SAVE_NEW: () => `${getLensBaseURL(true)}/filter-report`,

    RECOMMENDATION: {
      CLOUDFRONT: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/cloudFront`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportCloudFrontReport`,
      },
      S3: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/s3`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportS3Report`,
      },
      INSTANCES: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/instances`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportInstancesReport`,
      },
      EBS: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/ebs`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportEbsReport`,
      },
      EBS_SNAPSHOT: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/ebsSnapshot`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportEbsSnapshotReport`,
      },
      CLB: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/clb`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/exportClbReport`,
      },
      UPGRADE_VERSION: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/upgrade-version`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/export-upgrade-version`,
      },
      IDLE_NETWORK: {
        GET_DATA: `${LENS_BASE_URL}/recommendation/idle-network`,
        DOWNLOAD: `${LENS_BASE_URL}/recommendation/export/idle-network`,
      },
    },
    AWS_CONSOLE: {
      GET_DATA: `${LENS_BASE_URL}/admin-pages/cost/summary`,
      GET_DATA_V2_SUMMARY: `${LENS_BASE_URL}/admin-pages/cost/v2/summary`,
      GET_DATA_V2_DETAILS: `${LENS_BASE_URL}/admin-pages/cost/v2/detail`,
      EXPORT: `${LENS_BASE_URL}/admin-pages/cost/export`,
    },
    S3_CUDOS: {
      GET_S3_GRAPH_DATA: `${LENS_BASE_URL}/S3-dashboard/getS3BucketLast3MonthsCost`,
      GET_S3_USAGE_GRAPH_DATA: `${LENS_BASE_URL}/S3-dashboard/getLast3MonthsCostByUsageType`,
      GET_COST_BY_USAGE_GRAPH_DATA: `${LENS_BASE_URL}/S3-dashboard/getS3DailyCostByUsageType`,
      GET_S3_HIGHLIGHTS: `${LENS_BASE_URL}/S3-dashboard/getS3HighLight`,
      GET_S3_COST_BREAKUP: `${LENS_BASE_URL}/S3-dashboard/getS3CostBreakUp`,
      GET_S3_CARDS: `${LENS_BASE_URL}/S3-dashboard/getCardsDataForS3`,
      EXPORT_S3: `${LENS_BASE_URL}/S3-dashboard/export`,
    },
    DATA_TRANSFER_CUDOS: {
      GET_DATA_TRANSFER_CARDS: `${LENS_BASE_URL}/data-transfer-dashboard/cardsData`,
      GET_DATA_COST_BY_ACCOUNT: `${LENS_BASE_URL}/data-transfer-dashboard/costByAccount`,
      GET_DATA_COST_BY_TYPE: `${LENS_BASE_URL}/data-transfer-dashboard/costByUsageType`,
      GET_DATA_COST_BY_SERVICE_AND_TYPE: `${LENS_BASE_URL}/data-transfer-dashboard/costByServiceAndType`,
      GET_DATA_COST_BY_USAGE_AND_SERVICE: `${LENS_BASE_URL}/data-transfer-dashboard/dailyUsage/service`,
      GET_DATA_COST_BY_OPERATION_ACCOUNTWISE: `${LENS_BASE_URL}/data-transfer-dashboard/operation/account`,
      GET_DATA_COST_BY_OPERATION_REGION: `${LENS_BASE_URL}/data-transfer-dashboard/sankeyCost`,
      GET_DATA_TRANSFER_DASHBOARD_TOPRESOURECES: `${LENS_BASE_URL}/data-transfer-dashboard/topResources`,
      EXPORT_DATA_TRANSFER_CUDOS: `${LENS_BASE_URL}/data-transfer-dashboard/export`,
    },
  },
  CUDOS: {
    GET_CARDS: `${LENS_BASE_URL}/database/getCardsData`,
    GET_TOP_RESOURCES: `${LENS_BASE_URL}/database/topResources`,
    GET_TOP_RESOURCES_TABS: `${LENS_BASE_URL}/database/summary`,
    GET_TAOTAL_ACCOUNTS: `${LENS_BASE_URL}/database/getTotalCostByAccount`,
    GET_COST_REGION: `${LENS_BASE_URL}/database/getTotalCostByRegion`,
    GET_COST_ENGINE: `${LENS_BASE_URL}/database/getTotalCostByEngines`,
    GET_COST_BY_INSTANCE_TYPE: `${LENS_BASE_URL}/database/getCostByInstanceType`,
    GET_COST_BY_TOP_5_USAGE: `${LENS_BASE_URL}/database/getCostByTop5Usage`,
    GET_COST_BY_PRICING_TYPE: `${LENS_BASE_URL}/database/getCostByPricingType`,
    GET_COST_BY_USAGE_TYPE: `${LENS_BASE_URL}/database/getCostByUsageType`,
    GET_COST_BY_STORAGE_TYPE: `${LENS_BASE_URL}/database/getCostByStorageType`,
    GET_RI_COVERAGE_USAGE: `${LENS_BASE_URL}/database/getCostByTotalRiCoverage`,
    GET_COST_BY_SERVICE: `${LENS_BASE_URL}/database/getCostByService`,
    GET_TOP_BUCKET_BY_COST: `${LENS_BASE_URL}/database/getTopBucketByCost`,
    GET_S3_COST_BREAKDOWN: `${LENS_BASE_URL}/database/getS3CostBreakUp`,
    GET_SERVICES_BY_TYPE: `${LENS_BASE_URL}/database/getServicesByType`,
    GET_TYPE_BY_SERVICES: `${LENS_BASE_URL}/database/getTypesByService`,
    GET_REGIONS: `${LENS_BASE_URL}/database/getRegions`,
    GET_COST_BY_INTER_AZ: `${LENS_BASE_URL}/database/getCostByInterAZ`,
    GET_COST_BY_INTERNET_OUT: `${LENS_BASE_URL}/database/getCostByInternetOut`,
    GET_COST_BY_REGION_TO_REGION: `${LENS_BASE_URL}/database/getCostByRegionToRegion`,
    GET_CUDOS_OPERATION_TYPES: `${LENS_BASE_URL}/database/getCudosOperationTypes`,
    GET_CUDOS_STORAGE_TYPES: `${LENS_BASE_URL}/database/getCudosStorageTypes`,
    GET_COST_OF_SERVICE_BY_TYPE: `${LENS_BASE_URL}/database/getCostOfServiceByType`,
    GET_TOP_S3_OPERATION_COST_BY_TYPE: `${LENS_BASE_URL}/database/getTopS3OperationCostByType`,
    GET_TOP_S3_STORAGE_COST_BY_TYPE: `${LENS_BASE_URL}/database/getTopS3StorageCostByType`,
    GET_COST_OF_TYPE_BY_SERVICE: `${LENS_BASE_URL}/database/getCostOfTypeByService`,
    GET_TOP20_USAGE: `${LENS_BASE_URL}/database/getTop20InstanceByUsageCost`,
    GET_RECOMMENDATION_SUMMARY: `${LENS_BASE_URL}/database/getCudosRecommendationSummary`,
    GET_HIGHLIGHT_CARD_DATA: `${LENS_BASE_URL}/database/getCardsHighlights`,
    DATA_TRANSFER_SUMMARY_CARDS: `${LENS_BASE_URL}/database/getCardsSummary/v2`,
    GET_COST_OPERATION: `${LENS_BASE_URL}/database/getCostByOperation`,

    EXPORT_TOP20_USAGE: `${LENS_BASE_URL}/database/export`,
    EXPORT_TOTAL_COST_BY_ACCOUNT: `${LENS_BASE_URL}/database/exportTotalCostByAccount`,
    EXPORT_TOTAL_COST_BY_REGION: `${LENS_BASE_URL}/database/exportTotalCostByRegions`,
    EXPORT_TOTAL_COST_BY_ENGINE: `${LENS_BASE_URL}/database/exportTotalCostByEngines`,
    EXPORT_COST_BY_USAGE: `${LENS_BASE_URL}/database/exportCostByTop5Usage`,
    EXPORT_COST_BY_INSTANCE_TYPE: `${LENS_BASE_URL}/database/exportCostByInstanceType`,
    EXPORT_COST_BY_PRICING_TYPE: `${LENS_BASE_URL}/database/exportTotalCostByPricingType`,
    EXPORT_COST_BY_SERVICE: `${LENS_BASE_URL}/database/exportCostByService`,
    EXPORT_COST_OF_TYPE_BY_SERVICE: `${LENS_BASE_URL}/database/exportCostOfTypeByService`,
    EXPORT_COST_OF_SERVICE_BY_TYPE: `${LENS_BASE_URL}/database/exportCostOfServiceByType`,
    EXPORT_TOP_BUCKET_BY_STORAGE_COST: `${LENS_BASE_URL}/database/exportTopS3StorageCostByType`,
    EXPORT_TOP_BUCKET_BY_OPERATION_COST: `${LENS_BASE_URL}/database/exportTopS3OperationCostByType`,
    EXPORT_TOP_BUCKET_BY_COST: `${LENS_BASE_URL}/database/exportTopBucketByCost`,
    EXPORT_BY_RESOURCES: `${LENS_BASE_URL}/database/exportByResources`,
    EXPORT_S3_COST_BREAKUP: `${LENS_BASE_URL}/database/exportS3CostBreakUp`,
    EXPORT_COST_BY_OPERATION: `${LENS_BASE_URL}/database/exportCostByOperation`,
    EXPORT_COST_BY_REGION_TO_REGION: `${LENS_BASE_URL}/database/exportCostByRegionToRegion`,
    EXPORT_COST_BY_INTERNET_OUT: `${LENS_BASE_URL}/database/exportCostByInternetOut`,
    EXPORT_COST_BY_INTER_AZ: `${LENS_BASE_URL}/database/exportCostByInterAZ`,
  },
  CK_AZURE: {
    GET_CURRENCY: () => `${getLensBaseURL()}/currency/get`,
    RESOURCE_WISE_BREAKUP: {
      // monthly
      RESOURCE_WISE_MONTHLY: () => `${getLensBaseURL()}/resource-group/monthly`,
      RESOURCE_WISE_V2_MONTHLY_SUBSCRIPTIONID: () =>
        `${getLensBaseURL()}/resource-group/v2/monthly/groupBySubscriptionId`,
      RESOURCE_WISE_V2_MONTHLY_RESOURCENAME: () =>
        `${getLensBaseURL()}/resource-group/v2/monthly/groupByResourceName`,
      RESOURCE_WISE_MONTHLYDOWNLOAD: () => `${getLensBaseURL()}/resource-group/exportMonthlyReport`,
      // previous month
      RESOURCE_WISE_PREVIOUSMONTH: () => `${getLensBaseURL()}/resource-group/previousMonths`,
      RESOURCE_WISE_V2_PREVIOUSMONTH_SUBSCRIPTIONID: () =>
        `${getLensBaseURL()}/resource-group/v2/previousMonths/groupBySubscriptionId`,
      RESOURCE_WISE_V2_PREVIOUSMONTH_RESOURCENAME: () =>
        `${getLensBaseURL()}/resource-group/v2/previousMonths/groupByResourceName`,
      RESOURCE_WISE_PREVIOUSMONTHDOWNLOAD: () =>
        `${getLensBaseURL()}/resource-group/exportPreviousMonthReport`,
      // Daily
      RESOURCE_WISE_DAILY: () => `${getLensBaseURL()}/resource-group/dailyBreakup`,
      RESOURCE_WISE_V2_DAILY_SUBSCRIPTIONID: () =>
        `${getLensBaseURL()}/resource-group/v2/dailyBreakup/groupBySubscriptionId`,
      RESOURCE_WISE_V2_DAILY_RESOURCENAME: () =>
        `${getLensBaseURL()}/resource-group/v2/dailyBreakup/groupByResourceName`,
      RESOURCE_WISE_DAILYDOWNLOAD: () =>
        `${getLensBaseURL()}/resource-group/exportDailyBreakupReport`,
    },
    MANUAL_ONBOARDING: {
      ONBOARDING_STATUS: () => `${API_BASE_URL}/azure/onboard-status`,
      SUBMIT_ONBOARDINGDATA: () => `${API_BASE_URL}/azure/customer/partial-onboard`,
      ONBOARDING_POLICY: () => `${API_BASE_URL}/azure/customer/onboarding-meta`,
      VALIDATE_SUMMARY: (id) => `${API_BASE_URL}/azure/customer-onboarding/validate?id=${id}`,
      CUSTOMER_ONBOARDING_LIST: () => `${API_BASE_URL}/azure/customer-onboarding/list`,
    },
    ACTIVATION: {
      MARKETPLACE_USER: `${API_BASE_URL}/sso/user/info`,
      SAVE_MARKETPLACE_SIGNUP_DATA: `${API_BASE_URL}/sso/user/signup`,
    },
    GET_PROJECTCOUNT: `${API_BASE_URL}/azure/customer-onboard`,
    GET_MAV_EXIST: `${API_BASE_URL}/mav/customer/exist`,
    GET_SUBSCRIPTION_ID: `${CK_MARKETPLACE_BASE_URL}/subscription/azure/activate/`,
    SSO_LOGIN: `${API_BASE_URL}/sso/user/login`,
    MICROSOFT_LOGIN: `${API_BASE_URL}/sso/azure/login/`,
    GOOGLE_LOGIN: `${API_BASE_URL}/sso/google/login/`,
  },

  CK_AUTO: {
    CUSTOMER_AUTO_METADATA: `${API_BASE_URL}/customer/auto/metadata`,
    AWS_ACCOUNT: `${API_BASE_URL}/aws/addAwsAccount`,
    AWS_COMPLETE_ONBOARDING: `${API_BASE_URL}/ri-ops/onboard/details`,
    NON_AISPL_GET_AWS_ACCOUNT_IDS: `${API_BASE_URL}/ri-ops/account-ids`,
    SIGN_UP_CK_AUTO: `${API_BASE_URL}/user/signup`,
    LOGIN_PARTNER_META: `${API_BASE_URL}/anonymous/partner-meta`,
    SAVING_ACHIEVED_GET_CARDS: `${CK_AUTO_BASE_URL}/saving-achieved/get/cards`,
    SAVING_ACHIEVED_GET_FINALISED_REPORT: `${CK_AUTO_BASE_URL}/saving-achieved/finalized`,
    SAVING_ACHIEVED_GET_TOTAL_SUMMARY: `${CK_AUTO_BASE_URL}/saving-achieved/summary`,
    SAVING_ACHIEVED_GET_CARDS_AND_TABS: `${CK_AUTO_BASE_URL}/saving-achieved/service/total`,
    SAVING_ACHIEVED_GET_TABLE: `${CK_AUTO_BASE_URL}/saving-achieved/get`,
    SAVING_ACHIEVED_GET_TABLE_DOWNLOAD: `${CK_AUTO_BASE_URL}`,
    POTENTIAL_SAVINGS_GET_DETAILED: `${CK_AUTO_BASE_URL}/potential-savings/detailed`,
    POTENTIAL_SAVINGS_DETAILED_EXPORT: `${CK_AUTO_BASE_URL}/potential-savings/detailed/export`,
    MIN_MAX_DATES_CK_AUTO: `${CK_AUTO_BASE_URL}/dates/min-and-max`,
    GET_ACCOUNT_DETAILS: `${CK_AUTO_BASE_URL}/account/getAccountDetails`,
    GET_BUCKET_NAME: `${API_BASE_URL}/auto-meta/customer/s3-bucket-name`,
    GET_EXTERNAL_ID: `${API_BASE_URL}/customer-external-id/ri-ops`,
    SAVING_ACHIEVED_GET_TABLE_DOWNLOAD_PATH: `${CK_AUTO_BASE_URL}/saving-achieved/get/export`,
    POTENTIAL_SAVINGS_GRAPH_DATA: `${CK_AUTO_BASE_URL}/potential-savings/summary/v2`,
    PARTNER_POTENTIAL_SAVINGS_GRAPH_DATA: `${API_BASE_URL}/partner-offering/potential-saving/summary/v2`,
    PARTNER_POTENTIAL_SAVINGS_GET_DETAILED: `${API_BASE_URL}/partner-offering/existing-commitments`,
    PARTNER_POTENTIAL_SAVINGS_DETAILED_EXPORT: `${API_BASE_URL}/partner-offering/potential-saving/export`,
    RIOPS_GET_ENABLE_STATUS: `${API_BASE_URL}/ri-ops/enableRiOps`,
    RIOPS_GET_ACCOUNT_IDS: `${API_BASE_URL}/ri-ops/account-ids`,
    GET_CUSTOMER_PLAN: `${API_BASE_URL}/customer/plan`,
    ONBOARD_CUSTOMER: `${API_BASE_URL}/auto-meta/customer/onboard`,
    GET_CUR_DETAIL: `${API_BASE_URL}/auto-meta/customer/cur-onboarding-meta-data`,
    GET_CUR_INLINE_POLICY: `${API_BASE_URL}/auto-meta/customer/cur-onboarding-meta-data/inline-policy`,
    RIOPS_GET_ACCOUNT_ID_DETAILS: `${API_BASE_URL}/ri-ops/onboard/details`,
    PAYER_VALIDATE: `${API_BASE_URL}/payer/validate`,
    GET_NOTIFICATION: `${API_BASE_URL}/reminder`,
    VERIFY_ARN: `${API_BASE_URL}/ri-ops/onboard/validate`,
    CHECK_CUR_REPLICATION: `${API_BASE_URL}/auto-meta/check-cur-replication`,
    CUSTOMER_AND_PARTNER_LEVEL_PLAN: `${API_BASE_URL}/partner/customers-offering/all`,
    CK_CUSTOMER_OFFERING: `${API_BASE_URL}/partner-offering/get`,
    DISTINCT_OFFERINGS: `${API_BASE_URL}/partner-offering/all`,
    UPDATE_PARTNER_PLAN: `${API_BASE_URL}/partner-offering/update/default`,
    UPDATE_CUSTOMER_PLAN: `${API_BASE_URL}/partner-offering/customer/update`,
    PLAN_SELECTION_GET_TABLE_DOWNLOAD_PATH: `${API_BASE_URL}/partner/customers-offering/all/export`,
    GET_TOTAL_SAVING_CUTOMER: `${CK_AUTO_BASE_URL}/potential-savings/total-savings`,
    GET_TOTAL_SAVING_PARTNER: `${API_BASE_URL}/partner-offering/potential-saving/total-savings/`,
    VALIDATE_ARN: `${API_BASE_URL}/account-type/validate`,

    // Missed Savings
    MISSED_SAVING: {
      SERVICES: `${CK_AUTO_BASE_URL}/missed-savings/services/`,
      EXPORT: `${CK_AUTO_BASE_URL}/missed-savings/detail/export`,
      DETAILS: `${CK_AUTO_BASE_URL}/missed-savings/detail/`,
    },
  },
  LEGACY_LENS: {
    GET_BUCKET_NAME_LENS: `${API_BASE_URL}/lens-meta/customer/s3-bucket-name`,
    GET_PROJECT_COUNT_LEGACY_LENS: `${API_BASE_URL}/aws/account-onboard`,
  },
  VIRTUAL_RESERVATIONS: {
    GET_RESERVATIONLIST: (payload) => `${API_BASE_URL}/virtual-ri/${payload.service}/${payload.id}`,
    // GET_RESERVATIONLIST: (payload) =>
    //   `${API_BASE_URL}/virtual-ri/${payload.service}/getAll/${payload.id}`,
    GET_RESERVATIONLIST_CUSTOMER: (service) => `${API_BASE_URL}/virtual-ri/${service}`,
    GET_VR_DROPDOWN_CONFIG: `${API_BASE_URL}/virtual-ri/config`,
    GET_CART_TABLE_DATA: (service) => `${API_BASE_URL}/virtual-ri/${service}/search`,
    GET_CUSTOMER_ACCOUNTID: `${API_BASE_URL}/virtual-ri/accounts`,
    GET_PARTNER_ACCOUNTID: (customerId) => `${API_BASE_URL}/account/all?customerId=${customerId}`,
    PLACE_CART_ORDER: (params) =>
      `${API_BASE_URL}/virtual-ri/${params?.service}/place-order/${params?.customerId}`,
    PLACE_CART_ORDER_CUSTOMER: (params) =>
      `${API_BASE_URL}/virtual-ri/${params?.service}/place-order?accountId=${params?.accountId}`,
    UPDATE_RESERVATION: (service, customerId) =>
      `${API_BASE_URL}/virtual-ri/${service}/update/${customerId}`,
    DOWNLOAD_VR_DATA: `${API_BASE_URL}/virtual-ri/get/export`,
    GET_VR_DOWNLOAD_TABS_LIST: (customerId) =>
      `${API_BASE_URL}/virtual-ri/count${customerId ? `/${customerId}` : ""}`,
    COMMENT_HISTORY: (service, id) => `${API_BASE_URL}/virtual-ri/${service}/comment-history/${id}`,
  },
  FLAT_DISCOUNT: {
    GET_DISCOUNT_LIST: (customerId) => `${API_BASE_URL}/flat-discount/${customerId}`,
    UPDATE_FLAT_DISCOUNT: (customerId) => `${API_BASE_URL}/flat-discount/${customerId}`,
    GET_TEMPLATE_CONFIGURATION: `${API_BASE_URL}/discount-template/config`,
    SAVE_TEMPLATE_CONFIGURATION: `${API_BASE_URL}/discount-template`,
    GET_TEMPLATE_BY_ID: `${API_BASE_URL}/discount-template`,
    SAVE_FLAT_DISCOUNT: (id) => `${API_BASE_URL}/flat-discount/${id}`,
    TEMPLATE_LIST: `${API_BASE_URL}/discount-template/names`,
    TEMPLATE_LIST_SERVICE_WISE: (serviceName) =>
      `${API_BASE_URL}/discount-template/names?serviceType=${serviceName}&templateType=RESERVATION_TYPE`,
    TEMPLATE_MODAL_DATA: (id) => `${API_BASE_URL}/discount-template/${id}`,
    DOWNLOAD_FLAT_DISCOUNT: (id) => `${API_BASE_URL}/flat-discount/export/${id}`,
  },
  CHARGES_AND_REFUND: {
    DOWNLOAD_CHARGES_AND_REFUND: (id) => `${API_BASE_URL}/charges-refunds/export/${id}`, // Update path
    GET_CHARGES_AND_REFUND_LIST: (customerId) => `${API_BASE_URL}/charges-refunds/${customerId}`,
    GET_CHARGES_AND_REFUND_CONFIGURATION: () => `${API_BASE_URL}/config/charges-refunds/ui`,
    SET_CHARGES_AND_REFUND_CONFIGURATION: () => `${API_BASE_URL}/config/charges-refunds`,
    GET_CHARGE_AND_REFUND_OPTIONS: (params) =>
      `${API_BASE_URL}/config/charges-refunds?billdeskConfigType=${params}`,
    UPDATE_CHARGES_AND_REFUNDS: (customerId) => `${API_BASE_URL}/charges-refunds/${customerId}`,
    SAVE_CHARGES_AND_REFUNDS: (id) => `${API_BASE_URL}/charges-refunds/${id}`,
  },
  EDP_TEMPLATES: {
    CONFIGURATION_List: "https://run.mocky.io/v3/f738fe9e-a306-4170-8eb0-2d5b031b21fe",
    TEMPLATE_LIST: `${API_BASE_URL}/discount-template/names`,
    SAVE_EDP: (id) => `${API_BASE_URL}/edp-discount/${id}`,
    EDP_DISCOUNT_TEMPLATE: `${API_BASE_URL}/discount-template`,
    EDP_DISCOUNT_List: (id) => `${API_BASE_URL}/edp-discount/${id}`,
    EXPORT_EDP_LIST: (id) => `${API_BASE_URL}/edp-discount/export/${id}`,
  },
  EDP_DISCOUNT: {
    END_EDP_DISCOUNT: (id) => `${API_BASE_URL}/edp-discount/${id}`,
  },
  RESERVATION_DISCOUNT: {
    UPDATE_RESERVATION_DISCOUNT: (id) => `${API_BASE_URL}/reservation-discount/${id}`,
    GET_RESERVATION_DISCOUNT_LIST: (id) => `${API_BASE_URL}/reservation-discount/${id}`,
    GET_RESERVATION_DISCOUNT_DOWNLOAD_TABS_LIST: (customerId) =>
      `${API_BASE_URL}/reservation-discount/count${customerId ? `/${customerId}` : ""}`,
    DOWNLOAD_RESERVATION_DATA_DATA: `${API_BASE_URL}/reservation-discount/export`,
  },
  CREDITS: {
    GET_CREDITS_LIST: (id) => `${API_BASE_URL}/credits/${id}`,
    EXPORT_CREDITS_LIST: (id) => `${API_BASE_URL}/credits/export/${id}`,
    COMMENT_HISTORY: (id) => `${API_BASE_URL}/credits/comment-history/${id}`,
  },
  ADDITIONAL_DISCOUNT: {
    GET_DATA: (id) => `${API_BASE_URL}/virtual-ri/discount/${id}`,
    COMMENT_HISTORY: (customerId, id) =>
      `${API_BASE_URL}/virtual-ri/discount/comment-history/${customerId}/${id}`,
    EXPORT_LIST: (id) => `${API_BASE_URL}/virtual-ri/discount/export/${id}`,
    GET_SERVICE_LIST: `${API_BASE_URL}/config/virtual-ri/service`,
    SAVE_DISCOUNT: (id) => `${API_BASE_URL}/virtual-ri/discount/${id}`,
  },
  FINOPS: {
    GETMONTHLYREPORTLIST: `${KONG_URL}/finops/customer/all`,
    SAVEREPORTLIST: `${KONG_URL}/finops/monthly-report/generate`,
    START_DATE: `${KONG_URL}/finops/customer/min-date`,
    UPLOAD_MONTHLYREPORT: `${KONG_URL}/finops/monthly-report/upload`,
    ALL_RECOMMENDATIONS: `${KONG_URL}/lens/finops/recommendation/all`,
    CUSTOMER_WISE_RECOMMENDATIONS: `${KONG_URL}/lens/finops/recommendation/customer-wise`,
    GETMAVLISTONPARTNER: `${KONG_URL}/finops/customer/mavList`,
    GENERATE_WAR: `${KONG_URL}/war-report/war-excel/generate`,
    ALL_WAR_REPORT: `${KONG_URL}/war-report/war-excel/all`,
    DOWNLOAD_WAR: `${KONG_URL}/war-report/war-excel/downloadReport`,
    VALIDATE_ARNS: `${KONG_URL}/war-report/war-excel/test-arn`,
    ALL_WAR_SERVICES_LIST: `${KONG_URL}/war-report/war-excel/warServices`,
  },
  CK_MARKETPLACE: {
    GET_CUSTOMERS: `${CK_MARKETPLACE_BASE_URL}/partner-config/getCustomers`,
    CUSTOMER_METERING_RECORDS_SUMMARY: `${CK_MARKETPLACE_BASE_URL}/customer/metering-records-card`,
    CUSTOMER_METERING_RECORDS: `${CK_MARKETPLACE_BASE_URL}/customer/metering-records`,
    SEND_METERING_RECORDS: `${CK_MARKETPLACE_BASE_URL}/customer/metering/send-metering-record`,
    CUSTOMER_METERING_FINALIZED: `${CK_MARKETPLACE_BASE_URL}/customer/metering/finalized`,
    MARKETPLACE_MIN_DATE: `${CK_MARKETPLACE_BASE_URL}/partner/customers/min-date`,
    CUSTOMER_METERING_RECORDS_SHEET_DOWNLOAD: `${CK_MARKETPLACE_BASE_URL}/customer/metering-records/export`,
    CUSTOMER_INVOICE_RECORDS: `${CK_MARKETPLACE_BASE_URL}/pricing/info`,
    GET_RI_OPS_ACTIVE_CUSTOMERS: `${API_BASE_URL}/customer/getRiOpsActiveCustomers`,
    CUSTOMER_INVOICE_RECORDS_SHEET_DOWNLOAD: `${CK_MARKETPLACE_BASE_URL}/pricing/info/export`,
  },

  // Reservation Disocunt
  SAVERESERVATIONDISCOUNT: (id) => `${API_BASE_URL}/reservation-discount/${id}`,

  // Support Configuration Accordion :
  SUPPORTCONFIGURATIONFORM: `${API_BASE_URL}/config/support/ui`,
  GETSUPPORTCONFIGBYID: (custId) => `${API_BASE_URL}/support/${custId}`,
  MOVEACCOUNT: (custId) => `${API_BASE_URL}/support/move/${custId}`,
  EXPORT_EDP_LIST: (id) => `${API_BASE_URL}/support/export/${id}`,
  UPDATESUPPORTCONFIG: `${API_BASE_URL}/support/update`,
  CK_QUOTE_GEN: {
    GET_LIST: `${QUOTE_GEN_BASE_URL}/codegen/get`,
    UPLOAD_QUOTATION: `${QUOTE_GEN_BASE_URL}/codegen/upload`,
  },

  MEDIA_CONNECT: {
    GET_REGION_LIST: `${API_BASE_URL}/ppa/regions`,
    GET_PRICING_LIST: `${API_BASE_URL}/ppa/pricing`,
    GET_CBU_LIST: `${API_BASE_URL}/ppa/pricing`,
  },

  /* add selected Tab here  */
  PPA: {
    GET_PPA_TABLE_DATA: (id, tab) => `${API_BASE_URL}/ppa/${tab}/${id}`,
    UPDATE_PPA_DISCOUNT: (selectedTab, id) => `${API_BASE_URL}/ppa/${selectedTab}/${id}`,
    DOWNLOAD_PPA_DATA: `${API_BASE_URL}/ppa/export`,
    SAVE_PPA_CONFIGURE: (id, selectedKey) => `${API_BASE_URL}/ppa/${selectedKey}/${id}`,
    GET_PPA_DOWNLOAD_TABS_LIST: (id) => `${API_BASE_URL}/ppa/count${id ? `/${id}` : ""}`,
    GET_PPA_CONFIGURATION_RATE: () => `${API_BASE_URL}/config/media-live/ui`,
    COMMENT_HISTORY: (customerId, payload) =>
      `${API_BASE_URL}/ppa/${payload?.tab}/comment-history/${customerId}/${payload?.id}`,
    PREVIEW_DATA: (customerId, payload) =>
      `${API_BASE_URL}/ppa/${payload?.tab}/${customerId}/${payload?.id}`,
  },
  GET_CUSTOMER_INVOICE_DATE: (id) => `${API_BASE_URL}/customer/meta/${id}`,
  ORDERING_TEMPLATE: (id) => `${API_BASE_URL}/ordering-template${id ? `/${id}` : ""}`,
  SAVE_ORDERING_TEMPLATE: `${API_BASE_URL}/ordering-template`,
  GET_DEFAULT_ORDERING: `${API_BASE_URL}/config/ordering-template`,
  TEMPLATE_HISTORY: (id) => `${API_BASE_URL}/ordering-template/history/${id}`,
  CUSTOMER_TYPE_HISTORY: (id) => `${API_BASE_URL}/customer-type/history/${id}`,
  CUSTOMER_INVOICE_DATE_HISTORY: (id) =>
    `${API_BASE_URL}/customer-metadata/lastInvoicingDate/history/${id}`,
  GET_CUSTOMER_ORDERING_LIST_BY_ID: (id) => `${API_BASE_URL}/ordering-template/${id}`,

  // Account Sync API
  ACCOUNT_SYNC_API: `${KONG_URL}/scheduler/accounts/create`,
  PAYER_ACCOUNT_SYNC_API: `${KONG_URL}/scheduler/accounts/sync`,
  CUR: {
    GET_CUR_COLUMN_FILTERS: `${API_BASE_URL}/costAndUsage/curReport/getFilters`,
    SAVE_CUR_DATA: `${API_BASE_URL}/costAndUsage/curReport/save`,
    GET_CUR_LIST: `${API_BASE_URL}/costAndUsage/curReport/getAll`,
    GET_CUR_CONFIG_PREVIEW: `${API_BASE_URL}/costAndUsage/curReport/getConfigurationPreview`,
  },

  USAGE_STATEMENTS: {
    GET_CONFIG: `${API_BASE_URL}/config/usage-statement/ui`,
  },

  CUSTOMER_ACCOUNT: {
    GET_CUSTOMER_LIST: (id) => `${API_BASE_URL}/customer/accounts/all/${id}`,
    EXPORT_CUSTOMER_LIST: (id) => `${API_BASE_URL}/customer/accounts/export/${id}`,
  },

  HOURLY_GRID_COMPUTE: {
    GET_FILTER: `${LENS_BASE_URL}/hourly/getFiltersValues`,
    GET_GROUP_DATA: (id) => `${LENS_BASE_URL}/hourly/getGraphData?service=${id?.toUpperCase()}`,
    GET_GROUP_BY: (id) => `${LENS_BASE_URL}/hourly/getGroupByValues?service=${id?.toUpperCase()}`,
    SAVE_REPORT: "https://run.mocky.io/v3/217f0183-8ddc-493d-a381-f7ddf88f9569",
    UPDATE_REPORT: "https://run.mocky.io/v3/217f0183-8ddc-493d-a381-f7ddf88f9569",
    GET_VIEW_BY: `${LENS_BASE_URL}/hourly/getViewTypes`,
    GET_AGGREGATE_COST: `${LENS_BASE_URL}/hourly/getCostTypes`,
    GET_FILTERS_BY_FILTERNAME: (id) =>
      `${LENS_BASE_URL}/hourly/getFilters?service=${id?.toUpperCase()}`,
    GET_TAG_FILTERS_BY_FILTERNAME: `${LENS_BASE_URL}/hourly/getTagFilterValues`,
    GET_HEATMAP_LISTING: (id) =>
      `${LENS_BASE_URL}/hourly/getHourlyData?service=${id?.toUpperCase()}`,
    EXPORT_HEATMAP_DATA: (id) =>
      `${LENS_BASE_URL}/hourly/getHourlyData/export?service=${id?.toUpperCase()}`,
    GET_HEATMAP_MIN_MAX_LISTING: (id) => `${LENS_BASE_URL}/hourly/getCardsData?service=${id}`,
    GET_DASHBOARD_DATES: () => `${API_BASE_URL}/customer/dashboard/dates`,
  },
  RBAC: {
    GET_PERMISSION_GROUP_LIST: `${API_BASE_URL}/role/get/all`,
    GET_PERMISSION_GROUP_DETAIL: (roleId) => `${API_BASE_URL}/role/${roleId}`,
    GET_ACCESS_TYPES: `${API_BASE_URL}/role/access-level`,
    GET_MODULE_LIST: `${API_BASE_URL}/module/permission`,
    GET_MODULE_DASHBOARD_LIST: `${API_BASE_URL}/dashboard/permission`,
    GET_ACTION_TYPES: `${API_BASE_URL}/action-type/list`,
    CREATE_USER: `${API_BASE_URL}/role`,
    DELETE_PERMISSION_GROUP: (id) => `${API_BASE_URL}/role/delete?roleId=${id}`,
    TAGS: {
      GET_ALL: `${API_BASE_URL}/tag`,
      GET_FOR_CUSTOMER: `${API_BASE_URL}/tag/owner`,
      GET_BY_ID: (id) => `${API_BASE_URL}/tag/${id}`,
      DELETE: (id) => `${API_BASE_URL}/tag/${id}`,
      CREATE: `${API_BASE_URL}/tag`,
      UPDATE: (id) => `${API_BASE_URL}/tag/${id}`,
    },
  },
  CK_ANALYTICS: {
    MARKETPLACE_RI: {
      GET_FILTER_VALUES: `${CK_ANALYTICS_BASE_URL}/marketplace/filter`,
      EXPORT_EXCEL_SHEET: `${CK_ANALYTICS_BASE_URL}/marketplace/export`,
      GET_MARKETPLACE_RI_DATA: `${CK_ANALYTICS_BASE_URL}/marketplace/data`,
    },
    MARKETPLACE_RI_BULK_UPLOAD: {
      GET_PROCCESSED_FILE_DATA: `${CK_ANALYTICS_BASE_URL}/marketplace/search-history`,
      DOWNLOAD_PROCCESSED_EXCEL_SHEET: `${CK_ANALYTICS_BASE_URL}/marketplace/download`,
      UPLOAD_FILTERS_EXCEL_SHEET: `${CK_ANALYTICS_BASE_URL}/marketplace/upload`,
      DOWNLOAD_TEMPLATE_SHEET: `${STATIC_RESOURCE_URL}/Market-place-upload-template.xlsx`,
      GET_PROCCESSED_DATA: `${CK_ANALYTICS_BASE_URL}/marketplace/fetch-data`,
    },
    REVENUE: {
      getData: (subDashboard, monthlyOrQuaterly) =>
        `${CK_ANALYTICS_BASE_URL}/${subDashboard}/${monthlyOrQuaterly}/getData`,
      getDataByType: (subDashboard) => `${CK_ANALYTICS_BASE_URL}/${subDashboard}/getDataByType`,
      exportData: (subDashboard) => `${CK_ANALYTICS_BASE_URL}/${subDashboard}/export`,
    },
    RI_INVENTORY: {
      GET_TABS_DATA: `${CK_ANALYTICS_BASE_URL}/inventory/services`,
      GET_ARN_DATA: `${CK_ANALYTICS_BASE_URL}/inventory/all`,
      GET_INVENTORY_DETAILS: `${CK_ANALYTICS_BASE_URL}/inventory/details`,
      GET_INVENTORY_EC2_DETAILS: `${CK_ANALYTICS_BASE_URL}/inventory/ec2/details`,
      DOWNLOAD_EC2: `${CK_ANALYTICS_BASE_URL}/inventory/ec2/export`,
      DOWNLOAD: `${CK_ANALYTICS_BASE_URL}/inventory/export`,
    },
    TTN_RI_VS_VRI: {
      GET_TABLE_DATA: `${CK_ANALYTICS_BASE_URL}/ttnrivsvri/getData`,
      RI_DETAILS: (riType) => `${CK_ANALYTICS_BASE_URL}/ttnrivsvri/getData/payer/${riType}`,
      EXPORT: (riTypeWithPayer) => `${CK_ANALYTICS_BASE_URL}/ttnrivsvri/export/${riTypeWithPayer}`,
    },
    ERROR_INDEX: `${CK_ANALYTICS_BASE_URL}/validator/codes`,
    COVERAGE: {
      GET_CUSTOMER_WISE_DETAILS: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getCustomerWiseDetails`,
      GET_RI_EXPIRY: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getRiExpiry`,
      GET_CUSTOMER_WISE_DETAILS_OTHER_PAYERS: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getCustomerWiseDetailsOtherPayer`,
      GET_RI_EXPIRY_OTHER_PAYERS: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getRiExpiryForOtherPayer`,
      GET_RI_PRICE_TABLE_DATA: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getRiPriceTable`,
      GET_COVERAGE_TRAND_DATA: `${CK_ANALYTICS_BASE_URL}/ri-coverage/detail/getCoverageTrend`,
      GET_RI_COVERAGE_CART: (param) =>
        `${CK_ANALYTICS_BASE_URL}/ri-coverage/cart${param ? `?serviceName=${param}` : ""}`,
      RI_COVERAGE: `${CK_ANALYTICS_BASE_URL}/ri-coverage`,
      DATA_INFO: `${CK_ANALYTICS_BASE_URL}/ri-coverage/data`,
      EXPORT: `${CK_ANALYTICS_BASE_URL}/ri-coverage/export`,
    },
    UNUSED_INVENTORY: {
      WASTAGE: `${CK_ANALYTICS_BASE_URL}/ri-wastage`,
      WASTAGE_DATA: `${CK_ANALYTICS_BASE_URL}/ri-wastage/data`,
      WASTAGE_EXPORT: `${CK_ANALYTICS_BASE_URL}/ri-wastage/export`,
      GET_UP_COMING_RI_EXPIRY: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getUpComingRiExpiry`,
      GET_INVENTORY_DATA: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getInventoryList`,
      GET_ON_DEMAND_COST_DAILY: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getOnDemandCostDaily`,
      GET_MIN_OD_COST_DAILY: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getMinOnDemandCostDaily`,
      GET_RI_WASTAGE_HOURLY_DATA: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getRiWastageHourlyData`,
      GET_RI_WASTAGE_TRAND_DATA: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/getRiWastageTrendSummary`,
      EXPORT_RI_INVENTORY_TABLE_DATA: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/exportInventoryDetails`,
      EXPORT_WASTAGE_DETAILS: `${CK_ANALYTICS_BASE_URL}/ri-wastage/detail/exportWastageDetails`,
    },
    DASHBOARD_DATES: `${CK_ANALYTICS_BASE_URL}/dashboard/dates`,
    NOTIFICATIONS: {
      ON_DEMAND_SPEND: {
        GET_LIST: `${CK_ANALYTICS_BASE_URL}/onDemandSpend/get`,
        CREATE_ALERT: `${CK_ANALYTICS_BASE_URL}/onDemandSpend/create`,
        UPDATE_ALERT: `${CK_ANALYTICS_BASE_URL}/onDemandSpend/update`,
        DELETE_ALERT: (alertId) =>
          `${CK_ANALYTICS_BASE_URL}/onDemandSpend/delete?alertId=${alertId}`,
        GET_ALERT_DETAILS: (alertId) =>
          `${CK_ANALYTICS_BASE_URL}/onDemandSpend/getById?alertId=${alertId}`,
      },
    },
  },
  CK_SLACK_ERROR_LOG: `${API_BASE_URL}/trigger/slack-custom-message`,
  SAVE_BUY_AGAIN_DETAILS: (service, customerId) =>
    `${API_BASE_URL}/virtual-ri/${service}/buy-again/${customerId}`,
  SAVED_REPORT_TAB: `${API_BASE_URL}/dashboard/details/v2`,

  // EKS
  CUDOS_EKS: {
    SUMMARY_CARDS: `${LENS_BASE_URL}/database/getCardsSummary`,
    GET_COST_CLUSTER: `${LENS_BASE_URL}/database/getTotalCostByClusters`,
    GET_COST_NAMESPACE: `${LENS_BASE_URL}/database/getTotalCostByNamespace`,
    GET_COST_PURCHASE: `${LENS_BASE_URL}/database/getTotalCostByPurchaseType`,
    EXPORT_TOTAL_COST_BY_CLUSTER: `${LENS_BASE_URL}/database/exportTotalCostByClusters`,
    EXPORT_TOTAL_COST_BY_NAMESPACE: `${LENS_BASE_URL}/database/exportTotalCostByNamespace`,
    EXPORT_TOTAL_COST_BY_PURCHASE_TYPE: `${LENS_BASE_URL}/database/exportTotalCostByPurchaseType`,
    EXPORT_TOTAL_COST_BY_USAGE_TYPE: `${LENS_BASE_URL}/database/exportTotalCostByUsageType`,
    EXPORT_TOTAL_COST_BY_STORAGE_TYPE: `${LENS_BASE_URL}/database/exportTotalCostByStorageType`,
    EXPORT_TOP_20_USAGE: `${LENS_BASE_URL}/database/export`,
  },
  CUSTOMERS: {
    ALL: `${API_BASE_URL}/customer/all/v2`,
    TYPE: `${API_BASE_URL}/customer-type`,
    DOWNLOAD: `${API_BASE_URL}/customer/export`,
    SAVE_LASTINVOICE: `${API_BASE_URL}/customer-metadata/lastInvoicingDate`,
    GET_EFFECTIVE_DATE: `${API_BASE_URL}/customer-metadata/effective-date`,
  },
  CK_TUNER: {
    GET_HOMEPAGE_DATA: (startDate, endDate, mavId) =>
      `${CK_TUNER_BASE_URL}/analytics/wiseboard?startDate=${startDate}&endDate=${endDate}&accounts=${mavId}`,
    GET_AUDIT_DATA: `${CK_TUNER_BASE_URL}/app/analytics/savings/`,
    GET_WMR_DATA: `${CK_TUNER_BASE_URL}/app/analytics/wellManagedResources`,
    GET_RECOMMENDATIONS_DATA: `${CK_TUNER_BASE_URL}/app/recommendation`,
    GET_RECOMMENDATIONS_CARDS_DATA: `${CK_TUNER_BASE_URL}/app/analytics/home`,
    GET_RECOMMENDATIONS_SAVINGS: `${CK_TUNER_BASE_URL}/recommendation/potentialSavingsByType`,
    GET_ONBOARDED_ACCOUNTS_DETAILS: `${CK_TUNER_BASE_URL}/user-accounts/onboardingStatus`,
    ACTION_VALIDATE: (id) => `${CK_TUNER_BASE_URL}/user-accounts/validate?accountId=${id}`,
    ACTION_VALIDATE_ONBOARD: `${CK_TUNER_BASE_URL}/customer-onboarding/validateRole`,
    ACTION_UNLINK: (id) => `${CK_TUNER_BASE_URL}/user-accounts/${id}`,
    GET_ACCOUNTS_LISTING: `${CK_TUNER_BASE_URL}/user-accounts`,
    TOGGLE_SCHEDULER: `${CK_TUNER_BASE_URL}/user-accounts/toggle`,
    UPDATE_SCHEDULER: `${CK_TUNER_BASE_URL}/user-accounts/updateSchedulerConfig`,
    GET_PERMISSION_TEMPLATES: `${CK_TUNER_BASE_URL}/user-accounts/get-permission-template`,
    USER_ONBOARD_ATTEMPTS: `${CK_TUNER_BASE_URL}/user-accounts/attempts`,
    USER_ONBOARD_ATTEMPTS_POLL: (id) => `${CK_TUNER_BASE_URL}/user-accounts/attempts/${id}`,
    UPDATE_ACCESS: `${CK_TUNER_BASE_URL}/customer-onboarding/accessType`,
    // SCHEDULER_GET_LISTING: "https://mocki.io/v1/fb6156a8-b867-4bc7-9c38-9640efed4b8b",
    SCHEDULER_GET_LISTING: (selectedTab, mavId) =>
      `${CK_TUNER_BASE_URL}/scheduler/${selectedTab}?accounts=${mavId}`,
    SCHEDULER_GET_CARDS_GRAPHS: (selectedTab, startDate, endDate, mavId) =>
      `${CK_TUNER_BASE_URL}/scheduler/${selectedTab}/savings?startDate=${startDate}&endDate=${endDate}&accounts=${mavId}`,
    SCHEDULER_TOGGLE: (service) => `${CK_TUNER_BASE_URL}/scheduler/${service}/toggle`,
    SCHEDULER_SERVICE_TOGGLE: (service, type) =>
      `${CK_TUNER_BASE_URL}/scheduler/${service}/${type}`,
    SCHEDULER_CONFIG_UPDATE: (service) => `${CK_TUNER_BASE_URL}/scheduler/${service}/update`,
    SCHEDULER_BULK_TOGGLE: (service) => `${CK_TUNER_BASE_URL}/scheduler/${service}/bulkToggle`,
    GET_ACCOUNT_ID_WITH_ACCOUNT_NAME_V2: `${API_BASE_URL}/mav/accountIdsWithAccountName/v2`,
    SUMMARY: {
      GET_CARDS_DATA: `${CK_TUNER_URL}/summary/get-cards-data`,
      GET_TOP_RECOMMENDATIONS: `${CK_TUNER_URL}/summary/get-top-recommendation`,
      SAVINGS_GRAPH: (view, type = null) =>
        `${CK_TUNER_URL}/summary/${view}/saving${type ? `?type=${type}` : ""}`,
      GLANCE_GRAPH: (type) => `${CK_TUNER_URL}/summary/recommendation/glance?type=${type}`,
      ANALYSIS_GRAPH: (type) => `${CK_TUNER_URL}/summary/recommendation/analysis?type=${type}`,
    },
    GET_TUNER_DASHBORD: `${CK_TUNER_URL}/factors`,
    TUNER_GRID: `${CK_TUNER_URL}/factors/customers`,
    ADMIN: {
      GET_ENABLED_FEATURES: `${CK_TUNER_URL}/factors/by-customer`,
    },
  },
  CK_TUNER_ONBOARDING: {
    CUSTOM_TRUST_POLICY: (externalId, accessType) =>
      `${CK_TUNER_BASE_URL}/customer-onboarding/customTrustPolicy?externalId=${externalId}&accessType=${accessType}`,
    INLINE_POLICY: `${CK_TUNER_BASE_URL}/customer-onboarding/inlinePolicy`,
    ONBOARD: `${CK_TUNER_BASE_URL}/customer-onboarding/onboardCustomer`,
    MANAGED_INLINE_POLICY: `${CK_TUNER_BASE_URL}/customer-onboarding/managedInlinePolicy`,
    MANAGED_POLICY: (IAMRole, accessType) =>
      `${CK_TUNER_BASE_URL}/customer-onboarding/managedPolicy?accessType=${accessType}&iamRoleARN=${IAMRole}`,
  },
  CK_COMMENTS: {
    FETCH_COMMENTS: `${CK_COMMENTS}/fetch`,
    ADD_COMMENTS: `${CK_COMMENTS}/add`,
    GET_COMMENTS_COUNT: `${CK_COMMENTS}/fetch/countWithConfig`,
  },
};
