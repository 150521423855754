import React from "react";
import InternalError from "../../Components/Login/500_internalError";

const renderErrorDashbaord = (strapiError) => {
  switch (strapiError.errorCode) {
    case 500:
    case 502:
    case 503:
      return (
        <InternalError message="We are currently experiencing technical difficulties, please try again after sometime." />
      );
    default:
      return <p data-testid="not-found-message">{strapiError?.errorMessage}</p>;
  }
};

export default renderErrorDashbaord;
