import React from "react";
import loader from "../../../Assets/images/loader-new.svg";
import "./style.scss";

function Loader({ popup }) {
  return (
    <div
      className={`loader_wrapper ${popup && "loader-backdrop"}`}
      data-testid="loader-wrapper"
      id="loader"
    >
      <div className="loader">
        <img className="loader-logo" src={loader} alt="loader" border="0" />
        <div>
          <div className="loader-animation" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
