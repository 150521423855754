import React, { Suspense } from "react";
import "./style.scss";
import ReactFC from "react-fusioncharts";
import {
  areaChartConfiguration,
  barChartConfiguration,
  lineChartConfiguration,
  multipleLineChartConfiguration,
  groupChartConfiguration,
  stackColumnChartConfiguration,
  stackBarChartConfiguration,
  shankeyChartConfiguration,
  doughNutChartConfiguration,
} from "./utils";
import { getCurrencySymbol } from "../../../../Utils/commonUtils";

class FusionChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartConfig: barChartConfiguration(props),
      lineChartConfig: lineChartConfiguration(props),
      stackColumnChartConfig: stackColumnChartConfiguration(props),
      multipleLineChartConfig: multipleLineChartConfiguration(props),
      groupChartConfig: groupChartConfiguration(props),
      areaChartConfig: areaChartConfiguration,
      stackBarChartConfig: stackBarChartConfiguration(props),
      shankeyChartConfig: shankeyChartConfiguration(props),
      doughNutChartConfig: doughNutChartConfiguration(props),
    };
    this.InitialLoader();
  }

  componentDidUpdate(previousProps) {
    const { graphData, chartType, granualityType, togglerValue, bgColor, plotToolText } =
      this.props;
    if (
      JSON.stringify(previousProps.graphData) !== JSON.stringify(graphData) ||
      previousProps.chartType !== chartType ||
      previousProps.togglerValue !== togglerValue ||
      previousProps.granualityType !== granualityType ||
      previousProps.bgColor !== bgColor ||
      previousProps.plotToolText !== plotToolText
    ) {
      switch (chartType) {
        case "stackedcolumn2d": {
          const updatedChartConfig = this.updatedStackedColumnChart();
          this.UpdateGraphDataAtLast("stackColumnChartConfig", updatedChartConfig);
          break;
        }
        case "groupChart": {
          const updatedChartConfig = this.updatedGroupColumnChart();
          this.UpdateGraphDataAtLast("stackColumnChartConfig", updatedChartConfig);
          break;
        }
        case "multipleLineChart": {
          const updatedChartConfig = this.updatedMultipleLineChart();
          this.UpdateGraphDataAtLast("multipleLineChartConfig", updatedChartConfig);
          break;
        }
        case "line": {
          const updatedChartConfig = this.updatedLineChart();
          this.UpdateGraphDataAtLast("lineChartConfig", updatedChartConfig);
          break;
        }
        case "column2d": {
          const updatedChartConfig = this.updatedBarChart();
          this.UpdateGraphDataAtLast("barChartConfig", updatedChartConfig);
          break;
        }

        case "area": {
          const updatedChartConfig = this.updatedAreaChart();
          this.UpdateGraphDataAtLast("areaChartConfig", updatedChartConfig);
          break;
        }
        case "stackBarChart": {
          const updatedChartConfig = this.updateStackBarChart();
          this.UpdateGraphDataAtLast("stackBarChartConfig", updatedChartConfig);
          break;
        }
        default:
          this.checkMoreGraphType(chartType);
      }
    }
  }

  checkMoreGraphType = (chartType) => {
    switch (chartType) {
      case "shankey": {
        const updatedChartConfig = this.updatedShankeyChart();
        this.UpdateGraphDataAtLast("shankeyChartConfig", updatedChartConfig);
        break;
      }
      case "doughnutChart": {
        const updatedChartConfig = this.updatedDoughnutChart();
        this.UpdateGraphDataAtLast("doughNutChartConfig", updatedChartConfig);
        break;
      }
      default:
      // break;
    }
  };

  InitialLoader = () => {
    const { chartType } = this.props;
    switch (chartType) {
      case "stackedcolumn2d": {
        const updatedChartConfig = this.updatedStackedColumnChart();
        this.UpdateGraphDataAtLast("stackColumnChartConfig", updatedChartConfig);
        break;
      }
      case "groupChart": {
        const updatedChartConfig = this.updatedGroupColumnChart();
        this.UpdateGraphDataAtLast("groupChartConfig", updatedChartConfig);
        break;
      }
      case "multipleLineChart": {
        const updatedChartConfig = this.updatedMultipleLineChart();
        this.UpdateGraphDataAtLast("multipleLineChartConfig", updatedChartConfig);
        break;
      }
      case "line": {
        const updatedChartConfig = this.updatedLineChart();
        this.UpdateGraphDataAtLast("lineChartConfig", updatedChartConfig);
        break;
      }
      case "column2d": {
        const updatedChartConfig = this.updatedBarChart();
        this.UpdateGraphDataAtLast("barChartConfig", updatedChartConfig);
        break;
      }
      case "area": {
        const updatedChartConfig = this.updatedAreaChart();
        this.UpdateGraphDataAtLast("areaChartConfig", updatedChartConfig);
        break;
      }
      case "stackBarChart": {
        const updatedChartConfig = this.updateStackBarChart();
        this.UpdateGraphDataAtLast("stackBarChartConfig", updatedChartConfig);
        break;
      }
      default:
        this.checkMoreGraphType(chartType);
    }
  };

  UpdateGraphDataAtLast = (stateVariable, updatedChartConfig) => {
    this.setState({
      [stateVariable]: updatedChartConfig,
    });
  };

  updateStackBarChart = () => {
    const { stackBarChartConfig } = this.state;
    const { graphData, xAxisName, yAxisName } = this.props;
    const updatedChartConfig = { ...stackBarChartConfig };
    updatedChartConfig.dataSource.categories[0].category = graphData.category;
    updatedChartConfig.dataSource.dataset = graphData.dataset;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
  };

  updatedBarChart = () => {
    const {
      graphData,
      xAxisName,
      yAxisName,
      plotToolText,
      palettecolors,
      bgColor,
      numberPrefix = "",
      chartBottomMargin,
    } = this.props;
    const { barChartConfig } = this.state;
    const updatedChartConfig = { ...barChartConfig };
    updatedChartConfig.dataSource.data = graphData;
    updatedChartConfig.dataSource.chart.bgColor = bgColor || "#ffffff";

    if (graphData && graphData.length < 10) {
      updatedChartConfig.dataSource.chart.labelStep = "0";
    } else if (graphData && graphData.length > 10 && graphData.length < 20) {
      updatedChartConfig.dataSource.chart.labelStep = "1";
    } else if (graphData && graphData.length > 20) {
      updatedChartConfig.dataSource.chart.labelStep = "6";
    }
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    updatedChartConfig.dataSource.chart.chartBottomMargin = chartBottomMargin;
    updatedChartConfig.dataSource.chart.numberPrefix =
      numberPrefix === "" ? "" : `${getCurrencySymbol()}`;
    updatedChartConfig.dataSource.chart.palettecolors = palettecolors || "#2498fe";
    if (plotToolText) {
      updatedChartConfig.dataSource.chart.plotToolText = plotToolText;
    }
    return updatedChartConfig;
  };

  updatedAreaChart = () => {
    const {
      state: { areaChartConfig },
      props: { graphData, xAxisName, yAxisName },
    } = this;

    const updatedChartConfig = { ...areaChartConfig };
    updatedChartConfig.dataSource.data = graphData;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    return updatedChartConfig;
  };

  updatedShankeyChart = () => {
    const {
      state: { shankeyChartConfig },
      props: { graphData, yAxisName },
    } = this;

    const updatedChartConfig = { ...shankeyChartConfig };
    updatedChartConfig.dataSource.links = graphData.data.links;
    updatedChartConfig.dataSource.nodes = graphData.data.nodes;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    return updatedChartConfig;
  };

  updatedDoughnutChart = () => {
    const {
      state: { doughNutChartConfig },
      props: { graphData },
    } = this;
    const updatedChartConfig = { ...doughNutChartConfig };
    updatedChartConfig.dataSource.data = graphData.data;
    return updatedChartConfig;
  };

  updatedStackedColumnChart = () => {
    const {
      graphData,
      xAxisName,
      yAxisName,
      legendPosition,
      showLegend,
      interactiveLegend = "1",
      drawCrossLine = "1",
      palettecolors,
      numberPrefix,
      graphLabel,
      legendRight = false,
      bgColor,
      plotToolText,
      labelStep,
    } = this.props;
    const { stackColumnChartConfig } = this.state;
    const updatedChartConfig = { ...stackColumnChartConfig };
    updatedChartConfig.dataSource.categories[0].category = graphData.category;
    updatedChartConfig.dataSource.dataset = graphData.dataset;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    if (!legendRight) {
      updatedChartConfig.dataSource.chart.legendPosition = legendPosition;
    }
    updatedChartConfig.dataSource.chart.showLegend = showLegend;
    updatedChartConfig.dataSource.chart.interactiveLegend = interactiveLegend;
    updatedChartConfig.dataSource.chart.drawCrossLine = drawCrossLine;
    updatedChartConfig.dataSource.chart.bgColor = bgColor || "#ffffff";
    if (plotToolText) updatedChartConfig.dataSource.chart.plotToolText = plotToolText;

    updatedChartConfig.dataSource.chart.palettecolors =
      palettecolors || stackColumnChartConfig.dataSource.chart.palettecolors;
    if (graphLabel) {
      updatedChartConfig.dataSource.chart.numberPrefix = "";
    } else {
      updatedChartConfig.dataSource.chart.numberPrefix =
        numberPrefix === "" ? "" : `${getCurrencySymbol()}`;
    }
    if (graphData.category && !labelStep) {
      if (graphData.category.length < 10) {
        updatedChartConfig.dataSource.chart.labelStep = "0";
      } else if (graphData.category.length > 10 && graphData.category.length < 20) {
        updatedChartConfig.dataSource.chart.labelStep = "1";
      } else if (graphData.category.length > 20) {
        updatedChartConfig.dataSource.chart.labelStep = "6";
      }
    }
    return updatedChartConfig;
  };

  updatedGroupColumnChart = () => {
    const {
      graphData,
      xAxisName,
      yAxisName,
      // legendPosition,
      showLegend,
      height,
      showLabels,
      // palettecolors,
      numberPrefix,
      legendIconSides,
      labelStep,
    } = this.props;
    const { groupChartConfig } = this.state;
    const updatedChartConfig = { ...groupChartConfig };
    updatedChartConfig.height = height || "400";
    updatedChartConfig.dataSource.categories[0].category = graphData.category;
    updatedChartConfig.dataSource.dataset = graphData.dataset;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    updatedChartConfig.dataSource.chart.showLabels = showLabels || "0";
    updatedChartConfig.dataSource.chart.showLegend = showLegend;
    updatedChartConfig.dataSource.chart.legendIconSides = legendIconSides;
    updatedChartConfig.dataSource.chart.numberPrefix =
      numberPrefix === "" ? "" : `${getCurrencySymbol()}`;
    if (graphData.category && !labelStep) {
      if (graphData.category.length < 10) {
        updatedChartConfig.dataSource.chart.labelStep = "0";
      } else if (graphData.category.length > 10 && graphData.category.length < 20) {
        updatedChartConfig.dataSource.chart.labelStep = "1";
      } else if (graphData.category.length > 20) {
        updatedChartConfig.dataSource.chart.labelStep = "6";
      }
    }
    return updatedChartConfig;
    // this.UpdateGraphDataAtLast("groupChartConfig", updatedChartConfig);
    // break;
  };

  updatedMultipleLineChart = () => {
    const {
      graphData,
      xAxisName,
      yAxisName,
      legendPosition,
      showLegend,
      // palettecolors,
      showLabels,
      numberPrefix,
      chartLeftMargin,
      graphLabel,
      legendRight = false,
      labelStep,
    } = this.props;
    const { multipleLineChartConfig } = this.state;
    const updatedChartConfig = { ...multipleLineChartConfig };
    updatedChartConfig.dataSource.categories[0].category = graphData.category;
    updatedChartConfig.dataSource.dataset = graphData.dataset;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.showLabels = showLabels;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    if (!legendRight) {
      updatedChartConfig.dataSource.chart.legendPosition = legendPosition;
    }
    updatedChartConfig.dataSource.chart.showLegend = showLegend;

    if (graphLabel) {
      updatedChartConfig.dataSource.chart.numberPrefix = "";
    } else {
      updatedChartConfig.dataSource.chart.numberPrefix =
        numberPrefix === "" ? "" : `${getCurrencySymbol()}`;
    }
    updatedChartConfig.dataSource.chart.chartLeftMargin = chartLeftMargin || "20";
    if (!labelStep) {
      if (graphData.category.length < 10) {
        updatedChartConfig.dataSource.chart.labelStep = "0";
      } else if (graphData.category.length > 10 && graphData.category.length < 20) {
        updatedChartConfig.dataSource.chart.labelStep = "1";
      } else if (graphData.category.length > 20) {
        updatedChartConfig.dataSource.chart.labelStep = "6";
      }
    }
    return updatedChartConfig;
  };

  updatedLineChart = () => {
    const { graphData, xAxisName, yAxisName } = this.props;
    const { lineChartConfig } = this.state;
    const updatedChartConfig = { ...lineChartConfig };
    updatedChartConfig.dataSource.data = graphData;
    updatedChartConfig.dataSource.chart.xAxisName = xAxisName;
    updatedChartConfig.dataSource.chart.yAxisName = yAxisName;
    if (graphData && graphData.length < 10) {
      updatedChartConfig.dataSource.chart.labelStep = "0";
    } else if (graphData && graphData.length > 10 && graphData.length < 20) {
      updatedChartConfig.dataSource.chart.labelStep = "1";
    } else if (graphData && graphData.length > 20) {
      updatedChartConfig.dataSource.chart.labelStep = "6";
    }
    return updatedChartConfig;
  };

  renderCharts = () => {
    const { chartType } = this.props;
    const {
      barChartConfig,
      stackColumnChartConfig,
      groupChartConfig,
      multipleLineChartConfig,
      lineChartConfig,
      areaChartConfig,
      stackBarChartConfig,
      shankeyChartConfig,
      doughNutChartConfig,
    } = this.state;

    switch (chartType) {
      case "column2d": {
        return <ReactFC {...barChartConfig} />;
      }
      case "stackedcolumn2d": {
        return <ReactFC {...stackColumnChartConfig} />;
      }
      case "groupChart": {
        return <ReactFC {...groupChartConfig} />;
      }
      case "area": {
        return <ReactFC {...areaChartConfig} />;
      }
      case "multipleLineChart": {
        return (
          <Suspense fallback={<div>Loading</div>}>
            <ReactFC {...multipleLineChartConfig} />
          </Suspense>
        );
      }
      case "line": {
        return <ReactFC {...lineChartConfig} />;
      }
      case "stackBarChart": {
        return <ReactFC {...stackBarChartConfig} />;
      }
      case "shankey": {
        return <ReactFC {...shankeyChartConfig} />;
      }
      case "doughnutChart": {
        return <ReactFC {...doughNutChartConfig} />;
      }
      default:
        return null;
    }
  };

  render() {
    return <div className="fusion_chart_wrapper">{this.renderCharts()}</div>;
  }
}

export default FusionChart;
