import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as CloseIcons } from "../../../../Assets/icons/ic-cancel.svg";
import { ReactComponent as CloseIconsV2 } from "../../../../Assets/icons/close-model-icon.svg";
import { ReactComponent as CloseIconV3 } from "../../../../Assets/ckTuner/icons/cross.svg";
import "./style.scss";
import InfoBox from "../../../CK-Lens/Common/InfoBox";

export default function DialogComponent({
  handleClose,
  open,
  header = "",
  children,
  showCloseButton = true,
  className = "",
  bodyClass = "",
  scroll = "",
  infoMsg,
  CustomTopComponent = null,
  v3 = false,
}) {
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      className={`relative dialog-component-wrapper ${className}`}
      onClose={handleClose}
      aria-labelledby="customized-dialog"
      open={open}
      fullWidth
      maxWidth="xs"
      BackdropProps={{
        classes: {
          root: classes?.backDrop,
        },
      }}
      scroll={scroll}
    >
      {header ? (
        <DialogTitle className="dialog-header relative !text-[16px]" id="alert-dialog-title">
          {header}
          {CustomTopComponent && <CustomTopComponent />}
          {showCloseButton && (
            <CloseIcons
              onClick={handleClose}
              className="absolute right-[-7px] bottom-6 cursor-pointer "
            />
          )}
          {className.includes("notification-v2") && (
            <CloseIconsV2
              onClick={handleClose}
              className="absolute right-[21px] bottom-[20px] cursor-pointer "
            />
          )}
        </DialogTitle>
      ) : null}
      <DialogContent className={`dialog-body ${bodyClass}`} dividers>
        {v3 && (
          <CloseIconV3
            onClick={handleClose}
            className="absolute w-3.5 h-3.5 right-1 top-1 cursor-pointer z-10"
          />
        )}
        {infoMsg && <InfoBox message={infoMsg} customClass="m-[12px] dialog_modal_info_box" />}
        {children}
      </DialogContent>
    </Dialog>
  );
}
