import React, { useState } from "react";
import {
  getCurrencySymbol,
  numberWithCommas,
  numberWithCommasandDecimal,
} from "../../../../../../../Utils/commonUtils";
import ExcelDownload from "../../../../../../Common/ExcelDownload";
import { ReactComponent as TotalCost } from "../../../Assets/total-cost.svg";
import MultiLevelHeaderTable from "../../../../CostBreakup/MultiLevelHeader";
import { createHeaders, getstorageCostTableTotal } from "../../../helper/utils";
import Tabs from "../../../S3/graph/Tabs";
import { getTotalAccountsPayload, sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import commonService from "../../../../../../../Services/commonService";
import Loader from "../../../../../../Common/Loader";
import "./style.scss";

function CostBreakdown(props) {
  const {
    data,
    onExcelDownload,
    updateConfig,
    className = "",
    heading = "",
    tabs = [],
    configuration = {},
  } = props;
  const [selected, setSelected] = useState(
    tabs?.find((_) => _?.value === configuration?.selectedTab)?.label || tabs?.[0]?.label
  );
  const [showLoader, setShowLoader] = useState(false);
  const [grandTotal, setGrandTotal] = useState(getstorageCostTableTotal(data) || []);
  const [tableData, setTableData] = useState(data);
  const [ascending, setAscending] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const tableLegend = (item) => (
    <div className="item-container">
      <div className="item-heading">
        <span>{item?.column_Label}</span>
      </div>
      <div className="flex">
        <div className="item-content">
          <p>{item?.column_Inter}</p>
          <p>{`${numberWithCommas(item?.inter_az_usage?.toFixed(2))}`}</p>
        </div>
        <div className="item-content">
          <p>{item?.column_Cost}</p>

          <p>{`${numberWithCommasandDecimal(item?.inter_az_cost)}`}</p>
        </div>
      </div>
    </div>
  );

  const tabClicked = async (item) => {
    if (item?.label === selected) {
      return;
    }
    const config = { ...configuration, ...{ selectedTab: item?.value } };
    updateConfig(configuration?.name, config);
    setShowLoader(true);
    if (item?.label === selected?.label) return;
    setSelected(item?.label);
    const payload = getTotalAccountsPayload({
      service: item?.value,
    });
    const resp = await commonService.getTopResources(payload);
    setTableData(resp?.data?.data);
    setGrandTotal(getstorageCostTableTotal(resp?.data?.data));
    setShowLoader(false);
  };

  const tableSortHandler = (tableSortBy) => {
    const sortedData = sortHandler(tableData?.data, ascending, tableSortBy);
    setAscending(!ascending);
    setSortBy(tableSortBy);
    setTableData({
      ...tableData,
      data: sortedData,
    });
  };

  return (
    <div className={`data-transfer-cost-breakdown bg-[#fff] ${className}`}>
      <div className="breakdown-heading">
        <p className="dataTransferheading">{heading}</p>
        {tabs?.length !== 0 && (
          <ExcelDownload
            downloadHandler={() =>
              onExcelDownload(tabs?.find((_) => _?.label === selected)?.value, setExcelLoading)
            }
            hasData={!tableData?.data?.length}
            excelLoading={excelLoading}
          />
        )}
      </div>
      <Tabs
        tabs={tabs}
        tabClicked={tabClicked}
        selected={selected}
        wrapperClass="mt-[16px] justify-center "
        isLoading={showLoader}
      />
      {!showLoader ? (
        <div className="min-h-[250px] relative">
          {grandTotal?.length ? (
            <div className="total-container">
              {grandTotal?.find((item) => item?.column === `Total (${getCurrencySymbol()})`) && (
                <div className="total-cost">
                  <div className="flex gap-[7px] items-center">
                    <TotalCost />
                    <p className="total-value">Total Cost</p>
                  </div>
                  <p className="font-normal text-[22px] text-[#000000] leading-4 mt-[12px]">
                    {`${numberWithCommasandDecimal(
                      grandTotal?.find((item) => item?.column === `Total (${getCurrencySymbol()})`)
                        ?.total
                    )}`}
                  </p>
                </div>
              )}
              <div className="flex gap-[5px]">
                {grandTotal?.map(
                  (item) => item?.column !== `Total (${getCurrencySymbol()})` && tableLegend(item)
                )}
              </div>
            </div>
          ) : null}
          {tableData?.data?.length ? (
            <MultiLevelHeaderTable
              columns={tableData?.data ? createHeaders(tableData?.data, selected) : []}
              data={tableData?.data}
              total={{ total: tableData?.totalCosts?.total }}
              totalVisible
              sortHandlerParent={tableSortHandler}
              selectedSortHeader={sortBy}
              ascending={ascending}
              V2
              lastColumnSticky
            />
          ) : (
            <div className="no_data_found">
              <p>No Data Available</p>
            </div>
          )}
        </div>
      ) : (
        <div className="min-h-[400px]">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default CostBreakdown;
