import React, { useState, useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../Assets/icons/billdesk-icons/ic-delete.svg";
import { ReactComponent as AddIcon } from "../../../../../Assets/icons/billdesk-icons/ic-plus.svg";
import { noop } from "../../../../../Utils/commonUtils";
import { generateUniqueId } from "../../helper";
import { transformInitialFields, createNewField } from "./service/helper";
import "./style.scss";

function DynamicTextFields(props) {
  const {
    name = "",
    error = null,
    wrapperclass = "",
    headerClass = "",
    listWrapperClass = "",
    inputPlaceHolder = "",
    inputClass = "",
    headerLabel = "",
    headerLabelClass = "",
    handleChange = noop,
    resetCount = null,
    initialValue = [],
  } = props;
  const [fields, setFields] = useState([]);
  const [touch, setTouch] = useState({});

  useEffect(() => {
    if (resetCount) {
      if (initialValue?.length) {
        setFields([...(transformInitialFields(initialValue) || [])]);
      } else {
        setFields([createNewField()]);
      }
    }
  }, [resetCount]);

  const handleFieldChange = (id, targetValue) => {
    const updatedFields = fields?.map((field) =>
      field?.id === id ? { ...field, value: targetValue } : field
    );
    setFields(updatedFields);
    handleChange({ target: { name, value: updatedFields } });
  };

  const handleAddField = () => {
    const newId = generateUniqueId();
    const newField = { id: newId, value: "" };
    setFields([...fields, newField]);
    handleChange({ target: { name, value: [...fields, newField] } });
  };

  const handleDeleteField = (id) => {
    const updatedFields = fields?.filter((field) => field?.id !== id);
    setFields(updatedFields);
    handleChange({ target: { name, value: updatedFields } });
  };
  const handleBlur = (id) => {
    const updatedTouch = { ...touch, [id]: true };
    setTouch(updatedTouch);
  };

  useEffect(() => {
    if (Array.isArray(initialValue) && initialValue?.length) {
      setFields([...transformInitialFields(initialValue)]);
    } else {
      setFields([createNewField()]);
    }
  }, []);

  return (
    <div className={`ck-dynamicfields-wrapper ${wrapperclass}`}>
      <div className={`header ${headerClass}`}>
        <h3 className={headerLabelClass}>{headerLabel}</h3>
        <AddIcon onClick={handleAddField} className="add-icon cursor-pointer" />
      </div>
      <div className={`field-list ${listWrapperClass}`}>
        {fields?.map((field) => (
          <div key={field?.id} className="field-item">
            <div className="flex gap-[4px] items-center">
              <input
                name={name}
                type="text"
                value={field?.value}
                placeholder={inputPlaceHolder}
                onChange={(e) => handleFieldChange(field?.id, e.target.value)}
                onBlur={() => handleBlur(field?.id)}
                className={`field-input ${inputClass} ${
                  touch?.[`${field?.id}`] && error?.[`${field?.id}`] && "error_box"
                } ${field?.disabled && "bg-[#F1F2F6]"}`}
                disabled={field?.disabled}
              />
              {!field?.disabled && (
                <DeleteIcon
                  onClick={() => fields?.length > 1 && handleDeleteField(field?.id)}
                  className={`delete-icon ${
                    fields.length <= 1 ? "!cursor-not-allowed" : "cursor-pointer"
                  }`}
                />
              )}
            </div>
            {typeof error === "object" && touch?.[`${field?.id}`] && error?.[`${field?.id}`] && (
              <div className="error_message !relative !mt-[5px]">{error?.[`${field?.id}`]}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DynamicTextFields;
