import React from "react";
// import { Link } from "react-router-dom";
import {
  discountRateArr,
  discountRateValueArr,
  discountSchemeArr,
  discountTypeArr,
  // templateArr,
} from "./mocks";
import PopupList from "../../Common/popupList";
import Calendar from "../../FormConfig/Form/Components/Calendar/Calender";
import BasicSelect from "../../FormConfig/Form/Components/DropDown";
// import Routes from "../../../Config/Routes";
// import { ReactComponent as ExternalLink } from "../../../Assets/icons/external-link.svg";
import EditTable from "./editTable";
import { tenureTableHeaders } from "./configDiscountsConstants";
import RadioButtonGroup from "../../FormConfig/Form/Components/RadioButtonGroup";
import MultipleSelectCheckmarks from "../../Common/MultiSelectCheckMarks";
import Counter from "./counter";
import ListPopup from "../../Common/ListPopup";
import { newTemplateArr, selectAccountChargebackFormConfig } from "./newData";
// import PopOver from "../../CK-Lens/Common/FloatingDropdown";
// import DropDown from "../../FormConfig/Form/Components/DropDown";

import { getCurrencySymbol } from "../../../Utils/commonUtils";

export function returnMaxDecimal(value, pattern, decimal = 5) {
  if (value < 0) {
    return "";
  }
  if (!pattern.test(value)) {
    return Number(value).toFixed(decimal);
  }
  return value;
}

export function toRemove(value, pattern, decimal = 5) {
  if (value < 0) {
    return "";
  }
  if (!pattern.test(value)) {
    return Number(value).toFixed(decimal);
  }
  return value;
}

export const EDPSummaryHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Tenure",
    accessor: "tenure",
    component: PopupList,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: `Commitments (${getCurrencySymbol()})`,
    accessor: "commitment",
    component: PopupList,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Discount (%)",
    accessor: "discounts",
    component: PopupList,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: `Credits(${getCurrencySymbol()})`,
    accessor: "credits",
    component: PopupList,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },
  {
    Header: "End Date",
    accessor: "endDate",
    sortedIcon: false,
  },
  {
    Header: "Template Name",
    accessor: "templateName",
    sortedIcon: false,
  },
  {
    Header: "CBU Name",
    accessor: "cbu",
    sortedIcon: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
export const FlatSummaryHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    sortedIcon: false,
  },
  {
    Header: "Discount (%)",
    accessor: "discountPercentage",
    // component: PopupList,
    // props: {
    //   dataCount: 1,
    // },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },
  {
    Header: "Template Name",
    accessor: "templateName",
    sortedIcon: false,
  },
  {
    Header: "CBU Name",
    accessor: "CBUName",
    component: ListPopup,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
export const RDSummaryHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    sortedIcon: false,
  },
  {
    Header: "Discount Rate Application",
    accessor: "RDdiscountRateApplication",
    sortedIcon: false,
  },
  {
    Header: "Discount Rate Application Value",
    accessor: "RDdiscountRateApplicationValue",
    sortedIcon: false,
  },
  { Header: "Discount %", accessor: "discountPercentage", sortedIcon: false },
  {
    Header: "Start Date",
    accessor: "RDstartDate",
    sortedIcon: false,
  },
  {
    Header: "Template Name",
    accessor: "RDtemplateName",
    sortedIcon: false,
  },
  {
    Header: "CBU Name",
    accessor: "CBUName",
    component: ListPopup,
    props: {
      dataCount: 1,
      shouldSort: false,
    },
    sortedIcon: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
const getDiscountCostColumn = (chargeType, frquencyRadio) => {
  if (frquencyRadio !== "OneTime") {
    return [
      {
        Header: "End Date",
        accessor: "discountEndDate",
        sortedIcon: false,
      },
    ];
  }
  if (chargeType === "Fixed") {
    return [{ Header: "Fixed Cost", accessor: "fixedCost", sortedIcon: false }];
  }

  return [
    { Header: "Template Name", accessor: "templateName", sortedIcon: false },
    { Header: "Discount %", accessor: "discountPercentage", sortedIcon: false },
  ];
};
export const DiscountChargesHeader1 = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Type",
    accessor: "discountChargeType",
    sortedIcon: false,
  },
  {
    Header: "Frequency",
    accessor: "frequncyDuration",
    sortedIcon: false,
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },
];
export const DiscountChargesHeader2 = [
  {
    Header: "CBU Name",
    accessor: "CBUName",
    component: ListPopup,
    props: {
      dataCount: 1,
      shouldSort: false,
    },
    sortedIcon: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
export const DiscountChargesSummaryHeaders = (chargeType, frquencyRadio) => [
  ...DiscountChargesHeader1,
  ...getDiscountCostColumn(chargeType, frquencyRadio),
  ...DiscountChargesHeader2,
];
const flatRateHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    sortedIcon: false,
  },
  {
    Header: "Discount (%)",
    accessor: "discountPercentage",
    sortedIcon: false,
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },
  {
    Header: "Template Name",
    accessor: "templateName",
    sortedIcon: false,
  },
  {
    Header: "CBU Name",
    accessor: "CBUName",
    component: ListPopup,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
const PrivatePricingHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    sortedIcon: false,
  },

  {
    Header: "Tenure",
    accessor: "tenure",
    sortedIcon: false,
  },
  {
    Header: "Commitments(GB)",
    accessor: "commitment",
    component: PopupList,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },

  // {
  //   Header: "CBU Name",
  //   accessor: "CBUName",
  //   component: ListPopup,
  //   props: {
  //     dataCount: 1,
  //   },
  // },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
const savingsBundleHeaders = [
  {
    Header: "Discount Name",
    accessor: "discountName",
    sortedIcon: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    sortedIcon: false,
  },
  {
    Header: `Monthly Commitment (${getCurrencySymbol()})`,
    accessor: "discountPercentage",
    sortedIcon: false,
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: false,
  },
  {
    Header: "CBU Name",
    accessor: "CBUName",
    component: ListPopup,
    props: {
      dataCount: 1,
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    icons: true,
  },
];
const cloudFrontHeaders = (discountScheme) => {
  switch (discountScheme?.value) {
    case "savings_bundle":
      return savingsBundleHeaders;
    case "flat_rate":
      return flatRateHeaders;
    case "private_pricing":
      return PrivatePricingHeaders;
    default:
      break;
  }
  return null;
};
export const getSummaryTableHeaders = (discountType, discountScheme, chargeType, frquencyRadio) => {
  switch (discountType?.value) {
    case "edp":
      return EDPSummaryHeaders;
    case "flat":
      return FlatSummaryHeaders;
    case "reserved_discount":
      return RDSummaryHeaders;
    case "discount_and_charges":
      return DiscountChargesSummaryHeaders(chargeType, frquencyRadio);
    case "cloudfront":
      return cloudFrontHeaders(discountScheme);
    default:
      break;
  }
  return null;
};
export const getSummaryTableData = (
  discountType,
  discountScheme,
  chargeType,
  frquencyRadio,
  tableData = [],
  onActionClickHandler = null
) => ({
  headers: getSummaryTableHeaders(discountType, discountScheme, chargeType, frquencyRadio),
  data: tableData,
  onIconClick: onActionClickHandler,
  tableDataCopy: tableData,
});

export function numberToString(number) {
  // Check if the number ends with 1, 2, or 3, excluding numbers ending with 11, 12, or 13
  if (number % 10 === 1 && number % 100 !== 11) {
    return `${number}<sup>st</sup>`;
  }
  if (number % 10 === 2 && number % 100 !== 12) {
    return `${number}<sup>nd</sup>`;
  }
  if (number % 10 === 3 && number % 100 !== 13) {
    return `${number}<sup>rd</sup>`;
  }
  return `${number}<sup>th</sup>`;
}

export const discountTypeDropdownFormConfig = {
  type: "select",
  name: "selectDiscount",
  placeHolder: "Select Discount Type",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Select Discount Type",
  options: discountTypeArr,
  topLabel: { text: "Select Discount Type", fontSize: "small", class: "!text-black mandatory" },
};
export const discountSchemeDropdownFormConfig = {
  type: "select",
  name: "discountScheme",
  placeHolder: "Select Discount Scheme",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Select Discount Scheme",
  options: discountSchemeArr,
  topLabel: { text: "Select Discount Scheme", fontSize: "small", class: "!text-black mandatory" },
};
export const discountRateDropdownFormConfig = {
  type: "select",
  name: "discountRateApplication",
  placeHolder: "Discount Rate Application",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Discount Rate Application",
  options: discountRateArr,
  topLabel: {
    text: "Discount Rate Application",
    fontSize: "small",
    class: "!text-black mandatory",
  },
};

export const discountRateAppValueDropdownFormConfig = {
  type: "select",
  name: "discountRateApplicationValue",
  placeHolder: "Discount Rate Application Value",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Discount Rate Application Value",
  options: discountRateValueArr,
  topLabel: {
    text: "Discount Rate Application Value",
    fontSize: "small",
    class: "!text-black mandatory",
  },
};

export const templateDropdownFormConfig = (customerName) => ({
  type: "select",
  name: "selectTemplate",
  placeHolder: "Select Template",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Template",
  options: newTemplateArr?.[customerName] || [],
  topLabel: { text: "Template", fontSize: "small", class: "!text-black mandatory" },
});

export const returncommitmentsCalendar = (
  selectedDate,
  selectDateFormElement,
  onDateChange,
  index
) => (
  <div className="date_picker">
    <Calendar
      key={selectDateFormElement.name}
      {...selectDateFormElement}
      handleChange={(event) => onDateChange(event, index)}
      value={selectedDate}
      autoApply
      // values={values}
      // onBlurHandler={(event) => this.handleBlur(event, selectDateFormElement)}
    />
  </div>
);
export const returnCommitmentsTenure = (counter, onCounterChange) => (
  <div className="counter_wrapper">
    <p className="label_font_size mandatory">Tenure (Years)</p>
    <Counter onInputChange={onCounterChange} currentValue={counter} />
  </div>
);
export const returnCommitmentsTemplate = (
  getTemplateDropdownFormConfig,
  selectedTemplate,
  handleDropdownChange,
  index,
  onBlurHandler,
  touched,
  tooltipStyle
) => (
  <div className="select_template_wrapper select_discount_wrapper">
    {/* <div className="flex items-center"> */}
    <BasicSelect
      {...getTemplateDropdownFormConfig}
      value={selectedTemplate}
      enableFilter={{ placeHolder: "Search" }}
      handleChange={(e) => handleDropdownChange(e, index)}
      error="This field is required"
      onBlurHandler={onBlurHandler}
      touched={touched}
      tooltipStyle={tooltipStyle}
    />
  </div>
);

export const returnCommitmentsYearlyWiseTable = (obj) => {
  const {
    editableTableData,
    onTableInputChange,
    radioFormElement,
    handleRadioChange,
    radioValue,
    ConfigDiscountShortfall,
    shortfallCharge,
    handleShortfallChange,
    shortfallChargeAccount,
    handleShortfallChangeAccount,
    customerName,
    shortfallChargeAccountList,
    tooltipStyle,
  } = obj;
  return (
    <div className="table_wrapper">
      <p className="edp-config-table-heading">Year wise commitment(s)</p>
      <EditTable
        headers={tenureTableHeaders}
        tableData={editableTableData}
        tableWrapperClass="tenure_tableWrapper_edp"
        onTableInputChange={onTableInputChange}
        yearlyCommitmentCalculationRadioFormElement={radioFormElement}
        handleRadioChange={handleRadioChange}
        yearlyCommitmentCalculationRadio={radioValue}
        tooltipStyle={tooltipStyle}
        whiteTheme
      />
      <div className="!w-full flex">
        <RadioButtonGroup
          {...ConfigDiscountShortfall}
          value={shortfallCharge}
          handleChange={(e) => handleShortfallChange(e)}
          tooltipStyle={tooltipStyle}
          whiteTheme
        />
      </div>
      {shortfallCharge !== "NEVER" && (
        <div className="pt-[10px] w-[215px] chargeback_wrapper">
          <BasicSelect
            {...selectAccountChargebackFormConfig(customerName)}
            options={shortfallChargeAccountList}
            value={shortfallChargeAccount}
            handleChange={(e) => handleShortfallChangeAccount(e)}
          />
        </div>
      )}
      <hr />
    </div>
  );
};
export const returnFlatCBUDropdown = (cbuNamesList, selectedCbuList, updateCbuList) => (
  <MultipleSelectCheckmarks
    dataList={cbuNamesList}
    noDataMessage={!cbuNamesList?.length ? "No CBU Available" : ""}
    label="Select CBU"
    className="dropDown"
    selectedCbuList={selectedCbuList}
    updateCbuList={updateCbuList}
    getError={!selectedCbuList.length}
    id="FlatCbuNamesList"
  />
);
export const returnFlatRateCBUDropdown = (cbuNamesList, selectedCbuList, updateCbuList) => (
  <MultipleSelectCheckmarks
    dataList={cbuNamesList}
    noDataMessage={!cbuNamesList?.length ? "No CBU Available" : ""}
    label="Select CBU"
    className="dropDown"
    selectedCbuList={selectedCbuList}
    updateCbuList={updateCbuList}
    getError={!selectedCbuList.length}
    id="FlatRateCbuNamesList"
  />
);
export const returnSavingsCBUDropdown = (cbuNamesList, selectedCbuList, updateCbuList) => (
  <MultipleSelectCheckmarks
    dataList={cbuNamesList}
    noDataMessage={!cbuNamesList?.length ? "No CBU Available" : ""}
    label="Select CBU"
    className="dropDown"
    selectedCbuList={selectedCbuList}
    updateCbuList={updateCbuList}
    getError={!selectedCbuList.length}
    id="SavingsCbuNamesList"
  />
);
export const returnRDCBUDropdown = (RDCbuNamesList, selectedRDCbuList, updateRDCbuList) => (
  <MultipleSelectCheckmarks
    dataList={RDCbuNamesList}
    noDataMessage={!RDCbuNamesList?.length ? "No CBU Available" : ""}
    label="Select CBU"
    className="dropDown"
    selectedCbuList={selectedRDCbuList}
    updateCbuList={updateRDCbuList}
    getError={!selectedRDCbuList.length}
    id="RDCbuNamesList"
  />
);

export const returnCommitmentsConfigDiscount = (
  selectTemplate,
  discountPercentage,
  handleFlatDiscountInput
) => (
  <div className="commitments_configure_discount">
    <h6>Configure Discount</h6>
    <div className="commitments_configure_content">
      <div className="content_container">
        <p className="content_label">Template Name</p>
        <p className="content_value">{selectTemplate.label}</p>
      </div>
      <div className="content_container">
        <p className="content_label mandatory">Discount %</p>
        <input
          name="flatDiscountPercent"
          value={discountPercentage}
          maxLength={2}
          type="number"
          onChange={(e) => handleFlatDiscountInput(e)}
        />
      </div>
    </div>
  </div>
);

export const returnDiscountRateValue = (
  discountRateApplication,
  discountPercentage,
  onRDDiscountChange,
  index,
  discountRateApplicationValue
) => {
  switch (discountRateApplication?.value) {
    case "flat":
      return (
        <div className="content_container">
          <p className="content_label mandatory">Discount %</p>
          <input
            name="discountPercentage"
            value={discountPercentage}
            onChange={(e) => onRDDiscountChange(e, index)}
            maxLength={2}
            type="number"
          />
        </div>
      );
    case "relative":
      return (
        <div className="!w-[220px]">
          <BasicSelect
            {...discountRateAppValueDropdownFormConfig}
            value={discountRateApplicationValue}
            handleChange={(e) => onRDDiscountChange(e, index)}
          />
        </div>
      );

    default:
      break;
  }
  return null;
};
