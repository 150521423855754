import React from "react";
import "./style.scss";

function SummaryCards(props) {
  const { summaryCardsData = [], className = "" } = props;

  return (
    <div className={`flex gap-[5px] ${className}`}>
      {summaryCardsData?.map((summaryCardItem) => (
        <div className="ck-cudos-months-cards">
          <div className="graviton-heading">{summaryCardItem?.label}</div>
          <div className="graviton-content">
            {summaryCardItem?.value?.map((item, index, arr) => (
              <div className={index < arr.length - 1 && "divider gravitonItem"}>
                <div className="graviton">{item?.label}</div>
                <div className="graviton-amount">{item?.value ?? "--"}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default SummaryCards;
