import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { ReactComponent as DeleteIcon } from "../../../../../Assets/icons/deleteIcon.svg";

function DimensionBox(props) {
  const {
    dimenssionName,
    selectedConfiguration,
    deleteConfig = null,
    readOnly = false,
    CHIPS_THRESHOLD = 4,
    deleteConfigGroup = null,
    showChipOnly = false,
    includeSelectionFilter,
  } = props;
  const [moreButtonToggle, setMoreButtonToggle] = useState(true);
  return (
    <div className="individual_config ">
      {selectedConfiguration?.[dimenssionName]?.value?.length ? (
        <>
          {!showChipOnly && (
            <div className="flex justify-between items-center config_header p-[5px]">
              <h4 className="p-[3px] flex items-center">
                {dimenssionName} ({selectedConfiguration?.[dimenssionName]?.value?.length}){" "}
                {includeSelectionFilter && (
                  <div className="rounded-div">
                    <p>
                      {selectedConfiguration?.[dimenssionName]?.included ? "Included" : "Excluded"}
                    </p>
                  </div>
                )}
              </h4>
              {!readOnly && (
                <DeleteIcon
                  className="cursor-pointer deleteIcon"
                  onClick={() => {
                    deleteConfigGroup(dimenssionName);
                  }}
                />
              )}
            </div>
          )}
          <div key={dimenssionName} className="flex gap-[4px] p-[5px] flex-wrap dimension-box">
            {selectedConfiguration?.[dimenssionName]?.value?.map((singleConfig, index) => {
              if (index + 1 <= CHIPS_THRESHOLD) {
                return (
                  <p className="config_wrapper ">
                    <p>{singleConfig}</p>
                    {!readOnly && (
                      <ClearIcon
                        className="cursor-pointer"
                        onClick={() => {
                          deleteConfig(singleConfig, selectedConfiguration, dimenssionName);
                        }}
                      />
                    )}
                  </p>
                );
              }
              if (index + 1 >= 5) {
                return (
                  <>
                    <p className={`config_wrapper ${moreButtonToggle ? "hidden" : ""}`}>
                      <span>{singleConfig}</span>
                      {!readOnly && (
                        <ClearIcon
                          className="cursor-pointer"
                          onClick={() => {
                            deleteConfig(singleConfig, selectedConfiguration, dimenssionName);
                          }}
                        />
                      )}
                    </p>
                    {index + 1 === selectedConfiguration?.[dimenssionName]?.value?.length && (
                      <p
                        id={dimenssionName}
                        aria-hidden
                        className="more-button"
                        onClick={() => {
                          setMoreButtonToggle(!moreButtonToggle);
                        }}
                      >
                        <p>
                          {moreButtonToggle
                            ? `+${
                                selectedConfiguration[dimenssionName].value.length - CHIPS_THRESHOLD
                              } More`
                            : "Show Less"}
                          <ArrowForwardIosSharpIcon
                            sx={{ fontSize: "0.5rem" }}
                            className={`expend ${moreButtonToggle ? "" : "expend-close"}`}
                          />
                        </p>
                      </p>
                    )}
                  </>
                );
              }
              return "";
            })}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default DimensionBox;
