import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import moment from "moment";
import React from "react";

const ec2Config = [
  {
    label: "Instance Type:",
    key: "instance_type",
  },
  {
    label: "Platform:",
    key: "platform",
  },
  {
    label: "Payment Option:",
    key: "payment_option",
  },
  {
    label: "Tenancy: ",
    key: "tenancy",
  },
  {
    label: "Offering Class: ",
    key: "offering_class",
  },
  {
    label: "Region:",
    key: "region",
  },
  {
    label: "Term:",
    key: "term",
  },
  {
    label: "Quantity:",
    key: "quantity",
  },
];

const serviceMapper = (service) => {
  const data = {
    EC2: ec2Config,
    RDS: [
      {
        label: "DB Instance Class:",
        key: "instance_type",
      },
      {
        label: "Deployment Option:",
        key: "availability",
      },
      {
        // to be done
        label: "Product Description:",
        key: "engine_type",
      },
      {
        label: "Region:",
        key: "region",
      },
      {
        label: "Payment Option:",
        key: "payment_option",
      },
      {
        label: "Term:",
        key: "term",
      },
      {
        label: "Quantity:",
        key: "quantity",
      },
    ],
    ElastiCache: [
      {
        label: "Node Type:",
        key: "instance_type",
      },
      {
        // to BE done
        label: "Engine:",
        key: "engine_type",
      },
      {
        label: "Payment Option:",
        key: "payment_option",
      },
      {
        label: "Region:",
        key: "region",
      },
      {
        label: "Term:",
        key: "term",
      },
      {
        label: "Quantity:",
        key: "quantity",
      },
    ],
    Redshift: [
      {
        label: "Node Type:",
        key: "node_type",
      },
      {
        label: "Payment Option:",
        key: "payment_option",
      },
      {
        label: "Region:",
        key: "region",
      },
      {
        label: "Term:",
        key: "term",
      },
      {
        label: "Quantity:",
        key: "quantity",
      },
    ],
    OpenSearch: [
      {
        label: "Instance Type:",
        key: "instance_type",
      },
      {
        label: "Payment Option:",
        key: "payment_option",
      },
      {
        label: "Region:",
        key: "region",
      },
      {
        label: "Term:",
        key: "term",
      },
      {
        label: "Quantity:",
        key: "quantity",
      },
    ],
    SavingsPlan: [
      {
        label: "SavingsPlan Type:",
        key: "savingplan_type",
      },
      {
        label: "Region:",
        key: "region",
      },
      {
        label: "Instance Family:",
        key: "instance_family",
      },
      {
        label: "Payment Option:",
        key: "payment_option",
      },
      {
        label: "Term:",
        key: "term",
      },
      {
        label: "Hourly Commitment:",
        key: "hourly_commitment",
        prefix: "$",
      },
    ],
  };
  return data[service];
};

export const renderData = (state) => {
  const mappedItems = serviceMapper(state?.selected);

  // Split items into groups of two
  const groupedItems = mappedItems?.reduce((acc, item, index) => {
    if (index % 2 === 0) acc.push([item]);
    else acc[acc.length - 1].push(item);
    return acc;
  }, []);
  return groupedItems?.map((group) => (
    <div className="group-wrapper">
      {group?.map((item) => (
        <span className="input-value-pair !min-w-[50%]">
          <span className="label">{item?.label}</span>
          <span className="value">
            {item.prefix ? item.prefix : ""}
            {state?.modalData[item.key] || "---"}
          </span>
        </span>
      ))}
    </div>
  ));
};

export const renderBuyAgainModalBody = ({
  userData,
  state,
  modalCloseHandler,
  setState,
  handleBuyAgainSave,
}) => (
  <div>
    <header className="rounded-tr-[4px] rounded-tl-[4px]">Buy Again</header>
    <section>
      <div className="input-value-pair start-date">
        <span>New RI Start Date:</span>
        <span>
          {state?.modalData?.expiry_in_days < 0
            ? `${moment().add(1, "hour").format("DD-MMM-YYYY HH:00:00")} UTC`
            : `${moment
                .utc(state?.modalData?.end_date)
                .add(1, "hour")
                .format("DD-MMM-YYYY HH:00:00")} UTC`}{" "}
        </span>
      </div>
      <div>
        <span className="input-value-pair !min-w-[66%]">
          <span className="label">Account ID: </span>
          <span className="value">
            {state?.modalData?.account_id || "---"} ({userData?.currentUser?.name || "---"})
          </span>
        </span>
      </div>
      {renderData(state)}
    </section>
    <footer className="rounded-br-[4px] rounded-bl-[4px] border-solid border-t border-[#d9d9d9]">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="name"
              // checked={item.checked}
              className="acknowledge-checkbox"
              onChange={() =>
                setState((prevState) => ({
                  ...prevState,
                  acknowledge: !state?.acknowledge,
                }))
              }
              disableRipple
            />
          }
          label="I have reviewed the details."
        />
      </FormGroup>
      <div className="cta">
        <button
          type="button"
          className="button primary medium  rounded mr-[12px]"
          onClick={modalCloseHandler}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button primary medium  rounded mr-[12px]"
          onClick={() => handleBuyAgainSave(state, setState, userData, modalCloseHandler)}
          disabled={!state?.acknowledge}
        >
          Submit
        </button>
      </div>
    </footer>
  </div>
);
