import React, { useState, useEffect } from "react";
import { formatWithCommas, removeCommas } from "../service/helper";

function LabelInput({
  inputSuffix,
  name = "",
  value = "",
  onChange,
  placeholderText,
  disabled = false,
  className = "",
  inputContainerClass = "",
  inputClass = "",
}) {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(formatWithCommas(value));
  }, [value]);

  const handleChange = (e) => {
    const rawValue = removeCommas(e?.target?.value);
    onChange({ ...e, target: { ...e?.target, value: rawValue, name: e?.target?.name } });
  };

  return (
    <div className={`${className} label_input_container`}>
      <div className={`${inputContainerClass} right_container`}>
        <div className={`${inputContainerClass} input_container`}>
          <input
            name={name}
            value={displayValue}
            type="text"
            onChange={handleChange}
            placeholder={placeholderText}
            className={`input_label font-medium ${inputClass}`}
            disabled={disabled}
          />
          {inputSuffix && <span className="input_box_suffix">{inputSuffix}</span>}
        </div>
      </div>
    </div>
  );
}

export default LabelInput;
