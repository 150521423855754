import React from "react";
import "./style.scss";
import { ReactComponent as InfoIcon } from "../../../../Assets/icons/ic_i_new.svg";

function InfoBox(props) {
  const { customClass = "", message = "", CustomIcon = false } = props;
  return (
    <div className={`infobox_style ${customClass}`}>
      <span className="info_style">{CustomIcon || <InfoIcon class="infoIcon_style" />}</span>
      <span className="message_style">{message}</span>
    </div>
  );
}
export default InfoBox;
