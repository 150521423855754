import React from "react";
import Routes from "../../Config/Routes";
import { ReactComponent as PersonIcon } from "../../Assets/icons/person.svg";
import { ReactComponent as SignupIcon } from "../../Assets/icons/ic-signup.svg";
import { ReactComponent as PotentialSavingIcon } from "../../Assets/icons/ic-potential-saving-email.svg";
import { ReactComponent as OnboardingIcon } from "../../Assets/icons/ic-onboarding.svg";

export const doNothing = "";

export const buttonArr = {
  noPotential: [
    {
      id: 1,
      name: ["Sign in to view", " potential savings"],
      boldIndex: [1],
      url: Routes.LOGIN,
      img: <PotentialSavingIcon />,
      btnClass: "marketPlace_button mb-[10px] mt-[5px] min-w-[386px]",
    },
    {
      id: 2,
      name: ["Realize savings by", " enabling RI Ops"],
      boldIndex: [1],
      url: "/",
      img: <OnboardingIcon />,
      disabled: true,
      btnClass: "marketPlace_button mb-[35px] min-w-[386px]",
    },
  ],
  noRiOps: [
    {
      id: 1,
      name: ["Sign In to", " check insights"],
      boldIndex: [1],
      url: Routes.LOGIN,
      img: <PersonIcon />,
      btnClass: "marketPlace_button mb-[60px] mt-[30px]",
    },
  ],
  signup: [
    {
      id: 1,
      name: ["Signup and complete quick", " account onboarding"],
      boldIndex: [1],
      url: Routes.SINGUP,
      img: <SignupIcon />,
      btnClass: "marketPlace_button  mb-[60px] mt-[30px]",
    },
  ],
};

export const redirectPage = (url, pageType, customerId, navigate) => {
  if (pageType === "signup" && customerId) {
    navigate(`${url}?customerId=${customerId}`);
  } else {
    navigate(url);
  }
};

export const renderButtons = (pageType, enableriOps, customerId, navigate) => {
  if (pageType === "signup") {
    return buttonArr?.signup?.map((item) => (
      <button
        type="button"
        key={item?.id}
        className={item?.btnClass}
        disabled={item?.disabled}
        onClick={() => redirectPage(item.url, pageType, customerId, navigate)}
      >
        <span>{item.img}</span>
        <p>
          {item.name.map((nameItem, nameIndex) => {
            if (item?.boldIndex.includes(nameIndex)) {
              return <span className="bold-text">{nameItem}</span>;
            }
            return <span>{nameItem}</span>;
          })}
        </p>
      </button>
    ));
  }
  if (pageType === "login") {
    if (enableriOps !== "false") {
      return buttonArr?.noRiOps?.map((item) => (
        <button
          type="button"
          key={item?.id}
          className={item?.btnClass}
          disabled={item?.disabled}
          onClick={() => redirectPage(item.url, pageType, customerId, navigate)}
        >
          <span>{item.img}</span>
          <p>
            {item.name.map((nameItem, nameIndex) => {
              if (item?.boldIndex.includes(nameIndex)) {
                return <span className="bold-text">{nameItem}</span>;
              }
              return <span>{nameItem}</span>;
            })}
          </p>
        </button>
      ));
    }
    return buttonArr?.noPotential?.map((item) => (
      <button
        type="button"
        key={item?.id}
        className={item?.btnClass}
        disabled={item?.disabled}
        onClick={() => redirectPage(item.url, pageType, customerId, navigate)}
      >
        <span>{item.img}</span>
        <p>
          {item.name.map((nameItem, nameIndex) => {
            if (item?.boldIndex.includes(nameIndex)) {
              return <span className="bold-text">{nameItem}</span>;
            }
            return <span>{nameItem}</span>;
          })}
        </p>
      </button>
    ));
  }
  return "";
};

export const SAVINGDELIVERED_TEXT =
  "We will keep you updated via the Savings Delivered dashboard as we manage your RIs and deliver savings to you.";

export const COMPLETE_STEP_TEXT = "Please complete next steps";
