import React, { useState, useEffect } from "react";
import "../style.scss";

function CheckboxGroup(props) {
  const {
    items,
    className,
    handleChange,
    name = "",
    value = [],
    direction = "horizontal",
    labelClass,
    headingText = "",
    description = "",
    required = false,
    readOnly = false,
    parentClass = {},
    headingTextClass = "",
    descriptionClass = "",
    error = "",
    touched = false,
  } = props;
  const [checkedItems, setCheckedItems] = useState(value || []);

  const handleCheckboxChange = (e, itemName) => {
    let updatedList = [];
    if (e.target.checked) {
      updatedList = [...checkedItems, itemName];
    } else {
      updatedList = checkedItems.filter((i) => i !== itemName);
    }
    setCheckedItems(updatedList);
    handleChange({
      ...e,
      target: {
        name,
        value: updatedList,
      },
    });
  };

  useEffect(() => {
    if (value?.length) {
      setCheckedItems(value);
    }
  }, [value]);

  return (
    <>
      <div className={parentClass}>
        <div className="flex">
          <p className={`text-sm font-normal ${headingTextClass}`}>{headingText}</p>
          {required && !readOnly && <p className="requiredClass">*</p>}
        </div>
        {description && (
          <p className={`text-xs	pt-[9px] pb-[9px] ${descriptionClass}`}>{description}</p>
        )}
      </div>

      <div
        className={
          direction === "horizontal"
            ? `${className} horizontalCheckGroup`
            : `${className} verticalCheckGroup`
        }
      >
        {items?.length &&
          items.map((item) => (
            <div key={item?.name}>
              <label
                className={`${
                  item?.disable ? "checkbox-label-disabled" : labelClass
                } flex items-center`}
              >
                <input
                  type="checkbox"
                  value={item.value}
                  checked={checkedItems.find((i) => i === item?.value) || false}
                  onChange={(e) => handleCheckboxChange(e, item.value)}
                  className="checkboxClass"
                  disabled={readOnly || item.disable}
                  id="myCheckbox"
                />
                {item.name}
              </label>
              {item.description && <p className={descriptionClass}>{item.description}</p>}
            </div>
          ))}
      </div>
      {touched && error && <p className="error_message !mt-[10px]">{error}</p>}
    </>
  );
}

export default CheckboxGroup;
