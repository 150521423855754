import React from "react";
import { useVirtual } from "react-virtual";

function VirtualizedData({
  data,
  getTableRowTitle,
  getClassName,
  disableFunctionality,
  tableName,
  renderTableCells,
  parentRef,
  rowRenderKey,
}) {
  const rowVirtualizer = useVirtual({
    size: data?.length || 0,
    parentRef,
    getScrollElement: () => parentRef?.current,
    overscan: 50,
    measureSize: (el) => el?.getBoundingClientRect()?.height,
  });

  const items = rowVirtualizer?.virtualItems;
  const paddingTop = items?.length > 0 ? items?.[0]?.start : 0;
  const paddingBottom =
    items?.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  return (
    <>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: paddingTop }} />
        </tr>
      )}
      {items?.map((tableBody) => {
        const row = data?.[tableBody?.index];
        let tableRowKey;
        if (rowRenderKey) {
          tableRowKey = `${row?.[rowRenderKey]}`;
        } else {
          tableRowKey = `${tableBody?.key}_random_${Math.random() * 1000}`;
        }
        return (
          <tr
            key={tableRowKey}
            ref={tableBody?.measureRef}
            title={getTableRowTitle(tableBody)}
            className={getClassName(tableBody, disableFunctionality, tableName)}
          >
            {renderTableCells(row, tableBody?.index)}
          </tr>
        );
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: paddingBottom }} />
        </tr>
      )}
    </>
  );
}

export default VirtualizedData;
