import { useState } from "react";
import { isBilldeskRO } from "../../../Utils/commonUtils";

function usePermission() {
  const [permission, setPermission] = useState([]);
  const hasPermission = (name) => permission?.includes(name);

  const ignoreRules = (role, moduleName) => isBilldeskRO(role, moduleName);

  return [hasPermission, ignoreRules, setPermission];
}

export default usePermission;
