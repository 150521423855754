import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import moment from "moment";
import { ReactComponent as FilterIcon } from "../../../Assets/images/FilterIcon.svg";
import { ReactComponent as AppliedFilterIcon } from "../../../Assets/images/applied-filter.svg";
import "./style.scss";
import DateRangePick from "./filterDatepicker";

export default function DatesMultiselect(props) {
  const { selectedValue, min, max, onDateChange, name, viewportAwareDatePopover = false } = props;
  const [anchorEl, setAnchorEl] = useState(false);
  const [closeClearFilter, setCloseClearFilter] = useState(false);
  const [calendarOpenPosition, setCalendarOpenPosition] = useState("");

  const calendarDimensions = {
    height: 408,
    width: 625,
  };
  const calculateCalendarOrigin = (event) => {
    const { pageX } = event;
    const spaceToRight = window.innerWidth - pageX;
    let calendarPosition = "right";

    if (spaceToRight < calendarDimensions.width) {
      calendarPosition = "left";
    }
    return {
      calendarPosition,
    };
  };

  const handleClick = (event) => {
    if (viewportAwareDatePopover) {
      const { calendarPosition } = calculateCalendarOrigin(event);
      setCalendarOpenPosition(calendarPosition);
    }

    setCloseClearFilter(true);
    setTimeout(() => {
      document.getElementsByClassName("dateRange_pickerBtn")[0]?.click();
    }, 1);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCloseClearFilter(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // open ? setCloseClearFilter(true) : setCloseClearFilter(false);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="numberFilter">
      {moment(selectedValue[0]).isSame(moment(min)) &&
      moment(selectedValue[1]).isSame(moment(max)) ? (
        <FilterIcon aria-describedby={id} variant="contained" onClick={handleClick} />
      ) : (
        <AppliedFilterIcon aria-describedby={id} variant="contained" onClick={handleClick} />
      )}
      <Popover
        id={id}
        className={`numeric_filter dates_filter ${
          viewportAwareDatePopover ? "ck-popover--date-filter" : ""
        }`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
        }}
      >
        <DateRangePick
          onDateChange={onDateChange}
          startDate={moment()}
          endDate={moment()}
          selectedValue={selectedValue}
          name={name}
          max={max}
          min={min}
          anchorEl={closeClearFilter}
          opens={calendarOpenPosition}
        />
      </Popover>
    </div>
  );
}
