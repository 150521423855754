/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FormHelperText } from "@mui/material";
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import calendarIcon from "../../../../../Assets/icons/calender.svg";
import calendarSelectedIcon from "../../../../../Assets/icons/date-selected.svg";
import "./style.scss";
import "bootstrap-daterangepicker/daterangepicker.css";
import Text from "../Text";
import { noop } from "../../../../../Utils/commonUtils";
import CheckBoxComponent from "../Checkbox";
import { ReactComponent as ResetIcon } from "../../../../../Assets/icons/ResetIcon.svg";
import "../../../../CK-Lens/Common/dateRangePicker/dateRangePickerV2.scss";
import "../../../../CK-Lens/Dashboard/CostExplorer/style.scss";

function Calendar({
  toggleHandler,
  value,
  topLabel,
  error,
  className,
  placeHolder,
  handleChange,
  name,
  singleDatePicker,
  startDate,
  endDate,
  collapseState,
  touched,
  disableFutureDates = true,
  disablePreviousDates = false,
  values,
  maxDate,
  minDate,
  icon,
  disabled = false,
  openPosition = "left",
  showCheckBox = false,
  checkBoxName = "",
  checkBoxClass = "",
  autoApply = false,
  dropsProps = "down",
  buttonClass = "",
  resetDates = false,
  customDateRange = false,
  onBlurHandler = noop,
}) {
  const [close, setClose] = useState(collapseState);
  const dateRef = useRef();

  const accountCreatedOn = values?.createdDate;

  useEffect(() => {
    const newStartDate = value?.split(" - ")[0]?.trim();
    const newEndDate = value?.split(" - ")[1]?.trim();
    const rangeStart = moment(newStartDate).format("MM/DD/YYYY");

    if (newStartDate) {
      dateRef?.current?.setStartDate(rangeStart);
    }

    if (newEndDate) {
      const rangeEnd = moment(newEndDate).format("MM/DD/YYYY");
      dateRef?.current?.setEndDate(rangeEnd);
    } else if (singleDatePicker && newStartDate) {
      dateRef?.current?.setEndDate(rangeStart);
    }
  }, [value]);

  const rangesList = () => {
    if (!singleDatePicker) {
      const updatedRanges = customDateRange || {
        // "Auto Select": "",
        "3M": [
          moment().startOf("month").subtract(3, "months"),
          moment().endOf("month").subtract(1, "months"),
        ],
        "6M": [
          moment().startOf("month").subtract(6, "months"),
          moment().endOf("month").subtract(1, "months"),
        ],
        MTD: [moment().startOf("month"), moment()],
      };
      return updatedRanges;
    }
    return undefined;
  };
  const ref = useRef();

  // const toggleCalenderDropdown = () => {
  //   setClose(!close);
  // };

  const handleEvent = (event, picker) => {
    const date = singleDatePicker
      ? moment(picker.startDate._d).format("DD-MMM-YYYY")
      : `${moment(picker.startDate._d).format("DD-MMM-YYYY")} - ${moment(picker.endDate._d).format(
          "DD-MMM-YYYY"
        )}`;
    handleChange({ target: { value: date, name } });
    // toggleCalenderDropdown();
  };

  const onCancelHandler = () => {
    setClose(true);
  };

  const handleCheckBoxEvent = (event) => {
    const {
      target: { value: checkboxValue },
    } = event;

    handleChange({ target: { name: checkBoxName, value: checkboxValue } });
  };

  const onClearSelection = (e) => {
    e.stopPropagation();
    dateRef.current.setStartDate(startDate);
    dateRef.current.setEndDate(endDate);
    const picker = {
      startDate: {
        _d: new Date(startDate),
      },
      endDate: {
        _d: new Date(endDate),
      },
    };
    handleEvent({}, picker);
    setClose(true);
  };

  const returnMaxDate = () => {
    if (maxDate) {
      return moment(maxDate);
    }
    return disableFutureDates && moment();
  };

  const returnMinDate = () => {
    if (minDate) {
      return moment(minDate);
    }
    return disablePreviousDates && moment(accountCreatedOn);
  };

  const getIconSrc = () => {
    if (icon) {
      return icon;
    }
    return value && !disabled ? calendarSelectedIcon : calendarIcon;
  };

  const returnParent = () =>
    // let result;
    // if (haveparentEl) {
    //   result = singleDatePicker ? ".singleDateModule" : ".rangeDateModule";
    // } else {
    //   result =
    `.singleDateModule_${Math.floor(Math.random() * 10)}`;
  // }
  // return result;

  const datePickerEventHandler = (e) => {
    e.stopPropagation();
    if (e.type === "hide") {
      onCancelHandler();
    }
    if (e.type === "showCalendar") {
      setClose(false);
    }
  };

  const onCalendarClose = () => {
    onBlurHandler({ target: { name, value } });
  };

  return (
    <div className={`calender-wrapper ${className}`} id="cld_datepicker" ref={ref}>
      {resetDates && (
        <button
          onClick={() => {
            handleChange({ target: { name, value: null } });
          }}
          type="button"
          className={`!text-primary-blue font-medium text-xs absolute right-0 reset !mt-0 ${
            value ? "" : "disabled !text-[#898a90]"
          }`}
        >
          <ResetIcon />
          <span className="!ml-[5px]">Reset</span>
        </button>
      )}
      <DateRangePicker
        ref={dateRef}
        initialSettings={{
          singleDatePicker,
          startDate,
          endDate,
          showDropdowns: true,
          opens: openPosition,
          locale: {
            daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            monthNames: moment.months(),
          },
          ranges: rangesList(),
          maxDate: returnMaxDate(),
          minDate: returnMinDate(),
          alwaysShowCalendars: true,
          linkedCalendars: false,
          parentEl: returnParent(),
          autoApply: !singleDatePicker ? false : autoApply,
          drops: dropsProps,
        }}
        onApply={handleEvent}
        onCancel={onCancelHandler}
        onEvent={datePickerEventHandler}
        onCallback={noop}
        onHide={onCalendarClose}
      >
        <div className={`calender ${disabled ? "disabled" : ""}`}>
          {topLabel && (
            <Text
              text={topLabel.text}
              size={topLabel.fontSize}
              className={topLabel.class}
              type="primary"
            />
          )}

          <div
            // onClick={toggleCalenderDropdown}
            aria-hidden="true"
            className={`header ${buttonClass}  ${touched && error && !disabled && "error_box"} ${
              value && "filled"
            } ${!close && "dropdown"}`}
          >
            <span>{disabled ? placeHolder : value || placeHolder}</span>
            {!singleDatePicker && (
              <div
                className={`clear-selection-wrapper ${close ? "" : "show"} ${
                  touched && error ? "clear-selection-on-error" : ""
                }`}
              >
                <div
                  aria-hidden="true"
                  onClick={(e) => onClearSelection(e)}
                  className="clear-selection"
                >
                  <p>Clear Selection</p>
                  <RefreshIcon size="small" />
                </div>
              </div>
            )}
            <img
              aria-hidden="true"
              onClick={toggleHandler}
              src={getIconSrc()}
              alt={value && !disabled ? "calender selected icon" : "calender icon"}
            />
          </div>
          {touched && error && !disabled && (
            <FormHelperText className="error_message" error={error} size="small">
              {error}
            </FormHelperText>
          )}
        </div>
      </DateRangePicker>
      {showCheckBox && (
        <CheckBoxComponent
          name={checkBoxName}
          label={`No ${topLabel.text}`}
          checked={values?.[checkBoxName]}
          handleChange={(e) => handleCheckBoxEvent(e)}
          className={checkBoxClass}
        />
      )}
    </div>
  );
}

export default Calendar;
