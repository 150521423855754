import React from "react";
import internalErrorLogo from "../../../Assets/icons/505-error.png";
import "./style.scss";

function InternalError({ message = "" }) {
  return (
    <div className="page-not-found errorPageNotFound">
      <div className="blurbg" />
      <div className="not-foundIcon">
        <img src={internalErrorLogo} alt="logo" />
      </div>
      <div className="internalError-heading">
        <h3 className="error-heading">Internal Server Error</h3>
      </div>
      <div className="usefullLinks-container">
        <div className="internalError-texts">
          <p>
            {message ||
              `We're sorry, the server encountered an internal error and was unable to complete
            your request.`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InternalError;
