export const addSessionToUrl = (url) => {
  const path = url.split("?")[0]; // Extract the path
  const query = url.split("?")[1] || ""; // Extract query string, if present

  // Parse the existing query parameters
  const params = new URLSearchParams(query);
  params.set("session", sessionStorage.getItem("userData")); // Add or update the session parameter

  // Construct the updated URL
  return `${path}?${params.toString()}`;
};

export const openLinkInNewTab = (url) => {
  const updateUrl = addSessionToUrl(url);
  window.open(updateUrl, "_blank");
};
