import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { ReactComponent as FilterIcon } from "../../../Assets/images/FilterIcon.svg";
import "./style.scss";
import { ReactComponent as AppliedFilterIcon } from "../../../Assets/images/applied-filter.svg";
import FormInput from "../../CK-Auto/Common/Form/Input";
import { BTN_ARRAY } from "../../CK-Lens/Constants/constant";

export default function NumberMultiselect(props) {
  const {
    min,
    max,
    name,
    value,
    onRangeChange,
    filterName,
    filterSelectedName,
    filterDataName,
    filterCopyName,
  } = props;
  const [firstValue, setFirstValue] = useState(Math.floor(min));
  const [secondValue, setSecondValue] = useState(Math.ceil(max));
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    if (min === value?.[0] && max === value?.[1]) {
      setFirstValue(Math.floor(value?.[0]));
      setSecondValue(Math.ceil(value?.[1]));
    } else {
      setFirstValue(value?.[0]);
      setSecondValue(value?.[1]);
    }
  }, [value]);

  useEffect(() => {
    setFirstValue(Math.floor(value?.[0]));
    setSecondValue(Math.ceil(value?.[1]));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setSecondValue(e?.target?.value?.[1]);
    setFirstValue(e?.target?.value?.[0]);
  };

  const handleApplyCancel = (btnLabel) => {
    if (btnLabel === "Apply") {
      const newArrayValue = [Number(firstValue), Number(secondValue)];
      onRangeChange(
        newArrayValue,
        name,
        filterName,
        filterSelectedName,
        filterDataName,
        filterCopyName
      );
    } else if (min === value?.[0] && max === value?.[1]) {
      setFirstValue(Math.floor(value?.[0]));
      setSecondValue(Math.ceil(value?.[1]));
    } else {
      setFirstValue(value?.[0]);
      setSecondValue(value?.[1]);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="numberFilter">
      {min === value?.[0] && max === value?.[1] ? (
        <FilterIcon aria-describedby={id} variant="contained" onClick={handleClick} />
      ) : (
        <AppliedFilterIcon aria-describedby={id} variant="contained" onClick={handleClick} />
      )}
      <Popover
        PaperProps={{ style: { top: "10" } }}
        id={id}
        className="numeric_filter"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 10,
        }}
      >
        <div className="controller_wrapper">
          <h5>Set values Range</h5>
          <p>Choose a range to show values.</p>
          <div className="number_filter_wrapper">
            <div className="values_wrapper">
              <p>Min Value</p>
              <FormInput
                value={firstValue}
                type="number"
                onGetData={(ipnutName, inputValue) => setFirstValue(inputValue)}
                placeholder=""
                errorClass="errorClass"
                // regex={/^(?=.*[1-9])\d*(?:\.\d+)?$/}
                filterError
                comparisonError={
                  !!(+firstValue > +max || +secondValue < +firstValue || +firstValue < +min)
                }
                errorMessage={
                  +firstValue > +max || +secondValue < +firstValue || +firstValue < +min
                    ? "Please enter valid input"
                    : null
                }
              />
            </div>
            <div className="values_wrapper">
              <p>Max Value</p>
              <FormInput
                value={secondValue}
                type="number"
                onGetData={(ipnutName, inputValue) => setSecondValue(inputValue)}
                placeholder=""
                errorClass="errorClass"
                // regex={/^(?=.*[1-9])\d*(?:\.\d+)?$/}
                filterError
                comparisonError={
                  !!(+secondValue < +min || +secondValue < +firstValue || +secondValue > +max)
                }
                errorMessage={
                  +secondValue < +min || +secondValue < +firstValue || +secondValue > +max
                    ? "Please enter valid input"
                    : null
                }
              />
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Slider
              getAriaLabel={() => "Range"}
              value={[firstValue, secondValue]}
              onChange={handleChange}
              valueLabelDisplay="auto"
              max={Math.ceil(max)}
              min={Math.floor(min)}
            />
          </Box>
        </div>
        <div className="filters_options">
          {BTN_ARRAY.map((item) => (
            <button
              type="button"
              className={item?.class}
              onClick={() => handleApplyCancel(item?.label)}
            >
              {item?.label}
            </button>
          ))}
        </div>
      </Popover>
    </div>
  );
}
