import { getCurrencySymbol } from "../../../Utils/commonUtils";

export const tenureTableHeaders = [
  { label: "Tenure", value: "tenure", type: "", bodyClass: "min-w-[165px]" },
  {
    label: `Commitment (${getCurrencySymbol()})`,
    value: "commitment",
    type: "table-input",
    headerClass: "mandatory",
    bodyClass: "relative",
  },
  {
    label: "Discount (%)",
    value: "discount",
    type: "table-input",
    headerClass: "mandatory",
    bodyClass: "relative",
  },
  {
    label: `Credits (${getCurrencySymbol()})`,
    value: "credits",
    type: "table-input",
    bodyClass: "relative",
  },
  { label: "", value: "", type: "deleteIcon" },
];

export const someConstantToRemove = [
  { label: "Region Name", value: "childRegions", type: "text-dropDown" },
  { label: `HTTP Requests (${getCurrencySymbol()})`, value: "http", type: "table-input" },
  { label: `HTTPS Requests (${getCurrencySymbol()})`, value: "https", type: "table-input" },
  { label: `DT Out to Internet (${getCurrencySymbol()})`, value: "dtOut", type: "table-input" },
];

export const CBUList = [
  {
    id: 46055,
    name: "CBU1",
    accounts: ["123098745621"],
  },
  {
    id: 49747,
    name: "CBU2",
    accounts: [],
  },
  {
    id: 49748,
    name: "CBU3",
    accounts: [],
  },
];
