import React from "react";
import { ReactComponent as ResetIcon } from "../../../../Assets/icons/reset-icon.svg";

export function InludeExcludeOptions({
  includeExcludeOption,
  includeExcludeHandler,
  includeOnly,
  maxRequiredOptions,
}) {
  return (
    includeExcludeOption && (
      <div className="select_box_container !inline-flex">
        <select name="includeExcludeOptions" onChange={includeExcludeHandler} value={includeOnly}>
          <option value="Include Only">Include Only</option>
          {maxRequiredOptions > 1 && <option value="Exclude Only">Exclude Only</option>}
        </select>
      </div>
    )
  );
}

export function ResetFilters({ resetFilter, filterReset, value, initialValue }) {
  const resetFilterEnableDisable = () => {
    if (!value.length) {
      return !!initialValue.length;
    }
    let enable = false;
    if (initialValue.length) {
      if (initialValue.length !== value.length) {
        return true;
      }
      for (let i = 0; i < value?.length; i += 1) {
        const valueItem = value?.[i];
        const matchValue = initialValue?.find((item) => item?.value === valueItem?.value);

        if (!matchValue || (matchValue && !valueItem?.checked)) {
          enable = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < value?.length; i += 1) {
        if (value?.[i]?.checked) {
          enable = true;
          break;
        }
      }
    }
    return enable;
  };

  return (
    resetFilter && (
      <span
        aria-hidden="true"
        className={`inline-flex gap-x-1  text-xs items-center font-medium pl-[4px] filter-reset ${
          resetFilterEnableDisable() ? "text-[#0A3CA2] cursor-pointer" : "text-[#7C7C7C]"
        }`}
        onClick={filterReset}
      >
        <ResetIcon className="p-r[2px]" />
        <span className="font-[500] text-[11px]">Reset Filters</span>
      </span>
    )
  );
}
