/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import "../privatePricing.scss";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as SelectIcon } from "../../../../../Assets/icons/Vector.svg";
import { noop } from "../../../../../Utils/commonUtils";
import { ContentCopy, ResetValueIcon } from "../../../../../Constants/icons";
import ListSelectorNoData from "../../../../FormConfig/Form/Components/ListSelector.js/noData";
import { ReactComponent as Exclamation } from "../../../../../Assets/icons/exclamation.svg";
import {
  getConfigObject,
  getModalDataList,
  renderAddOnFunctionalityRows,
  renderInputsRows,
  renderOutputsRows,
  renderStatmuxRows,
} from "./util";
import CloneModal from "../../../../CK-Lens/Common/CloneModal";
import patterns from "../../../../FormConfig/Form/patterns";
import { updateHeadingOnreadOnly } from "../utils";

function EditableTableWithTabs({
  headers,
  tableData = [],
  heading,
  handleChange = noop,
  name,
  modalTitle,
  showNoData = false,
  configureRate = {},
  readOnly = false,
  value: initialValue,
}) {
  const [selectedTabs, setSelectedTabs] = useState(initialValue?.headers?.[0] || headers?.[0]);
  const [selectedRegion, setSelectedRegion] = useState(
    tableData?.[0] || initialValue?.tableData?.[0]
  );
  const [regionTabDetails, setRegionTabDetails] = useState(initialValue?.values || {});
  const [openModalValue, setOpenModalValue] = useState("");
  const [cloneItemSelectedList, setCloneItemSelectedList] = useState([]);
  const [modalData, setModalData] = useState({});
  const [childTab, setChildTab] = useState({});

  useEffect(() => {
    const obj = {};
    tableData.forEach((item) => {
      obj[item.childRegions] = getModalDataList(tableData, item.childRegions);
    });
    setModalData(obj);
    setSelectedTabs(initialValue?.headers?.[0] || headers?.[0] || {});
    setSelectedRegion(initialValue?.tableData?.[0] || tableData?.[0] || {});
    if (headers?.length && tableData?.length) {
      setRegionTabDetails(getConfigObject(headers, tableData, configureRate));
    }
    handleChange({
      target: {
        name,
        value: cloneDeep(getConfigObject(headers, tableData, configureRate)),
      },
    });
  }, [headers, tableData]);

  useEffect(() => {
    setChildTab(
      getConfigObject(headers, tableData, configureRate)?.[selectedTabs?.key]?.[0]?.tabList?.[0] ||
        {}
    );

    if (readOnly) {
      setChildTab(
        getConfigObject(initialValue?.headers, initialValue?.tableData, initialValue?.values)?.[
          selectedTabs?.key
        ]?.[1]?.tabList?.[0] ||
          getConfigObject(initialValue?.headers, initialValue?.tableData, initialValue?.values)?.[
            selectedTabs?.key
          ]?.[0]?.tabList?.[0] ||
          {}
      );
    }

    setCloneItemSelectedList([]);
  }, [headers, tableData, selectedTabs]);

  const handleResetButtonClick = () => {
    setSelectedTabs(headers?.[0]);
    setSelectedRegion(tableData?.[0]);
    setRegionTabDetails(getConfigObject(headers, tableData, configureRate));
    handleChange({
      target: {
        name,
        value: {},
      },
    });
  };

  const getKeys = (data, path = []) => {
    const keys = new Set();

    Object.entries(data).forEach(([key, value]) => {
      const newPath = [...path, key];
      if (newPath.length % 2 === 1) {
        keys.add(key);
      }
      if (typeof value === "object") {
        getKeys(value, newPath).forEach((item) => keys.add(item));
      }
    });

    return [...keys];
  };

  const renderTabs = () => {
    let tabs;
    if (readOnly) {
      const configObject = getConfigObject(
        initialValue?.headers,
        initialValue?.tableData,
        initialValue?.values
      );
      const selectedTab = configObject?.[selectedTabs?.key];
      tabs = selectedTab?.[0]?.tabList || selectedTab?.[1]?.tabList;
    } else {
      tabs = regionTabDetails[selectedTabs?.key]?.[0]?.tabList;
    }
    return tabs?.map((tab) => (
      <div
        className={`text-[10px] leading-[12px] tracking-normal text-center px-[10px] py-[12.5px] cursor-pointer ${
          tab?.value === childTab?.value ? "active text-[#0A3CA2] font-bold" : "text-[#151E33]"
        }`}
        key={tab?.value}
        aria-hidden
        type="button"
        onClick={() => setChildTab(tab)}
      >
        {tab?.label}
      </div>
    ));
  };

  const handleInputChange = (newValue, columnKey, key, codec = "", resolution = "", tab = "") => {
    // eslint-disable-next-line complexity
    setRegionTabDetails((prevData) => {
      const newData = cloneDeep(prevData);
      const activeRegionData = newData?.[selectedTabs?.key].find(
        (item) => item?.region === selectedRegion?.childRegions
      );
      if (!activeRegionData) return newData;
      const activeConfig = cloneDeep(activeRegionData?.config);

      // const regex = /^(?!0\d*)(\d+(\.\d{0,5})?)?$/;
      const regex = patterns.decimalLimit5;
      const match = newValue?.match(regex);

      if (newValue?.includes("-") || newValue?.includes("e")) {
        return newData;
      }

      if (match !== null) {
        switch (selectedTabs?.key) {
          case "addOnFunctionality":
            if (activeConfig) {
              const updatedColumnKey = { ...activeConfig?.[columnKey], [key]: newValue };
              activeRegionData.config = { ...activeConfig, [columnKey]: updatedColumnKey };
            }
            break;
          case "statmux":
            if (activeConfig) {
              activeConfig.Resolution[resolution].Codec[codec].FrameRate[columnKey][key] = newValue;
              activeRegionData.config = activeConfig;
            }
            break;
          case "outputs":
            if (activeConfig) {
              switch (tab) {
                case "AVC_Outputs":
                  activeConfig[tab].Resolution[resolution].BitRate[codec].Pipeline[key][columnKey] =
                    newValue;
                  activeRegionData.config = activeConfig;
                  break;
                case "HEVC_Outputs":
                  activeConfig[tab].Resolution[resolution].FrameRate[codec].Pipeline[key][
                    columnKey
                  ] = newValue;
                  activeRegionData.config = activeConfig;
                  break;
                case "Audio_Only":
                  activeConfig[tab].Pipeline[columnKey][key] = newValue;
                  activeRegionData.config = activeConfig;
                  break;
                default:
                  break;
              }
            }
            break;
          case "inputs":
            if (activeConfig) {
              activeConfig[tab].Resolution[resolution].Pipeline[key][columnKey] = newValue;
              activeRegionData.config = activeConfig;
            }
            break;
          default:
            break;
        }
        activeRegionData.activeClone = newValue !== "";
      } else {
        return newData;
      }

      handleChange({ target: { name, value: { ...newData } } });
      return newData;
    });
  };

  const renderHeader = () => {
    const data = regionTabDetails?.[selectedTabs?.key]?.find(
      (item) => item?.region === selectedRegion?.childRegions
    )?.config;

    if (selectedTabs?.key === "addOnFunctionality") {
      return (
        <>
          <th>Add On Functionality</th>
          <th>Rate</th>
        </>
      );
    }
    if (selectedTabs?.key === "statmux") {
      const activeData = data;

      if (!activeData) return null;

      const columnKeys = getKeys(activeData);

      return (
        <>
          {columnKeys.map((columnKey) => (
            <th key={columnKey}>{columnKey}</th>
          ))}
        </>
      );
    }
    if (selectedTabs?.key === "inputs" || selectedTabs?.key === "outputs") {
      const activeData = data?.[childTab?.value];

      if (!activeData) return !readOnly ? null : <th>No Data</th>;

      const columnKeys = getKeys(activeData);

      return (
        <>
          {columnKeys.map((columnKey) => (
            <th key={columnKey}>{columnKey}</th>
          ))}
        </>
      );
    }
    return null;
  };

  const renderRows = () => {
    const data = regionTabDetails[selectedTabs?.key]?.find(
      (item) => item?.region === selectedRegion?.childRegions
    )?.config;

    if (!data) return null;

    switch (selectedTabs?.key) {
      case "addOnFunctionality":
        return renderAddOnFunctionalityRows(data, handleInputChange, readOnly);
      case "statmux":
        return renderStatmuxRows(data, handleInputChange, readOnly);
      case "outputs":
        return renderOutputsRows(data, handleInputChange, childTab, readOnly);
      case "inputs":
        return renderInputsRows(data, handleInputChange, childTab, readOnly);
      default:
        return null;
    }
  };

  const contentCopyHandler = (value) => {
    setOpenModalValue(value);
  };

  const handleApplyCancel = (e) => {
    if (e === "Cancel") {
      setCloneItemSelectedList([]);
      setOpenModalValue(null);
    } else if (e === "Apply Rate") {
      const cloneConfig = regionTabDetails?.[selectedTabs?.key]?.find(
        (item) => openModalValue === item?.region
      )?.config;
      const updatedRegionTabDetails = cloneDeep(regionTabDetails);
      const selectedTabList = updatedRegionTabDetails?.[selectedTabs?.key]?.map((item) => {
        const newItem = cloneDeep(item);
        if (cloneItemSelectedList?.includes(newItem?.region)) {
          newItem.config = cloneConfig;
          newItem.activeClone = true;
        }
        return newItem;
      });
      updatedRegionTabDetails[selectedTabs.key] = selectedTabList;
      setRegionTabDetails(updatedRegionTabDetails);
      setCloneItemSelectedList([]);
      setOpenModalValue(null);
      handleChange({ target: { name, value: { ...updatedRegionTabDetails } } });
    }
  };

  const onModalChange = (checkedItem) => {
    if (!cloneItemSelectedList?.includes(checkedItem)) {
      setCloneItemSelectedList([...cloneItemSelectedList, checkedItem]);
    } else {
      setCloneItemSelectedList(cloneItemSelectedList?.filter((item) => item !== checkedItem));
    }
  };

  return (
    <div
      className={`page_container editable_table_with_tabs ${
        readOnly ? "read_only_commitment" : ""
      }`}
    >
      <div className="editable_table_header">
        <div className="flex items-center">
          <h2 className="editable_table_heading">{updateHeadingOnreadOnly(heading, readOnly)}</h2>

          {showNoData && (
            <button
              type="button"
              className="reset_btn "
              onClick={handleResetButtonClick}
              disabled={!regionTabDetails?.[selectedTabs?.key]?.find((item) => item?.activeClone)}
            >
              <ResetValueIcon />
              <span className="text-[#0A3CA2] text-[12px] font-normal ml-[5px]">Reset</span>
            </button>
          )}
        </div>
      </div>
      {showNoData && (
        <div className="VRItabs flex border border-solid border-[#DDDFE8] mt-[12px] rounded-[3px] px-[10px] py-[9px] justify-between bg-[#EFF4FF]">
          <div className="vr-tabs ">
            {(initialValue?.headers || headers).map(
              (tab) =>
                tab?.label && (
                  <button
                    type="button"
                    onClick={() => setSelectedTabs(tab)}
                    className={`tab_button ${tab?.label === selectedTabs?.label ? "active" : ""}`}
                  >
                    {tab?.label}
                  </button>
                )
            )}
          </div>
        </div>
      )}
      {showNoData ? (
        <div className="w-full flex gap-[8px]">
          <div className="Region_list leading-[17.4px] ">
            <div className="item_list item_header">Selected Region(s)</div>
            {(initialValue?.tableData || tableData)?.map((item) => (
              <div
                className={`item_list flex justify-between items-center ${
                  item?.childRegions === selectedRegion?.childRegions ? "active" : ""
                }`}
                aria-hidden
                onClick={() => setSelectedRegion(item)}
              >
                <div>{item?.childRegions}</div>
                {tableData.length > 1 ? (
                  <div
                    className={
                      regionTabDetails?.[selectedTabs?.key]?.find(
                        (ele) => ele?.region === item?.childRegions
                      )?.activeClone
                        ? ""
                        : "cursor-not-allowed"
                    }
                  >
                    <ContentCopy
                      className={
                        regionTabDetails?.[selectedTabs?.key]?.find(
                          (ele) => ele?.region === item?.childRegions
                        )?.activeClone
                          ? "active_copy cursor-pointer"
                          : "pointer-events-none cursor-default"
                      }
                      onClick={() => contentCopyHandler(item?.childRegions)}
                    />
                  </div>
                ) : null}
                {openModalValue === item?.childRegions && (
                  <CloneModal
                    setSelectedRows={setCloneItemSelectedList}
                    header={modalTitle}
                    data={modalData[item?.childRegions]}
                    onModalChange={onModalChange}
                    handleApplyCancel={handleApplyCancel}
                    selectedRows={cloneItemSelectedList}
                    handleModalClose={setOpenModalValue}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="table_container relative">
            <div className="px-[10px] py-[5px] bg-[#EFF4FF] leading-[17.4px] border-b border-[#DDDFE8] border-solid flex items-center justify-between">
              <div className="text-[12px] font-medium text-[#0A3CA2] leading-[17.4px]">
                Codec Details
              </div>
              <div className="text-[11px] font-normal tracking-[0px] text-left text-[#898A90] leading-[17.4px] flex items-center gap-[6px]">
                <Exclamation />
                Each input value is in ($) / hour
              </div>
            </div>
            <div>
              {(Object.keys(childTab)?.length ||
                regionTabDetails?.[selectedTabs?.key]?.[0]?.tabList?.length > 0) && (
                <div className="codec-child-tabs bg-[#F9FBFF] text-[#151E33] flex items-center px-[10px] border-b border-[#DDDFE8] border-solid">
                  {renderTabs()}
                </div>
              )}
              <table
                className={`codec-detail-table ${selectedTabs?.key ? selectedTabs?.key : ""} ${
                  childTab?.key ? childTab?.key : ""
                }`}
              >
                <thead>
                  <tr>{Object?.keys(regionTabDetails)?.length > 0 && renderHeader()}</tr>
                </thead>
                <tbody>{Object?.keys(regionTabDetails)?.length > 0 && renderRows()}</tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="table_container h-[400px] empty_list !w-full relative">
          <ListSelectorNoData message="Please select region and pricing type" Icon={SelectIcon} />
        </div>
      )}
    </div>
  );
}

export default EditableTableWithTabs;
