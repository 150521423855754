/* eslint-disable complexity */
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import Form from "../../FormConfig/Form";
import Button from "../../FormConfig/Form/Components/Button";
import Text from "../../FormConfig/Form/Components/Text";
import PageTitle from "../PageTitle";
import withRouter from "../WithRouter";
import { ReactComponent as ResetIcon } from "../../../Assets/icons/reset-icon.svg";
import BreadCrumb from "../BreadCrumb";
import { PERMISSIONS_ENUM } from "../../../Constants/permissions";

class FormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      resetCount: 0,
      values: props.currentFormValues || {},
      initialValues: JSON.parse(JSON.stringify(props.currentFormValues || {})),
      acknowledged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      props: { resetCount },
    } = this;

    if (prevProps.resetCount !== resetCount) {
      this.resetForm();
    }
  }

  resetForm = () => {
    let {
      state: { resetCount },
    } = this;
    resetCount += 1;
    this.setState({ resetCount });
  };

  renderButtons = () => {
    const {
      state: { isError, acknowledged },
      props: {
        navigate,
        goBack,
        submitButtonText,
        showReset = true,
        showCancelBtn = true,
        cancelBtnFn,
        cancelBtnStyle = "",
        multipleFormError,
        submitBtnStyle,
        showSubmitBtn = true,
        customButton,
        resetOnCancel,
        currentFormValues,
        btnSize,
        cancelButtonText = "Cancel",
        ResetBtnText = "Reset",
        consent = false,
      },
      resetForm,
      disableResetButton,
    } = this;

    const isResetButtonDisabled = disableResetButton();

    return (
      <div className="w-full flex justify-between">
        {consent && (
          <FormControlLabel
            className={`acknowledge_wrapper ${acknowledged ? "" : "inactive"}`}
            control={
              <Checkbox
                checked={acknowledged}
                onChange={(e) => {
                  this.setState({
                    acknowledged: e.target.checked,
                  });
                }}
              />
            }
            label={consent}
          />
        )}
        <div className="flex items-center">
          {showReset && (
            <>
              <span
                aria-hidden="true"
                className={`flex gap-x-1 text-[#0A3CA2] reset-button text-xs items-center cursor-pointer font-medium ${
                  isResetButtonDisabled && "disabled"
                }`}
                onClick={resetForm}
              >
                <ResetIcon className="w-2.5 h-2.5" /> {ResetBtnText}
              </span>
              <span className="seperator" />
            </>
          )}
          {(goBack || cancelBtnFn) && showCancelBtn && (
            <Button
              className={`${
                cancelBtnStyle || ""
              } rounded mr-[8px] bg-white !font-medium text-[12px]`}
              size={btnSize}
              variant="outline-primary-nohover"
              handleClick={() => {
                if (goBack) navigate(goBack);
                if (cancelBtnFn) cancelBtnFn();
                if (resetOnCancel) resetForm();
              }}
              text={cancelButtonText}
            />
          )}
          {showSubmitBtn && (
            <Button
              className={` ${submitBtnStyle || ""} rounded !font-medium text-[12px]`}
              size={btnSize}
              variant="primary"
              id="submit"
              disabled={this.isDisabled(isError, multipleFormError, currentFormValues)}
              text={submitButtonText || "Submit"}
              type="submit"
            />
          )}
          {customButton && customButton}
        </div>
      </div>
    );
  };

  checkConsent = (isError) => {
    const {
      state: { acknowledged },
    } = this;
    return isError || this.disableResetButton() || !acknowledged;
  };

  isDisabled = (isError, multipleFormError = false, currentFormValues = {}) => {
    const {
      props: { overrideFormErrors, enableSubmitButton, consent = false },
    } = this;
    if (overrideFormErrors !== undefined) {
      if (!overrideFormErrors) {
        return consent && this.checkConsent(isError);
      }
      return overrideFormErrors;
    }
    if (multipleFormError !== false) {
      return (
        multipleFormError || currentFormValues?.partneremailsettings?.setting !== "Custom Emails"
      );
    }
    // this flag used to enable submit button when when all required fields are filled on initial render.
    // for refernce go throught with the RI_marketplace manual input component
    if (enableSubmitButton) {
      return isError;
    }

    return consent ? this.checkConsent(isError) : isError || this.disableResetButton();
  };

  disableResetButton = () => {
    const {
      state: { values, initialValues },
    } = this;

    return JSON.stringify(values) === JSON.stringify(initialValues);
  };

  handleReset = (values) => {
    const {
      props: { onResetHandler },
    } = this;

    this.setState({ values });

    if (onResetHandler && typeof onResetHandler === "function") {
      onResetHandler(values);
    }
  };

  handleChange = (...props) => {
    const {
      props: { onChangeHandler, getErrorState },
      state: { isError },
    } = this;

    const [, values] = props;
    this.setState({ values, acknowledged: false });
    if (getErrorState) {
      getErrorState(isError);
    }

    props?.push(this?.disableResetButton());

    if (onChangeHandler && typeof onChangeHandler === "function") {
      onChangeHandler(...props);
    }
  };

  submitHandler = async (values) => {
    const {
      props: { handleSubmit },
    } = this;
    if (handleSubmit && typeof handleSubmit === "function") {
      if (await handleSubmit(values))
        this.setState({ initialValues: JSON.parse(JSON.stringify(values)) });
    }
  };

  render() {
    const {
      props: {
        pageTitle,
        crumbs,
        inputFields,
        currentFormValues,
        allMandatory = true,
        children,
        information,
        titleBottomBorder,
        onInitHandler,
        pageTitleStyle = "",
        formWrapperClass = "",
        hideTitle = false,
        documentTitle = "",
        addUserWrapperStyle = "",
        removeFooter,
        showErrorOnLoad,
        checkErrorOnLoadForFilledFields,
        formTitleConfig,
        formFooterStyle,
        V2,
        changeValidationStructure,
        showErrorOnSubmit = false,
        consent,
        hasPermission,
        onResetValuesHandler,
        overWritePermission = false, // for overwrite write permission pass this value as true
        dashboardWrapperClass = "",
      },
      state: { resetCount },
      renderButtons,
      handleReset,
      handleChange,
      submitHandler,
    } = this;
    const hasWritePermision = overWritePermission || hasPermission(PERMISSIONS_ENUM.WRITE);
    return (
      <div className={`dashboard_wrapper ${V2 ? "form-wrapper-v2" : ""} ${dashboardWrapperClass}`}>
        {(documentTitle || pageTitle) && <PageTitle pageTitle={documentTitle || pageTitle || ""} />}
        {!!crumbs?.length && <BreadCrumb crumbs={crumbs} />}
        {!hideTitle && (
          <Text
            text={pageTitle}
            size="large"
            type="primary"
            hr={titleBottomBorder}
            className={pageTitleStyle}
          />
        )}
        {allMandatory && (
          <p className={`px-5 pb-[6.5px] ${pageTitle ? "pt-4" : "pt-1"} text-[11px] font-semibold`}>
            Fields marked with <span className="text-red-500">*</span> are mandatory.
          </p>
        )}

        <div
          className={`createInvoice p-0 add-user-wrapper widget-basic mx-5 ${addUserWrapperStyle} ${
            !allMandatory && "mt-[15px]"
          } mb-7`}
        >
          {formTitleConfig && (
            <div className={formTitleConfig?.formDivClass}>
              {formTitleConfig?.formIcon}
              <p className="font-medium px-2.5">{formTitleConfig?.formTitle}</p>
            </div>
          )}
          <Form
            formConfig={inputFields}
            handleSubmit={submitHandler}
            isError={(value) => this.setState({ isError: value })}
            formValues={currentFormValues || {}}
            wrapperStyle={`m-5 flex-1 relative ${formWrapperClass}`}
            resetCount={resetCount}
            information={information}
            onChangeHandler={handleChange}
            onInitHandler={onInitHandler}
            onResetHandler={handleReset}
            showErrorOnLoad={showErrorOnLoad}
            checkErrorOnLoadForFilledFields={checkErrorOnLoadForFilledFields}
            changeValidationStructure={changeValidationStructure}
            showErrorOnSubmit={showErrorOnSubmit}
            hasWritePermision={hasWritePermision}
            onResetValuesHandler={onResetValuesHandler}
          >
            {hasWritePermision && !removeFooter && (
              <div
                className={`form-footer flex justify-between items-center bg-[#F0F6FF] p-[15px] ${formFooterStyle}`}
              >
                {children && <div className="flex flex-1">{children}</div>}
                <div className={consent ? "w-full" : "flex items-center justify-between ml-auto"}>
                  {renderButtons()}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
export default withRouter(FormWrapper);
