import React from "react";
import { ReactComponent as Arrow } from "../../../Assets/arrow.svg";
import "./style.scss";

function HighLightCards(props) {
  const { cardsData = [], className = "" } = props;
  return (
    <div className={`ck-cudos-highlight-cards-wrapper ${className}`}>
      {cardsData?.map((item) => (
        <>
          <div className="ck-cudos-highlight-heading-part flex gap-[5px] items-center px-[12px] py-[7px]">
            <div className="text-[14px] font-semibold text-[#0c477e]">{item?.label}</div>
          </div>
          <div className="ck-cudos-highlight-card">
            {item?.value?.map((data) => (
              <div className={`card-wrapper ${data?.link ? "has-link" : "no-link"}`}>
                {data?.link ? (
                  <div className="ck-cudos-highlight-data-link text-[12px]">
                    <div className="ck-cudos-highlight-label">{data?.label}</div>
                    <Arrow
                      onClick={() => {
                        window.open(`/dashboard${data?.link}`, "_blank");
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="ck-cudos-highlight-label">{data?.label}</div>
                )}

                <div className="text-[14px] font-bold text-[#0c477e] mt-[8px] leading-[14px]">
                  {data?.value || "--"}
                </div>
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}
export default HighLightCards;
