export const utilizationHeaders = [
  { key: "Notifications Groups" },
  { key: "Threshold" },
  { key: "Period" },
  { key: "Actions" },
];

export const CostDeviationHeaders = [
  { key: "budgetName", value: "Budget Name" },
  { key: "notificationGroups", value: "Notification Group(s)" },
  { key: "slackChannel", value: "Slack Channel" },
  { key: "budgetPeriod", value: "Type" },
  { key: "budgetCostAlert", value: "Budgeted Cost" },
  { key: "multifunctional", value: "Actions" },
];

export const UtilizationBAHeaders = [
  { key: "name", value: "Budget Name" },
  { key: "emailGroups", value: "Notification Groups" },
  { key: "slackChannel", value: "Slack Channel" },
  { key: "serviceType", value: "Service Type" },
  { key: "period", value: "Period" },
  { key: "threshold", value: "Threshold" },
  { key: "edit", value: "Actions" },
];

export const BudgetAlertTabs = {
  "Cost Deviation": "1",
  "RI Expiry": "2",
  "RI Utilization": "3",
  "Daily Summary Report": "4",
};

export const BudgetAlertMenu = [
  {
    id: 37,
    name: "Cost Deviation",
    parent: {
      id: 36,
      name: "Budget Alerts",
      type: "SIDE_MENU",
    },
    type: "ALERT",
    order: "1",
  },
  {
    id: 38,
    name: "RI Expiry",
    parent: {
      id: 36,
      name: "Budget Alerts",
      type: "SIDE_MENU",
    },
    type: "ALERT",
    order: "2",
  },
  {
    id: 39,
    name: "RI Utilization",
    parent: {
      id: 36,
      name: "Budget Alerts",

      type: "SIDE_MENU",
    },
    type: "ALERT",
    order: "3",
  },
  {
    id: 40,
    name: "Daily Summary Report",
    parent: {
      id: 36,
      name: "Budget Alerts",

      type: "SIDE_MENU",
    },
    type: "ALERT",
    order: "4",
  },
];
export const allAwsServiceScopeOption = "All AWS Services";
export const filterSpecificScopeOption = "Filter Specific AWS Cost dimensions";
