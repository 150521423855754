import React, { useRef, useState } from "react";
import { FormHelperText } from "@mui/material";
import Text from "./Text";
import collapseIcon from "../../../../Assets/icons/collapse.svg";
import expandIcon from "../../../../Assets/icons/expand.svg";
import useDetectOutsideClick from "../../../../CustomHooks/useDetectOutsideClick";
import { noop } from "../../../../Utils/commonUtils";

export default function ReadOnlyDropdown({
  name,
  error,
  className,
  placeHolder,
  options,
  label,
  touched,
  onBlurHandler = noop,
  disableBlurHandler = false,
}) {
  const [collapse, setCollapse] = useState(true);

  const renderLabel = () => (
    <div className="selected-options-readonly tracking-[-0.28px] flex">
      {options?.map((item, index) => {
        if (index < 2) {
          return (
            <div className="chip">
              <span className="chip-text">{item?.label}</span>
            </div>
          );
        }
        return null;
      })}
      {options?.length > 2 && (
        <span className="list-popup-wrapper">
          <span className="show-more !ml-1">+{options.length - 2}</span>
        </span>
      )}
    </div>
  );

  const ref = useRef();

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    if (!collapse) {
      setCollapse(true);
      if (!disableBlurHandler) {
        onBlurHandler({ target: { value: "", name } });
      }
    }
  });

  return (
    <div className={`dropdown-wrapper ${className}`}>
      {label?.text && (
        <Text text={label?.text} size="small" type="primary" className={label?.class} />
      )}
      <div ref={ref} className="dropdown">
        <div
          onClick={() => setCollapse(!collapse)}
          aria-hidden="true"
          className={`header ${options?.length && "filled"} ${!collapse && "dropdown"} ${
            touched && error && "error_box"
          }`}
        >
          <span>{renderLabel() || placeHolder}</span>
          <img src={collapse ? expandIcon : collapseIcon} alt={collapse ? "collapse" : "expand"} />
        </div>
        {!collapse && (
          <div className="body">
            <div name={name} className="option-wrapper">
              {options?.map((option) => (
                <div
                  className="option"
                  key={option?.value}
                  value={option?.value}
                  aria-hidden="true"
                >
                  {option?.label}
                </div>
              ))}
            </div>
          </div>
        )}
        {touched && error && (
          <FormHelperText error={error} size="small">
            {error}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
