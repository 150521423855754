import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Routes from "../../../Config/Routes";
import commonService from "../../../Services/commonService";
import cloudkeeperLogo from "../../../Assets/images/Cloudonomic-logo.svg";
import { updatePartnerFavicon } from "../../Login/utils";
import { preloadImages } from "../../../Utils/userUtils";
import Loader from "../Loader";
import "./style.scss";

export default function WelcomeMarketplace() {
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadhandler = async () => {
    setLoading(true);
    try {
      if (process.env.REACT_APP_BASE_URL !== window?.location?.origin) {
        const response = await commonService.partnerCreateUser();
        const partnerLogo = response?.data?.data?.logo || "";
        if (partnerLogo) {
          await preloadImages(partnerLogo);
        }
        setLogo(partnerLogo);
      } else {
        updatePartnerFavicon();
      }
      setLoading(false);
    } catch (e) {
      navigate(Routes?.LOGIN);
    }
  };

  useEffect(() => {
    loadhandler();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="welcome-container">
      <div className="page-content">
        <img className="logo" src={logo || cloudkeeperLogo} alt="logo" loading="eager" />
        <p className="mt-[55px] text-[22px] font-medium w-[75%] text-center">
          Thank you for subscribing to CK Lens via Marketplace. At this point, there is no further
          action required on your side and your access to the CK Lens platform will continue to
          function as is.
        </p>
      </div>
    </div>
  );
}
