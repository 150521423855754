import React, { useEffect, useState, useRef } from "react";
import { FormHelperText } from "@mui/material";
import ChipMultiSelect from "../../FormConfig/Form/Components/ChipMultiSelect";
import "./style.scss";
import useDetectOutsideClick from "../../../CustomHooks/useDetectOutsideClick";

export default function MultiChipComponent({
  value = [], // if it is in edit form
  name, // name of multiselect
  placeHolder,
  error,
  touched,
  v2 = false,
  wrapperWidthClass = "",
  getSeletedItems,
  onInputChange,
  onChangeHandler,
  clearAllFilter,
  listOptions,
  selectedItems,
  collapse,
  filterSelected,
  setCollapse,
  setFilterSelected,
  onClickHandler,
  listOptionsAll,
  noDataFound,
  inputClass = "",
  searchedText = "",
}) {
  const [chipHeaderHeight, setChipHeaderHeight] = useState("0px");
  const dropdownRef = useRef(null);

  useDetectOutsideClick(dropdownRef, (e) => {
    e.stopPropagation();
    if (!collapse) {
      onClickHandler();
    }
  });

  useEffect(() => {
    if (document.getElementById("chipHeader")) {
      setChipHeaderHeight(document.getElementById("chipHeader").offsetHeight);
    }
  }, [document.getElementById("chipHeader")]);

  return (
    <div className="invoice-customer" ref={dropdownRef}>
      <div
        onClick={() => setCollapse(!collapse)}
        aria-hidden="true"
        id="chipHeader"
        className={`header multiChipHeader ${touched && error && "error_box"} ${
          value?.length && "filled"
        } ${!collapse && "dropdown"}`}
      >
        <div
          className={v2 ? "flex items-center chipWrapClass" : ""}
          style={{ minHeight: `${chipHeaderHeight - 2}px`, height: "auto" }}
        >
          {getSeletedItems()}
          <div
            className={
              selectedItems.length
                ? "w-[100%] multiSelectInput"
                : "w-[100%] multiSelectInput placeholderWidth"
            }
            aria-hidden
            aria-describedby="input type"
            onClick={(e) => {
              e.stopPropagation();
              setCollapse(false);
            }}
          >
            <input
              type="text"
              placeholder={!selectedItems.length ? placeHolder : ""}
              className={`${
                selectedItems.length ? "multiSelectSearch" : "multiSelectSearch placeholderPadding"
              } ${inputClass}`}
              onChange={(e) => {
                e.stopPropagation();
                onInputChange(e);
              }}
              value={searchedText}
            />
          </div>
        </div>
      </div>
      {!collapse && (
        <ChipMultiSelect
          searchEnabled
          unitEnabled={false}
          options={listOptions}
          name={name}
          handleChange={onChangeHandler}
          selected={selectedItems}
          clearAllFilter={clearAllFilter}
          noDataText="No Data Available"
          filterSelected={filterSelected}
          setFilterSelected={setFilterSelected}
          wrapperWidthClass={wrapperWidthClass}
          listOptionsAll={listOptionsAll}
          noDataFound={noDataFound}
          searchedText={searchedText}
        />
      )}
      {touched && error && (
        <FormHelperText className="error_message wrapperWidthClass" error={error} size="small">
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
