/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import moment from "moment";
import { noop } from "../../../../Utils/commonUtils";
import {
  exclusionPreferenceValidation,
  getPartnerInvoideDate,
  renderYears,
} from "./PrivatePricingAgreements/util";
import {
  CloudfrontPricingCommitments,
  yearlyCommitmentStartDate,
  counterConfig,
  moduleSelectYearlyCpp,
} from "../../../FormConfig";
import RadioButtonGroup from "../../../FormConfig/Form/Components/RadioButtonGroup";
import Calendar from "../../../FormConfig/Form/Components/Calendar/Calender";
import {
  getDefaultCase,
  getDropDownCase,
  getInputCase,
  NoDataFoundMessage,
  yearlyCommitmentTableDropDownList,
  yearlyHeaders,
  selectAccountChargebackFormConfig,
  returnClassNameCommitmentTable,
  updatedAccountConfig,
  updateHeadingOnreadOnly,
  getUpdatedYearlyHeaders,
  exclusionPreferenceSection,
} from "./utils";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/deleteIcon.svg";
import BasicSelect from "../../../FormConfig/Form/Components/DropDown";
import Counter from "../../EdpDiscounts/counter";
import { returnMaxDecimal } from "../../EdpDiscounts/configDiscountsUtils";
import ListSelectorNoData from "../../../FormConfig/Form/Components/ListSelector.js/noData";
import { ReactComponent as SelectIcon } from "../../../../Assets/icons/Vector.svg";
import patterns from "../../../FormConfig/Form/patterns";
import "./style.scss";

function ConfigureCommitment({
  isEditable,
  heading,
  shortfallCharge = "NEVER",
  options,
  error = {},
  customerAccount,
  commitmentHeader = null,
  units = false,
  yearlyCommitmentTableData = [
    {
      year: 1,
      qty: null,
      unit: units
        ? {
            value: "GB_YEAR",
            label: "GB/Year",
          }
        : null,
    },
  ],
  value: currentFormValue,
  tableData = (currentFormValue?.tableData && [...currentFormValue.tableData]) || [
    ...yearlyCommitmentTableData,
  ],
  handleChange = noop,
  name: componentName,
  showNoData = false,
  readOnly = false,
}) {
  const [state, setState] = useState({
    commitmentStartDate: moment().format("DD-MMM-YYYY"),
    tenure: tableData.length,
    shortfallCharge,
    tableData,
    customerAccount,
    exclusionPreference: "",
  });

  const [touched, setTouched] = useState(false);

  const dropDownConfig = { ...moduleSelectYearlyCpp };
  dropDownConfig.options = [...yearlyCommitmentTableDropDownList];

  const handleChangeInternal = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleChange({ target: { name: componentName, value: { ...state, [name]: value } } });
  };

  const onTableInputChange = (tableInputName, value, tableIndex) => {
    const pattern = patterns?.number10Decimal4;
    if (!pattern.test(value)) {
      return;
    }
    setState((prevState) => {
      const updatedTableData = prevState.tableData.map((item, index) => {
        if (index === tableIndex) {
          return {
            ...item,
            [tableInputName]: value,
          };
        }
        return item;
      });
      handleChange({
        target: { name: componentName, value: { ...prevState, tableData: updatedTableData } },
      });
      return {
        ...prevState,
        tableData: updatedTableData,
      };
    });
  };

  const handleCounterChange = (valueObj, type = "number") => {
    const pattern = /^\d+$/;
    const numPattern = /^[1-9][0-9]?$/;
    const updateEditableData = [...state.tableData];

    if (type === "input") {
      const numWithTrim = valueObj.value?.trim();
      const inputValue = numPattern.test(numWithTrim);
      const valueToSet = inputValue ? returnMaxDecimal(numWithTrim, pattern, 0) : state.tenure;

      if (updateEditableData.length > Number(valueToSet)) {
        const rowsAfterDelete = updateEditableData.slice(0, Number(valueToSet));
        setState((prevState) => {
          const updatedState = {
            ...prevState,
            tableData: rowsAfterDelete,
            tenure: valueToSet,
          };
          handleChange({
            target: { name: componentName, value: updatedState },
          });
          return updatedState;
        });
      } else if (updateEditableData.length < Number(valueToSet)) {
        const rowsToAdd = Number(valueToSet) - updateEditableData.length;
        const lengthofData = updateEditableData.length;
        const newRows = Array.from({ length: rowsToAdd }, (_, index) => ({
          year: Number(lengthofData + index + 1),
          qty: null,
          unit: units
            ? {
                value: "GB_YEAR",
                label: "GB/Year",
              }
            : null,
        }));
        setState((prevState) => {
          const updatedState = {
            ...prevState,
            tableData: [...prevState.tableData, ...newRows],
            tenure: valueToSet,
          };
          handleChange({
            target: { name: componentName, value: updatedState },
          });
          return updatedState;
        });
      }
    } else {
      if (type === "add") {
        const obj = {
          year: Number(updateEditableData.length + 1),
          qty: null,
          unit: units
            ? {
                value: "GB_YEAR",
                label: "GB/Year",
              }
            : null,
        };
        setState((prevState) => {
          const updatedState = {
            ...prevState,
            tableData: [...prevState.tableData, obj],
            tenure: valueObj.value,
          };
          handleChange({
            target: { name: componentName, value: updatedState },
          });
          return updatedState;
        });
      }
      if (type === "subtract") {
        updateEditableData.pop();
        setState((prevState) => {
          const updatedState = {
            ...prevState,
            tableData: [...updateEditableData],
            tenure: valueObj.value,
          };
          handleChange({
            target: { name: componentName, value: updatedState },
          });
          return updatedState;
        });
      }
    }
  };

  const handleChangeDropdown = (e, tableIndex) => {
    const { value } = e.target;

    setState((prevState) => {
      const updatedTableData = prevState.tableData.map((item, index) => {
        if (index === tableIndex) {
          return {
            ...item,
            unit: value,
          };
        }
        return item;
      });

      handleChange({
        target: {
          name: componentName,
          value: {
            ...prevState,
            tableData: updatedTableData,
          },
        },
      });

      return {
        ...prevState,
        tableData: updatedTableData,
      };
    });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = state.tableData
      .filter((_, i) => i !== index)
      .map((row, i) => ({
        ...row,
        year: i + 1,
      }));

    setState((prev) => {
      const updatedState = {
        ...prev,
        tableData: updatedRows,
        tenure: prev.tenure - 1,
      };

      handleChange({
        target: { name: componentName, value: updatedState },
      });

      return updatedState;
    });
  };

  const handleShortfallChangeAccount = (e) => {
    const { value } = e.target;
    setState((prevState) => {
      const updatedState = {
        ...prevState,
        customerAccount: value,
      };
      handleChange({
        target: { name: componentName, value: updatedState },
      });
      return updatedState;
    });
  };

  const exclusionPreferencehandler = (event) => {
    const {
      target: { name, value },
    } = event;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleChange({ target: { name: componentName, value: { ...state, [name]: value } } });
  };

  const renderContent = (header, data, tableIndex, errorMsg) => {
    const { type } = header;
    // if (isEditable) {
    switch (type) {
      case "dropdown":
        return getDropDownCase(
          dropDownConfig,
          data,
          header,
          tableIndex,
          handleChangeDropdown,
          state.tableData
        );
      case "input":
        return getInputCase(
          header,
          data,
          tableIndex,
          state.tableData,
          onTableInputChange,
          errorMsg
        );
      case "Actions":
        return (
          <div
            aria-hidden
            onClick={() => handleDeleteRow(tableIndex)}
            className={`${state.tableData.length > 1 ? "cursor-pointer" : "pointer-events-none"}`}
          >
            {state?.tenure > 1 && !readOnly && <DeleteIcon className="delete_icon" />}
          </div>
        );
      default:
        return getDefaultCase(header, data, renderYears);
    }
  };

  return (
    <div className={returnClassNameCommitmentTable(isEditable, readOnly)}>
      <div className="header">
        <h1 className="title">{updateHeadingOnreadOnly(heading, readOnly)}</h1>
      </div>
      {showNoData ? (
        <>
          <div className="commitment-container">
            <div className="dropdownContatiner">
              <div className="counter_container">
                <p className="label_font_size mandatory">
                  {readOnly ? "Tenure" : "Select Tenure (Years)"}
                </p>
                <Counter
                  {...counterConfig}
                  currentValue={readOnly ? currentFormValue?.tenure : state.tenure}
                  onInputChange={handleCounterChange}
                />
              </div>

              <Calendar
                {...yearlyCommitmentStartDate}
                error={state.commitmentStartDate === null}
                handleChange={(event) => handleChangeInternal(event)}
                value={
                  moment(currentFormValue?.commitmentStartDate).format("DD-MMM-YYYY") ||
                  state.commitmentStartDate
                }
                touched={{ yearlyCommitmentStartDate: false }}
                onBlurHandler={(event) => {
                  console.log(event);
                }}
                disablePreviousDates
                disableFutureDates={false}
                autoApply
                minDate={getPartnerInvoideDate()}
              />
            </div>
            <div className="yearly-commitment-container">
              {commitmentHeader ? (
                <div className="commitment-header">
                  <h7 className="title mandatory">Yearly Wise Commitments</h7>
                </div>
              ) : (
                ""
              )}

              <div className="table_container" id="yearyly_wise_contianer">
                <table>
                  <thead>
                    <tr>
                      {getUpdatedYearlyHeaders(units, yearlyHeaders)?.map((header) => (
                        <th className={header.className}>{header?.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {state?.tableData?.length ? (
                      <>
                        {state.tableData.map((item, index) => (
                          <tr>
                            {getUpdatedYearlyHeaders(units, yearlyHeaders).map((header) => (
                              <td>
                                <div>
                                  {renderContent(
                                    header,
                                    item,
                                    index,
                                    typeof error === "string" ? error : error?.[index]
                                  )}
                                </div>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    ) : (
                      NoDataFoundMessage()
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="btn_group_container">
              <RadioButtonGroup
                {...CloudfrontPricingCommitments}
                topLabel={{
                  ...CloudfrontPricingCommitments?.topLabel,
                  text: readOnly ? "Shortfall Chargeback" : "Handle Shortfall Chargeback?",
                }}
                value={currentFormValue?.shortfallCharge || state?.shortfallCharge}
                handleChange={(event) => handleChangeInternal(event)}
                isEditable={isEditable}
              />
            </div>
            {(currentFormValue?.shortfallCharge
              ? currentFormValue.shortfallCharge !== "NEVER"
              : state.shortfallCharge !== "NEVER") && (
              <div className="account-dropdown">
                <BasicSelect
                  {...updatedAccountConfig(selectAccountChargebackFormConfig, readOnly)}
                  value={currentFormValue?.customerAccount || state?.customerAccount}
                  options={options}
                  handleChange={(e) => {
                    handleShortfallChangeAccount(e);
                  }}
                  className="title"
                  error={error?.customerAccount}
                  onBlurHandler={() => {
                    setTouched(true);
                  }}
                  touched={touched}
                />
              </div>
            )}
          </div>
          <div className="preference-section !w-full">
            {exclusionPreferenceSection({
              currentFormValue,
              state,
              exclusionPreferenceValidation,
              exclusionPreferencehandler,
            })}
          </div>
        </>
      ) : (
        <div className="commitment-container h-[400px] relative">
          <ListSelectorNoData message="Please select region and pricing type" Icon={SelectIcon} />
        </div>
      )}
    </div>
  );
}

export default ConfigureCommitment;
