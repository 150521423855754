/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import "./style.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { InputLabel } from "@mui/material";

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 400,
//     },
//   },
// };

export default function MultipleSelectCheckmarks(props) {
  const {
    selectedChecks = [],
    dataList,
    getError,
    multiSelectValid,
    id,
    label,
    updateCbuList,
    selectedCbuList,
    noDataMessage,
    width = "auto",
  } = props;
  const [touched, setTouched] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const selectedCheck = [];

  if (selectedChecks?.length) {
    selectedChecks?.split(",")?.forEach((item) => selectedCheck.push(item.id));
  }

  const [variantName, setVariantName] = useState(selectedCheck);

  useEffect(() => {
    setVariantName([...selectedCbuList]);
    setTouched(false);
  }, [id]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("selectAll") && !selectAll) {
      setSelectAll(true);
      setVariantName(dataList.map((item) => item.id));
    } else if (variantName?.length === dataList?.length && !selectAll) {
      setSelectAll(true);
    } else if (value.includes("selectAll") && selectAll) {
      setSelectAll(false);
      setVariantName([]);
    } else {
      setVariantName(value);
    }
  };

  const onSave = () => {
    setVariantName(variantName);
    updateCbuList(variantName);
    document.getElementsByClassName("MuiBackdrop-root")[0].click();
  };

  const onCancel = () => {
    setVariantName(selectedCbuList);
    document.getElementsByClassName("MuiBackdrop-root")[0].click();
  };

  const deleteHandler = (value) => {
    const updatedDataList = selectedCbuList.filter((item) => item !== value);
    setVariantName(updatedDataList);
    updateCbuList(updatedDataList);
  };

  return (
    <div className="multipleSelectCheckmarks custom_multiselect">
      <p className="mandatory">{label}</p>
      <FormControl
        size="small"
        sx={{ m: 1, width: "100%" }}
        label="Oauth Token"
        variant="outlined"
        required
        error={!!getError && touched && !multiSelectValid}
        className="multicheck"
      >
        <InputLabel htmlFor="name-multiple">Select CBU</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id={id}
          multiple
          className="multicheckselect"
          value={variantName || []}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
                width, // Set the width of the dropdown menu
              },
            },
          }}
          onChange={(event) => handleChange(event)}
          onFocus={() => setTouched(true)}
          input={<OutlinedInput label={`${label}*`} />}
          renderValue={(selected) => (
            <Box
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              className="emailTableChips"
              id="multichips"
            >
              {selected.map((value, index) =>
                index < 2 ? (
                  <Chip
                    key={value}
                    label={dataList.filter((item) => item.id === value)?.[0]?.name}
                    onDelete={() => deleteHandler(value)}
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                  />
                ) : (
                  index === 2 && (
                    <Chip
                      className="optext-length"
                      key={value}
                      label={`+ ${selected.length - 2}`}
                    />
                  )
                )
              )}
            </Box>
          )}
          // MenuProps={MenuProps}
        >
          {/* {dataList.length ? ( */}
          {/* <> */}
          {dataList.length > 0 ? (
            <MenuItem key="Select All" value="selectAll" className="custom_select">
              <Checkbox style={{ padding: 0 }} checked={variantName?.length === dataList.length} />
              <ListItemText primary="Select All" />
            </MenuItem>
          ) : (
            <p className="no_multiCheckListMessage">{noDataMessage}</p>
          )}
          {dataList.map((item) => (
            <MenuItem key={item.name} value={item.id} className="custom_select">
              <Checkbox style={{ padding: 0 }} checked={variantName?.includes(item.id)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
          <div className="multiButtons">
            <Button
              variant="outlined"
              size="small"
              id="close-filter"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              id="apply-filter"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
          </div>
          {/* </> */}
          {/* ) : null} */}
        </Select>
      </FormControl>
    </div>
  );
}
