import React, { useEffect, useState } from "react";

// material ui impoers
import Button from "@mui/material/Button";

// moment for data formatting
import moment from "moment";

// redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withRouter from "../../../../Common/WithRouter";
import actions from "../../../../../Actions";

import "./style.scss";

// icons
import { ReactComponent as ExcelIcon } from "../../../../../Assets/icons/others/ic-xls-v2.svg";
import { ReactComponent as ExcelDisableIcon } from "../../../../../Assets/icons/excel-disabled.svg";
import { ReactComponent as DownloadIcon } from "../../../../../Assets/icons/others/download.svg";
import { ReactComponent as ExcelLoader } from "../../../../../Assets/icons/others/excel-loader-blue.svg";

function CostBreakupHeaderV2(props) {
  const {
    hasData,
    isLoading,
    downloadSheet,
    selectedDate,
    navigate,
    accountDate: { accountStartDate, accountEndDate },
    accountDate,
    excelLoading,
    customClass = "",
    downloadButtonStyle = "",
  } = props;
  const [selectedDateHeader, setSelectedDate] = useState(null);

  let startDate = moment();
  let endDate = moment();

  if (accountDate) {
    startDate = moment(new Date(accountStartDate));
    endDate = moment(new Date(accountEndDate));
  }

  const result = [];
  const dataQuery = localStorage.getItem("query");
  const updatedUrl = new URLSearchParams(window.location.search);
  const updatedDate = moment(`${updatedUrl.get("year")}/${updatedUrl.get("month")}/01`).format(
    "YYYY-MM-01"
  );

  useEffect(() => {
    if (
      !!accountEndDate &&
      updatedUrl?.get("year") &&
      updatedUrl?.get("month") &&
      !moment(`${updatedUrl.get("year")}-${updatedUrl.get("month")}-01`).isBetween(
        moment(accountStartDate),
        moment(accountEndDate),
        "month",
        "[]"
      )
    ) {
      const temp = accountEndDate;
      const searchQuery = `?month=${moment(temp).format("MM")}&year=${moment(temp).format("YYYY")}`;
      localStorage.setItem("query", searchQuery);
      const searchURL = new URLSearchParams(window.location.search);
      const selected = searchURL.get("selected");
      navigate({
        search: `?month=${moment(temp).format("MM")}&year=${moment(temp).format(
          "YYYY"
        )}&selected=${selected}`,
      });
      const date = new URLSearchParams(window.location.search);
      const updatedselectedDate = moment([date.get("year"), date.get("month") - 1]).format(
        "YYYY-MM-01"
      );
      setSelectedDate(updatedselectedDate);
      selectedDate(temp);
    } else if (dataQuery !== null) {
      const date = new URLSearchParams(window.location.search);
      const searchQuery = `?month=${date.get("month")}&year=${date.get("year")}`;
      localStorage.setItem("query", searchQuery);
      const month = date.get("month");
      const year = date.get("year");
      const formmatData = `${year}-${month}-01`;
      setSelectedDate(formmatData);
    } else {
      setSelectedDate(
        moment(`${updatedUrl.get("year")}/${updatedUrl.get("month")}/01`).format("YYYY-MM-01")
      );
    }
  }, [updatedUrl, updatedDate, accountEndDate, accountStartDate]);
  if (endDate.isBefore(startDate)) {
    // throw "End date must be greated than start date.";
  }

  while (moment(startDate.format("YYYY-MM-01")).isBefore(endDate)) {
    result.push(startDate.format("YYYY-MM-01"));
    startDate.add(1, "month");
  }

  const handleDateChange = (event) => {
    const {
      target: { value },
    } = event;
    const searchQuery = `?month=${moment(value).format("MM")}&year=${moment(value).format("YYYY")}`;
    localStorage.setItem("query", searchQuery);
    const searchURL = new URLSearchParams(window.location.search);
    const selected = searchURL.get("selected");
    navigate(
      `?month=${moment(value).format("MM")}&year=${moment(value).format(
        "YYYY"
      )}&selected=${selected}`
    );
    setSelectedDate(value);
    selectedDate(event);
  };

  // const downloadSheet = () => {
  //   props.downloadSheet();
  // };
  return (
    <div className={`billing_console_header ${customClass}`} id="dropdownMenu1">
      <div className="month-dropdownCont month-dropdownCont_v2">
        <label htmlFor="dropdown" className="dropdown v2_label_style">
          Month
        </label>
        <select
          id="dropdown"
          value={selectedDateHeader}
          onChange={(e) => handleDateChange(e)}
          className={
            isLoading
              ? "custom_selectbox v2_custom_select disabled !px-[8px] !py-[9px] !text-xs"
              : "custom_selectbox v2_custom_select !px-[8px] !text-xs"
          }
          disabled={isLoading}
        >
          {result
            .slice(0)
            .reverse()
            .map((item) => (
              <option value={item} id={moment(item).format("MMMM_YYYY")}>
                {moment(item).format("MMMM YYYY")}
              </option>
            ))}
        </select>
      </div>
      <Button
        variant="outlined"
        size="medium"
        onClick={() => {
          downloadSheet();
        }}
        className={`download_v2 download_button_style !px-[6px] ${hasData ? "disable_v2" : ""} ${
          excelLoading ? "download_loading" : ""
        } ${downloadButtonStyle}`}
        disabled={hasData || excelLoading}
        id="download"
      >
        {hasData ? (
          <ExcelDisableIcon className="btn_icon_style download_excel_v2" />
        ) : (
          <ExcelIcon className="btn_icon_style download_excel_v2" />
        )}
        Download
        {excelLoading ? (
          <ExcelLoader className="excel-loading-gif mr_5 !ml-[5px] !mr-0" />
        ) : (
          <DownloadIcon className="btn_icon_style !ml-[5px] no-margin-right" />
        )}
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountDate: state.AccountDateReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CostBreakupHeaderV2));
