import { Switch } from "@material-ui/core";
import React, { useState, useEffect } from "react";

function ToggleInput({
  name,
  className,
  value,
  handleChange,
  rightText = "Include Only",
  leftText = "Exclude Only",
}) {
  const [checked, setChecked] = useState(value || false);
  const onChangeHandler = (event) => {
    setChecked(event.target.checked);
    handleChange({ target: { name, value: event.target.checked } });
  };

  useEffect(() => {
    if (value !== checked) {
      setChecked(value);
    }
  }, [value]);

  return (
    <div className={`flex items-center ${className}`}>
      <p
        className={`active-items text-xs ml-1 !mt-0 ${
          checked === false ? "font-bold !text-[#0A3CA2]" : "font-[500] !text-[#555B6C]"
        }`}
      >
        {leftText}
      </p>
      <div className={`${checked ? "toggleEnable" : "toggleDisable"} switch-icon`}>
        <Switch
          size="small"
          name={name}
          value={checked}
          checked={checked}
          title="Enable/Disable"
          onChange={onChangeHandler}
        />
      </div>
      <p
        className={`active-items text-xs !mt-0 ${
          checked === true ? "font-bold !text-[#0A3CA2]" : "font-[500] !text-[#555B6C]"
        }`}
      >
        {rightText}
      </p>
    </div>
  );
}

export default ToggleInput;
