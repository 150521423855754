import React from "react";
import "./style.scss";
import { numberWithCommas } from "../../../../../../Utils/commonUtils";
import {
  ReturnSortIcon,
  sortHandler,
  ReturnSingleLevelTdClass,
} from "../../../CostBreakup/costbreakupUtils";

class SingleLevelTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ascending: false,
      data: [],
      selectedSortHeader: "",
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      data,
    });
  }

  componentDidUpdate(prevProps) {
    const { data = [] } = this.props;

    if (JSON.stringify(data) !== JSON.stringify(prevProps.data || [])) {
      this.setState({
        data,
      });
    }
  }

  sortHandler = (sortBy, data) => {
    const { ascending, selectedSortHeader } = this.state;
    let updateAscending = ascending;
    let newSortedData = [];

    if (selectedSortHeader !== sortBy) {
      updateAscending = false;
    }
    newSortedData = sortHandler(data, updateAscending, sortBy);
    this.setState({
      data: newSortedData,
      ascending: !updateAscending,
      selectedSortHeader: sortBy,
    });
  };

  getSubLegendsClass = (currElement, prevElement) => {
    if (currElement?.subLegend || prevElement?.subLegend) {
      return "sub-legend-header";
    }
    return null;
  };

  render() {
    const {
      props: {
        heading,
        headings,
        diableFunctionality,
        footer,
        hasDeletePermission,
        hasCreatePermission,
        newFooter,
        dataTransferResourceTable,
        V2,
        lastColumnSticky,
        showSortingOnTotal,
      },
      state: { data = [], ascending, selectedSortHeader },
    } = this;
    const tableHeaders = headings.filter(
      (header) => !(header.value === "Actions" && !hasCreatePermission && !hasDeletePermission)
    );

    return (
      <div
        className={`table_wrapper ${!data.length && "w100"} ${
          V2 ? "Striped-Table single-striped-table" : ""
        } ${lastColumnSticky ? "last-column-sticky" : ""}`}
      >
        {data.length ? (
          <table id={heading ? heading?.split(/\s/).join("") : ""}>
            <thead>
              {tableHeaders &&
                tableHeaders.map((element, index) => (
                  <th
                    key={element.key}
                    className={
                      element.key === "notificationGroups"
                        ? "normalText"
                        : this.getSubLegendsClass(element, tableHeaders[index - 1])
                    }
                  >
                    {element.subLegend && (
                      <div className="sub-legends-wrapper">
                        <div className="sub-legends-content-wrapper">
                          <span>{element.subLegend}</span>
                          <div className="horizontal-line" />
                        </div>
                      </div>
                    )}
                    <span
                      className={`header-name ${
                        element.value === "Total Cost" ? dataTransferResourceTable : ""
                      } ${
                        lastColumnSticky &&
                        tableHeaders?.length === index + 1 &&
                        "header-name-last-sticky"
                      }`}
                    >
                      <span
                        className="cursor-pointer select-none"
                        onClick={() => this.sortHandler(element?.key, data)}
                        aria-hidden
                      >
                        {element?.value}
                      </span>
                      <span
                        className={`${
                          V2
                            ? `mt-[-3px] ${
                                tableHeaders?.length === index + 1 ? "!ml-[0px]" : "!ml-[10px]"
                              }`
                            : ""
                        } cursor-pointer`}
                        aria-hidden="true"
                        onClick={() => this.sortHandler(element.key, data)}
                      >
                        {lastColumnSticky &&
                        tableHeaders?.length === index + 1 &&
                        !showSortingOnTotal
                          ? null
                          : ReturnSortIcon(selectedSortHeader === element?.key, ascending, V2)}
                      </span>
                    </span>
                  </th>
                ))}
            </thead>
            <tbody>
              {!!data &&
                data.map((row) => (
                  <tr
                    key={row.id}
                    id={row.id}
                    className={(diableFunctionality && (row.active ? "active" : "disabled")) || ""}
                  >
                    {tableHeaders.map((item) => (
                      <td
                        id={`${item.key}`}
                        className={`${ReturnSingleLevelTdClass(item)} ${
                          item.key === "total" && dataTransferResourceTable
                        }`}
                      >
                        {item.prefix || ""}
                        {typeof row[item.key] === "number"
                          ? numberWithCommas(row[item.key]?.toFixed(2))
                          : row[item.key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
            <tfoot>
              {!!newFooter &&
                tableHeaders.map((item) => (
                  <td key={`footer-${item?.key}`}>
                    {typeof newFooter[item?.key] === "number"
                      ? numberWithCommas(newFooter[item?.key]?.toFixed(2))
                      : numberWithCommas(newFooter[item?.key])}
                  </td>
                ))}
              {!!footer &&
                footer?.length &&
                footer?.map((item) => <td key={`footer-${item}`}>{item}</td>)}
            </tfoot>
          </table>
        ) : (
          <div>
            <h5 className="no_data">No data Available</h5>
          </div>
        )}
      </div>
    );
  }
}

export default SingleLevelTable;
