import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { filterList, noop, sortByName } from "../../../../../Utils/commonUtils";
import SearchBar from "../../../../FormConfig/Form/Components/SearchBar";

export default function ModalMultiselect({
  value,
  name,
  searchEnabled,
  className,
  options,
  handleChange,
  unitEnabled = false,
  label,
  placeholder,
  selected = [],
  noDataText,
  noDataLabel,
  children,
  resetSelection = noop,
}) {
  const listOfOption = sortByName(options, "value");
  const [searchTerm, setSearchTerm] = useState("");
  const searchResult = listOfOption?.filter(filterList(searchTerm, "value"));
  const unSelectedItems = searchResult.filter((option) => !option.checked);

  const getSeletedItems = (e, option) =>
    e.target.checked ? [...selected, option] : selected.filter((ele) => ele.value !== option.value);

  const handleMultiSelectChange = (e, option) => {
    const selectedItems = getSeletedItems(e, option);

    const optionClone = option;
    optionClone.checked = e.target.checked;

    handleChange({ ...e, target: { ...e.target, value: selectedItems, name: e.target.name } });
  };

  const selectAll = (e) => {
    const { checked } = e.target;
    const selectedItems = searchResult.filter((option) => {
      const optionClone = option;
      optionClone.checked = e.target.checked;
      return optionClone.checked;
    });

    handleChange({
      ...e,
      target: {
        ...e.target,
        value: checked
          ? [
              ...selected.filter(
                (item) => !searchResult.find((option) => option.value === item.value)
              ),
              ...selectedItems,
            ]
          : selected.filter((item) => !searchResult.find((option) => option.value === item.value)),
        name: e.target.name,
      },
    });
  };

  const onSearchHandler = (event) => {
    resetSelection();
    setSearchTerm(event.target.value);
  };

  const renderCheckBoxes = () =>
    searchResult.length ? (
      <>
        <div className="option py-0.5 flex items-center justify-between">
          <FormControlLabel
            control={
              <Checkbox
                className={className}
                name={name}
                id={value}
                disableRipple
                checked={!unSelectedItems?.length}
                onChange={(e) => selectAll(e)}
              />
            }
            label="Select All"
          />
        </div>

        {searchResult.map((option) => (
          <div className="option py-0.5 flex items-center justify-between" key={option.label}>
            <FormControlLabel
              control={
                <Checkbox
                  className={className}
                  name={name}
                  id={value}
                  checked={option.checked}
                  disableRipple
                  onChange={(e) => handleMultiSelectChange(e, option)}
                />
              }
              label={option.value}
            />
            {unitEnabled && <div className="unit text-xs px-2 py-1 ">{option.unit}</div>}
          </div>
        ))}
      </>
    ) : (
      <span className="text-[10px] no_data_found">No Data Available</span>
    );

  return (
    <div className="">
      <div aria-hidden="true" className="search_wrapper wrapper_padding">
        <span className="text-black search-label">{label}</span>
        {searchEnabled && !!options.length && (
          <SearchBar
            placeholder={placeholder}
            searchTerm={searchTerm}
            onChangeHandler={onSearchHandler}
          />
        )}
      </div>
      <div className="multi-select-dropdown options_wrapper_multiselect">
        {options?.length ? (
          renderCheckBoxes()
        ) : (
          <div className="display_flex gap-y-1 flex-col w-[100%] m-auto no-item-selected-list">
            {/* <img src={warningIcon} alt="warning-icon" /> */}
            <span className="no-data-label text-[13px] mb-4 text-[#0A3CA2]">{noDataLabel}</span>
            <p className="!text-[10px] !text-center !text-[#7C7C7C]">{noDataText}</p>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
