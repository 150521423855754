const workerScript = `
  function numberFilter(tableData, item, filters) {
    const quantityValues = tableData.map((singleRow) => singleRow[item]);
    const definedQuantityValues = quantityValues.filter((value) => value !== undefined);
    const max = Math.max(...definedQuantityValues).toFixed(2);
    const min = Math.min(...definedQuantityValues).toFixed(2);
    const filterIndividualNumber = {
      name: item,
      key: item,
      max,
      min,
      selectedValue: [min, max],
      type: "number",
    };
    filters.push(filterIndividualNumber);
  }

  function dateFilter(tableData, item, filters) {
    const min = new Date(Math.min(...tableData.map((e) => new Date(e[[item]]))));
    const max = new Date(Math.max(...tableData.map((e) => new Date(e[[item]]))));
    const filterIndividual = {
      name: item,
      key: item,
      selectedDates: [min, max],
      min,
      max,
      type: "dates",
    };
    filters.push(filterIndividual);
  };

  function isDate(item) {
    return item.toLowerCase().includes("date");
  }


  function stringFilter(tableData, item, filters) {
    if (isDate(item)) {
      dateFilter(tableData, item, filters);
    } else {
      const uniqueValue = [
        ...new Set(tableData.map((internalObj) => (internalObj[item] !== "" ? internalObj[item] : ""))),
      ].filter((internalItem) => internalItem !== "");
      const filterIndividual = {
        name: item,
        key: item,
        values: uniqueValue.filter((singleUniqueValue) => singleUniqueValue !== undefined),
        value: [],
        filterKey: "Include Only",
        type: "string",
      };
      filters.push(filterIndividual);
     }
    
  }

  self.onmessage = function(e) {
    try {
      const tableData = e.data.tableData;
      const filters = [];
      const headers = tableData.length ? tableData[0] : [];
      if (headers) {
        Object.keys(headers).forEach((item) => {
          switch (typeof headers[item]) {
            case "string":
              stringFilter(tableData, item, filters);
              break;
            case "number":
              numberFilter(tableData, item, filters);
              break;
            default:
              // Optionally handle other types or skip
          }
        });
      }
      self.postMessage({ filters });
    } catch (error) {
      self.postMessage({ error: error.message });
    }
  }
`;

export default workerScript;
