const initState = {
  sidebarList: [],
  menuCollapse: false,
  mavList: [],
  moduleList: [],
};
const initAction = { type: "", payload: {} };

export default function sidebarReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "RENDER_DASHBOARDLIST":
      return { ...state, ...action.payload };
    case "TOGGLE_SIDEBAR":
      return { ...state, menuCollapse: action.payload };
    case "MAV_LIST":
      return { ...state, mavList: action.payload };
    case "USER_LIST":
      return { ...state, userList: action.payload };
    case "MODULE_LIST":
      return { ...state, moduleList: action.payload };
    case "NOTIFICATION_LIST":
      return { ...state, notificationList: action.payload };
    case "LOGEDIN_USER_DATA":
      return { ...state, logedInUserData: action.payload };
    default:
      return state;
  }
}
