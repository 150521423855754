import { generateUniqueId } from "../../../helper";

export const transformInitialFields = (value) =>
  value?.map((val) => ({
    ...val,
    id: val?.id ? val?.id : generateUniqueId(),
    disabled: true,
  }));

export const createNewField = (id = 1) => ({ id, value: "" });
