const initState = { isLoaderVisible: false };
const initAction = { type: "", payload: {} };

export default function dashbaordReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "SHOW_APP_LOADER":
    case "HIDE_APP_LOADER":
      return { ...state, isLoaderVisible: action.payload };
    case "RELOAD":
      return { ...state, reload: action.payload };
    case "INPROGRESS":
      return { ...state, inprogress: action.payload };
    case "SET_CURRENT_DASHBOARD":
      return { ...state, currentDashboard: action.payload };
    default:
      return state;
  }
}
