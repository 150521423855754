import React, { useEffect, useState } from "react";
import Text from "../../FormConfig/Form/Components/Text";
import "./style.scss";
import getMultiSelectChips from "./util";
import MultiChipComponent from "./MultiChipComponent";

// eslint-disable-next-line max-lines-per-function
export default function MultiChipSelector({
  value = [], // if it is in edit form
  name, // name of multiselect
  labelText,
  placeHolder,
  options = [], // array of object to show dropdown options which contain name, value, id, checked.
  handleChange,
  error,
  touched,
  v2 = false,
  required = false,
  readMode,
  labelClass,
  wrapperWidthClass = "",
  noDataFound,
  inputClass = "",
}) {
  const [collapse, setCollapse] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  // const [chipHeaderHeight, setChipHeaderHeight] = useState("0px");
  const [listOptions, setListOptions] = useState([]);
  const [listOptionsAll, setListOptionAll] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  // const [filterSelected, setFilterSelected] = useState("include");

  useEffect(() => {
    setItems(options);
  }, [options]);

  // const clearAllFilter = () => {
  //   setSelectedItems([]);
  // };

  const selectedOptionsFirst = (list) => {
    if (value?.length && !collapse && JSON.stringify(value) === JSON.stringify(selectedItems)) {
      return list.sort((a, b) => Number(b.checked) - Number(a.checked));
    }
    return list;
  };

  const onClickHandler = () => {
    // let selectedOptions = [];
    // if (filterSelected === "Clear") {
    //   selectedOptions = [];
    // } else if (filterSelected === "include") {
    //   selectedOptions = listOptions.filter((item) => item.checked);
    // } else {
    //   selectedOptions = listOptions.filter((item) => !item.checked);
    // }
    handleChange({
      target: {
        value: (selectedItems.length && selectedItems) || [],
        name,
        // isIncludeAll: !(filterSelected === "Clear" || filterSelected === "exclude"),
      },
    });

    setCollapse(!collapse);
  };

  const onChangeHandler = (event) => {
    setSelectedItems(event.target.value.filter((item) => item.checked));
    setListOptions(
      items.map((i) => ({
        ...i,
        checked: event.target.value.find((itemsD) => itemsD?.value === i?.value)?.checked
          ? true
          : false || i?.checked,
      }))
    );
    setSearchedText("");
  };

  const mappedDataChecked = (sourceData, findData) => {
    const mappedFinalData = sourceData.map((i) => ({
      ...i,
      checked: findData.find((itemsFind) => itemsFind?.value === i?.value)?.checked
        ? true
        : false || false,
    }));
    return mappedFinalData;
  };

  const removeChipHandler = (e, item, index) => {
    e.stopPropagation();
    const updatedSelectedItems = selectedItems;
    updatedSelectedItems[index].checked = false;
    setSelectedItems(updatedSelectedItems.filter((ele) => ele.checked));
    setListOptions(mappedDataChecked(listOptions, updatedSelectedItems));
    setListOptionAll(mappedDataChecked(listOptionsAll, updatedSelectedItems));
    onChangeHandler({ ...e, target: { ...e.target, value: updatedSelectedItems, name } });
    handleChange({
      target: {
        value: updatedSelectedItems.filter((i) => i?.checked) || [],
        name,
      },
    });
  };

  const getSeletedItems = () => getMultiSelectChips(selectedItems, removeChipHandler, readMode);
  const onInputChange = (e) => {
    setSearchedText(e.target.value);
    if (e.target.value) {
      const listData = selectedOptionsFirst(items);
      const filteredListData = listData.filter((listI) =>
        listI?.label?.toLowerCase().includes(e.target.value.toLocaleLowerCase())
      );
      setListOptions(mappedDataChecked(filteredListData, value));
    } else {
      setListOptions(mappedDataChecked(selectedOptionsFirst(items), value));
    }
  };

  useEffect(() => {
    const listOptionsData = mappedDataChecked(selectedOptionsFirst(items), value);
    setListOptions(listOptionsData);
    setListOptionAll(listOptionsData);
  }, [items]);

  useEffect(() => {
    if (value?.length) {
      setSelectedItems(value.map((i) => ({ ...i, checked: i?.checked || true })));
    }
  }, [value]);

  return (
    <div
      className={`invoice-customer-wrapper multi-select-wrapper ${
        readMode && "wrapper_readmode"
      } ${wrapperWidthClass}`}
      style={{ border: "0px" }}
    >
      {labelText && (
        <div className="flex">
          <Text text={labelText} labelClass={labelClass} />
          {required && <Text text="*" labelClass="requiredClass" type="primary" />}
        </div>
      )}
      {readMode ? (
        <div
          className={v2 ? "flex items-center flex-wrap gap-y-2" : ""}
          style={readMode ? { marginTop: "6px" } : null}
        >
          {getSeletedItems()}
        </div>
      ) : (
        <MultiChipComponent
          name={name}
          placeHolder={placeHolder}
          error={error}
          touched={touched}
          v2
          wrapperWidthClass={wrapperWidthClass}
          getSeletedItems={getSeletedItems}
          onInputChange={onInputChange}
          onChangeHandler={onChangeHandler}
          listOptions={listOptions}
          selectedItems={selectedItems}
          collapse={collapse}
          setCollapse={setCollapse}
          onClickHandler={onClickHandler}
          listOptionsAll={listOptionsAll}
          noDataFound={noDataFound}
          inputClass={inputClass}
          searchedText={searchedText}
        />
      )}
    </div>
  );
}
