import React, { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useNavigate } from "react-router";
import logoSrc from "../Assets/cloudkeeper-logo.svg";
import TableForm from "../../../../Common/TableForm";
import { defaultFormValues, formInputFeilds } from "./util";
import urls from "../../../../../Services/axios/urls";
import Routes from "../../../../../Config/Routes";
import { checkURLForToken, onboardingAxiosService } from "../util";
import { getToast, pushEventToGTM } from "../../../../../Utils/commonUtils";
import commonService from "../../../../../Services/commonService";
import { platformSpecificEvent } from "../../../../CK-Auto/signup/utils";
import "./style.scss";

function OnboardingActivation() {
  const [formData, setFormData] = useState(cloneDeep(defaultFormValues));
  const [isLoading, setIsLoading] = useState(true);
  const [isLens, setIsCkLens] = useState(false);
  const navigate = useNavigate();

  const loadhandler = async () => {
    try {
      checkURLForToken();
      const apiUrl = `${urls.CK_AZURE.ACTIVATION.MARKETPLACE_USER}`;
      const response = await onboardingAxiosService.get(apiUrl);
      const partnerMeta = await commonService.partnerCreateUser();
      const { isLegacyLensEnabled } = partnerMeta.data.data;
      setIsCkLens(isLegacyLensEnabled);
      if (Object?.keys?.(response?.data)?.length) {
        setFormData(response?.data || formData);
      } else {
        navigate(Routes.LOGIN);
      }
    } catch (e) {
      getToast("error", e?.response?.data?.message || "Something went wrong");
      navigate(Routes.LOGIN);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadhandler();
  }, []);

  const submitHandler = async (data) => {
    try {
      const payload = data || {};
      payload.transactionId = sessionStorage.getItem("SSOTransactionID");
      const apiUrl = `${urls.CK_AZURE.ACTIVATION.SAVE_MARKETPLACE_SIGNUP_DATA}`;
      await onboardingAxiosService.post(apiUrl, payload);
      localStorage.removeItem("userData");
      const gtmConfig = platformSpecificEvent({ isCkAuto: false, isLens });
      pushEventToGTM(gtmConfig);
      navigate(Routes.AZURE_SSO_LOGIN);
    } catch (e) {
      navigate(Routes.LOGIN);
    }
  };

  return (
    !isLoading && (
      <>
        <div className="activation flex items-center justify-center">
          <div className="container mx-auto flex flex-col py-[24px] justify-center items-center max-w-[510px]">
            <div className="mb-[36.29px]">
              <img className="logo_img" alt="Logo" src={logoSrc} />
            </div>
            <div className="content">
              <div className="flex flex-col text-center w-full mb-[30px]">
                <h2 className="text-2xl font-semibold leading-tight mb-[16px]">
                  Verify your details
                </h2>
                <p>
                  Congratulations for subscribing to CloudKeeper Lens, please review the details and
                  proceed with activation
                </p>
              </div>
              <TableForm
                data={formData}
                formInputFeilds={formInputFeilds}
                submitHandler={submitHandler}
              />
            </div>
          </div>
        </div>
        <div className="footer azure_activation_footer">
          <p className="!font-normal !text-white">
            CloudKeeper {new Date().getFullYear()} | All Rights Reserved{" "}
          </p>
        </div>
      </>
    )
  );
}

export default OnboardingActivation;
