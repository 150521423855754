import React, { useState } from "react";
import Loader from "../Loader";
import urls from "../../../Services/axios/urls";
import { strapiBasePath } from "../../../Services/Strapi/urls";

function SsoCta({ isMicrosoftEnabled, isGoogleEnabled, loginBtnEnabled }) {
  const [ssoLoginClicked, setSsoLoginClicked] = useState(false);
  const getSSOLink = (ssoKey) => {
    switch (ssoKey) {
      case "Microsoft":
        window.location.href = `${urls?.CK_AZURE?.MICROSOFT_LOGIN}${window?.location?.hostname}`;
        break;
      case "Google":
        window.location.href = `${urls?.CK_AZURE?.GOOGLE_LOGIN}${window?.location?.hostname}`;
        break;
      default:
        break;
    }
  };

  const ssoHandleClick = (ssoKey) => {
    setSsoLoginClicked(true);
    getSSOLink(ssoKey);
  };

  const getSSOCTA = (icon, button = {}) => (
    <button
      className={loginBtnEnabled[0]?.button?.className}
      onClick={() => ssoHandleClick(button?.key)}
      type={loginBtnEnabled[0]?.button?.type}
    >
      <div>
        {icon}
        <span>{button?.label}</span>
      </div>
    </button>
  );

  return (
    <>
      {(isMicrosoftEnabled || isGoogleEnabled) && (
        <div className="SSO_wrapper">
          <hr />
          <div className="option_divider">
            <p>{loginBtnEnabled[0]?.heading}</p>
          </div>
          {isMicrosoftEnabled &&
            getSSOCTA(
              <img
                src={`${strapiBasePath}${loginBtnEnabled[0]?.button?.image?.data?.attributes?.url}`}
                alt={loginBtnEnabled[0]?.button?.label}
              />,
              loginBtnEnabled[0]?.button
            )}
          {isGoogleEnabled &&
            getSSOCTA(
              <img
                src={`${strapiBasePath}${loginBtnEnabled[2]?.button?.image?.data?.attributes?.url}`}
                alt={loginBtnEnabled[2]?.button?.label}
              />,
              loginBtnEnabled[2]?.button
            )}
        </div>
      )}
      {ssoLoginClicked && <Loader popup />}
    </>
  );
}

export default SsoCta;
