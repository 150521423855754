export const generateRandomId = () => {
  const randomId = Math.floor(Math.random() * 900) + 100;
  return String(randomId);
};

export const newConfigureDiscountListing = {
  MobiKwik: [
    {
      id: generateRandomId(),
      name: "MobiKwik",
      cbuName: "MobiKwik",
      discountType: "Reservation Discount",
      discountName: "Discount on Linux EC2-OnDemand",
      discountPercentage: 25,
      templateName: "EC2-Linux-OnDemand",
      starts: "1-Aug-2021",
      expires: "",
      priority: 1,
      description: "EC2-Linux-OnDemand",
      serviceType: "EC2",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "3 yr All UF CSP",
      templateApplied: "Custom-MobiKwik-EC2-Linux-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "MobiKwik",
      cbuName: "MobiKwik",
      discountType: "Reservation Discount",
      discountName: "Discount on Non-Linux EC2 On-Demand",
      discountPercentage: 14,
      templateName: "EC2-NonLinux-OnDemand",
      starts: "1-Aug-2021",
      expires: "",
      priority: 2,
      description: "EC2-NonLinux-OnDemand",
      serviceType: "EC2",
      discountApplication: "FLAT",
      discountApplicationDetail: "15",
      templateApplied: "Custom-MobiKwik-EC2-NonLinux-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "MobiKwik",
      cbuName: "MobiKwik",
      discountType: "Flat Discount",
      discountName: "Discount on Total Cost",
      discountPercentage: 7,
      templateName: "Flat Discount Template",
      starts: "1-Aug-2021",
      expires: "",
      priority: 3,
      description: "Flat Discount Template",
    },
  ],
  Igloo: [
    {
      id: generateRandomId(),
      name: "Igloo",
      cbuName: "Igloo",
      discountType: "Reservation Discount",
      discountName: "Discount on Aurora MySQL & PostgreSQL RDS-OnDemand",
      discountPercentage: 15,
      templateName: "RDS-Aurora MySQL & PostgreSQL-OnDemand",
      starts: "1-Apr-2023",
      expires: "",
      priority: 1,
      description: "RDS-Aurora MySQL & PostgreSQL-OnDemand",
      serviceType: "RDS",
      discountApplication: "FLAT",
      discountApplicationDetail: "12",
      templateApplied: "Custom-Igloo-RDS-Aurora MySQL & PostgreSQL-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Igloo",
      cbuName: "Igloo",
      discountType: "Reservation Discount",
      discountName: "EC2InstanceSavingsPlan Discount",
      discountPercentage: "EC2InstanceSavingsPlan",
      templateName: "",
      starts: "1-Apr-2023",
      expires: "",
      priority: 2,
      description: "",
      serviceType: "EC2",
      discountApplication: "FLAT",
      discountApplicationDetail: "10",
      templateApplied: "Custom-Igloo-EC2InstanceSavingsPlan",
    },
  ],
  Offeo: [
    {
      id: generateRandomId(),
      name: "Imaginary Ones",
      cbuName: "Imaginary Ones",
      discountType: "CloudFront Flat Discount",
      discountName: "CloudFront Data Transfer Discount",
      discountPercentage: 60,
      templateName: "CloudFront Data Transfer Template",
      starts: "1-Apr-2023",
      expires: "",
      priority: 1,
      description: "CloudFront Data Transfer Template", // Missing
    },
    {
      id: generateRandomId(),
      name: "Imaginary Ones",
      cbuName: "Imaginary Ones",
      discountType: "Reservation Discount",
      discountName: "RDS OnDemand DIscount",
      discountPercentage: 20,
      templateName: " RDS-OnDemand",
      starts: "1-Apr-2023",
      expires: "",
      priority: 2,
      description: "RDS-OnDemand",
      serviceType: "RDS",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "3 yr All UF RDS",
      templateApplied: "Custom-Offeo-RDS-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Imaginary Ones",
      cbuName: "Imaginary Ones",
      discountType: "Reservation Discount",
      discountName: "EC2InstanceSavingsPlans",
      discountPercentage: "EC2InstanceSavingsPlans",
      templateName: "",
      starts: "1-Apr-2023",
      expires: "",
      priority: 3,
      description: "",
      serviceType: "EC2",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "1 yr No UF CSP",
      templateApplied: "Custom-Offeo-RDS-EC2InstanceSavingsPlans",
    },
    {
      id: generateRandomId(),
      name: "Offeo",
      cbuName: "Offeo",
      discountType: "CloudFront Flat Discount",
      discountName: "CloudFront Data Transfer Discount",
      discountPercentage: 60,
      templateName: "CloudFront Data Transfer Template",
      starts: "1-Apr-2023",
      expires: "",
      priority: 1,
      description: "CloudFront Data Transfer Template", // Missing
    },
    {
      id: generateRandomId(),
      name: "Offeo",
      cbuName: "Offeo",
      discountType: "Reservation Discount",
      discountName: "RDS OnDemand DIscount",
      discountPercentage: 20,
      templateName: "RDS-OnDemand",
      starts: "1-Apr-2023",
      expires: "",
      priority: 2,
      description: " RDS-OnDemand",
      serviceType: "RDS",
      discountApplication: "FLAT",
      discountApplicationDetail: "19",
      templateApplied: "Custom-Offeo-RDS-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Offeo",
      cbuName: "Offeo",
      discountType: "Reservation Discount",
      discountName: "EC2InstanceSavingsPlans",
      discountPercentage: "EC2InstanceSavingsPlans",
      templateName: "",
      starts: "1-Apr-2023",
      expires: "",
      priority: 3,
      description: "",
      serviceType: "EC2",
      discountApplication: "FLAT",
      discountApplicationDetail: "20",
      templateApplied: "Custom-Offeo-RDS-EC2InstanceSavingsPlans",
    },
  ],
  Snackmagic: [
    {
      id: generateRandomId(),
      name: "Snackmagic",
      cbuName: "Snackmagic",
      discountType: "Flat Discount",
      discountName: "Discount on Total Cost",
      discountPercentage: 4,
      templateName: "Flat Discount Template",
      starts: "1-Sep-2022",
      expires: "",
      priority: 1,
      description: "Flat Discount Template",
    },
  ],
  Spenmo: [
    {
      id: generateRandomId(),
      name: "Spenmo",
      cbuName: "Spenmo",
      discountType: "Reservation Discount",
      discountName: "Discount on MySQL & PostgreSQL RDS-OnDemand",
      discountPercentage: 15,
      templateName: "RDS MySQL & PostgreSQL-OnDemand",
      starts: "1-Jun-2023",
      expires: "",
      priority: 1,
      description: "RDS MySQL & PostgreSQL-OnDemand",
      chargeBackAccountID: [{ value: "186563999319", label: "186563999319" }],
      serviceType: "RDS",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "1 yr No UF RDS",
      templateApplied: "Custom-Spenmo-RDS MySQL & PostgreSQL-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Spenmo",
      cbuName: "Spenmo",
      discountType: "Reservation Discount",
      discountName: "ComputeSavingsPlans",
      discountPercentage: "ComputeSavingsPlans",
      templateName: "",
      starts: "1-Jun-2023",
      expires: "",
      priority: 2,
      description: "",
      serviceType: "Compute",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "3 yr All UF Compute",
      templateApplied: "Custom-Spenmo-ComputeSavingsPlans",
    },
  ],
  CurrentMobile: [
    {
      id: generateRandomId(),
      name: "CurrentMobile",
      cbuName: "CurrentMobile",
      discountType: "CloudFront Flat Discount",
      discountName: "CloudFront Data Transfer Discount",
      discountPercentage: 50,
      templateName: "CloudFront Data Transfer Template",
      starts: "1-Aug-2021",
      expires: "",
      priority: 1,
      description: "CloudFront Data Transfer Template",
    },
    {
      id: generateRandomId(),
      name: "CurrentMobile",
      cbuName: "CurrentMobile",
      discountType: "Reservation Discount",
      discountName: "Discount on EC2 & RDS On-Demand",
      discountPercentage: 23,
      templateName: "EC2-RDS-OnDemand",
      starts: "1-Aug-2021",
      expires: "",
      priority: 2,
      description: "EC2-RDS-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "CurrentMobile",
      cbuName: "CurrentMobile",
      discountType: "Reservation Discount",
      discountName: "Lambda Discount",
      discountPercentage: 10,
      templateName: "Lambda-OnDemand",
      starts: "1-Apr-2022",
      expires: "",
      priority: 3,
      description: "Lambda-OnDemand",
    },
  ],
  "Tata Play": [
    {
      id: generateRandomId(),
      name: "Tata Play Broadband Pvt. Ltd.",
      cbuName: "Tata Play Broadband Pvt. Ltd.",
      discountType: "EDP Discount",
      discountName: "Tata Play EDP Configuration",
      discountPercentage: 12,
      templateName: "EDP Discount Template",
      templateApplied: "Tata Play EDP Template",
      starts: "1-Jan-2022",
      expires: "31-Dec-2024",
      priority: 1,
      chargeBackType: "Year End",
      chargeBackAccountID: [{ value: "033319837796", label: "033319837796" }],
      termLevelSummary: "",
      tenure: 3,
      activeYearSummary: 2,
      description: "Tata Play EDP exclude services",
      commitments: [
        {
          tenure: "1st Year",
          commitment: "4000000",
          discount: "8",
          credits: "20000",
        },
        {
          tenure: "2nd Year",
          commitment: "5000000",
          discount: "9",
          credits: "25000",
        },
        {
          tenure: "3rd Year",
          commitment: "6000000",
          discount: "10",
          credits: "30000",
        },
      ],
    },
    {
      id: generateRandomId(),
      name: "Tata Play Ltd. - Karnataka",
      cbuName: "Tata Play Ltd. - Karnataka",
      discountType: "Reservation Discount",
      discountName: "On-Demand EC2 Discount",
      discountPercentage: 18,
      templateName: "EC2-OnDemand",
      starts: "1-Dec-2020",
      expires: "",
      priority: 1,
      description: "EC2-OnDemand",
      serviceType: "EC2",
      discountApplication: "RELATIVE",
      discountApplicationDetail: "3 yr All UF CSP",
      templateApplied: "Custom-Tata Play Ltd. - Karnataka-EC2-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Tata Play Delhi",
      cbuName: "Tata Play Delhi",
      discountType: "Reservation Discount",
      discountName: "On-Demand EC2 Discount",
      discountPercentage: 25,
      templateName: "EC2-OnDemand",
      starts: "1-Sep-2022",
      expires: "",
      priority: 1,
      description: "EC2-OnDemand",
      serviceType: "EC2",
      discountApplication: "FLAT",
      discountApplicationDetail: "5",
      templateApplied: "Custom-Tata Play Delhi-EC2-OnDemand",
    },
    {
      id: generateRandomId(),
      name: "Tata Play Delhi",
      cbuName: "Tata Play Delhi",
      discountType: "EDP Discount",
      discountName: "Tata Play EDP Configuration",
      discountPercentage: 12,
      templateName: "EDP Discount Template",
      templateApplied: "Tata Play EDP Template",
      starts: "1-Jan-2019",
      expires: "31-Dec-2021",
      priority: 2,
      chargeBackType: "Year End",
      chargeBackAccountID: [{ value: "077822383897", label: "077822383897" }],
      termLevelSummary: "",
      tenure: 3,
      activeYearSummary: 3,
      description: "Tata Play EDP exclude services",
      commitments: [
        {
          tenure: "1st Year",
          commitment: "2000000",
          discount: "6",
          credits: "10000",
        },
        {
          tenure: "2nd Year",
          commitment: "3000000",
          discount: "7",
          credits: "15000",
        },
        {
          tenure: "3rd Year",
          commitment: "4000000",
          discount: "8",
          credits: "20000",
        },
      ],
    },
    {
      id: generateRandomId(),
      name: "Tata Play - HOBS",
      cbuName: "Tata Play - HOBS",
      discountType: "CloudFront Private Pricing",
      discountName: "Tata Play CloudFront Private Pricing Discount",
      discountPercentage: "Private Pricing",
      templateName: "",
      templateApplied: "Ciustom-PPA Cloudfront-2023 Tata Play",
      starts: "1-Jan-2022",
      expires: "31-Dec-2023",
      priority: 2,
      activeYearSummary: 2,
      chargeBackType: "Year End",
      chargeBackAccountID: [{ value: "033319837796", label: "033319837796" }],
      termLevelSummary: "",
      tenure: 3,
      description: "",
      commitments: [
        {
          tenure: 1,
          qty: 500,
          unit: "TB/Year",
        },
        {
          tenure: 2,
          qty: 500,
          unit: "TB/Year",
        },
      ],
    },
    {
      id: generateRandomId(),
      name: "Tata Play Broadband Pvt. Ltd.",
      cbuName: "Tata Play Broadband Pvt. Ltd.",
      discountType: "CloudFront Private Pricing",
      discountName: "Tata Play CloudFront Private Pricing Discount",
      discountPercentage: "Private Pricing",
      templateName: "",
      templateApplied: "Ciustom-PPA Cloudfront-2023 Tata Play",
      starts: "1-Jan-2020",
      expires: "31-Dec-2021",
      priority: 2,
      activeYearSummary: 2,
      chargeBackType: "Year End",
      chargeBackAccountID: [{ value: "033319837796", label: "033319837796" }],
      termLevelSummary: "",
      tenure: 3,
      description: "",
      commitments: [
        {
          tenure: 1,
          qty: 400,
          unit: "TB/Year",
        },
        {
          tenure: 2,
          qty: 400,
          unit: "TB/Year",
        },
      ],
    },
    {
      id: generateRandomId(),
      name: "MSP Charges",
      cbu: "All CBU",
      discountType: "Discount and Charges",
      type: "Charge",
      frequency: "Recurring",
      fixedCost: "1000",
      percentageValue: "10",
      templateApplied: "MSP Charges Template",
      starts: "1-May-2023",
      expires: "30-Apr-2026",
      description: "Monthly MSP Charges",
    },
    {
      id: generateRandomId(),
      name: "WAR Charges",
      cbu: "All CBU",
      discountType: "Discount and Charges",
      type: "Charge",
      frequency: "OneTime",
      fixedCost: "1000",
      percentageValue: "",
      templateApplied: "",
      starts: "1-May-2023",
      expires: "",
      description: "Onetime WAR Charges",
    },
    {
      id: generateRandomId(),
      name: "Flat Discount",
      cbu: "All CBU",
      discountType: "Discount and Charges",
      type: "Discount",
      frequency: "Recurring",
      fixedCost: "",
      percentageValue: "10",
      templateApplied: "Flat Disocunt Template",
      starts: "1-May-2023",
      expires: "30-Apr-2026",
      description: "Flat Discounts",
    },
    {
      id: generateRandomId(),
      name: "OneTime Discount",
      cbu: "All CBU",
      discountType: "Discount and Charges",
      type: "Discount",
      frequency: "OneTime",
      fixedCost: "",
      percentageValue: "12",
      templateApplied: "OneTime Discount Template",
      starts: "1-May-2023",
      expires: "",
      description: "OneTime Discount",
    },
    {
      id: generateRandomId(),
      name: "OneTime Fixed Discount",
      cbu: "All CBU",
      discountType: "Discount and Charges",
      type: "Discount",
      frequency: "OneTime",
      fixedCost: "2000",
      percentageValue: "",
      templateApplied: "",
      starts: "1-May-2023",
      expires: "",
      description: "OneTime Fixed Discount",
    },
  ],
};

export const updateedTemplateArr = {
  MobiKwik: [
    { value: "EC2-Linux-OnDemand", label: "EC2-Linux-OnDemand" },
    { value: "EC2-NonLinux-OnDemand", label: "EC2-NonLinux-OnDemand" },
    { value: "Flat Discount Template", label: "Flat Discount Template" },
  ],
  Igloo: [
    {
      value: " RDS-Aurora MySQL & PostgreSQL-OnDemand",
      label: " RDS-Aurora MySQL & PostgreSQL-OnDemand",
    },
  ],
  Offeo: [
    { value: "CloudFront Data Transfer Template", label: "CloudFront Data Transfer Template" },
    { value: "RDS-OnDemand", label: "RDS-OnDemand" },
  ],
  Snackmagic: [{ value: "Flat Discount Template", label: "Flat Discount Template" }],
  Spenmo: [{ value: "RDS MySQL & PostgreSQL-OnDemand", label: "RDS MySQL & PostgreSQL-OnDemand" }],
  CurrentMobile: [
    { value: "CloudFront Data Transfer Template", label: "CloudFront Data Transfer Template" },
    { value: "EC2-RDS-OnDemand", label: "EC2-RDS-OnDemand" },
    { value: "Lambda-OnDemand", label: "Lambda-OnDemand" },
  ],
  "Tata Play": [
    { value: "EDP Discount Template", label: "EDP Discount Template" },
    { value: "EC2-OnDemand", label: "EC2-OnDemand" },
  ],
};

export const newTemplateArr = {
  MobiKwik: {
    edp: [{ value: "", label: "" }],
    cloudfront: { "private-pricing": [] },
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  Igloo: {
    edp: [{ value: "", label: "" }],
    cloudfront: { "private-pricing": [{ value: "", label: "" }] },
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  Offeo: {
    edp: [{ value: "", label: "" }],
    cloudfront: { "private-pricing": [{ value: "", label: "" }] },
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  Snackmagic: {
    edp: [{ value: "", label: "" }],
    cloudfront: { "private-pricing": [{ value: "", label: "" }] },
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  Spenmo: {
    edp: [{ value: "", label: "" }],
    cloudfront: { "private-pricing": [{ value: "", label: "" }] },
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  CurrentMobile: {
    edp: [{ value: "", label: "" }],
    "reserved-discount": {
      EC2: [{ value: "", label: "" }],
      RDS: [{ value: "", label: "" }],
      ElastiCache: [{ value: "", label: "" }],
      Redshift: [{ value: "", label: "" }],
      Opensearch: [{ value: "", label: "" }],
      CloudFront: [{ value: "", label: "" }],
      Compute: [{ value: "", label: "" }],
    },
  },
  "Tata Play": {
    edp: [
      {
        id: 1,
        name: "Tata Play EDP Template",
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        discountType: "Flat/EDP",
        type: "custom",
        configuration: ["Exclude", "AWS Elastic Beanstalk", "Amazon EC2"],
        createdBy: "System Admin",
        date: "12-jul-2022",
        inUse: "Yes",
        exclude: false,
        value: "Tata Play EDP Template",
        label: "Tata Play EDP Template",
      },
      {
        id: 2,
        name: "Tata Play EDP Included Services Template",
        description: "Tata Play EDP Included Services Template",
        discountType: "Flat/EDP",
        type: "custom",
        configuration: ["Include", "AWS Elastic Beanstalk", "Amazon RDS", "Amazon S3"],
        createdBy: "System Admin",
        date: "12-jul-2022",
        inUse: "Yes",
        exclude: false,
        value: "Tata Play EDP Included Services Template",
        label: "Tata Play EDP Included Services Template",
      },
      {
        id: 3,
        name: "EDP Excluded Services Template",
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        discountType: "Flat/EDP",
        type: "default",
        configuration: ["Exclude", "AWS Elastic Beanstalk", "Amazon EC2"],
        createdBy: "System Admin",
        date: "12-jul-2022",
        inUse: "Yes",
        exclude: false,
        value: "EDP Excluded Services Template",
        label: "EDP Excluded Services Template",
      },
      {
        id: 4,
        name: "EDP Included Services Template",
        description: "EDP Included Services Template",
        discountType: "Flat/EDP",
        type: "default",
        configuration: ["Include", "AWS Elastic Beanstalk", "Amazon RDS", "Amazon S3"],
        createdBy: "System Admin",
        date: "12-jul-2022",
        inUse: "Yes",
        exclude: false,
        value: "EDP Included Services Template",
        label: "EDP Included Services Template",
      },
      // { name: "Tata Play EDP Template", label: "Tata Play EDP Template", type: "default", id: 2 },
    ],
    discount_and_charges: [
      {
        id: 1, // tobeadded
        discountType: "Reservation Discount",
        name: "MSP Charges Template",
        value: "MSP Charges Template", // tobeadded
        label: "MSP Charges Template", // tobeadded
        accountFamilies: [],
        type: "default", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      {
        id: 2, // tobeadded
        discountType: "Reservation Discount",
        name: "Flat Disocunt Template",
        value: "Flat Disocunt Template", // tobeadded
        label: "Flat Disocunt Template", // tobeadded
        accountFamilies: [],
        type: "custom", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      {
        id: 3, // tobeadded
        discountType: "Reservation Discount",
        name: "OneTime Discount Template",
        value: "OneTime Discount Template", // tobeadded
        label: "OneTime Discount Template", // tobeadded
        accountFamilies: [],
        type: "custom", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      {
        id: 4, // tobeadded
        discountType: "Reservation Discount",
        name: "MSP Charges Default-Template",
        value: "MSP Charges Default-Template", // tobeadded
        label: "MSP Charges Default-Template", // tobeadded
        accountFamilies: [],
        type: "default", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      {
        id: 5, // tobeadded
        discountType: "Reservation Discount",
        name: "Flat Disocunt Default-Template",
        value: "Flat Disocunt Default-Template", // tobeadded
        label: "Flat Disocunt Default-Template", // tobeadded
        accountFamilies: [],
        type: "default", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      {
        id: 6, // tobeadded
        discountType: "Reservation Discount",
        name: "Flat Disocunt Custom-Template",
        value: "Flat Disocunt Custom-Template", // tobeadded
        label: "Flat Disocunt Custom-Template", // tobeadded
        accountFamilies: [],
        type: "custom", // default custom
        templateType: {
          label: "Reservation Discounts",
          value: "reservation_discounts",
        },
        description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
        invoiceformat: "NEVER", // optional
        exclude: false,
        "aws-service-families": [], // optional
        service: {
          label: "generic",
          value: "generic",
        },
        nameExclude: false,
        offeringType: [
          {
            label: "No upfront",
            value: "No upfront",
            checked: true,
          },
        ],
        offeringTypeExclude: false,
        operatingSystem: [
          {
            label: "Red Hat Enterprise Linux with HA",
            value: "Red Hat Enterprise Linux with HA",
            checked: true,
          },
        ],
        operatingSystemExclude: false,
        instanceType: [
          {
            label: "m6id.large",
            value: "m6id.large",
            checked: true,
          },
        ],
        instanceTypeExclude: false,
        tenancy: [
          {
            label: "Default",
            value: "Default",
            checked: true,
          },
        ],
        tenancyExclude: false,
        region: [
          {
            label: "Europe (Zurich)",
            value: "Europe (Zurich)",
            checked: true,
          },
        ],
        regionExclude: false,
      },
      // { name: "Tata Play EDP Template", label: "Tata Play EDP Template", type: "default", id: 2 },
    ],
    reserved_discount: {
      EC2: [
        // { value: "EC2-OnDemand-Linux_OS-Template", label: "EC2-OnDemand-Linux_OS-Template" },
        // { value: "EC2-OnDemand-NonLinux_OS-Template", label: "EC2-OnDemand-NonLinux_OS-Template" },
        // { value: "EC2-OnDemand-Windows_OS-Template", label: "EC2-OnDemand-Windows_OS-Template" },
        // {
        //   value: "EC2-OnDemand-All_OS-Mumbai_Region-Template",
        //   label: "EC2-OnDemand-All_OS-Mumbai_Region-Template",
        // },

        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          name: "EC2-OnDemand-Linux_OS-Template",
          value: "EC2-OnDemand-Linux_OS-Template", // tobeadded
          label: "EC2-OnDemand-Linux_OS-Template", // tobeadded
          accountFamilies: [],
          type: "custom", // default custom
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
          invoiceformat: "NEVER", // optional
          exclude: false,
          "aws-service-families": [], // optional
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          offeringType: [
            {
              label: "No upfront",
              value: "No upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Red Hat Enterprise Linux with HA",
              value: "Red Hat Enterprise Linux with HA",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "m6id.large",
              value: "m6id.large",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-NonLinux_OS-Template", // tobeadded
          label: "EC2-OnDemand-NonLinux_OS-Template", // tobeadded
          type: "custom",
          name: "EC2-OnDemand-NonLinux_OS-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-NonLinux_OS-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.m6i.2xlarge",
              value: "db.m6i.2xlarge",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "SQL Server",
              value: "SQL Server",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          region: [
            {
              label: "Asia Pacific (Tokyo)",
              value: "Asia Pacific (Tokyo)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-Windows_OS-Template", // tobeadded
          label: "EC2-OnDemand-Windows_OS-Template", // tobeadded
          type: "custom",
          name: "EC2-OnDemand-Windows_OS-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-Windows_OS-Template ",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Windows",
              value: "Windows",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "m6a.metal",
              value: "m6a.metal",
              checked: true,
            },
          ],
          instanceTypeExclude: true,
          instanceTypeExcludeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-All_OS-Mumbai_Region-Template", // tobeadded
          label: "EC2-OnDemand-All_OS-Mumbai_Region-Template", // tobeadded
          type: "custom",
          name: "EC2-OnDemand-All_OS-Mumbai_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-All_OS-Mumbai_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Windows",
              value: "Windows",
              checked: true,
            },
            {
              label: "Linux/Unix",
              value: "Linux/Unix",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "m6a.metal",
              value: "m6a.metal",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          instanceTypeExcludeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 5, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-Linux_OS-Default-Template", // tobeadded
          label: "EC2-OnDemand-Linux_OS-Default-Template", // tobeadded
          type: "default",
          name: "EC2-OnDemand-Linux_OS-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-Linux_OS-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.m6i.2xlarge",
              value: "db.m6i.2xlarge",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "SQL Server",
              value: "SQL Server",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          region: [
            {
              label: "Asia Pacific (Tokyo)",
              value: "Asia Pacific (Tokyo)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 6, // tobeadded
          discountType: "Reservation Discount",
          name: "EC2-OnDemand-NonLinux_OS-Default-Template",
          value: "EC2-OnDemand-NonLinux_OS-Default-Template", // tobeadded
          label: "EC2-OnDemand-NonLinux_OS-Default-Template", // tobeadded
          accountFamilies: [],
          type: "default", // default custom
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Tata Play EC2-All-Linux-All 3Year Partial upfront",
          invoiceformat: "NEVER", // optional
          exclude: false,
          "aws-service-families": [], // optional
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          offeringType: [
            {
              label: "No upfront",
              value: "No upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Red Hat Enterprise Linux with HA",
              value: "Red Hat Enterprise Linux with HA",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "m6id.large",
              value: "m6id.large",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 7, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-Windows_OS-Default-Template", // tobeadded
          label: "EC2-OnDemand-Windows_OS-Default-Template", // tobeadded
          type: "default",
          name: "EC2-OnDemand-Windows_OS-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-Windows_OS-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Windows",
              value: "Windows",
              checked: true,
            },
            {
              label: "Linux/Unix",
              value: "Linux/Unix",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "m6a.metal",
              value: "m6a.metal",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          instanceTypeExcludeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 8, // tobeadded
          discountType: "Reservation Discount",
          value: "EC2-OnDemand-All_OS-Mumbai_Region-Default-Template", // tobeadded
          label: "EC2-OnDemand-All_OS-Mumbai_Region-Default-Template", // tobeadded
          type: "default",
          name: "EC2-OnDemand-All_OS-Mumbai_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2-OnDemand-All_OS-Mumbai_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "EC2",
            value: "EC2",
          },
          nameExclude: false,
          descriptionExclude: false,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          operatingSystem: [
            {
              label: "Windows",
              value: "Windows",
              checked: true,
            },
          ],
          operatingSystemExclude: false,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "m6a.metal",
              value: "m6a.metal",
              checked: true,
            },
          ],
          instanceTypeExclude: true,
          instanceTypeExcludeExclude: false,
          tenancy: [
            {
              label: "Default",
              value: "Default",
              checked: true,
            },
          ],
          tenancyExclude: false,
          region: [
            {
              label: "Europe (Zurich)",
              value: "Europe (Zurich)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
      ],
      RDS: [
        {
          id: 1, // tobeadded
          value: "RDS-OnDemand-MySQL_Engine-Template",
          label: "RDS-OnDemand-MySQL_Engine-Template",
          discountType: "Reservation Discount",
          type: "custom",
          name: "RDS-OnDemand-MySQL_Engine-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-MySQL_Engine-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: true,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "RDS-OnDemand-NonLicence_Engine-Template",
          label: "RDS-OnDemand-NonLicence_Engine-Template",
          name: "RDS-OnDemand-NonLicence_Engine-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-NonLicence_Engine-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "RDS-OnDemand-Oracle_Engine-Template",
          label: "RDS-OnDemand-Oracle_Engine-Template",
          name: "RDS-OnDemand-Oracle_Engine-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-Oracle_Engine-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: true,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
          offeringType: [
            {
              label: "No upfront",
              value: "No upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          dbInstanceClassExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "RDS-OnDemand-All_Engine-Mumbai_Region-Template",
          label: "RDS-OnDemand-All_Engine-Mumbai_Region-Template",
          name: "RDS-OnDemand-All_Engine-Mumbai_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-All_Engine-Mumbai_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: true,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: true,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
          offeringType: [],
          offeringTypeExclude: false,
          dbInstanceClassExcludeExclude: false,
        },
        {
          id: 5, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "RDS-OnDemand-MySQL_Engine-Default-Template",
          label: "RDS-OnDemand-MySQL_Engine-Default-Template",
          name: "RDS-OnDemand-MySQL_Engine-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-MySQL_Engine-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: true,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: true,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
          offeringType: [],
          offeringTypeExclude: false,
          dbInstanceClassExcludeExclude: false,
        },
        {
          id: 6, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "RDS-OnDemand-NonLicence_Engine-Default-Template",
          label: "RDS-OnDemand-NonLicence_Engine-Default-Template",
          name: "RDS-OnDemand-NonLicence_Engine-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-NonLicence_Engine-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: true,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
          offeringType: [
            {
              label: "No upfront",
              value: "No upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          dbInstanceClassExcludeExclude: false,
        },
        {
          id: 7, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "RDS-OnDemand-Oracle_Engine-Default-Template",
          label: "RDS-OnDemand-Oracle_Engine-Default-Template",
          name: "RDS-OnDemand-Oracle_Engine-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-Oracle_Engine-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: false,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: true,
          regionExcludeExclude: false,
        },
        {
          id: 8, // tobeadded
          value: "RDS-OnDemand-All_Engine-Mumbai_Region-Default-Template",
          label: "RDS-OnDemand-All_Engine-Mumbai_Region-Default-Template",
          discountType: "Reservation Discount",
          type: "default",
          name: "RDS-OnDemand-All_Engine-Mumbai_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "RDS-OnDemand-All_Engine-Mumbai_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "RDS",
            value: "RDS",
          },
          nameExclude: false,
          descriptionExclude: false,
          dbInstanceClass: [
            {
              label: "db.r4.16xlarge",
              value: "db.r4.16xlarge",
              checked: true,
            },
            {
              label: "db.r5.2xlarge.tpc2.mem4x",
              value: "db.r5.2xlarge.tpc2.mem4x",
              checked: true,
            },
          ],
          dbInstanceClassExclude: false,
          productDescription: [
            {
              label: "PostgreSQL",
              value: "PostgreSQL",
              checked: true,
            },
            {
              label: "Aurora MySQL",
              value: "Aurora MySQL",
              checked: true,
            },
          ],
          productDescriptionExclude: true,
          productDescriptionExcludeExclude: false,
          region: [
            {
              label: "Asia Pacific (Osaka)",
              value: "Asia Pacific (Osaka)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
      ],
      ElastiCache: [
        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "ElastiCache-OnDemand-All_Engine-Template",
          label: "ElastiCache-OnDemand-All_Engine-Template",
          name: "ElastiCache-OnDemand-All_Engine-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "ElastiCache-OnDemand-All_Engine-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "ElastiCache",
            value: "ElastiCache",
          },
          nameExclude: false,
          descriptionExclude: false,
          engine: [
            {
              label: "Memcached",
              value: "Memcached",
              checked: true,
            },
          ],
          engineExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: true,
          offeringTypeExcludeExclude: false,
          region: [
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "ElastiCache-OnDemand-Redis_Engine-Template",
          label: "ElastiCache-OnDemand-Redis_Engine-Template",
          name: "ElastiCache-OnDemand-Redis_Engine-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "ElastiCache-OnDemand-Redis_Engine-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "ElastiCache",
            value: "ElastiCache",
          },
          nameExclude: false,
          descriptionExclude: false,
          engine: [
            {
              label: "Memcached",
              value: "Memcached",
              checked: true,
            },
          ],
          engineExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          offeringTypeExcludeExclude: false,
          region: [
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: true,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "ElastiCache-OnDemand-All_Engine-Default-Template",
          label: "ElastiCache-OnDemand-All_Engine-Default-Template",
          name: "ElastiCache-OnDemand-All_Engine-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "ElastiCache-OnDemand-All_Engine-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "ElastiCache",
            value: "ElastiCache",
          },
          nameExclude: false,
          descriptionExclude: false,
          engine: [
            {
              label: "Memcached",
              value: "Memcached",
              checked: true,
            },
          ],
          engineExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          offeringTypeExcludeExclude: false,
          region: [
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: true,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "ElastiCache-OnDemand-Redis_Engine-Default-Template",
          label: "ElastiCache-OnDemand-Redis_Engine-Default-Template",
          name: "ElastiCache-OnDemand-Redis_Engine-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "ElastiCache-OnDemand-Redis_Engine-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "ElastiCache",
            value: "ElastiCache",
          },
          nameExclude: false,
          descriptionExclude: false,
          engine: [
            {
              label: "Memcached",
              value: "Memcached",
              checked: true,
            },
          ],
          engineExclude: false,
          offeringType: [
            {
              label: "Partial upfront",
              value: "Partial upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: true,
          offeringTypeExcludeExclude: false,
          region: [
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
        },
      ],
      Redshift: [
        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "Redshift-OnDemand-All_Region-Template",
          label: "Redshift-OnDemand-All_Region-Template",
          name: "Redshift-OnDemand-All_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Redshift-OnDemand-All_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Redshift",
            value: "Redshift",
          },
          nameExclude: false,
          descriptionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Hyderabad)",
              value: "Asia Pacific (Hyderabad)",
              checked: true,
            },
            {
              label: "Asia Pacific (Jakarta)",
              value: "Asia Pacific (Jakarta)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "Redhift--OnDemand-dc2_Family-Template",
          label: "Redhift--OnDemand-dc2_Family-Template",
          name: "Redhift--OnDemand-dc2_Family-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Redhift--OnDemand-dc2_Family-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Redshift",
            value: "Redshift",
          },
          nameExclude: false,
          descriptionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Hyderabad)",
              value: "Asia Pacific (Hyderabad)",
              checked: true,
            },
            {
              label: "Asia Pacific (Jakarta)",
              value: "Asia Pacific (Jakarta)",
              checked: true,
            },
          ],
          regionExclude: true,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "Redshift-OnDemand-All_Region-Default-Template",
          label: "Redshift-OnDemand-All_Region-Default-Template",
          name: "Redshift-OnDemand-All_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Redshift-OnDemand-All_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Redshift",
            value: "Redshift",
          },
          nameExclude: false,
          descriptionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Hyderabad)",
              value: "Asia Pacific (Hyderabad)",
              checked: true,
            },
            {
              label: "Asia Pacific (Jakarta)",
              value: "Asia Pacific (Jakarta)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "Redhift--OnDemand-dc2_Family-Default-Template",
          label: "Redhift--OnDemand-dc2_Family-Default-Template",
          name: "Redhift--OnDemand-dc2_Family-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Redhift--OnDemand-dc2_Family-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Redshift",
            value: "Redshift",
          },
          nameExclude: false,
          descriptionExclude: false,
          nodeType: [
            {
              label: "cache.m4.10xlarge",
              value: "cache.m4.10xlarge",
              checked: true,
            },
            {
              label: "cache.m4.2xlarge",
              value: "cache.m4.2xlarge",
              checked: true,
            },
          ],
          nodeTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Hyderabad)",
              value: "Asia Pacific (Hyderabad)",
              checked: true,
            },
            {
              label: "Asia Pacific (Jakarta)",
              value: "Asia Pacific (Jakarta)",
              checked: true,
            },
          ],
          regionExclude: true,
          offeringType: [
            {
              label: "All upfront",
              value: "All upfront",
              checked: true,
            },
          ],
          offeringTypeExclude: false,
          regionExcludeExclude: false,
        },
      ],
      Opensearch: [
        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "Opensearch-OnDemand-All_Region-Template",
          label: "Opensearch-OnDemand-All_Region-Template",
          name: "Opensearch-OnDemand-All_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Opensearch-OnDemand-All_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Opensearch",
            value: "Opensearch",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: false,
          instanceType: [
            {
              label: "u-24tb1.112xlarge",
              value: "u-24tb1.112xlarge",
              checked: true,
            },
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "Opensearch-OnDemand-Mumbai&Singapore_Region-Template",
          label: "Opensearch-OnDemand-Mumbai&Singapore_Region-Template",
          name: "Opensearch-OnDemand-Mumbai&Singapore_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Opensearch-OnDemand-Mumbai&Singapore_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Opensearch",
            value: "Opensearch",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: true,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "c5a.large",
              value: "c5a.large",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "Opensearch-OnDemand-All_Region-Default-Template",
          label: "Opensearch-OnDemand-All_Region-Default-Template",
          name: "Opensearch-OnDemand-All_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Opensearch-OnDemand-All_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Opensearch",
            value: "Opensearch",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: true,
          instanceType: [
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
            {
              label: "c5a.large",
              value: "c5a.large",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "Opensearch-OnDemand-Mumbai&Singapore_Region-Default-Template",
          label: "Opensearch-OnDemand-Mumbai&Singapore_Region-Default-Template",
          name: "Opensearch-OnDemand-Mumbai&Singapore_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Opensearch-OnDemand-Mumbai&Singapore_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Opensearch",
            value: "Opensearch",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: false,
          instanceType: [
            {
              label: "u-24tb1.112xlarge",
              value: "u-24tb1.112xlarge",
              checked: true,
            },
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
        },
      ],
      CloudFront: [
        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "CloudFront-InternetOut_DataTransfer-Template",
          label: "CloudFront-InternetOut_DataTransfer-Template",
          name: "CloudFront-InternetOut_DataTransfer-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront-InternetOut_DataTransfer-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: false,
          instanceType: [
            {
              label: "u-24tb1.112xlarge",
              value: "u-24tb1.112xlarge",
              checked: true,
            },
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "CloudFront-HTTP&HTTPS_Request-Template",
          label: "CloudFront-HTTP&HTTPS_Request-Template",
          name: "CloudFront-HTTP&HTTPS_Request-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront-HTTP&HTTPS_Request-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          awsService: [
            {
              label: "Fargate",
              value: "Fargate",
              checked: true,
            },
            {
              label: "Lambda",
              value: "Lambda",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          usageType: [
            {
              label: "HTTP Requests",
              value: "HTTP Requests",
              checked: true,
            },
          ],
          usageTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Singapore)",
              value: "Asia Pacific (Singapore)",
              checked: true,
            },
            {
              label: "Asia Pacific (Seoul)",
              value: "Asia Pacific (Seoul)",
              checked: true,
            },
          ],
          regionExclude: false,
          nameExclude: false,
          descriptionExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "CloudFront&WAF_All_Region-Template",
          label: "CloudFront&WAF_All_Region-Template",
          name: "CloudFront&WAF_All_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront&WAF_All_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          awsService: [
            {
              label: "Fargate",
              value: "Fargate",
              checked: true,
            },
            {
              label: "Lambda",
              value: "Lambda",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          usageType: [
            {
              label: "Data Transfer Internet out",
              value: "Data Transfer Internet out",
              checked: true,
            },
          ],
          usageTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Singapore)",
              value: "Asia Pacific (Singapore)",
              checked: true,
            },
            {
              label: "Asia Pacific (Seoul)",
              value: "Asia Pacific (Seoul)",
              checked: true,
            },
          ],
          regionExclude: false,
          nameExclude: false,
          descriptionExclude: false,
          usageTypeExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "CloudFront-InternetOut_DataTransfer-Default-Template",
          label: "CloudFront-InternetOut_DataTransfer-Default-Template",
          name: "CloudFront-InternetOut_DataTransfer-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront-InternetOut_DataTransfer-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          awsService: [
            {
              label: "Fargate",
              value: "Fargate",
              checked: true,
            },
            {
              label: "Lambda",
              value: "Lambda",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          usageType: [
            {
              label: "HTTP Requests",
              value: "HTTP Requests",
              checked: true,
            },
          ],
          usageTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Singapore)",
              value: "Asia Pacific (Singapore)",
              checked: true,
            },
            {
              label: "Asia Pacific (Seoul)",
              value: "Asia Pacific (Seoul)",
              checked: true,
            },
          ],
          regionExclude: false,
          nameExclude: false,
          descriptionExclude: false,
        },
        {
          id: 5, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "CloudFront-HTTP&HTTPS_Request-Default-Template",
          label: "CloudFront-HTTP&HTTPS_Request-Default-Template",
          name: "CloudFront-HTTP&HTTPS_Request-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront-HTTP&HTTPS_Request-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          awsService: [
            {
              label: "Fargate",
              value: "Fargate",
              checked: true,
            },
            {
              label: "Lambda",
              value: "Lambda",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          usageType: [
            {
              label: "Data Transfer Internet out",
              value: "Data Transfer Internet out",
              checked: true,
            },
          ],
          usageTypeExclude: false,
          region: [
            {
              label: "Asia Pacific (Singapore)",
              value: "Asia Pacific (Singapore)",
              checked: true,
            },
            {
              label: "Asia Pacific (Seoul)",
              value: "Asia Pacific (Seoul)",
              checked: true,
            },
          ],
          regionExclude: false,
          nameExclude: false,
          descriptionExclude: false,
          usageTypeExcludeExclude: false,
        },
        {
          id: 6, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "CloudFront&WAF_All_Region-Default-Template",
          label: "CloudFront&WAF_All_Region-Default-Template",
          name: "CloudFront&WAF_All_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "CloudFront&WAF_All_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "CloudFront",
            value: "CloudFront",
          },
          nameExclude: false,
          descriptionExclude: false,
          region: [
            {
              label: "Middle East (Bahrain)",
              value: "Middle East (Bahrain)",
              checked: true,
            },
            {
              label: "South America (Sao Paulo)",
              value: "South America (Sao Paulo)",
              checked: true,
            },
          ],
          regionExclude: false,
          instanceType: [
            {
              label: "u-24tb1.112xlarge",
              value: "u-24tb1.112xlarge",
              checked: true,
            },
            {
              label: "r5b.12xlarge",
              value: "r5b.12xlarge",
              checked: true,
            },
          ],
          instanceTypeExclude: false,
        },
      ],
      Compute: [
        {
          id: 1, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "EC2&Lambda*Fargate_All_Region-Template",
          label: "EC2&Lambda*Fargate_All_Region-Template",
          name: "EC2&Lambda*Fargate_All_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2&Lambda*Fargate_All_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Compute",
            value: "Compute",
          },
          nameExclude: false,
          descriptionExclude: false,
          awsService: [
            {
              label: "EC2",
              value: "EC2",
              checked: true,
            },
            {
              label: "CloudFront",
              value: "CloudFront",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          region: [
            {
              label: "Canada (Central)",
              value: "Canada (Central)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
        {
          id: 2, // tobeadded
          discountType: "Reservation Discount",
          type: "custom",
          value: "Lambda&Fargate-All_Region-Template",
          label: "Lambda&Fargate-All_Region-Template",
          name: "Lambda&Fargate-All_Region-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Lambda&Fargate-All_Region-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Compute",
            value: "Compute",
          },
          nameExclude: false,
          descriptionExclude: false,
          awsService: [
            {
              label: "EC2",
              value: "EC2",
              checked: true,
            },
            {
              label: "CloudFront",
              value: "CloudFront",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          region: [
            {
              label: "Europe (Spain)",
              value: "Europe (Spain)",
              checked: true,
            },
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 3, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "EC2&Lambda*Fargate_All_Region-Default-Template",
          label: "EC2&Lambda*Fargate_All_Region-Default-Template",
          name: "EC2&Lambda*Fargate_All_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "EC2&Lambda*Fargate_All_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Compute",
            value: "Compute",
          },
          nameExclude: false,
          descriptionExclude: false,
          awsService: [
            {
              label: "EC2",
              value: "EC2",
              checked: true,
            },
            {
              label: "CloudFront",
              value: "CloudFront",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          region: [
            {
              label: "Europe (Spain)",
              value: "Europe (Spain)",
              checked: true,
            },
            {
              label: "EU (London)",
              value: "EU (London)",
              checked: true,
            },
          ],
          regionExclude: false,
          regionExcludeExclude: false,
        },
        {
          id: 4, // tobeadded
          discountType: "Reservation Discount",
          type: "default",
          value: "Lambda&Fargate-All_Region-Default-Template",
          label: "Lambda&Fargate-All_Region-Default-Template",
          name: "Lambda&Fargate-All_Region-Default-Template",
          accountFamilies: [],
          templateType: {
            label: "Reservation Discounts",
            value: "reservation_discounts",
          },
          description: "Lambda&Fargate-All_Region-Default-Template",
          invoiceformat: "NEVER",
          exclude: false,
          "aws-service-families": [],
          service: {
            label: "Compute",
            value: "Compute",
          },
          nameExclude: false,
          descriptionExclude: false,
          awsService: [
            {
              label: "EC2",
              value: "EC2",
              checked: true,
            },
            {
              label: "CloudFront",
              value: "CloudFront",
              checked: true,
            },
          ],
          awsServiceExclude: false,
          region: [
            {
              label: "Canada (Central)",
              value: "Canada (Central)",
              checked: true,
            },
            {
              label: "EU (Frankfurt)",
              value: "EU (Frankfurt)",
              checked: true,
            },
          ],
          regionExclude: false,
        },
      ],
    },
  },
};

export const newChargeBackAccounts = {
  MobiKwik: [
    { value: "100831699389", label: "100831699389" },
    { value: "111923415455", label: "111923415455" },
    { value: "155235264790", label: "155235264790" },
  ],

  Igloo: [{ value: "331601008074", label: "331601008074" }],
  Offeo: [
    { value: "180088340548", label: "180088340548" },
    { value: "403446852182", label: "403446852182" },
    { value: "638479608455", label: "638479608455" },
  ],
  Snackmagic: [
    { value: "855872280235", label: "855872280235" },
    { value: "961085378371", label: "961085378371" },
  ],
  Spenmo: [
    { value: "186563999319", label: "186563999319" },
    { value: "374071252451", label: "374071252451" },
    { value: "824355866762", label: "824355866762" },
  ],
  CurrentMobile: [
    { value: "040336117571", label: "040336117571" },
    { value: "744052949255", label: "744052949255" },
  ],
  "Tata Play": [
    { value: "033319837796", label: "033319837796" },
    { value: "064021681730", label: "064021681730" },
    { value: "077822383897", label: "077822383897" },
    { value: "091484669965", label: "091484669965" },
    { value: "101479758648", label: "101479758648" },
    { value: "125106242642", label: "125106242642" },
    { value: "125280307264", label: "125280307264" },
  ],
};

export const selectAccountChargebackFormConfig = () => ({
  type: "select",
  name: "selectAccountChargeback",
  placeHolder: "Select Account",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Select Account to Apply Chargeback",
  options: [],
  enableFilter: { placeHolder: "Search" },
  topLabel: {
    text: "Select an account for applying chargeback",
    fontSize: "small",
    class: "!text-black !text-[14px] font-medium mandatory whitespace-nowrap",
  },
});

export const newCBUList = {
  MobiKwik: [
    {
      id: 46055,
      name: "MobiKwik",
      accounts: [],
    },
  ],
  Igloo: [
    {
      id: 46056,
      name: "Igloo",
      accounts: [],
    },
  ],
  Offeo: [
    {
      id: 46057,
      name: "Offeo",
      accounts: [],
    },
    {
      id: 46058,
      name: "Imaginary Ones",
      accounts: [],
    },
  ],
  Snackmagic: [
    {
      id: 46059,
      name: "Snackmagic",
      accounts: [],
    },
  ],
  Spenmo: [
    {
      id: 46060,
      name: "Spenmo",
      accounts: [],
    },
  ],
  CurrentMobile: [
    {
      id: 46061,
      name: "CurrentMobile",
      accounts: [],
    },
  ],
  "Tata Play": [
    {
      id: 46062,
      name: "Tata Play Broadband Pvt. Ltd.",
      accounts: [],
    },
    {
      id: 46063,
      name: "Tata Play Ltd. - Karnataka",
      accounts: [],
    },
    {
      id: 46064,
      name: "Tata Play Delhi",
      accounts: [],
    },
    {
      id: 46065,
      name: "Tata Play - HOBS",
      accounts: [],
    },
  ],
};

export const discountConfig = {
  EDP: "Enterprise Discount Program (EDP)",
  PPA: "Private Pricing Agreements (PPA)",
  RD: "Reservation Pricing",
  "discount-and-charges": "Discounts and Charges",
};
export const discountCofigAddButtonText = {
  EDP: "Configure EDP",
  "discount-and-charges": "Configure Discount and Charges",
  PPA: {
    "CloudFront Private Pricing": "Configure CloudFront PPA",
  },
  RD: {
    EC2: "Configure EC2 Discount",
    RDS: "Configure RDS Discount",
    ElastiCache: "Configure ElastiCache Discount",
    Redshift: "Configure Redshift Discount",
    Opensearch: "Configure Opensearch Discount",
    CloudFront: "Configure CloudFront Discount",
    Compute: "Configure Compute Discount",
  },
};

export const discountCofigPageTitle = {
  edp: "Configure EDP",
  cloudfront: { "private-pricing": "Configure CloudFront PPA" },
  "reserved-discount": {
    EC2: "Configure EC2 Reservation Pricing",
    RDS: "Configure RDS Reservation Pricing",
    ElastiCache: "Configure ElastiCache Reservation Pricing",
    Redshift: "Configure Redshift Reservation Pricing",
    Opensearch: "Configure Opensearch Reservation Pricing",
    CloudFront: "Configure CloudFront Reservation Pricing",
    Compute: "Configure Compute Reservation Pricing",
  },
  "discount-and-charges": "Discount and Charges",
};
export const discountCofigToastMessage = {
  edp: "EDP Configured Successfully",
  cloudfront: { "private-pricing": "CloudFront PPA Configured Successfully" },
  "reserved-discount": {
    EC2: "EC2 Reservation Configured Successfully",
    RDS: "RDS Reservation Configured Successfully",
    ElastiCache: "ElastiCache Reservation Configured Successfully",
    Redshift: "Redshift Reservation Configured Successfully",
    Opensearch: "Opensearch Reservation Configured Successfully",
    CloudFront: "CloudFront Reservation Configured Successfully",
    Compute: "Compute Reservation Configured Successfully",
  },
  "discount-and-charges": "Discount and Charges Configured Successfully",
};
