import React from "react";
import "./style.scss";
import NoResponse from "../Assets/no-response.svg";
import { AzureContactUs } from "../../../../../Constants/Constant";

function AzureSubscriptionError() {
  return (
    <div className="status-message error flex items-center justify-center h-screen">
      <div className="container mx-auto flex flex-col px-5 py-[24px] justify-center items-center">
        <img className="no-response mb-[37px]" alt="no-response" src={NoResponse} />
        <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
          <h2 className="pb-[10px]">We did not get response from Azure for this subscription</h2>
          <p>
            <span> Please retry by subscribing again from Azure marketplace. Contact </span>
            <a href={`mailto:${AzureContactUs}`}>Support</a>
            <span>, if needed.</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AzureSubscriptionError;
