import React, { useState, useEffect } from "react";

function SeeMoreText({ text = "", maxLength = 34, component = "" }) {
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    setShowFullText(false);
  }, [text]);

  const actualText = text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
  const truncatedText = showFullText ? text : actualText;

  const toggleTextVisibility = (event) => {
    event.preventDefault();
    setShowFullText(!showFullText);
  };

  return (
    <span>
      <span>{truncatedText}</span>
      <span>
        {text.length > maxLength ? (
          <span
            aria-hidden="true"
            onClick={toggleTextVisibility}
            className={`pl-[4px] cursor-pointer !pointer-events-auto underline ${
              component ? "text-[#0A3CA2]" : "!text-[#FFFFFF]"
            }`}
          >
            {showFullText ? "Show Less" : "Show More"}
          </span>
        ) : null}
      </span>
    </span>
  );
}

export default SeeMoreText;
