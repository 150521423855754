const editFormData = {};
const initAction = { type: "", payload: {} };

export default function BudgetAlertReducer(state = editFormData, action = initAction) {
  switch (action.type) {
    case "EDIT_BUDGET_ALERT":
      return action.payload;
    case "DELETE":
      return state;
    default:
      return state;
  }
}
