import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Container from "./container-export";
import "./style.scss";

function Draggable({ listOrder, onCardUpdate, readOnly }) {
  return (
    <div className={`draggable ${readOnly ? "readOnly" : null}`}>
      <DndProvider backend={HTML5Backend}>
        <Container data={listOrder} onOrderUpdate={onCardUpdate} readOnly={readOnly} />
      </DndProvider>
    </div>
  );
}

export default Draggable;
