import React from "react";
import { Tooltip } from "@mui/material";
import "./multiHeaderFormStyles.scss";
import cloneDeep from "lodash.clonedeep";
import { noop } from "../../../../Utils/commonUtils";
import { ReactComponent as RemoveIcon } from "../../../../Assets/icons/delete-icon.svg";
import { ReactComponent as AddIcon } from "../../../../Assets/icons/ic-addIcon.svg";

function MultiAdditionComponent(props) {
  const {
    formElement: { handleAdd = noop, level = 0, handleDelete = noop },
    formValues,
    errors,
    handleChange,
    onBlurHandler = noop,
    formElement,
    resetClicked,
    touch,
    componentWrapper,
    formWrapperClass,
  } = props;

  const _formWrapperClass = formWrapperClass || formElement?.formWrapperClass || "";

  const onChangeHandler = (handleParams, item) => {
    handleChange(handleParams, item);
  };

  const deleteHandler = () => {
    const newFormElement = cloneDeep(formElement);
    newFormElement.customEvent.callback = () => {
      handleDelete(level, formElement);
    };
    onChangeHandler(
      {
        target: {
          name: "addMore",
          value: false,
        },
      },
      newFormElement
    );
  };

  const renderButtons = () => (
    <div className="flex items-center gap-3">
      <Tooltip className="multi-header-tooltip-class" title="Add" arrow placement="top">
        <span
          className={`add-btn-wrapper cursor-pointer ${!formElement.addMore ? "hide" : ""}`}
          aria-hidden
          onClick={() => {
            handleAdd(level, formElement);
            const newFormElement = cloneDeep(formElement);
            delete newFormElement.resetValues;
            onChangeHandler(
              {
                target: {
                  name: "addMore",
                  value: true,
                },
              },
              newFormElement
            );
          }}
        >
          Add
          <AddIcon className="h-[12px] w-[12px] ml-[5px]" />
        </span>
      </Tooltip>

      {!formElement.hideDelete && (
        <Tooltip className="multi-header-tooltip-class" title="Delete" arrow placement="top">
          <RemoveIcon onClick={deleteHandler} className="h-[14px] w-[14px] cursor-pointer" />
        </Tooltip>
      )}
    </div>
  );

  return (
    <div className="flex items-center">
      <div className={`!w-[100%] header-component-div ${formElement?.wrapperClass} flex`}>
        {formElement?.heading?.name && (
          <div className="header-comp-div px-[10px] py-[8px] flex items-center justify-center flex-col gap-2">
            <div className="flex items-center">
              <div className={formElement?.heading?.class}>{formElement?.heading?.name}</div>
              {formElement?.heading?.tooltip?.icon && (
                <div className="!pl-[15px]">
                  <Tooltip
                    className="multi-header-tooltip-class"
                    title={formElement?.heading?.tooltip?.text}
                    arrow
                    placement="bottom"
                  >
                    {formElement?.heading?.tooltip?.icon}
                  </Tooltip>
                </div>
              )}
            </div>
            {formElement?.heading?.description && (
              <div className={formElement?.heading?.descriptionClass}>
                {formElement?.heading?.description}
              </div>
            )}
            {renderButtons()}
          </div>
        )}

        <div className={`flex w-[100%] ${componentWrapper}`}>
          <div className={`!py-[14px] !pb-[16px] !px-[12px] w-[100%] ${_formWrapperClass}`}>
            {formElement?.config?.map((item, index) =>
              item.type ? (
                <>
                  <item.component
                    key={item.name}
                    {...item}
                    name={item.name}
                    formElement={{
                      ...item,
                      handleAdd: () => {
                        handleAdd(level + 1, formElement, item);
                      },
                      handleDelete: () => {
                        handleDelete(level + 1, formElement, item, index);
                      },
                      addMore: item?.addMore || formElement.config.length === index + 1,
                      level: level + 1,
                      hideDelete: item?.hideDelete,
                    }}
                    value={formValues?.[item?.name]}
                    handleChange={(event, formConfig) => onChangeHandler(event, formConfig || item)}
                    error={errors?.[item?.name]}
                    touched={touch?.[item?.name]}
                    onBlurHandler={(event, formConfig) => {
                      onBlurHandler(event, formConfig || item);
                    }}
                    resetClicked={resetClicked}
                    formValues={formValues}
                    errors={errors}
                    touch={touch}
                    checked={formValues[item.name]}
                  />
                  {item?.description?.name && (
                    <div className={item?.description?.class}>{item?.description?.name}</div>
                  )}
                </>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiAdditionComponent;
