import Login from "../Components/Login";
import ForgetPassword from "../Components/Login/ForgotPassword";
import ConfirmUser from "../Components/Login/ConfirmUser";
import ResetPassword from "../Components/Login/ResetPassword";
import PageNotFound from "../Components/Login/PageNotFound";
import Routes from "./Routes";
import Signup from "../Components/CK-Auto/signup";
import ResendEmail from "../Components/Common/ResendEmail";
import WelcomeMarketPlace from "../Components/Common/WelcomeMarketplace";

const PublicRouteConfig = [
  {
    component: Login,
    exact: true,
    path: Routes.LOGIN,
  },
  {
    component: ForgetPassword,
    exact: true,
    path: Routes.FORGOTPASSWORD,
  },
  {
    component: ResetPassword,
    exact: true,
    path: Routes.RESETPASSWORD,
  },
  {
    component: ResetPassword,
    exact: true,
    path: Routes.CONFIRMUSER,
  },
  {
    component: Signup,
    exact: true,
    path: Routes.REGISTER,
  },
  {
    component: ConfirmUser,
    exact: true,
    path: Routes.CONFIRM_CUSTOMER,
  },
  {
    component: PageNotFound,
    exact: true,
    path: Routes.PAGENOTFOUND,
  },
  {
    component: ResendEmail,
    exact: true,
    path: Routes.RESENDEMAIL,
  },
  {
    component: WelcomeMarketPlace,
    exact: true,
    path: Routes?.WELCOMEMARKETPLACE,
  },
  {
    component: PageNotFound,
    exact: true,
    path: "*",
  },
];

export default PublicRouteConfig;
