import MarketPlace from "../Components/MarketPlace";
import Routes from "./Routes";
import AzureSubscriptionActivation from "../Components/CK-Azure/AzureOnboarding/manualOnboarding/AzureLanding/SubscriptionActivation";
import AzureSubscriptionError from "../Components/CK-Azure/AzureOnboarding/manualOnboarding/AzureLanding/SubscriptionError";
import OnboardingActivation from "../Components/CK-Azure/AzureOnboarding/manualOnboarding/OnboardingActivation";
import AzureSsoLogin from "../Components/CK-Azure/AzureOnboarding/manualOnboarding/AzureLanding/SsoLogin";

const NoAuthRouteConfig = [
  {
    component: MarketPlace, // FOR AWS
    exact: true,
    path: Routes.MARKETPLACE,
  },
  {
    component: AzureSubscriptionActivation,
    exact: true,
    path: Routes.AZURE_SUBSCRIPTION_ACTIVATION,
  },
  {
    component: AzureSubscriptionError,
    exact: true,
    path: Routes.AZURE_SUBSCRIPTION_ERROR,
  },
  {
    component: OnboardingActivation,
    exact: true,
    path: Routes.AZURE_ONBOARDING_ACTIVATION,
  },
  {
    component: AzureSsoLogin,
    exact: true,
    path: Routes.AZURE_SSO_LOGIN,
  },
];

export default NoAuthRouteConfig;
