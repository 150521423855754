import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

export default function TooltipPopup({
  content,
  style,
  iconSrc,
  icon,
  placement = "right",
  rotate = true,
  arrow = true,
}) {
  const tooltipTop = {
    "& .MuiTooltip-tooltip": style,
    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: style?.backgroundColor,
      },
    },
  };

  return (
    <span className="icon-info ml-1 icon-info ml-[5px]">
      <Tooltip
        title={content()}
        arrow={arrow}
        placement={placement}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 400 }}
        PopperProps={{ sx: tooltipTop }}
      >
        {iconSrc ? (
          <img className={`${rotate && "rotate-180"}`} src={iconSrc} alt="Info Icon" />
        ) : (
          icon
        )}
      </Tooltip>
    </span>
  );
}
