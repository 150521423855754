export default function sortAndModifyObjectsByGroupName(objects) {
  // First, sort the objects by groupName
  objects.sort((a, b) => {
    if (a.groupName < b.groupName) {
      return -1;
    }
    if (a.groupName > b.groupName) {
      return 1;
    }
    return 0;
  });
  let currentGroupName = null;
  let order = 1;

  // Second, modify the order to appear sequentially for each group
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < objects.length; i++) {
    if (objects[i].groupName !== currentGroupName) {
      currentGroupName = objects[i].groupName;
      order = 1;
    }
    // eslint-disable-next-line no-plusplus, no-param-reassign
    objects[i].order = order++;
  }

  return objects;
}
