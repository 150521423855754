import React, { useEffect, useState } from "react";
import "./style.scss";

export default function ListRenderer({ options, modalState, selectedItems, value }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(options);
  }, [options]);

  const selectedOptionsFirst = (listing) => {
    if (value?.length && JSON.stringify(value) === JSON.stringify(selectedItems)) {
      return listing.sort((a, b) => Number(b.checked) - Number(a.checked));
    }
    return listing;
  };

  useEffect(() => {
    const updatedList = selectedOptionsFirst(options);
    setList(updatedList);
  }, [modalState]);

  return (
    <div className="multiselect_list_render">
      {list && list.length > 0 ? (
        list.map((item, index) => (
          <p
            className={`${item.checked ? "selected" : "not_selected"} ${
              item.checked && index === list.findLastIndex((i) => i.checked) ? "last-selected" : ""
            }`}
          >
            {item.value}
          </p>
        ))
      ) : (
        <p className="">No data</p>
      )}
    </div>
  );
}
