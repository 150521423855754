const transformData = (originalData = []) => {
  const groupedData = {};

  originalData?.forEach((item) => {
    const date = item?.lastInvoiceDate;
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date]?.push({
      label: item?.label,
      value: item?.value,
    });
  });

  const transformedData = Object.entries(groupedData)?.map(([date, items]) => ({
    groupName: date,
    items,
  }));

  return transformedData;
};

export default transformData;
