/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import { VariableSizeList as List } from "react-window";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import warningIcon from "../../../../Assets/icons/warning.svg";
import SearchBar from "./SearchBar";
import { filterList, noop } from "../../../../Utils/commonUtils";
import { labelTooltip } from "../../../TransferList/util";
import sortAndModifyObjectsByGroupName from "../../../../Utils/sortByGroup";
import "./input.scss";
import { InludeExcludeOptions, ResetFilters } from "./utils";

const getSeletedItems = (e, option, selected) =>
  e.target.checked ? [...selected, option] : selected.filter((ele) => ele.value !== option.value);

const onSearchHandler = (event, resetSelection, setSearchTerm) => {
  setSearchTerm(event.target.value);
};

const moreThanOne = (maxRequiredOptions) => (maxRequiredOptions > 1 ? "s" : "");

export default function MultiSelect({
  value,
  name,
  searchEnabled,
  className,
  options,
  handleChange,
  unitEnabled = false,
  label,
  placeholder,
  selected,
  noDataText,
  noDataLabel,
  isIcon = true,
  children,
  resetSelection = noop,
  searchIconPostion = "",
  dashboard = "",
  isSelectAll = true,
  noDataIcon = "",
  noSearchFoundText = "",
  disabled = false,
  tooltip,
  modifyLabelValue = false,
  maxRequiredOptions = 0,
  includeExcludeOption = false,
  resetFilter = false,
  includeOnlyHandler = noop,
  includeOnly = "",
  initialValue = [],
  grouping = false,
  readOnly = false,
  virtualized = false,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const searchResult = grouping
    ? sortAndModifyObjectsByGroupName(options?.filter(filterList(searchTerm, "value")))
    : options?.filter(filterList(searchTerm, modifyLabelValue ? "label" : "value"));
  const unSelectedItems = searchResult?.filter((option) => !option.checked);

  const handleMultiSelectChange = (e, option) => {
    const selectedItems = getSeletedItems(e, option, selected);
    if (maxRequiredOptions > 0 && selectedItems.length > maxRequiredOptions) {
      return;
    }
    const optionClone = option;
    optionClone.checked = e.target.checked;
    handleChange({
      ...e,
      target: { ...e.target, value: selectedItems, name: e.target.name },
    });
  };

  const selectAll = (e) => {
    const { checked } = e.target;
    const selectedItems = searchResult.filter((option) => {
      const optionClone = option;
      optionClone.checked = e.target.checked;
      return optionClone;
    });

    handleChange({
      ...e,
      target: {
        ...e.target,
        value: checked
          ? [
              ...selected.filter(
                (item) => !searchResult.find((option) => option.value === item.value)
              ),
              ...selectedItems,
            ]
          : selected.filter((item) => !searchResult.find((option) => option.value === item.value)),
        name: e.target.name,
      },
    });
  };

  const includeExcludeHandler = (e) => {
    includeOnlyHandler({
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const returnMultiselect = () => dashboard === "" && !maxRequiredOptions && !grouping;

  const renderCheckBoxes = () => {
    const disabledOptinsLength = searchResult?.filter((item) => item?.disabled).length;
    return searchResult.length ? (
      <>
        {returnMultiselect() && (
          <div
            className={`option py-0.5 flex items-center justify-between ${
              disabledOptinsLength ? "disabled" : ""
            }`}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={className}
                  name={name}
                  id={value}
                  disableRipple
                  disabled={disabled || disabledOptinsLength || readOnly}
                  checked={!unSelectedItems?.length}
                  onChange={(e) => selectAll(e)}
                />
              }
              label="Select All"
            />
          </div>
        )}
        {dashboard === "transferList" &&
          searchResult?.length > 1 &&
          isSelectAll &&
          !maxRequiredOptions && (
            <div
              className={`option py-0.5 flex items-center justify-between ${
                !unSelectedItems?.length ? "bg-[#F6F9FF]" : ""
              }`}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className={className}
                    name={name}
                    id={value}
                    disableRipple
                    disabled={disabled}
                    checked={!unSelectedItems?.length}
                    onChange={(e) => selectAll(e)}
                  />
                }
                label="Select All"
              />
            </div>
          )}
        {virtualized ? (
          <List
            className="List"
            height={1000}
            itemCount={searchResult.length}
            itemSize={() => 30}
            width={290}
          >
            {({ index, style }) => {
              const option = searchResult[index];
              return (
                <div
                  style={style}
                  className={`option py-0.5 flex items-center justify-between ${
                    option.disabled ? "disabled" : ""
                  }  ${readOnly ? "readOnly" : ""}`}
                  key={`${option?.id}_${option?.value}`}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={`${className} ${option?.groupName ? "hidden" : ""}`}
                        name={name}
                        id={value}
                        checked={option.checked}
                        disableRipple
                        disabled={disabled || option.disabled || readOnly}
                        onChange={(e) => handleMultiSelectChange(e, option)}
                      />
                    }
                    label={modifyLabelValue ? option?.label : option?.value}
                  />
                  {option.order === 1 && (
                    <div className="tag">
                      <span>ID:</span>
                      <span className="group_name">{option.groupName}</span>
                    </div>
                  )}

                  {tooltip && labelTooltip(option.value)}
                  {unitEnabled && <div className="unit text-xs px-2 py-1 ">{option.unit}</div>}
                </div>
              );
            }}
          </List>
        ) : (
          searchResult.map((option) => (
            <div
              className={`option py-0.5 flex items-center justify-between ${
                option.disabled ? "disabled" : ""
              }  ${readOnly ? "readOnly" : ""}`}
              key={`${option?.id}_${option?.value}`}
            >
              {option.order === 1 && (
                <div className="tag">
                  <span>ID:</span>
                  <span className="group_name">{option.groupName}</span>
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    className={`${className} ${option?.groupName ? "hidden" : ""}`}
                    name={name}
                    id={value}
                    checked={option.checked}
                    disableRipple
                    disabled={disabled || option.disabled || readOnly}
                    onChange={(e) => handleMultiSelectChange(e, option)}
                  />
                }
                label={modifyLabelValue ? option?.label : option?.value}
              />
              {tooltip && labelTooltip(option.value)}
              {unitEnabled && <div className="unit text-xs px-2 py-1 ">{option.unit}</div>}
            </div>
          ))
        )}
      </>
    ) : (
      <span className="text-[10px] no_data_found py-[20px]">
        {noSearchFoundText || "No Data Available"}
      </span>
    );
  };

  const filterReset = (e) => {
    options.forEach((option) => {
      const optionClone = option;
      optionClone.checked = false;
    });

    handleChange({
      ...e,
      target: {
        ...e.target,
        value: initialValue,
        name: e.target.name,
      },
    });
    if (!e.target.checked) {
      resetSelection();
    }
  };

  return (
    <div
      className={`multi-select-wrapper ${!!selected?.length && "selected"} ${
        options?.length === 1 ? "!overflow-visible " : ""
      } `}
    >
      <div aria-hidden="true" className={`search-wrapper ${!!options?.length && "!border-0"}`}>
        {label && <span className="text-black search-label">{label}</span>}
        {searchEnabled && !!options?.length && (
          <SearchBar
            placeholder={placeholder}
            searchTerm={searchTerm}
            onChangeHandler={(e) => onSearchHandler(e, resetSelection, setSearchTerm)}
            searchIconPostion={searchIconPostion}
          />
        )}
      </div>
      <InludeExcludeOptions
        includeExcludeOption={includeExcludeOption}
        includeExcludeHandler={includeExcludeHandler}
        includeOnly={includeOnly}
        maxRequiredOptions={maxRequiredOptions}
      />
      <ResetFilters
        resetFilter={resetFilter}
        filterReset={filterReset}
        options={options}
        value={selected}
        initialValue={initialValue}
      />
      <div
        className={`multi-select-dropdown flex flex-col ${
          !searchResult?.length && "justify-center items-center relative"
        } ${searchResult?.length === 1 ? "!overflow-visible" : ""}`}
      >
        {options?.length ? (
          renderCheckBoxes()
        ) : (
          <div className="display_flex gap-y-1 flex-col w-[100%] m-auto no-item-selected-list">
            {isIcon && <img src={noDataIcon || warningIcon} alt="warning-icon" />}
            <span
              className={`${
                isIcon
                  ? "no-data-label text-[13px] mb-4 text-[#0A3CA2]"
                  : "text-[12px] font-medium text-[#0A3CA2]"
              }`}
            >
              {noDataLabel}
            </span>
            <p className="!text-[10px] !text-center !text-[#7C7C7C]">{noDataText}</p>
          </div>
        )}
      </div>
      {!!maxRequiredOptions && (
        <div className="px-[15px] pt-[5px] pb-[14px] text-red-500 text-[12px] font-[400]">
          * Maximun {maxRequiredOptions} filter{moreThanOne(maxRequiredOptions)} can be selected
        </div>
      )}
      {children}
    </div>
  );
}
