import React from "react";
import { ReactComponent as Cluster } from "../../Assets/cluster.svg";

import { ReactComponent as Nodes } from "../../Assets/nodes.svg";
import { ReactComponent as Pods } from "../../Assets/pods.svg";
import nodesBackground from "../../Assets/nodesBackground.svg";
import podsBackground from "../../Assets/podsBackground.svg";
import clusterBackground from "../../Assets/clusterBackground.svg";
import { ReactComponent as ActiveDaily } from "../../Assets/daily-active.svg";
import { ReactComponent as InactiveActiveDaily } from "../../Assets/daily-inactive.svg";
import { ReactComponent as ActiveMonthly } from "../../Assets/monthly-active.svg";
import { ReactComponent as InactiveMonthly } from "../../Assets/monthly-inactive.svg";
import { ReactComponent as StackGraphActive } from "../../Assets/stack-graph-active.svg";
import { ReactComponent as StackGraphInActive } from "../../Assets/stack-graph-inactive.svg";
import { ReactComponent as LineGraphActive } from "../../Assets/line-graph-active.svg";
import { ReactComponent as LineGraphInActive } from "../../Assets/line-graph-inactive.svg";
import { ReactComponent as BarChartActive } from "../../Assets/bar-graph-active.svg";
import { ReactComponent as BarChartInActive } from "../../Assets/bar-graph-inactive.svg";

const colorArray = ["text-[#0C477E] bg-[#E5F3FF]", "text-[#A52918] bg-[#FFEAE7]"];

export const SUMMARY_CARDS_IMAGES = [
  {
    icon: "",
    backgroundImage: "",
    colorClasses: colorArray,
  },
  {
    icon: "",
    backgroundImage: "",
    colorClasses: colorArray,
  },
  {
    icon: <Cluster />,
    backgroundImage: clusterBackground,
  },
  {
    icon: <Nodes />,
    backgroundImage: nodesBackground,
  },
  {
    icon: <Pods />,
    backgroundImage: podsBackground,
  },
];

export const commonProps = (activeChartType) => ({
  type: activeChartType || "stackedcolumn2d",
  tabs: false,
  hideScroll: true,
  showMoreIcon: true,
  showGraphExpandIcon: true,
  xaxislinecolor: "#EDEFF0",
  yaxislinecolor: "#EDEFF0",
  divLineDashed: "0",
  divLineColor: "#D7D7D7",
  numVDivLines: "0",
  legendIconSides: 0,
  labelFontColor: "#2B2B2B",
  labelFontSize: 9,
  rotateLabels: 1,
  yAxisValueFontColor: "#2B2B2B",
  yAxisValueFontSize: 9,
  height: 300,
  labelStep: "0",
  palettecolors: "#8ECFFF,#60C0A6,#F6B273,#FBD064,#907BE2,#2A86FF,#EA6AA7,#4E8649,#4184A1",
  wrapperClass: "w-[49.8%] !mt-[10px] !mb-[0px] graph-wrapper-style",
});

export const CUDOS_EKS_GRAPH = (activeChartType) => [
  {
    heading: "Total Cost by Clusters",
    name: "costByClusters",
    firstHeader: {
      key: "rowName",
      value: "Cluster Name",
      align: "Left",
    },
    ...commonProps(activeChartType),
  },
  {
    heading: "Total Cost by Namespace",
    name: "costByNamespace",
    firstHeader: {
      key: "rowName",
      value: "Namespace",
      align: "Left",
    },
    ...commonProps(activeChartType),
  },
  {
    heading: "Total Cost by Region",
    name: "costByRegions",
    firstHeader: {
      key: "rowName",
      value: "Regions",
      align: "Left",
    },
    ...commonProps(activeChartType),
  },
  {
    heading: "Total Cost by Instance Type",
    firstHeader: {
      key: "rowName",
      value: "Instance Type",
      align: "Left",
    },
    name: "costByInstanceType",
    ...commonProps(activeChartType),
  },
  {
    heading: "Total Cost by Purchase Type",
    name: "costByPurchaseType",
    firstHeader: {
      key: "rowName",
      value: "Purchase Type",
      align: "Left",
    },
    ...commonProps(activeChartType),
  },
];

export const IMAGES_LIST = [
  {
    text: "groupChart",
    activeIcon: <BarChartActive />,
    inactiveIcon: <BarChartInActive />,
    label: "Bar",
  },
  {
    text: "stackedcolumn2d",
    activeIcon: <StackGraphActive />,
    inactiveIcon: <StackGraphInActive />,
    label: "Stack",
  },
  {
    text: "multipleLineChart",
    activeIcon: <LineGraphActive />,
    inactiveIcon: <LineGraphInActive />,
    label: "Line",
  },
];

export const DAILY_MONTHLY_TOGGLE = [
  {
    label: "Daily",
    value: "daily",
    activeIcon: <ActiveDaily />,
    inactiveIcon: <InactiveActiveDaily />,
  },
  {
    label: "Monthly",
    value: "monthly",
    activeIcon: <ActiveMonthly />,
    inactiveIcon: <InactiveMonthly />,
  },
];

const headerClass =
  "!bg-[#0A3CA2] text-[#fff] px-[8px] py-[5px] cursor-pointer border-r border-solid border-[#E0F1FF]";
const bodyClass = "py-[4px] px-[8px] text-[11px]";

const commonHeaders = { bodyClass, headerClass, defaultValue: "" };
const commonHeadersRightAlign = {
  bodyClass: `${bodyClass} !text-right`,
  headerClass: `${headerClass} !text-right`,
  defaultValue: "",
};
export const TOP_20_INSTANCE_HEADERS = [
  {
    value: "Cluster Name",
    key: "cluster_name",
    ...commonHeaders,
  },
  {
    value: "Namespace",
    key: "namespace",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Node Instance Type",
    key: "node_instance_type",
    ...commonHeaders,
  },
  {
    value: "Node Purchase Option",
    key: "node_purchase_option",
    ...commonHeaders,
  },
  {
    value: "Used CPU Cost",
    key: "used_cpu_cost",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused CPU Cost",
    key: "unused_cpu_cost",
    ...commonHeadersRightAlign,
  },
  {
    value: "Used Memory Cost",
    key: "used_memory_cost",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused Memory Cost",
    key: "unused_memory_cost",
    ...commonHeadersRightAlign,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const TOP_20_INSTANCE_HEADERS_ELASTICACHE = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Instance Name",
    key: "instance_name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "DB Engine",
    key: "cache_engine",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeaders,
  },
  {
    value: "Total Cost",
    ...commonHeaders,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const SELECTED_TAB = "EKS";
