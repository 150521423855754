const initState = { isSwitchRoleVisible: false };
const initAction = { type: "", payload: {} };

export default function showSwitchRoleReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "SHOW_SWITCH_ROLE":
      return { ...state, isSwitchRoleVisible: action.payload };
    default:
      return state;
  }
}
