const initialData = {};
const initAction = { type: "", payload: {} };

export default function DefaultPathReducer(state = initialData, action = initAction) {
  switch (action.type) {
    case "DEFAULT_PATH":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
