/* eslint-disable max-lines-per-function */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import "./style.scss";

function ListSelectorNoData({ message = "", Icon }) {
  return (
    <div className="listSelector_nodata">
      <Icon />
      <p>{message}</p>
    </div>
  );
}

export default ListSelectorNoData;
