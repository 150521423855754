import React, { useState, useEffect } from "react";
import "./style.scss";
import { formatWithCommas, removeCommas } from "../../../FormConfig/Form/service/helper";

// eslint-disable-next-line complexity
function FormInput(props) {
  const {
    onGetData,
    id,
    placeholder,
    name,
    type,
    value = "",
    errorMessage = "",
    disabled = false,
    errorClass = "",
    parentClass = "",
    comparisonError = false,
    filterError = false,
    commaFormatting = false,
  } = props;

  const [focused, setFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(formatWithCommas(value));
  }, [value]);

  const onChangeHandler = (event) => {
    const rawValue = removeCommas(event.target.value);
    onGetData(event.target.name, rawValue);
    if (filterError) {
      setFocused(true);
    }
  };

  const handleError = () => {
    const { regex } = props;
    if (regex && value) {
      const regexValue = !!String(value)?.match(regex);
      return !regexValue;
    }
    return false;
  };

  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        value={commaFormatting ? displayValue : value}
        onChange={onChangeHandler}
        onBlur={() => setFocused(true)}
        className={`${
          (handleError() || comparisonError) && focused ? "error_box" : "input_box"
        } ${parentClass}`}
      />
      {errorMessage && !filterError && handleError() && focused && value.length > 0 && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
      {comparisonError && !filterError && value?.length > 0 && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
      {comparisonError && filterError && focused && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
    </>
  );
}

export default FormInput;
