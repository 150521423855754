import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { ReactComponent as DragIcon } from "../../../Assets/icons/drag-icon.svg";

function Card({ order, text, index, moveCard, readOnly }) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      const updateItem = item;
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      updateItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({
      order,
      index,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !readOnly,
  });

  const opacity = isDragging ? 0 : 1;

  if (!readOnly) {
    drag(drop(ref));
  }

  return (
    <div
      ref={ref}
      className="drag_card flex gap-[7px] items-center border-[1px] border-solid border-[#dddfe8] bg-[#f6f7f9] px-[10px] py-[6px] text-[12px] rounded-[2px]"
      style={{ opacity }}
    >
      {!readOnly && <DragIcon className="-mt-[2px]" />}

      <div>{text}</div>
      <div className="card-element px-[9px] py-[7px] bg-[#fff] rounded-[100%] text-[11px] font-semibold leading-[10.55px] border-[1px] border-solid border-[#DDDFE8] ml-auto">
        {index + 1}
      </div>
    </div>
  );
}

export default Card;
