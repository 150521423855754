const initState = { userData: null };
const initAction = { type: "", payload: {} };

export default function userReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "SAVE_USER_DATA":
      return { ...state, userData: action.payload };
    case "S3_BASE_URL":
      return { ...state, s3BaseUrl: action.payload };
    default:
      return state;
  }
}
