export const formatWithCommas = (value) => {
  if (!value) return value;

  const parts = value?.toString()?.split(".");
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts?.join(".");
};

export const removeCommas = (value) => value?.replace(/,/g, "");
