import React from "react";
import { Tooltip } from "@mui/material";
import { noop } from "../../../../../../../Utils/commonUtils";

import BasicSelect from "../../../../../../FormConfig/Form/Components/DropDown";
import { ReactComponent as InfoIcon } from "../../../../../../../Assets/icons/compute-info.svg";

function Select({ options = [], handleChange = noop, selected = "" }) {
  return (
    <div className="flex items-center gap-[5px]">
      {options.length ? (
        <>
          <span className="text-[10px] font-medium text-[#2b2b2b] leading-[10px]">To Region:</span>
          <BasicSelect
            name="Graph Dropdown"
            placeHolder=""
            className=" !min-w-[150px] cudos-region-select-wrapper"
            options={options}
            noDataMessage="No Data Available"
            handleChange={(e) => handleChange(e.target.value, "select")}
            value={selected}
          />
          <Tooltip
            title="Select a destination region from the dropdown to view corresponding source regions displayed in the graph."
            arrow
            placement="top"
          >
            <span className="infoIcon">
              <InfoIcon fontSize="small" className="iconSize" />
            </span>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
}

export default Select;
