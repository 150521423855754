import React from "react";
import "../style.scss";
import CloudLoader from "../../Assets/cloud-loader.gif";

function AzureLoader({ title, message }) {
  return (
    <div className="status-message loading flex items-center justify-center h-screen">
      <div className="container mx-auto flex flex-col px-5 py-[24px] justify-center items-center">
        <img className="cloud-loader mb-[37px]" alt="no-response" src={CloudLoader} />
        <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
          {title && <h2 className="pb-[10px]">{title}</h2>}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default AzureLoader;
