import React from "react";
import Text from "./Text";

export default function ReadOnlyView(props) {
  const { data, wrapperClass } = props;
  return (
    <div className={`read-only-view ${wrapperClass}`}>
      {data?.map((item) => (
        <div className={`read-only-item  ${item?.class}`}>
          <div className="flex items-center">
            {item.icon}
            <Text
              text={item?.label}
              className={`${item?.labelClass} ${item?.hideDefaultClass ? "" : "!text-[#898A90]"}`}
            />
          </div>
          <Text
            text={item?.text}
            className={`${item?.textClass} !text-[11px] !text-[#000]`}
            title={item?.title}
          />
          {item?.component && item?.component}
        </div>
      ))}
    </div>
  );
}
