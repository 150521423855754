import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../Utils/commonUtils";
import { redirectUser } from "../Utils/userUtils";
import renderErrorDashbaord from "../Services/Strapi/StrapiFallback";

function PublicRoute({ children }) {
  const userType = isLoggedIn();
  const strapiError = useSelector((state) => state.StrapiReducer);
  if (strapiError?.errorCode && strapiError?.errorCode !== 200)
    return renderErrorDashbaord(strapiError);
  return isLoggedIn() ? <Navigate to={redirectUser(userType)} /> : children;
}

export default PublicRoute;
