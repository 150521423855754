import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import { ReactComponent as ExpiredIcon } from "../../../Assets/icons/verification-icon.svg";
import "../ConfirmUser/style.scss";
import PageTitle from "../../Common/PageTitle";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls from "../../../Services/Strapi/urls";
import Loader from "../../Common/Loader";

function VerificationLinkExpired(props) {
  const pageHeader = props;
  const [strapiDataState, setStrapiDataState] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getStrapiData = async () => {
      const data = await fetchStrapiData(strapiUrls.VERIFICATION_LINK_EXPIRED);
      setStrapiDataState(data);
      setLoading(false);
    };

    getStrapiData();
  }, []);

  return (
    <div className="absolute top-[15%] left-[50%] translate-x-[-50%]">
      <PageTitle pageTitle={strapiDataState.pageTitle} pageHeader={pageHeader} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="verification_expired">
          <div className="expired_icons">
            <ExpiredIcon />
          </div>
          <h3>{strapiDataState?.heading}</h3>
          <p>{strapiDataState?.description}</p>
          <div className="text-center">
            <Link
              href={strapiDataState?.routelink?.href}
              className="button primary rounded-[4px] leading-none px-[50px] h-[35px] text-[14px] !font-medium"
            >
              {strapiDataState?.routelink?.hypertext}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default VerificationLinkExpired;
