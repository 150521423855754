import React from "react";
import "./style.scss";

function PublicFooter({ show, leftText = false, allRightsFooter, isCkAuto, strapiData }) {
  return (
    show && (
      <div
        className={`login-footer ${!leftText ? "single-content" : ""} ${
          isCkAuto ? "auto-module-footer" : "non-auto-module-footer"
        }`}
      >
        {leftText && (
          <div className="login-left">
            {strapiData?.pretext} <a href={strapiData?.href}>{strapiData?.hypertext}</a>
          </div>
        )}
        <div className="login-right">{allRightsFooter}</div>
      </div>
    )
  );
}

export default PublicFooter;
