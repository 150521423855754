import React, { useState } from "react";
import MultiLevelHeaderTable from "../../../CostBreakup/MultiLevelHeader";
import { getDatabaseData } from "../../../CostBreakup/constant";
import {
  CostBreakupHeaders,
  getMaxIndex,
  sortHandler,
} from "../../../CostBreakup/costbreakupUtils";
import ExcelDownload from "../../../../../Common/ExcelDownload";
import { downloadExcel, noop } from "../../../../../../Utils/commonUtils";

export default function DynomoDB(props) {
  const {
    cardsData,
    name,
    message = "",
    otherFooter = {},
    lastColumnSticky = true,
    totalVisible = true,
    onlyFirstColumnSticky = false,
    exportServiceCall = noop,
    showExcelButton = false,
  } = props;

  const [tableData, setTableData] = useState(cardsData?.data || []);
  const [ascending, setAscending] = useState(false);
  const [selectedSortHeader, setSelectedSortHeader] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const createHeaders = (service) => {
    const maxIndex = getMaxIndex(tableData);
    const Headers = tableData[maxIndex];

    tableData.map((item, index) => {
      Object.keys(Headers).map((internalItem) => {
        if (!(internalItem in item)) {
          const tableObj = tableData[index];
          tableObj[internalItem] = 0;
        }
        return internalItem;
      });
      return item;
    });

    const dbData = getDatabaseData();
    const selected = dbData?.enum?.[service];
    const Headerconstant = dbData?.headerConstant?.[selected];

    if (Headers) {
      const cost = CostBreakupHeaders(Headerconstant, Headers);
      return cost;
    }
    return null;
  };

  const sortHandlerFunc = (sortBy) => {
    let updateAscending = ascending;
    let sortedData = [];

    if (selectedSortHeader !== sortBy) {
      updateAscending = false;
    }
    sortedData = sortHandler(tableData, updateAscending, sortBy);
    setTableData(sortedData);
    setAscending(!updateAscending);
    setSelectedSortHeader(sortBy);
  };

  const exportDownload = async (service) => {
    setExcelLoading(true);
    try {
      const response = await exportServiceCall(service);
      downloadExcel(response);
    } finally {
      setExcelLoading(false);
    }
  };

  return (
    <div className="new-background costBreakCommonBackground w-full">
      <div className="flex items-center mx-[12px] my-[12px] justify-between">
        <p className="dataTransferheading">Top Resources</p>
        {showExcelButton && (
          <div className="excel-button">
            <ExcelDownload
              excelLoading={excelLoading}
              downloadHandler={() => {
                exportDownload(name);
              }}
              hasData={!tableData?.length}
            />
          </div>
        )}
      </div>
      {tableData?.length > 0 ? (
        <MultiLevelHeaderTable
          columns={createHeaders(name)}
          data={tableData}
          className=""
          customClass="mt-[5px] ml-[14px] mr-[12px]"
          total={cardsData?.totalCosts}
          others={otherFooter}
          sortHandlerParent={sortHandlerFunc}
          selectedSortHeader={selectedSortHeader}
          ascending={ascending}
          V2
          lastColumnSticky={lastColumnSticky}
          totalVisible={totalVisible}
          onlyFirstColumnSticky={onlyFirstColumnSticky}
        />
      ) : (
        <div className="no_data_found">
          {message === "" ? <p>{name && `No Data Available For ${name}`}</p> : <p>{message}</p>}
        </div>
      )}
    </div>
  );
}
