import sendBeaconWrapper from "../../../../Services/beacon";
import formatJsonToString from "../../../../Utils/formatJsonToString";
import getFormattedTimestamp from "../../../../Utils/formattedTimestamp";
import URLS from "../../../../Services/axios/urls";
import getPayload from "./getPayload";

// Function to create the payload
export const createPayload = ({ error, errorInfo, currentUser }) => {
  const { formattedDate } = getFormattedTimestamp();
  const affectedHostUrl = window.location.href;
  const formattedErrorInfo = formatJsonToString(errorInfo);
  const currentUserInformation = formatJsonToString(currentUser);
  const payload = getPayload({
    formattedDate,
    affectedHostUrl,
    formattedErrorInfo,
    currentUserInformation,
    error,
  });

  return payload;
};

// Function to send payload via fetch
export const sendPayload = async (payload) => {
  sendBeaconWrapper(URLS.CK_SLACK_ERROR_LOG, payload, false); // false is to send the fetch request instead of beacon
};
