const initialData = {
  dataStatus: "",
  fileUploaded: false,
  analyticsFiltersId: "",
  autoAnalyticsFiltersId: "",
};
const initAction = { type: "", payload: {} };

export default function MarketPlaceBulkUploadReducer(state = initialData, action = initAction) {
  switch (action.type) {
    case "SET_DATA_STATUS":
      return { ...state, dataStatus: action.payload };
    case "SET_FILE_UPLOADED":
      return { ...state, fileUploaded: action.payload };
    case "SET_UPLOADED_ANALYTICS_AUTO_FILTER_ID":
      return { ...state, autoAnalyticsFiltersId: action.payload };
    case "SET_UPLOADED_ANALYTICS_FILTER_ID":
      return { ...state, analyticsFiltersId: action.payload };
    default:
      return state;
  }
}
