import moment from "moment";
// import { getNAObjectForCard } from "../../cudosUtil";
import { getCurrencySymbol, noop } from "../../../../../../Utils/commonUtils";
import numberWithCommasandDecimalAndDollar from "../../../../../CK-Analytics/Dashboard/Commitments/Inventory/utils/NumberWithCommasAndDecimalAndDollar";

// export const getMonthCardsData = (cudosCardData = {}) => {
//   const data = new URLSearchParams(window.location.search);
//   const cardsData =
//     !!cudosCardData &&
//     cudosCardData?.analyticsTrendDto?.list?.map((individualOption) => ({
//       month: moment(individualOption?.monthYear, "MMM-YYYY").format("MMM YYYY"),
//       amount: individualOption?.currentMonthValue ? individualOption?.currentMonthValue : 0,
//       percentage: individualOption?.isForecastPresent
//         ? cudosCardData?.recommendationSummary?.percentageTrend || 0
//         : individualOption?.percentageTrend || 0,
//       differenceBetweenValues: individualOption?.differenceBetweenValues
//         ? individualOption?.differenceBetweenValues
//         : 0,
//       forecastValue: individualOption?.forecastValue || 0,
//       isPercentageBoxVisible: individualOption?.isPercentageBoxVisible,
//       isForecastPresent: individualOption?.isForecastPresent,
//     }));

//   for (let i = cardsData.length; i < 3; i += 1) {
//     cardsData.unshift(
//       getNAObjectForCard(
//         moment(`${data.get("month")} ${data.get("year")}`, "MM YYYY").subtract(i, "months")
//       )
//     );
//   }
//   const recommendationSummary = {
//     savings: cudosCardData?.recommendationSummary?.savings || 0,
//     link: cudosCardData?.recommendationSummary?.link,
//     heading: cudosCardData?.recommendationSummary?.serviceName || "",
//     description: cudosCardData?.recommendationSummary?.titleLine || "",
//   };
//   return { cardsData, recommendationSummary };
// };

export const handleKeyPress = (event, item, fn, activeGranularity) => {
  if (event.key === "Enter" || event.key === " ") {
    if (activeGranularity) {
      fn(item, activeGranularity);
    } else {
      fn(item);
    }
  }
};

export const getUpdatedGraphConstantData = ({
  graphName = "",
  reportGranularity = "",
  type = "",
  data,
  loading,
  graphDataConstant = [],
  graphData = [],
}) => {
  const graphDataConstantCopy = [...graphDataConstant];

  const updatedGraphData = graphData.map((graph, index) => {
    if (graph?.name === graphName) {
      const updatedGraphDataConstantItem = graphDataConstantCopy[index];
      if (type === "calendarType") {
        updatedGraphDataConstantItem.calendarType = {
          text: reportGranularity === "daily" ? "Last 30 days" : "Last 180 days",
          class: "!text-[9px] !text-[#B0B0B0] font-normal mr-[11px]",
        };
        updatedGraphDataConstantItem.xAxisName = reportGranularity === "daily" ? "Days" : "Months";
        updatedGraphDataConstantItem.active = reportGranularity.toUpperCase();
      } else if (type === "costUsageType") {
        updatedGraphDataConstantItem.active = reportGranularity;
        updatedGraphDataConstantItem.prefix =
          reportGranularity === "COST" ? `${getCurrencySymbol()}` : "";
        const selected = reportGranularity?.toLowerCase();
        updatedGraphDataConstantItem.yAxisName =
          selected.charAt(0).toUpperCase() + selected.slice(1);
      } else if (type === "excelLoading") {
        updatedGraphDataConstantItem.excelLoading = loading;
      } else {
        updatedGraphDataConstantItem.type = reportGranularity;
      }
      if (type !== "excelLoading") {
        updatedGraphDataConstantItem.loading = loading;
      }
      graphDataConstantCopy[index] = updatedGraphDataConstantItem;
      return type !== "excelLoading" ? { ...graph, data } : graph;
    }
    return graph;
  });
  return [updatedGraphData, graphDataConstantCopy];
};

export const getFooterData = (data, headers = []) => {
  const footerObj = {};
  headers.forEach((header, index) => {
    if (index === 0) footerObj[header.key] = "Total";
    else if (index === headers.length - 1)
      footerObj[header.key] = numberWithCommasandDecimalAndDollar(
        data?.data?.data?.totalCosts?.cost || 0
      );
    else footerObj[header.key] = "";
  });

  return footerObj;
};

export const setGraphContent = ({
  graphName = "",
  graphDataConstant = [],
  graphData = [],
  loading,
  setGraphData = noop,
  setGraphDataConstant = noop,
}) => {
  const [updatedLoadingGraphData, graphDataLoadingConstantCopy] = getUpdatedGraphConstantData({
    graphName,
    reportGranularity: "",
    type: "excelLoading",
    data: [],
    loading,
    graphDataConstant,
    graphData,
  });
  const updateExcelLoadingState = {
    graphData: updatedLoadingGraphData,
    graphDataConstant: graphDataLoadingConstantCopy,
  };
  setGraphData(updateExcelLoadingState?.graphData);
  setGraphDataConstant(updateExcelLoadingState?.graphDataConstant);
};

export const getRouteUrlWithoutSelected = (navigate = noop, accountDate = {}, baseUrl = "") => {
  const data = new URLSearchParams(window.location.search);
  const query = localStorage.getItem("query");

  // Check if "month" and "year" query params exist in the current URL
  if (data.get("month") && data.get("year")) {
    const updatedUrl = `month=${data.get("month")}&year=${data.get("year")}`;

    setTimeout(() => {
      navigate(`${baseUrl}?${updatedUrl}`);
    }, 50);
  } else {
    // Default "month" and "year" values based on `accountEndDate`
    const defaultMonth = moment(accountDate?.accountEndDate).format("MM");
    const defaultYear = moment(accountDate?.accountEndDate).format("YYYY");

    let updatedUrl = query ? `${query}` : `?month=${defaultMonth}&year=${defaultYear}`;

    // Check if the baseUrl contains "dashboard/database"
    if (baseUrl.includes("dashboard/database")) {
      updatedUrl = `?selected=elasticache&month=${defaultMonth}&year=${defaultYear}`;
    }

    setTimeout(() => {
      navigate(`${baseUrl}${updatedUrl}`, {
        month: defaultMonth,
        year: defaultYear,
      });
    }, 50);
  }
};
