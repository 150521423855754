import React, { useEffect, useState } from "react";
import Text from "../../FormConfig/Form/Components/Text";
import showError from "./utils";

// eslint-disable-next-line max-lines-per-function
export default function PartnerEmailSettings({
  value,
  handleChange,
  error,
  label,
  name,
  className,
  options,
  disabled,
  formValues,
  onTabChange,
}) {
  const [touched, setTouched] = useState({});
  const [selected, setSelected] = useState(value?.setting);
  const [customNoreplyEmail, setCustomNoreplyEmail] = useState(
    value?.setting === "Custom Emails" ? value?.forNoReply : ""
  );
  const [customSupportEmail, setCustomSupportEmail] = useState(
    value?.setting === "Custom Emails" ? value?.forSupport : ""
  );
  const [customSenderName, setCustomSenderName] = useState(
    value?.setting === "Custom Emails" ? value?.senderName : ""
  );

  useEffect(() => {
    setSelected(value?.setting);

    if (value?.setting !== "Custom Emails") {
      setTouched({});
    }
  }, [value]);

  useEffect(() => {
    if (formValues.partneremailsettings?.resetClicked) {
      setCustomNoreplyEmail(value?.setting === "Custom Emails" ? value?.forNoReply : "");
      setCustomSupportEmail(value?.setting === "Custom Emails" ? value?.forSupport : "");
      setCustomSenderName(value?.setting === "Custom Emails" ? value?.senderName : "");
    }
  }, [formValues.partneremailsettings?.resetClicked]);

  const handleSelect = (e, option) => {
    const isDefault = option.label.includes("Default");
    let partnerSettings = {};

    if (isDefault) {
      partnerSettings = {
        setting: "Default Emails",
        senderName: option.senderName.value,
        forSupport: option.forSupport.value,
        forNoReply: option.forNoReply.value,
      };
    } else {
      partnerSettings = {
        setting: "Custom Emails",
        senderName: customSenderName,
        forSupport: customSupportEmail,
        forNoReply: customNoreplyEmail,
      };
    }

    setSelected(option.label);
    handleChange({ target: { value: partnerSettings, name } });
    setTimeout(onTabChange, 100);
  };

  const renderOrSeperator = () => <p className="orSeperator">Or</p>;
  const renderRadioButtons = (option) => (
    <>
      <input
        type="radio"
        id={option.label}
        name={option.label}
        checked={selected === option.label}
        value={value}
        className="!m-0"
        onChange={(e) => handleSelect(e, option)}
        disabled={disabled}
      />
      <label
        className={`option-label !font-medium ${option.label === selected && "selected"} ${
          disabled && "disable"
        }`}
        htmlFor={option.label}
      >
        {option.label}
      </label>
    </>
  );

  const handleEmailsInput = (e, item) => {
    const { target } = e;
    if (item === "forNoReply") {
      setCustomNoreplyEmail(target.value);
    }
    if (item === "forSupport") {
      setCustomSupportEmail(target.value);
    }
    if (item === "senderName") {
      setCustomSenderName(target.value);
    }

    const updatedValues = {
      ...value,
      senderName: customSenderName,
      forNoReply: customNoreplyEmail,
      forSupport: customSupportEmail,
    };

    updatedValues[item] = target.value;

    handleChange({
      target: {
        value: updatedValues,
        name,
      },
    });
  };

  const handleBlur = (e, inputName) => {
    setTouched({ ...touched, [inputName]: true });
    handleEmailsInput(e, inputName);
  };

  const getEmailValue = (email) => (selected === "Custom Emails" ? email : "");

  const renderOptionInput = (option, key, state) => (
    <>
      <span className={`key ${option.label.includes("Default") && "default"}`}>
        {option[key].key}
      </span>
      <span className={`value ${option.label === selected && "selected"}`}>
        {option.label.includes("Default") ? (
          option[key].value
        ) : (
          <>
            <input
              className={` ${error?.[key] && touched?.[key] && "error_box"} ${
                !disabled && "!bg-white"
              }`}
              disabled={disabled || selected?.includes("Default")}
              placeholder={key === "senderName" ? "Enter Sender Name" : "Enter Email"}
              value={getEmailValue(state)}
              onChange={(e) => handleEmailsInput(e, key)}
              onBlur={(e) => handleBlur(e, key)}
            />
            {showError(error, key, touched)}
          </>
        )}
      </span>
    </>
  );

  return (
    <div className={`partner-email-settings-wrapper ${className} !p-[0px]`}>
      {label && <Text text={label} className="upload-label !text-[16px]" />}
      <div className="partner-email-settings flex items-center !justify-start">
        {options.map((option) => (
          <>
            <div className="option py-[5px]">
              <div className="flex items-center">{renderRadioButtons(option)}</div>
              <div
                className={`option-input  ${disabled && "disable"} ${
                  option.label.includes("Default") && "default"
                } ${option.label === selected && "selected"}`}
              >
                {renderOptionInput(option, "senderName", customSenderName)}
                {renderOptionInput(option, "forSupport", customSupportEmail)}
                {renderOptionInput(option, "forNoReply", customNoreplyEmail)}
              </div>
            </div>
            {renderOrSeperator()}
          </>
        ))}
      </div>
    </div>
  );
}
