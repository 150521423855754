import moment from "moment";
import React from "react";
import { ReactComponent as DragIcon } from "../../../Assets/icons/DragIcon.svg";
import EDPDiscountTypeTooltip, { addPercentageToFlatDiscount, descriptionTooltip } from "./util";
import { ConfigDiscountsCalendar } from "../../FormConfig";
import { newTemplateArr } from "./newData";
import ListPopup from "../../Common/ListPopup";

export const discountTypeArr = [
  { value: "edp", label: "EDP" },
  { value: "flat", label: "Flat" },
  { value: "reserved_discount", label: "Reserved" },
  { value: "cloudfront", label: "Cloudfront" },
];

export const discountSchemeArr = [
  { value: "private_pricing", label: "Private Pricinig" },
  { value: "savings_bundle", label: "Savings Bundle" },
  { value: "flat_rate", label: "Flat Rate" },
];

export const discountRateArr = [
  { value: "relative", label: "Relative" },
  { value: "flat", label: "Flat" },
];
export const discountRateValueArr = [
  { value: "1yearAllUpfront", label: "1 Year/ All Upfront" },
  { value: "1yearPartialUpfront", label: "1 Year/ Partial Upfront" },
  { value: "1yearNoUpfront", label: "1 Year/ No Upfront" },
  { value: "3yearAllUpfront", label: "3 Year/ All Upfront" },
  { value: "3yearPartialUpfront", label: "3 Year/ Partial Upfront" },
  { value: "3yearNoUpfront", label: "1 Year/ No Upfront" },
];
export const inputFieldsInitialState = {
  name: { value: "", touched: false },
  description: { value: "", touched: false },
};
export const templateArr = [
  { value: "template1", label: "Template 1" },
  { value: "template2", label: "Template 2" },
  { value: "template3", label: "Template 3" },
];

export const tenureTableData = [
  {
    tenure: "1<sup>st</sup> Year",
    commitment: "",
    discount: "",
    credits: "",
  },
];

export const RDCommitmentTableData = (customerName) => [
  {
    id: 1,
    discountRateApplication: discountRateArr[0],
    discountRateApplicationValue: "",
    discountPercentage: "",
    commitmentStartDate: moment(ConfigDiscountsCalendar?.startDate).format("DD-MMM-YYYY"),
    selectTemplate: newTemplateArr?.[customerName]?.[0],
    isAddedEnabled: true,
    isAdded: false,
  },
];
export const RDCommitmentInitialData = (customerName) => ({
  // id: 1,
  discountRateApplication: discountRateArr[0],
  discountRateApplicationValue: "",
  discountPercentage: "",
  commitmentStartDate: moment(ConfigDiscountsCalendar?.startDate).format("DD-MMM-YYYY"),
  selectTemplate: newTemplateArr?.[customerName]?.[0],
  isAddedEnabled: true,
  isAdded: false,
});

export const selectedDiscountsTableData = [
  {
    cbu: "CBU1",
    discountName: "RD1",
    startDate: "21-July-2023",
    endDate: "21-July-2024",
    tenure: 1,
    comments: "test comment",
    disount: 12,
    credit: 22,
    templateName: "EC2-Windows-OnDemand",
  },
  {
    cbu: "CBU1",
    discountName: "RD1",
    startDate: "21-July-2023",
    endDate: "21-July-2024",
    tenure: 1,
    comments: "test comment",
    disount: 12,
    credit: 22,
    templateName: "EC2-Windows-OnDemand",
  },
  {
    cbu: "CBU1",
    discountName: "RD1",
    startDate: "21-July-2023",
    endDate: "21-July-2024",
    tenure: 1,
    comments: "test comment",
    disount: 12,
    credit: 22,
    templateName: "EC2-Windows-OnDemand",
  },
  {
    cbu: "CBU1",
    discountName: "RD1",
    startDate: "21-July-2023",
    endDate: "21-July-2024",
    tenure: 1,
    comments: "test comment",
    disount: 12,
    credit: 22,
    templateName: "EC2-Windows-OnDemand",
  },
  {
    cbu: "CBU1",
    discountName: "RD1",
    startDate: "21-July-2023",
    endDate: "21-July-2024",
    tenure: 1,
    comments: "test comment",
    disount: 12,
    credit: 22,
    templateName: "EC2-Windows-OnDemand",
  },
];
export const generateRandomId = () => {
  const randomId = Math.floor(Math.random() * 900) + 100;
  return String(randomId);
};

export const ConfigureDiscountListing = {
  EDP: {
    headers: [
      {
        Header: "Discount Name",
        accessor: "discountName",
        sortedIcon: true,
        render: (item) => descriptionTooltip(item, "discountName"),
        customClass: "!relative",
      },
      {
        Header: "Template Applied",
        accessor: "templateApplied",
        render: (item) => descriptionTooltip(item, "templateApplied"),
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Tenure",
        accessor: "tenure",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Year",
        accessor: "year",
        subLegend: "Active Year Summary",
        customClass: "!relative sub-legend-header-configure",
        childClass: "!w-[360%]",
        sortedIcon: true,
      },
      {
        Header: "Commitment",
        accessor: "commitment",
        subLegend: "",
        customClass: "!relative sub-legend-header-configure",
        sortedIcon: true,
      },
      {
        Header: "Discount",
        accessor: "discount",
        subLegend: "",
        customClass: "!relative sub-legend-header-configure",
        sortedIcon: true,
      },
      {
        Header: "Credits",
        accessor: "credits",
        subLegend: "",
        customClass: "!relative sub-legend-header-configure",
        sortedIcon: true,
      },
      {
        Header: "Term Level Summary",
        accessor: "termLevelSummary",
        render: (item) => EDPDiscountTypeTooltip(item, "EDP"),
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "ChargeBack Type",
        accessor: "chargeBackType",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "ChargeBack Account ID",
        accessor: "chargeBackAccountID",
        component: ListPopup,
        props: {
          dataCount: 1,
        },
        customClass: "!relative",
        sortedIcon: true,
      },
      // {
      //   Header: "Discount Type",
      //   accessor: "discountType",
      //   sortedIcon: true,
      //   render: (item) => EDPDiscountTypeTooltip(item),
      // },
      // {
      //   Header: "Discount",
      //   accessor: "discountPercentage",
      //   render: (item) => dicountToolip(item),
      // },
      // {
      //   Header: "Template Name",
      //   accessor: "templateName",
      //   render: (item) => descriptionTooltip(item, "templateName"),
      // },
      {
        Header: "Start Date",
        accessor: "starts",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
      {
        Header: "End Date",
        accessor: "expires",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
      // {
      //   Header: "Priority",
      //   accessor: "priority",
      //   sortedIcon: true,
      // },
    ],
    activeCount: 4,
    totalCount: 21,
    data: [],
  },
  PPA: {
    headers: [
      // {
      //   Header: "CBU Name",
      //   accessor: "cbuName",
      // },
      {
        Header: "Discount Name",
        accessor: "discountName",
        sortedIcon: true,
        render: (item) => descriptionTooltip(item, "discountName"),
        customClass: "!relative",
      },
      // {
      //   Header: "Template Applied",
      //   accessor: "templateApplied",
      //   render: (item) => descriptionTooltip(item, "templateApplied"),
      //   customClass: "!relative",
      //   sortedIcon: true,
      // },
      {
        Header: "Year",
        accessor: "year",
        subLegend: "Active Year Summary",
        customClass: "!relative sub-legend-header-configure",
        childClass: "!w-[350%]",
        sortedIcon: true,
      },
      {
        Header: "Data Transfer",
        accessor: "dataTransfer",
        subLegend: "",
        customClass: "!relative sub-legend-header-configure",
        sortedIcon: true,
      },
      {
        Header: "Data Transfer Unit Type",
        accessor: "dataTransferUnitType",
        subLegend: "",
        customClass: "!relative sub-legend-header-configure",
        sortedIcon: true,
      },
      {
        Header: "Term Level Summary",
        accessor: "termLevelSummary",
        render: (item) => EDPDiscountTypeTooltip(item, "PPA"),
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "ChargeBack Type",
        accessor: "chargeBackType",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "ChargeBack Account ID",
        accessor: "chargeBackAccountID",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Start Date",
        accessor: "starts",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
      {
        Header: "End Date",
        accessor: "expires",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
    ],
    activeCount: 4,
    totalCount: 21,
    data: [],
  },
  RD: {
    headers: [
      {
        Header: "CBU(s)",
        accessor: "cbuName",
        sortedIcon: true,
      },
      {
        Header: "Discount Name",
        accessor: "discountName",
        sortedIcon: true,
        render: (item) => descriptionTooltip(item, "discountName"),
      },
      {
        Header: "Template Applied",
        accessor: "templateApplied",
        render: (item) => descriptionTooltip(item, "templateApplied"),
        sortedIcon: true,
      },
      {
        Header: "Discount Application",
        accessor: "discountApplication",
        sortedIcon: true,
      },
      {
        Header: "Discount Application Detail",
        accessor: "discountApplicationDetail",
        sortedIcon: true,
        render: (item) => addPercentageToFlatDiscount(item),
      },

      {
        Header: "Start Date",
        accessor: "starts",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap",
      },
      {
        Header: "End Date",
        accessor: "expires",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap",
      },
    ],
    activeCount: 4,
    totalCount: 21,
    data: [],
  },
  "discount-and-charges": {
    headers: [
      {
        Header: "Name",
        accessor: "name",
        sortedIcon: true,
        customClass: "!relative",
      },
      {
        Header: "Description",
        accessor: "description",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "CBU",
        accessor: "cbu",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Type",
        accessor: "type",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Start Date",
        accessor: "starts",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
      {
        Header: "End Date",
        accessor: "expires",
        sortedIcon: true,
        customClass: "!relative",
        bodyClass: "whitespace-nowrap",
      },
      {
        Header: "Fixed Cost",
        accessor: "fixedCost",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "% value",
        accessor: "percentageValue",
        customClass: "!relative",
        sortedIcon: true,
      },
      {
        Header: "Template Applied",
        accessor: "templateApplied",
        render: (item) => descriptionTooltip(item, "templateApplied"),
        customClass: "!relative",
        sortedIcon: true,
      },
      // {
      //   Header: "Priority",
      //   accessor: "priority",
      //   sortedIcon: true,
      // },
    ],
    activeCount: 4,
    totalCount: 21,
    data: [],
  },
};

export const getOnDemandDragCardHtml = (opacity, data, ref, handlerId) => (
  <div className="flex w-[full] dragCardHTML ">
    <div style={{ opacity }} className={`dragCard ${data?.new ? "newDragCard" : ""}`}>
      <div className="flex justify-between w-[100%] card">
        {Object.keys(data).map(
          (item, index) =>
            index !== 0 &&
            item !== "checked" &&
            item !== "name" &&
            item !== "description" &&
            item !== "type" &&
            item !== "new" &&
            item !== "commitments" && (
              <p className="flex-1 w-[12%] text-center !text-[#000] font-medium break-words">
                {item === "discountType" ? EDPDiscountTypeTooltip(data) : <span>{data[item]}</span>}
              </p>
            )
        )}
      </div>
    </div>
    <div ref={ref} data-handler-id={handlerId} className="dragIcon mx-5">
      <DragIcon />
    </div>
  </div>
);
