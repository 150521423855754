/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import axios from "axios";
// import Text from "../../FormConfig/Form/Components/Text";
import { Checkbox, FormControlLabel } from "@mui/material";
import withRouter from "../../Common/WithRouter";
import PageTitle from "../../Common/PageTitle";
import commonService from "../../../Services/commonService";
import Loader from "../../Common/Loader";
import InfiniteScroll from "../../Common/InfiniteScroll";
import FormWrapper from "../../Common/FormWrapper";
import {
  discountEDPTemplateValidations,
  listSelectorValidation,
  serviceTypeValidation,
  templateDescription,
  templateName,
} from "../../FormConfig";
import "./style.scss";
import { getToast, toCamelCase } from "../../../Utils/commonUtils";
import Routes from "../../../Config/Routes";
import { TEMPLATE_TYPE } from "../../../Constants/Constant";

let axiossource;

class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configData: [],
      acknowledged: false,
      formData: {
        serviceType: { label: "EC2", value: "EC2" },
      },
      errorValueData: undefined,
      loading: true,
      configuration: {},
    };
    axiossource = axios.CancelToken.source();
  }

  async componentDidMount() {
    const {
      props: { setPermission, accessList },
      state: { formData },
    } = this;
    setPermission(accessList);
    const updatedFormData = { ...formData };
    const UrlQuery = new URLSearchParams(window.location.search);
    const queryId = UrlQuery.get("id");
    if (queryId) {
      const payload = {
        id: queryId,
      };
      const DataById = await commonService.getDiscountConfigById(payload);
      const {
        data: { data },
      } = DataById;

      const IdData = data;
      updatedFormData.serviceType = { label: IdData?.serviceType, value: IdData?.serviceType };
      updatedFormData.templateName = `Copy of ${IdData?.name}`;
      updatedFormData.templateType = TEMPLATE_TYPE.find(
        (item) => item?.label === IdData?.templateType
      );

      updatedFormData.description = IdData?.description;
      updatedFormData.listSelector = { ...this.convertData(IdData?.config) };

      this.setState({
        formData: updatedFormData || {},
      });
    }
    let updatedService = null;
    if (updatedFormData?.templateType?.value === "RESERVATION_TYPE") {
      updatedService = updatedFormData?.serviceType?.value;
    }
    const response = await commonService.getDiscountConfigApi({
      templateType: updatedFormData?.templateType?.value,
      serviceType: updatedService,
    });
    const {
      data: { dimensionConfig = [], templateType = TEMPLATE_TYPE },
    } = response?.data || {};
    discountEDPTemplateValidations.options = templateType;
    this.setState({
      configData: dimensionConfig,
      loading: false,
      configuration: response?.data?.data,
    });
  }

  componentWillUnmount() {
    if (axiossource) {
      axiossource.cancel("Landing Component got unmounted");
    }
  }

  createPayload = (data) => {
    const convertedData = {
      name: data?.templateName,
      description: data?.description,
      templateType: data?.templateType?.value,
      discountTemplateGenerationType: "CUSTOM",
      serviceType:
        data?.templateType?.value === "RESERVATION_TYPE" ? data?.serviceType?.value : null,
      config: [],
    };

    const { listSelector } = data;

    Object.keys(listSelector).forEach((key) => {
      const { value, displayName, included } = listSelector[key];
      const groupKey = toCamelCase(key);

      const configItem = {
        groupKey,
        displayName,
        excludeSelected: !included,
        items: value,
      };

      convertedData.config.push(configItem);
    });

    return convertedData;
  };

  convertData = (data) => {
    const updatedConfig = {};
    data.forEach((item) => {
      updatedConfig[item.displayName] = {
        value: item?.items,
        included: !item?.excludeSelected,
        displayName: item?.displayName,
      };
    });
    return updatedConfig;
  };

  handleSubmit = async (props) => {
    const response = await commonService.saveDiscountConfig(this.createPayload(props));

    const {
      data: { message },
    } = response;

    getToast("success", message);
    this.handleCancel();
  };

  handleCancel = () => {
    const {
      props: { navigate },
    } = this;

    navigate(Routes.TEMPLATE_ON_DEMAND_DISCOUNTS, {});
  };

  leftSideItems = () => {
    const { acknowledged, errorValueData } = this.state;
    return (
      <FormControlLabel
        className="acknowledge_wrapper"
        control={
          <Checkbox
            checked={acknowledged}
            onChange={() => {
              this.setState(
                {
                  acknowledged: !acknowledged,
                },
                () => {
                  this.handleOnInputChange(null, null, errorValueData);
                }
              );
            }}
          />
        }
        label="I acknowledge that I have reviewed the changes."
      />
    );
  };

  handleOnInputChange = async (event, formData, errorValues) => {
    const {
      acknowledged,
      formData: { serviceType, templateType },
    } = this.state;
    const { target: { name = "", value = "" } = {}, eventType } = event || {};
    const UrlQuery = new URLSearchParams(window.location.search);
    const queryId = UrlQuery.get("id");
    const updatedForm = { ...formData };

    if (eventType !== "blur") {
      if (name === "templateType" || name === "serviceType") {
        delete updatedForm.listSelector;
        this.setState({
          isLoading: true,
          formData: updatedForm,
        });
        let updatedService = null;
        const updatedTemplate = name === "templateType" ? value.value : templateType?.value;
        if (updatedTemplate === "RESERVATION_TYPE") {
          updatedService = name === "serviceType" ? value.value : serviceType?.value;
        }

        const response = await commonService.getDiscountConfigApi({
          templateType: updatedTemplate,
          serviceType: updatedService,
        });
        this.setState({
          configData: response?.data?.data?.dimensionConfig || [],
          isLoading: false,
        });
      }
      this.setState({
        error: !(
          errorValues !== undefined &&
          acknowledged &&
          Object.keys(errorValues)?.length === 0
        ),
        errorValueData: errorValues,
      });
      if (queryId && !errorValues && acknowledged) {
        this.setState({
          error: false,
          errorValueData: {},
        });
      }
    }
  };

  render() {
    const {
      state: {
        isLoading,
        numberOfPages,
        isUpdating,
        // configData: [data1],
        configData,
        error,
        formData,
        loading,
        configuration,
      },
      props: { pageTitle, pathArray, hasPermission },
      handleOnInputChange,
      handleSubmit,
      handleCancel,
    } = this;
    const { state } = this.props || {};
    const { id = "" } = state || {};

    const UrlQuery = new URLSearchParams(window.location.search);
    const queryId = UrlQuery.get("id");
    const updatedDiscountEDPTemplateValidations = { ...discountEDPTemplateValidations };
    if (queryId) {
      updatedDiscountEDPTemplateValidations.className = `${discountEDPTemplateValidations.className} disabled`;
    }

    const pathArrayClone = pathArray;
    pathArrayClone[1] = { ...pathArrayClone[1], state: { id } };
    if (queryId) {
      pathArrayClone[2] = { label: "Clone Discount Template" };
    } else {
      pathArrayClone[2] = { label: "Create Discount Template" };
    }

    let pageTitleClone = pageTitle;

    if (queryId) {
      pageTitleClone = "Clone Discount Template";
    } else {
      pageTitleClone = "Create Discount Template";
    }

    listSelectorValidation.configData = configData;

    // creating form input variable to feed in form-wrapper
    const formConfiguration = [updatedDiscountEDPTemplateValidations];

    // if (formData?.templateType?.value === "CHARGES_AND_REFUNDS") {
    //   // Add a service variable in this
    //   serviceTypeValidation.options = [
    //     { label: "Charge", value: "Charge" },
    //     { label: "Refund", value: "Refund" },
    //   ];
    //   formConfiguration?.push(serviceTypeValidation);
    // }

    if (formData?.templateType?.value === "RESERVATION_TYPE") {
      // Add a service variable in this
      serviceTypeValidation.options =
        configuration?.serviceTypeList?.map((item) => ({
          label: item,
          value: item,
        })) || [];
      formConfiguration?.push(serviceTypeValidation);
    }
    formConfiguration?.push(templateName, templateDescription, listSelectorValidation);

    return (
      <div className="dashboard_wrapper Create-Discount-Template">
        <InfiniteScroll height="full">
          <PageTitle pageTitle={pageTitleClone || ""} />
          <div className={` ${!numberOfPages ? "h-[calc(100%-90px)]" : ""} list-wrapper`}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!loading ? (
                  <FormWrapper
                    inputFields={[...formConfiguration]}
                    handleSubmit={handleSubmit}
                    currentFormValues={formData}
                    onChangeHandler={handleOnInputChange}
                    cancelBtnFn={handleCancel}
                    pageTitle={pageTitleClone || ""}
                    submitButtonText={queryId ? "Clone" : "Submit"}
                    crumbs={[...pathArrayClone]}
                    titleBottomBorder
                    showCancelBtn
                    goBack="/template/on-demand-discounts"
                    showReset={false}
                    overrideFormErrors={error}
                    allMandatory={false}
                    onInitHandler={(values, errors) => {
                      this.setState({ errorValueData: errors });
                    }}
                    showErrorOnLoad={!!queryId}
                    hasPermission={hasPermission}
                  >
                    {this.leftSideItems()}
                  </FormWrapper>
                ) : null}

                {isUpdating && <Loader popup />}
              </>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData || {},
});

export default connect(mapStateToProps, null)(withRouter(CreateTemplate));
