import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../Utils/commonUtils";
import Routes from "./Routes";

function PrivateRoute({ children }) {
  const checkLogout = localStorage.getItem("logoutButtonClick");
  const url = window.location;
  return isLoggedIn() ? (
    children
  ) : (
    <Navigate to={checkLogout ? Routes.LOGIN : `${Routes.LOGIN}?redirect-url=${url.pathname}`} />
  );
}

export default PrivateRoute;
