import Routes from "../../Config/Routes";

const getApiNameFromUrl = () => {
  const { pathname } = window.location;
  if (pathname?.toLowerCase() === Routes.CONFIRMUSER?.toLowerCase()) {
    return "CREATE_PASSWORD";
  }
  if (pathname?.toLowerCase() === Routes.RESETPASSWORD?.toLowerCase()) {
    return "RESET_PASSWORD";
  }
  return "";
};

export default getApiNameFromUrl;
