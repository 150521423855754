// Utility function to format a Date object into a readable string
const getFormattedTimestamp = (date = new Date()) => {
  // Helper function to pad numbers with leading zeros
  const pad = (number, length = 2) => String(number).padStart(length, "0");

  // Extract individual date and time components
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds(), 3);

  // Construct and return the formatted date string
  return {
    date,
    formattedDate: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`,
  };
};

export default getFormattedTimestamp;
