import encryptedPassCode from "../../Utils/passwordEncryptionUtil";
import { CommonServiceNameForResetConfirm } from "../Dashboard/UserManagement/util";
import { getCookieByName, getLocalStorageUserData } from "../../Utils/commonUtils";

const changePasswordIcon = (statePasswordValidations, inputType) => {
  const type = inputType === "password" ? "text" : "password";

  const updatedValidation = { ...statePasswordValidations };

  updatedValidation.selectedVisibilityIcon = updatedValidation.iconList[type];
  updatedValidation.type = type;
  return updatedValidation;
};

const getLoginPayload = (values) => ({
  email: values.email?.toLowerCase(),
  password: encryptedPassCode(values.password),
});

const getRegisterPayload = (values) => ({
  email: values?.email?.toLowerCase(),
  firstName: values?.firstName,
  lastName: values?.lastName,
  companyName: values?.companyName,
});

const getPageName = (partnerName) => {
  if (partnerName === "CKAuto") {
    return "CK Auto";
  }
  return partnerName;
};

const getSignUpContext = (partnerName) => ({
  hutk: getCookieByName("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
  pageUri: `${window.location.origin}${window.location.pathname}`,
  pageName: `${getPageName(partnerName)} Sign Up`,
});

const getForgetPassPayload = (values) => ({
  email: values.email?.toLowerCase(),
});

const getResetPassPayload = (values) => {
  const query = new URLSearchParams(window.location.search);
  const passwordresettoken = query.get("code");
  const passwordresetemail = query.get("email");
  const confirmUsertoken = query.get("confirmation_code");
  const confirmUserEmail = query.get("user_name");

  if (CommonServiceNameForResetConfirm() === "confirmSignUpV2") {
    return {
      code: confirmUsertoken,
      password: encryptedPassCode(values?.password),
      email: confirmUserEmail?.toLowerCase(),
    };
  }

  return {
    passwordResetToken: passwordresettoken,
    newPassword: encryptedPassCode(values?.password),
    email: passwordresetemail?.toLowerCase(),
  };
};

const getSwitchRolePayload = (values) =>
  values.selectedRole === "PARTNER"
    ? {
        userType: values.selectedRole,
        partnerId: values.selectedPartner,
        // role: values.role,
      }
    : {
        userType: values.selectedRole,
        partnerId: values.selectedPartner,
        customerId: values.selectedCustomer,
        // role: values.role,
      };

export const getSwitchBackPayload = () => {
  const userDataAfterSwitch = getLocalStorageUserData();
  let switchBackPayload = {};
  // todo roleaccess switch Role || remove Role || code Commentted
  // const selectedItemRole = (role) => `${role?.toUpperCase().replace(" ", "_")}`;
  if (userDataAfterSwitch?.currentUser && userDataAfterSwitch?.currentUser?.type === "PARTNER") {
    switchBackPayload = {
      // role: selectedItemRole(userDataAfterSwitch?.currentUser?.role),
      userType: "PARTNER",
      partnerId: userDataAfterSwitch?.currentUser?.id,
    };
  } else {
    switchBackPayload = {
      // role: selectedItemRole(userDataAfterSwitch?.loggedInUser?.roleType)?.split(["-"])[1],
      userType: "CLOUDONOMICS",
    };
  }

  return switchBackPayload;
};

export const updatePartnerFavicon = (fabIcon) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = fabIcon?.data?.data ? `${fabIcon.data.data}?timestamp=${Date.now()}` : "/cklens.ico";
};

export {
  changePasswordIcon,
  getLoginPayload,
  getForgetPassPayload,
  getResetPassPayload,
  getSwitchRolePayload,
  getRegisterPayload,
  getSignUpContext,
};

export const getWrapperClass = (classUpdated = "", signUpCheck = false) => {
  if (signUpCheck) {
    return classUpdated;
  }
  return "";
};

export const headingTexts = {
  "ck-auto": {
    heading: "Login to view your potential savings",
    subHeading: "",
  },
  default: {
    heading: "Login",
    subHeading: "",
  },
};
