/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-prototype-builtins */
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { optionalRules } from "../../../FormConfig";
import patterns from "../../../FormConfig/Form/patterns";
import { ReactComponent as InfoIcon } from "../../../../Assets/images/ic-info.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/icons/eye-open.svg";

/* eslint-disable import/prefer-default-export */

const requiredPostiveNumberOnly = (name = "") => ({
  rules: {
    required: true,
    pattern: !name?.includes("%")
      ? patterns.numberGreaterThanZero
      : patterns.positiveNumberLessThen100,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
});

const positiveNumbers = (name = "") => ({
  rules: {
    required: false,
    pattern: !name?.includes("%")
      ? patterns.numberGreaterThanZero
      : patterns.positiveNumberLessThen100,
  },
  messages: {
    pattern: "Please enter a valid {{fieldName}}",
  },
});

const requiredRules = {
  rules: {
    required: true,
  },
  messages: {
    required: "This field is required",
  },
};

export const getSupportTooltipStructure = (type, listing = false) => {
  const headingStyle =
    "font-medium text-[12px]  border-b border-solid border-[#DDDFE8] !text-[#151E33] py-[7px] px-[10px] !mt-[0] tooltip-heading bg-[#F0F6FF]";

  const contentHeadingStyle =
    "!font-normal content-heading !text-[#151E33] text-[12px] pb-[10px] border-b border-solid border-[#DDDFE8] w-[107px] text-right";

  const contentChargeStyle = "!font-normal !text-[#151E33] text-[11px] pt-[4px]";

  const contentDiscountStyle = "!font-normal text-[11px] !text-[#46AF49]";

  const tooltipIconStyle = "ml-[5px] inline-block relative tooltip-box";

  const exampleStyle = "text-[#151E33] text-[10px] px-[10px] pt-[5px]";

  const renderIcon = listing ? (
    <EyeIcon className="eye-icon inline-block h-[12px] w-[18px] text-[#0A3CA2]" />
  ) : (
    <InfoIcon className="info-icon inline-block h-[11px] mt-[-2px] w-[11px]" />
  );

  switch (type) {
    case "Within Service":
      return (
        <Tooltip
          title={
            <div className="discount-representation-tooltip !w-[177px]">
              <p className={headingStyle}>Within Service</p>
              <p className={exampleStyle}>Example:</p>
              <div className="py-[10px] px-[15px] flex flex-col items-end">
                <p className={contentHeadingStyle}>Support Charges</p>
                <p className={`${contentChargeStyle} my-[8px]`}>Charge: $1000</p>
                <p className={contentDiscountStyle}>Discount: - $300</p>
              </div>
            </div>
          }
          placement="bottom-start"
        >
          <div className={tooltipIconStyle}>{renderIcon}</div>
        </Tooltip>
      );
    case "Partner discount (Separate Line)":
      return (
        <Tooltip
          title={
            <div className="discount-representation-tooltip !w-[225px]">
              <p className={headingStyle}>Partner Discount (Separate Line)</p>
              <p className={exampleStyle}>Example:</p>
              <div className="py-[10px] px-[15px] flex flex-col items-end">
                <p className={contentHeadingStyle}>Support Charges</p>
                <p className="!font-normal !text-[#151E33] text-[11px] pt-[4px]  border-b border-solid border-[#DDDFE8] pb-[5px] mt-[5px]">
                  Charge: $1000
                </p>
                <p className="!font-normal !text-[#151E33] text-[11px] my-[5px]">Discounts</p>

                <p className={contentDiscountStyle}>Discount: - $300</p>
              </div>
            </div>
          }
          placement="bottom-start"
        >
          <div className={tooltipIconStyle}>{renderIcon}</div>
        </Tooltip>
      );
    case "Charges Only (After Discount)":
      return (
        <Tooltip
          title={
            <div className="discount-representation-tooltip !w-[208px]">
              <p className={headingStyle}>Charges Only (After Discount)</p>
              <p className={exampleStyle}>Example:</p>
              <div className="py-[10px] px-[15px] flex flex-col items-end">
                <p className={contentHeadingStyle}>Support Charges</p>
                <p className="!font-normal !text-[#151E33] text-[11px] pt-[4px]">Charge: $700</p>
              </div>
            </div>
          }
          placement="bottom-start"
        >
          <div className={tooltipIconStyle}>{renderIcon}</div>
        </Tooltip>
      );
  }
};

export const getFormObj = (item, minDate) => {
  switch (item.type) {
    case "input":
      return {
        type: "text",
        name: item.key,
        placeHolder: item?.placeholder || `Enter ${item?.displayName}`,
        label: item?.displayName,
        inputClass: `${item?.key} !h[46px] w-[174px] inline-block`,
        validations: item?.mandatory
          ? requiredPostiveNumberOnly(item?.displayName)
          : positiveNumbers(item?.displayName),
        filledStyle: "!text-[14px]",
        labelClass: item?.mandatory ? "mandatory" : "",
        showLabel: "true",
        disabled: item?.disabled,
        disabledClass: item?.disabled ? "!bg-[#F1F2F6] border-[#DDDFE8]" : "",
        enableDisable: item?.enableDisable,
        executeEnableDisableLater: item?.executeEnableDisableLater,
        revalidation: true,
      };
    case "checkbox":
      return {
        type: "checkbox",
        name: item?.key,
        label: item?.label,
        className: "pt-1.5 w-full charge-minimum-checkbox",
        validations: item?.mandatory ? requiredRules : optionalRules,
        disabled: item?.disabled,
        tooltip: item?.tooltip,
        revalidation: true,
      };
    case "radio-tabs":
      return {
        type: "radio",
        name: item?.key,
        buttonStyle: item?.buttonStyle || true,
        childClassName: "pt-[5px]",
        tabsStyle: item?.tabsStyle,
        options: item?.options || [],
        validations: item?.disabled ? optionalRules : requiredRules,
        className: item?.className,
        disabled: item.disabled,
        topLabel: {
          text: item.label,
          class: item.mandatory ? "mandatory" : "",
        },
        mandatory: item.mandatory,
        renderTooltipStructure: item?.tooltip ? getSupportTooltipStructure : "",
        revalidation: true,
      };
    case "date-picker":
      return {
        type: "calender",
        name: item.key,
        placeHolder: "DD-MM-YYYY",
        className: "singleDateModule !w-full inline-block !mb-[11px]",
        singleDatePicker: true,
        topLabel: {
          text: item.displayName,
          fontSize: "",
          class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
        },
        labelText: item.displayName,
        validations: item?.mandatory ? requiredRules : optionalRules,
        disableFutureDates: false,
        minDate,
        collapseState: true,
        disabled: item?.disabled,
        revalidation: true,
      };
    case "dropdown":
      return {
        type: "select",
        name: item?.key || "test",
        placeHolder: item?.placeholder || `Select ${item?.displayName}`,
        className: `${item?.key} ${item?.class} module_dropdown !mb-[11px] Grid_dropdown v2 !w-[174px] !float-none inline-block`,
        label: "Default Format",
        topLabel: {
          text: item?.displayName,
          fontSize: "x-small",
          class: item?.mandatory ? "mandatory" : "",
        },
        validations: item.mandatory ? requiredRules : optionalRules,
        options: item?.options?.map((optionValue) => optionValue),
        disabled: item?.disabled,
        enableFilter: item?.key === "billerAccountId" ? { placeHolder: "Search..." } : null,
        optional: item?.optional,
        revalidation: true,
      };
  }
  //   return {};
};

export const getRecursiveFormField = (
  data = [],
  selectedConfig = "",
  updatedConfig = [],
  formData = {},
  minDate = null
) => {
  data?.[selectedConfig]?.forEach((item) => {
    if (item?.type === "dropdown" && item?.options?.length < 2) {
      if (item?.key === "billerAccountId" && formData?.billerAccountId?.value) {
        formData[item?.key] = formData?.billerAccountId;
      } else {
        formData[item?.key] = item?.options?.[0];
      }
    }
    if (item?.type === "radio-tabs" && item?.disabled === false && !formData?.[item?.key]) {
      formData[item?.key] = item?.options?.[0]?.value;
    }
    if (item?.key === "discountRepresentation") {
      if (!!formData?.discount || !!formData?.additionalDiscount || !!formData?.privateRate) {
        formData[item?.key] = formData?.discountRepresentation || item?.options?.[0]?.value;
        item.disabled = false;
      } else {
        formData[item?.key] = null;
        item.disabled = true;
      }
    }
    if (
      formData?.chargeMin === true &&
      formData?.pricingType?.value === "Private Pricing" &&
      formData?.applicationMethod?.value !== "Private Rate via Template"
    ) {
      formData.chargeMinType = "Custom";
    }
    if (item?.type === "radio-tabs" && item?.options?.length === 1) {
      formData[item?.key] = item?.options?.[0]?.value;
    }
    if (!item?.hasOwnProperty("configuration")) {
      updatedConfig?.push(getFormObj(item, minDate));
    }
    if (item?.hasOwnProperty("configuration")) {
      updatedConfig?.push(getFormObj(item, minDate));
      getRecursiveFormField(
        item.configuration,
        formData?.[item?.key]?.value || formData?.[item?.key],
        updatedConfig,
        formData,
        minDate
      );
    }
  });
  return { formFields: updatedConfig, formValues: formData };
};

export const isArray = (variable) => Array.isArray(variable);
export const isObject = (variable) =>
  variable !== null && typeof variable === "object" && !Array.isArray(variable);

export function compareArrays(array1 = [], array2 = []) {
  return (
    array1?.length === array2?.length &&
    array1
      ?.slice()
      ?.sort()
      ?.every((value, index) => value === array2?.slice()?.sort()?.[index])
  );
}
