import React, { useState } from "react";
import { Popover, Tooltip } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/editIcon.svg";
import { ReactComponent as SaveRowIcon } from "../../../../Assets/icons/blue-tick.svg";
import { ReactComponent as CancelRowIcon } from "../../../../Assets/icons/cancel-table-row.svg";

import "./style.scss";

function TextEditModal(props) {
  const { data, header, handleEditclick, showEditIcon = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(data);
  const [editing, setEditing] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEditing(!editing);
  };

  const handleClose = () => {
    setValue(data);
    setAnchorEl(null);
    setEditing(!editing);
  };

  const open = Boolean(anchorEl);
  const id = "simple-popover-mav";

  const onEditclick = () => {
    handleEditclick(value);
    handleClose();
  };
  return (
    <>
      <div className="text-wrapper">
        <p>{data}</p>
        {showEditIcon && (
          <Tooltip title="Edit" arrow placement="top">
            <EditIcon
              data-testid="EditIcon"
              onClick={handleClick}
              className={`${!!editing && "editing"} edit-icon`}
            />
          </Tooltip>
        )}
      </div>
      <Popover
        data-testid={id}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="edit-mav-modal">
          <p className="modal-heading">Edit {header}</p>
          <p className="input-heading"> {header}</p>
          <div className="d-flex">
            <input value={value} onChange={(e) => setValue(e.target.value)} />
            <Tooltip data-testid="Cancel" title="Cancel" arrow placement="top-start">
              <CancelRowIcon onClick={handleClose} className="crossIconButton" />
            </Tooltip>
            <Tooltip title="Save" arrow placement="top-start">
              <SaveRowIcon
                data-testid="Save"
                onClick={() => (value ? onEditclick() : null)}
                className={`checkMarkButton ${value === "" ? "disabled-save" : ""}`}
              />
            </Tooltip>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default TextEditModal;
