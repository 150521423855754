import React, { useEffect, useRef, useState } from "react";
import { FormHelperText } from "@mui/material";
import MultiSelect from "../../../../FormConfig/Form/Components/MultiSelect";
import Text from "../../../../FormConfig/Form/Components/Text";
import collapseIcon from "../../../../../Assets/icons/collapse.svg";
import expandIcon from "../../../../../Assets/icons/expand.svg";
import Button from "../../../../FormConfig/Form/Components/Button";
import useDetectOutsideClick from "../../../../../CustomHooks/useDetectOutsideClick";
import getMultiSelectChips from "./util";
import { removeDuplicates } from "../../../../../Utils/commonUtils";
import "./style.scss";

// eslint-disable-next-line max-lines-per-function
export default function InvoiceCustomer({
  value = [],
  name,
  labelText,
  placeHolder,
  className,
  options,
  handleChange,
  error,
  touched,
  onBlurHandler,
  resetClicked = false,
  labelClass,
  v2 = false,
  searchPlaceHolder = "customer(s)",
  handleOptions = false,
  isIcon = true,
  modifyLabelValue = false,
  resetCount = null,
  maxRequiredOptions = 0,
  includeExcludeOption = false,
  resetFilter = false,
  noDataLabel = "",
  initialValue,
  grouping = false,
  readOnly = false,
  disabled = false,
  disableBlurHandler = false,
  // defaultCss = false,
  modifiedValues = 0,
  preserveOptionState = false,
}) {
  const dropdownRef = useRef(null);
  const [collapse, setCollapse] = useState(true);
  const [selectedItems, setSelectedItems] = useState([...value]);
  const [items, setItems] = useState([]);
  const [includeOnly, setIncludeOnly] = useState("Include Only");

  useEffect(() => {
    if (resetClicked || resetCount) {
      setSelectedItems([...(initialValue || [])]);
      const newItems = items.map((item) => ({ ...item, checked: false }));
      setItems(newItems);
    }
  }, [resetClicked, resetCount]);

  useEffect(() => {
    if (modifiedValues) {
      setSelectedItems([...value]);
      setItems([...value]);
    }
  }, [modifiedValues]);

  useEffect(() => {
    const updatedOptions = options?.map((item) => {
      const valueItem = value?.find((intItem) => intItem?.value === item?.value);
      return {
        ...item,
        checked: valueItem?.checked || false,
        disabled: valueItem?.disabled || false, // Add this line to set disabled key
      };
    });

    setItems(updatedOptions);
  }, []);

  useEffect(() => {
    setItems(options);
  }, [options]);

  const selectedOptionsFirst = (list) => {
    if (value?.length && !collapse && JSON.stringify(value) === JSON.stringify(selectedItems)) {
      return list.sort((a, b) => Number(b.checked) - Number(a.checked));
    }
    return list;
  };

  const handleOptionSelection = (list) => {
    const updatedList = list.map((item) => {
      const valueItem = selectedItems?.find((intItem) => intItem?.value === item?.value);
      return {
        ...item,
        checked: valueItem?.checked || false,
      };
    });
    return updatedList.sort((a, b) => Number(b.checked) - Number(a.checked));
  };

  const handleClose = () => {
    setSelectedItems(removeDuplicates(value));
    setCollapse(true);

    items?.forEach((option) => {
      const optionClone = option;
      const isItemChecked = value?.find((val) => val.label === option.label);
      if (!isItemChecked) {
        optionClone.checked = false;
      } else if (preserveOptionState) {
        optionClone.checked = option?.checked;
      } else {
        optionClone.checked = true;
      }
    });
    setItems(items);
  };

  useDetectOutsideClick(dropdownRef, (e) => {
    e.stopPropagation();
    if (!collapse) {
      handleClose();
      if (!disableBlurHandler) {
        onBlurHandler({
          target: { value: value || [], name, eventName: "blur" },
          eventType: "blur",
        });
      }
    }
  });

  const onChangeHandler = (event) => {
    const {
      target: { value: targetValue },
    } = event;
    setSelectedItems(removeDuplicates(targetValue.filter((item) => item.checked)));
  };

  const onClickHandler = () => {
    let selectedOptions = [];
    if (includeExcludeOption && includeOnly === "Exclude Only") {
      selectedOptions = removeDuplicates(
        options.filter((item) => !item.checked).map((item) => ({ ...item, checked: true }))
      );
    } else {
      selectedOptions = removeDuplicates(options.filter((item) => item.checked));
    }

    handleChange({
      target: {
        value: handleOptions ? selectedItems : selectedOptions || [],
        name,
      },
      onCancelChange: handleClose,
    });

    setCollapse(!collapse);
  };

  const removeChipHandler = (e, item) => {
    e.stopPropagation();
    const updatedSelectedItems = selectedItems.filter((intItem) => item?.value !== intItem?.value);
    handleChange({ target: { value: updatedSelectedItems, name } });
    setSelectedItems(updatedSelectedItems);

    items.forEach((option) => {
      const optionClone = option;
      optionClone.checked =
        updatedSelectedItems?.find((intItem) => intItem?.value === option?.value)?.checked || false;
    });
    setItems(items);
  };

  const getSeletedItems = () => {
    if (!selectedItems?.filter((item) => item.checked).length) {
      return placeHolder;
    }
    const length = selectedItems?.filter((item) => item.checked);

    if (v2) {
      return getMultiSelectChips(selectedItems, removeChipHandler, modifyLabelValue, readOnly);
    }
    return `${length.length} Selected`;
  };

  const includeOnlyHandler = (event) => {
    const {
      target: { value: targetValue },
    } = event;
    setIncludeOnly(targetValue);
  };

  return (
    <div
      data-name={name}
      className={`invoice-customer-wrapper ${className} ${disabled ? "multiselect_disabled" : ""}`}
    >
      {labelText && <Text text={labelText} labelClass={labelClass} size="x-small" type="primary" />}
      <div className="invoice-customer" ref={dropdownRef}>
        <div
          onClick={() => setCollapse(!collapse)}
          aria-hidden="true"
          className={`header ${touched && error && "error_box"} ${value?.length && "filled"} ${
            !collapse && "dropdown"
          }`}
        >
          <div className={v2 ? "flex items-center" : ""}>{getSeletedItems()}</div>
          <img src={collapse ? expandIcon : collapseIcon} alt={collapse ? "collapse" : "expand"} />
        </div>
        {!collapse && (
          <MultiSelect
            searchEnabled
            unitEnabled={false}
            options={handleOptions ? handleOptionSelection(items) : selectedOptionsFirst(items)}
            name={name}
            handleChange={onChangeHandler}
            placeholder={searchPlaceHolder}
            selected={selectedItems}
            maxRequiredOptions={maxRequiredOptions}
            includeExcludeOption={includeExcludeOption}
            resetFilter={resetFilter}
            includeOnlyHandler={includeOnlyHandler}
            includeOnly={includeOnly}
            resetClicked={resetClicked}
            noDataLabel={noDataLabel}
            isIcon={isIcon}
            modifyLabelValue={modifyLabelValue}
            initialValue={initialValue}
            grouping={grouping}
            readOnly={readOnly}
          >
            <div
              className={
                readOnly
                  ? "hidden"
                  : "flex h-[50px] justify-end items-center bg-[#F0F6FF] px-5 py-[10px] clear-both !font-medium invoice-customer-footer"
              }
            >
              <div className="flex items-center">
                <Button
                  className="rounded mr-[8px] bg-white"
                  size="small"
                  variant="outline-primary-nohover"
                  text="Cancel"
                  handleClick={handleClose}
                />
                <Button
                  className="rounded !font-medium"
                  size="small"
                  variant="primary"
                  id="submit"
                  text="Save"
                  disabled={!items?.length}
                  type="button"
                  handleClick={onClickHandler}
                />
              </div>
            </div>
          </MultiSelect>
        )}
        {touched && error && (
          <FormHelperText className="error_message" error={error} size="small">
            {error}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
