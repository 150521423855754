import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "../../FormConfig/Form";
import {
  getResetPassPayload,
  updatePartnerFavicon,
  changePasswordIcon,
  getWrapperClass,
} from "../utils";
import commonService from "../../../Services/commonService";
import withRouter from "../../Common/WithRouter";
import TooltipPopup from "../../Common/Tooltip";
import Routes from "../../../Config/Routes";
import Button from "../../FormConfig/Form/Components/Button";
import { getToast, isSignUpEnabled, isEmpty } from "../../../Utils/commonUtils";
import PageTitle from "../../Common/PageTitle";

import {
  CommonServiceNameForResetConfirm,
  getButtonAndHeaderName,
} from "../../Dashboard/UserManagement/util";
import VerificationLinkExpired from "../VerificationLinkExpired/VerificationLinkExpired";
import Loader from "../../Common/Loader";
import actions from "../../../Actions";
import PublicFooter from "../../CK-Auto/Common/PublicFooter";
import { preloadImages } from "../../../Utils/userUtils";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../../Services/Strapi/urls";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statePasswordValidations: {
        toggleCallback: this.toggleCallback,
      },
      stateConfirmPasswordValidations: {
        toggleCallback: this.toggleCallback,
      },
      isError: false,
      isLoading: false,
      isPassReset: false,
      headerAndButtonName: getButtonAndHeaderName(),
      partnerLogo: "",
      // isLoadingLogo: false,
      isVerificationLinkExpired: false,
      isRender: true,
      signUpCheck: props.ckAutoEnabled,
      allRightsFooter: "",
      partnerDisplayName: "",
      strapiDataState: {},
    };
  }

  async componentDidMount() {
    const {
      props: { navigate },
      state: { isVerificationLinkExpired },
    } = this;
    const { pathname } = window.location;
    this.setState({ isRender: false });
    if (pathname === Routes.CONFIRMUSER) {
      await this.handleUserVerification();
    }
    if (!isVerificationLinkExpired) {
      const strapiApiData = await this.getStrapiData(pathname);
      if (strapiApiData) {
        await this.getPartnerIcon(strapiApiData);
      }
    }
    const Token = getResetPassPayload();
    if (!(Token?.passwordResetToken || Token?.code)) {
      setTimeout(() => {
        navigate(Routes.LOGIN);
      }, 0);
    }

    this.setState({ isRender: true });
  }

  getStrapiData = async (pathname) => {
    const { statePasswordValidations, stateConfirmPasswordValidations } = this.state;
    const data = await fetchStrapiData(strapiUrls.RESET_PASSWORD(pathname));
    this.restructureFormIcons(data?.formComponent?.data?.attributes);
    this.setState({
      strapiDataState: data,
      statePasswordValidations: {
        ...statePasswordValidations,
        ...data?.formComponent?.data?.attributes?.form?.[0],
      },
      stateConfirmPasswordValidations: {
        ...stateConfirmPasswordValidations,
        ...data?.formComponent?.data?.attributes?.form?.[1],
      },
    });
    return data;
  };

  restructureFormIcons = (data) => {
    data.form.forEach((item) => {
      if (item.type === "password") {
        const {
          iconList: { text, password, selected },
        } = item;

        const itemClone = item;

        const passwordSvgString = password?.data?.attributes?.html;
        const textSvgString = text?.data?.attributes?.html;
        const selVisibileIconSvgString = selected?.data?.attributes?.html;

        itemClone.iconList.password = (
          <span dangerouslySetInnerHTML={{ __html: passwordSvgString }} />
        );
        itemClone.iconList.text = <span dangerouslySetInnerHTML={{ __html: textSvgString }} />;
        itemClone.selectedVisibilityIcon = (
          <span dangerouslySetInnerHTML={{ __html: selVisibileIconSvgString }} />
        );
      }
    });
  };

  getPartnerIcon = async (strapiData) => {
    const {
      props: { navigate },
    } = this;
    try {
      if (process.env.REACT_APP_BASE_URL !== window.location.origin) {
        // api call partner logo
        const result = await commonService.partnerCreateUser();
        const partnerDisplayName =
          result?.data?.data?.displayName || result?.data?.data?.partnerName || "";
        const partnerLogo = result?.data?.data?.logo;
        if (partnerLogo) {
          await preloadImages(partnerLogo);
        }

        const favIcon = {
          data: {
            data: result?.data?.data?.favicon,
          },
        };
        updatePartnerFavicon(favIcon);
        this.setState({
          partnerLogo,
          signUpCheck: !!isSignUpEnabled(result?.data?.data),
          partnerDisplayName,
          allRightsFooter: `${partnerDisplayName} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`,
        });
      } else {
        this.setState({
          allRightsFooter: `${strapiData?.pageFooter?.pretext} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`,
        });
        updatePartnerFavicon();
      }
    } catch (error) {
      updatePartnerFavicon();
      navigate(Routes.PAGENOTFOUND);
    }
  };

  handleUserVerification = async () => {
    const query = new URLSearchParams(window.location.search);
    const payload = this.getPayload(query);
    const {
      props: { navigate },
    } = this;
    try {
      if (payload?.email && payload?.code) {
        await commonService.getUserVerification(payload);
        // this.setState({ isRender: true });
      } else {
        navigate(Routes.LOGIN);
      }
    } catch (error) {
      this.handleAPIErrors(error);
    }
  };

  handleAPIErrors = (error) => {
    const {
      props: { navigate },
    } = this;
    const {
      response: { status },
    } = error;
    if (status === 401) {
      navigate(Routes.LOGIN);
    }
    if (status === 710 || status === 700) {
      this.setState({ isVerificationLinkExpired: true });
    }
    if (status === 701) {
      getToast("error", "User already verified");
      navigate(Routes.LOGIN);
    }
  };

  getPayload = (searchParams) => {
    const payload = Object.fromEntries([...searchParams]);
    const { confirmation_code: code, user_name: email } = payload;
    return {
      email: email?.toLowerCase(),
      code,
    };
  };

  toggleCallback = (inputType, name) => {
    const {
      state: { statePasswordValidations, stateConfirmPasswordValidations },
    } = this;
    switch (name) {
      case "password":
        this.setState({
          statePasswordValidations: changePasswordIcon(statePasswordValidations, inputType),
        });
        break;
      case "confirmpassword":
        this.setState({
          stateConfirmPasswordValidations: changePasswordIcon(
            stateConfirmPasswordValidations,
            inputType
          ),
        });
        break;
      default:
        break;
    }
  };

  handleSubmit = async (values) => {
    try {
      const {
        props: { navigate },
      } = this;
      const { pathname } = window.location;
      this.setState({ isLoading: true });
      const payload = getResetPassPayload(values);
      const commonServiceName = CommonServiceNameForResetConfirm();
      let toastMssg = "Password reset successful";

      await commonService[commonServiceName](payload);
      this.setState({ isLoading: false, isPassReset: true });
      if (commonServiceName === "confirmSignUpV2") {
        toastMssg = "Signup Successful";
      }
      getToast("success", toastMssg);
      if (pathname?.toLowerCase() !== Routes.RESETPASSWORD?.toLowerCase()) {
        navigate(Routes.LOGIN);
      }
    } catch (err) {
      // getToast("error", err.response.data.message);
      this.setState({ isLoading: false, isPassReset: false });
    }
  };

  tooltipContent = () => {
    const { strapiDataState } = this.state;
    const strapiData = strapiDataState?.tooltip?.content;
    return (
      <ul className="list-disc">
        {strapiData?.map((data) => (
          <li className="py-1">{data}</li> // Accessing data correctly
        ))}
        {/* // <li className="py-1">Password must contain 8-20 characters.</li>
      // <li className="py-1">Password must contain atleast one alphabet.</li> */}
        {/* <li className="py-1">Password must contain atleast one upper case character from A-Z.</li> */}
        {/* <li className="py-1">Password must contain atleast one numeric digit from 0-9.</li> */}
        {/* <li className="py-1">
      //   {"Password must contain atleast one special symbol from !@#$%^&*=_?{}<>\\/:|[]."}
      // </li> */}
      </ul>
    );
  };

  render() {
    const {
      handleSubmit,
      state: {
        isLoading,
        statePasswordValidations,
        stateConfirmPasswordValidations,
        isError,
        isPassReset,
        partnerLogo,
        headerAndButtonName,
        isVerificationLinkExpired,
        isRender,
        signUpCheck,
        allRightsFooter,
        partnerDisplayName,
        strapiDataState,
        strapiDataState: { AfterFormSuccess: [afterFormSuccess] = [] } = {},
        strapiDataState: { formComponent: { data: { attributes } = {} } = {} } = {},
      },
    } = this;
    const passwordValidationsClone = statePasswordValidations;
    passwordValidationsClone.validations = {
      rules: attributes?.form?.[0]?.rules,
      messages: attributes?.form?.[0]?.messages,
    };
    const confirmPasswordValidationsClone = stateConfirmPasswordValidations;
    confirmPasswordValidationsClone.validations = {
      rules: attributes?.form?.[1]?.rules,
      messages: attributes?.form?.[1]?.messages,
    };
    const logoImage = `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`;
    if (!isRender) {
      return <Loader />;
    }

    return (
      <div
        className={`logIn-mainContainer ${getWrapperClass(
          "login_wrapper_Without_bg",
          signUpCheck
        )} items-center`}
      >
        {isVerificationLinkExpired ? (
          <VerificationLinkExpired pageHeader={signUpCheck} />
        ) : (
          <div className="login_wrapper public_module reset_password">
            <PageTitle
              pageTitle={strapiDataState?.pageTitle}
              customPartnerDisplayName={partnerDisplayName}
              pageHeader={signUpCheck}
            />
            <div className="logo-container">
              <div className={` ${partnerLogo && " logoWrapper "} logo`}>
                <img src={`${partnerLogo || logoImage}`} alt="logo" />
              </div>
            </div>
            <div className="login-form-wrapper">
              <div className={`login_form ${isPassReset && "email-sent"}`}>
                {!isPassReset ? (
                  <>
                    <h1>{headerAndButtonName}</h1>
                    <p className="text-secondary flex items-center !mb-1 text-xs">
                      {" "}
                      {strapiDataState?.description}
                      {!isEmpty(strapiDataState) && (
                        <TooltipPopup
                          style={strapiDataState?.tooltip?.style}
                          content={this.tooltipContent}
                          iconSrc={`${strapiBasePath}${strapiDataState?.tooltip?.icon?.data?.attributes?.url}`}
                        />
                      )}
                    </p>
                    <Form
                      isError={(value) => this.setState({ isError: value })}
                      formConfig={[passwordValidationsClone, confirmPasswordValidationsClone]}
                      handleSubmit={handleSubmit}
                      formValues={{}}
                    >
                      <Button
                        text={headerAndButtonName.toUpperCase()}
                        className={attributes?.form?.[2]?.className}
                        disabled={isError || isLoading}
                        isLoading={isLoading}
                        type={attributes?.form?.[2]?.type}
                      />
                      <div className="return-signin !mt-[6px] !mb-1 ">
                        <span className="text-secondary text-[13px] font-medium !text-[#7C7C7C]">
                          {strapiDataState?.routelink?.pretext}
                        </span>
                        <Link to={strapiDataState?.routelink?.href}>
                          {strapiDataState?.routelink?.hypertext}
                        </Link>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <img
                      src={`${strapiBasePath}${afterFormSuccess?.image?.data?.attributes?.url}`}
                      alt="email sent"
                    />
                    <p className="email-sent-info one">{afterFormSuccess?.title}</p>
                    <p className="email-sent-info two">{afterFormSuccess?.description}</p>
                    <Link
                      className="btn-filled login-btn forgot-return-btn"
                      to={afterFormSuccess?.link?.href}
                    >
                      {afterFormSuccess?.link?.hypertext}
                    </Link>
                  </>
                )}
              </div>
            </div>
            {!signUpCheck && <div className="footer-text">{allRightsFooter}</div>}
          </div>
        )}
        <PublicFooter
          show={signUpCheck}
          leftText={false}
          allRightsFooter={allRightsFooter}
          strapiData={strapiDataState?.publicFooter}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  ckAutoEnabled: state.CkAutoReducer.signupEnabled,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
