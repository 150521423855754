/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import Button from "@mui/material/Button";
import { ReactComponent as ResetIcon } from "../../../Assets/icons/reset-icon.svg";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

export default function ColumnSelector(props) {
  const {
    selectedChecks = [],
    getError,
    multiSelectValid,
    data = [],
    handleEditclick,
    columnManager = false,
    onReset,
    resetEnable = false,
  } = props;
  const [list, setList] = useState([...data]);

  /**
   * The 'listClone' variable serves the purpose of retaining the previous state in the event of a cancellation action.
   * This enables us to revert changes and maintain data consistency without impacting the user experience.
   */

  const [touched, setTouched] = useState(false);
  const selectedCheck = [];

  if (selectedChecks?.length) {
    selectedChecks?.split(",")?.forEach((item) => selectedCheck?.push(item?.id));
  }
  const ref = useRef();
  useEffect(() => {
    setList(data);
  }, [data]);

  const scrollTotop = () => {
    setTimeout(() => {
      document.getElementsByClassName("MuiList-root")[0]?.scroll({
        top: 0,
        left: 0,
      });
    }, 0);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const updatedData = [];

    if (value.includes("selectAll")) {
      if (list.length === list.filter((item) => item.checked).length) {
        list.forEach((item) => {
          const updatedItem = { ...item };
          updatedItem.checked = false;
          updatedData.push(updatedItem);
        });
        setList(updatedData);
      } else {
        list.forEach((item) => {
          const updatedItem = { ...item };
          updatedItem.checked = true;
          updatedData.push(updatedItem);
        });
        setList(updatedData);
      }
      scrollTotop();
    } else {
      list.forEach((item) => {
        const updatedItem = { ...item };
        if (value.includes(item.id)) {
          updatedItem.checked = true;
        } else {
          updatedItem.checked = false;
        }
        updatedData.push(updatedItem);
      });
      setList(updatedData);
    }
  };

  const onSave = () => {
    document.getElementsByClassName("MuiBackdrop-root")?.[0]?.click();
    setList(list);
    handleEditclick(list);
  };

  const onCancel = () => {
    setList(data);
    document.getElementsByClassName("MuiBackdrop-root")?.[0]?.click();
  };

  const onResetClick = () => {
    document.getElementsByClassName("MuiBackdrop-root")?.[0]?.click();
    onReset();
  };

  const renderValue = () => <p />;

  return (
    <div className="custom_multiselect columnManager" id="columnSelector-parent">
      <FormControl
        sx={{ m: 1, width: 170, mt: 3 }}
        ref={ref}
        error={!!getError && touched && !multiSelectValid}
        className="column_selector_dropdown"
      >
        <Select
          multiple
          displayEmpty
          value={list.filter((item) => item.checked).map((item) => item.id)}
          onChange={handleChange}
          renderValue={renderValue}
          MenuProps={MenuProps}
          onClose={onCancel}
          onFocus={() => setTouched(true)}
          inputProps={{ "aria-label": "Without label" }}
          className={`${!resetEnable ? "resetEnabled" : ""}`}
          onOpen={() => scrollTotop()}
        >
          {data.length > 0 ? (
            <MenuItem
              key="Select All"
              value="selectAll"
              className={`columnManager-custom_select !px-[10px] text-left ${
                list.filter((item) => item.checked).length === list.length ? "Mui-selected" : ""
              }`}
            >
              <Checkbox checked={list.filter((item) => item.checked).length === list.length} />
              <ListItemText primary="Select All" />
            </MenuItem>
          ) : null}
          {list.map((item) => (
            <MenuItem
              key={item.name}
              value={item.id}
              className={`${columnManager ? "columnManager-custom_select" : ""} !px-[10px] !py-0`}
              disabled={item.disabledMenuItem}
            >
              <Checkbox checked={item.disabledMenuItem || item?.checked} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}

          <div className="multiButtons">
            <Button
              variant="outlined"
              size="small"
              id="close-filter"
              className={resetEnable ? "columnManager_Disabled" : ""}
              onClick={() => {
                onResetClick();
              }}
              disabled={resetEnable}
            >
              <ResetIcon className="w-2 h-2 mr-[4px]" />
              Reset
            </Button>
            <Button
              variant="contained"
              size="small"
              id="apply-filter"
              className="!text-white"
              onClick={() => {
                onSave();
              }}
            >
              Apply
            </Button>
          </div>
        </Select>
      </FormControl>
    </div>
  );
}
