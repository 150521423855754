import React, { Fragment, useState } from "react";

import "./style.scss";

function FormInput(props) {
  const {
    onGetData,
    id,
    placeholder,
    name,
    type,
    value = "",
    errorMessage = "",
    disabled = false,
    errorClass = "",
    parentClass = "",
    comparisonError = false,
    filterError = false,
  } = props;
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(false);
  const [compareError, setCompareError] = useState(comparisonError);
  const onChangeHandler = (event) => {
    onGetData(event.target.name, event.target.value);
    if (filterError) {
      setFocused(true);
      setError(true);
    }
    if (event.target.value > 0) {
      setCompareError(false);
    }
  };

  const handleError = () => {
    const { regex } = props;
    if (regex) {
      const regexValue = !!String(value)?.match(regex);
      return !regexValue;
    }
    return false;
  };

  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
        onBlur={() => {
          setFocused(true);
          handleError();
        }}
        className={`${
          focused && (error || compareError) ? "error_box" : "input_box"
        } ${parentClass}`}
      />
      {errorMessage && !filterError && handleError() && focused > 0 && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
      {comparisonError && !filterError && value?.length > 0 && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
      {comparisonError && filterError && focused && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
    </>
  );
}

export default FormInput;
