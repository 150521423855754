import React from "react";
import { ResetIcon } from "../../../../../Constants/icons";
import ExcelDownloadV2 from "../../../../Common/ExcelDownloadV2";
import TableFilter from "../../../../Common/FilterMultiselect/TableFilter";

export function FilltersOnTable({
  applyFiltersOnTable,
  tableHeading,
  disableHandling,
  filterSelected,
  resetButton,
  excelDownloadHandler,
  data,
  excelLoading,
}) {
  return (
    applyFiltersOnTable && (
      <div className="flex justify-between">
        {tableHeading && (
          <span className="ml-[17px] text-[14px] font-[500] text-black content-around">
            {tableHeading}
          </span>
        )}
        <div className="flex items-center mr-[17px]">
          <span
            aria-hidden="true"
            className={`flex justify-end gap-x-1 text-[#0A3CA2] text-xs items-center font-medium cursor-pointer pb-[4px] reset-icon-btn mt-[2px]  ${
              disableHandling(filterSelected) && "text-[#7c7d7e] pointer-events-none"
            }`}
            onClick={() => resetButton()}
          >
            <ResetIcon className="w-2 h-2" /> <span>Reset Filters</span>
          </span>
          <span className="seperator" />
          <ExcelDownloadV2
            downloadHandler={excelDownloadHandler}
            hasData={!data?.length}
            excelLoading={excelLoading}
          />
        </div>
      </div>
    )
  );
}

export function MultiLevelTableFooter({
  totalVisible,
  footerGroups,
  tableData,
  target,
  rows,
  renderFooter,
  total,
  footerV2Condition,
  renderNumber,
  V2,
}) {
  return (
    <tfoot>
      {totalVisible &&
        footerGroups.map((group, index) => (
          <>
            {tableData?.length >= target && index === 0 && (
              <tr
                id="others"
                className={`${index === 0 ? "" : "secondarytotal"} ${
                  rows.length % 2 === 0 ? "even-row-color" : "odd-row-color"
                }`}
                {...group.getFooterGroupProps()}
              >
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps()}
                    id={`${column.id === "total" ? "grandTotal-other" : "total-other"} ${
                      renderFooter(column, tableData, total, false) === ""
                        ? "!border-r-0"
                        : "!border-l-[1px] !border-t-[0px] !border-solid !border-[#fff]"
                    }`}
                  >
                    {renderFooter(column, tableData, total, false)}{" "}
                  </td>
                ))}
              </tr>
            )}
            {index === 0 && (
              <tr
                id="total"
                className={`${index === 0 ? "" : "secondarytotal"}`}
                {...group.getFooterGroupProps()}
              >
                {group.headers.map((column, totalIndex) => (
                  <td
                    {...column.getFooterProps()}
                    className={`${V2 ? "sticky bottom-0 !z-10" : ""} ${
                      V2
                        ? footerV2Condition(
                            renderNumber(column, total) === "" ||
                              renderNumber(column, total) === undefined
                          )
                        : ""
                    }`}
                    id={`${
                      totalIndex === group.headers.length - 1
                        ? "grandTotal"
                        : V2 && "individual_total"
                    }`}
                  >
                    {renderNumber(column, total)}
                  </td>
                ))}
              </tr>
            )}
          </>
        ))}
    </tfoot>
  );
}

export function TableHeader({
  headerGroups,
  tableData,
  ReturnThClass,
  V2,
  stickyList,
  getIconHeader,
  sortHandlerParent,
  summaryTable,
  selectedSortHeader,
  ascending,
  lastColumnSticky,
  applyFiltersOnTable,
  renderTableFilters,
  mouseOverFilter,
}) {
  return (
    <thead>
      {headerGroups &&
        headerGroups.map((headerGroup, groupIndex) => (
          <tr {...headerGroup?.getHeaderGroupProps()}>
            {headerGroup?.headers &&
              headerGroup?.headers?.map(
                (column, index) =>
                  tableData?.[0]?.[column?.id] !== "undefined" && (
                    <th
                      {...column.getHeaderProps()}
                      className={`${V2 ? ReturnThClass(column, index, stickyList, true) : ""} ${
                        groupIndex !== headerGroups.length - 1 ? "no-background-color" : ""
                      } ${typeof column.render("Header") === "object" ? "remove-border" : ""} ${
                        V2 && headerGroup.headers.length - 1 === index ? "add-border" : ""
                      }  ${column?.id}`}
                      id={column?.id}
                    >
                      {getIconHeader({
                        column,
                        sortHandlerParent,
                        summaryTable,
                        selectedSortHeader,
                        ascending,
                        V2,
                        lastColumnSticky,
                        applyFiltersOnTable,
                        renderTableFilters,
                        mouseOverFilter,
                      })}
                    </th>
                  )
              )}
          </tr>
        ))}
    </thead>
  );
}

export function RenderTableFilters({
  column,
  filters,
  filterSelected,
  handleFilters,
  setFilterSelected,
  onIncludeChange,
  filterData,
  cancelFilter,
  setFilters,
  onRangeChangeTable,
}) {
  return (
    <TableFilter
      filters={filters}
      heading={column?.id}
      filterType={column?.type}
      disableResetButton
      filterSelected={filterSelected}
      handleFilters={(value, name) => {
        const updatedFilterSelected = handleFilters(value, name, filterSelected, filters);
        setFilterSelected(updatedFilterSelected);
      }}
      onIncludeChange={(value, name) => {
        onIncludeChange(value, name);
      }}
      filterData={filterData}
      cancelFilter={(selectedList) => {
        cancelFilter(selectedList, filterSelected, setFilterSelected, setFilters);
      }}
      onRangeChange={(value, name) => {
        onRangeChangeTable(value, name);
      }}
      showSortIcon
      filterRefClass="filter_div"
    />
  );
}

export const footerV2Condition = (condition) =>
  condition ? "!border-r-[0px]" : "!border-l-[1px] !border-t-[0px] !border-solid !border-[#fff] ";

export function TotalNumberFooter({ footer }) {
  return (
    <tfoot>
      {footer.map((item) => (
        <tr id={item?.total === "Total" ? "total" : ""}>
          {Object.keys(item).map((internalItem) => (
            <td>{item[internalItem]}</td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}
