import React, { useState } from "react";
import { noop } from "../../../../../Utils/commonUtils";
import "./style.scss";

export default function OptionInputField(props) {
  const {
    name,
    options,
    labelText,
    labelClass,
    buttonWrapperClass,
    inputLabel,
    handleChange = noop,
  } = props;

  const [value, setValue] = useState();
  const [unit, setUnit] = useState(options[0]);

  const onChangeHandler = (e) => {
    const newValue = e.target.value;

    if (/^\d*(\.\d{0,4})?$/.test(newValue)) {
      setValue(newValue);
      handleChange({ target: { name, value: { unit, value: newValue } } });
    }
  };

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
    handleChange({ target: { name, value: { unit: e.target.value, value } } });
  };

  return (
    <div className="optional-input-field">
      <h4 className={labelClass}>{labelText}</h4>
      <div className="flex items-center border-solid border-[#DDDFE8] border-[1px] pl-[15px] pr-[7px] py-[6px] mt-[5px] gap-[8px] rounded-[4px]">
        <input
          placeholder={inputLabel}
          id={name}
          type="text"
          className="!m-0 border-none text-[14px] font-medium"
          value={value}
          onChange={onChangeHandler}
          pattern="^\d*(\.\d{0,4})?$"
        />
        <div className={`radio-button-wrapper ${buttonWrapperClass}`}>
          {options.map((option) => (
            <label
              className={`relative !px-[12px] !py-[6px] border-solid border-[1px] border-[#D5DDEC] text-[12px] font-semibold ${
                unit === option ? "active" : ""
              }`}
            >
              <input
                className="absolute invisible"
                type="radio"
                value={option}
                checked={unit === option}
                onChange={handleUnitChange}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
