/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { renturnPartnerEmailTooltipStyle } from "../../../Utils/commonUtils";
import Form from "../../FormConfig/Form";
import Text from "../../FormConfig/Form/Components/Text";
import {
  smtpSettingsEncryptionValidations,
  smtpSettingsPasswordValidations,
  smtpSettingsPortValidations,
  smtpSettingsServerValidations,
  smtpSettingsUsernameValidations,
} from "../../FormConfig/index";
import { changePasswordIcon } from "../../Login/utils";

export default function SmtpSettings({
  onChangeHandler,
  formValues,
  onInitHandler,
  handleChange,
  ...rest
}) {
  const [statePasswordValidations, setStatePasswordValidations] = useState(
    smtpSettingsPasswordValidations
  );

  const [resetCount, setResetCount] = useState(0);

  const toggleCallback = (inputType) => {
    const updatedValidation = changePasswordIcon(smtpSettingsPasswordValidations, inputType);
    setStatePasswordValidations(updatedValidation);
  };

  useEffect(() => {
    if (formValues.smtpsettings?.resetClicked) {
      setResetCount(resetCount + 1);
    }
  }, [formValues.smtpsettings?.resetClicked]);

  useEffect(() => {
    if (rest.disabled) {
      toggleCallback("text");
    }
  }, [rest.disabled]);

  const onChange = (event, values, formErrors) => {
    handleChange({ target: { name: "smtpsettings", value: values, errors: formErrors } });
  };

  statePasswordValidations.toggleCallback = toggleCallback;
  statePasswordValidations.disabled = rest.disabled;

  smtpSettingsServerValidations.disabled = rest.disabled;
  smtpSettingsPortValidations.disabled = rest.disabled;
  smtpSettingsEncryptionValidations.disabled = rest.disabled;
  smtpSettingsUsernameValidations.disabled = rest.disabled;

  return (
    <div className="smtp-settings-wrapper mt-[10px]">
      <Text
        text="SMTP Settings"
        label={
          <p>
            To integrate AWS SES in the SMTP settings, setup the AWS SES in your AWS account and add
            the details below. Here is{" "}
            <a
              target="_blank"
              className="!underline !font-bold hover:text-white"
              href="https://docs.aws.amazon.com/ses/latest/dg/getting-started.html"
              rel="noreferrer"
            >
              the link
            </a>{" "}
            to the AWS documentation.
          </p>
        }
        tooltipPlacement="bottom-start"
        iconSize="big"
        className="label primary medium flex items-center !text-[16px] mb-2.5 pl-0"
        tooltipOtherStyles={renturnPartnerEmailTooltipStyle()}
      />
      <div className="border border-solid border-[#DEDEDE] rounded p-[15px]">
        <Form
          formConfig={[
            smtpSettingsServerValidations,
            smtpSettingsPortValidations,
            smtpSettingsEncryptionValidations,
            smtpSettingsUsernameValidations,
            statePasswordValidations,
          ]}
          formValues={formValues.smtpsettings || {}}
          onChangeHandler={onChange}
          onInitHandler={(values, formErrors) => onInitHandler(values, formErrors, "smtpsettings")}
          resetCount={resetCount}
        />
      </div>
    </div>
  );
}
