import React, { useState } from "react";
import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as CalendarIcon } from "../../../../../Assets/icons/calender.svg";
import { ReactComponent as CalendarBlueIcon } from "../../../../../Assets/icons/ic-date.svg";
import { ReactComponent as ResetIcon } from "../../../../../Assets/icons/ResetIcon.svg";
import "./style.scss";

export default function MonthYearCalendar(props) {
  const [open, setOpen] = useState(false);
  const {
    topLabel,
    className,
    readOnly = false,
    handleChange,
    minDate,
    maxDate,
    value,
    name,
    placeHolder = "MMM YYYY",
    mandatory = false,
    resetDates = false,
  } = props;

  const onChangehandler = (date) => {
    handleChange({ target: { name, value: date.format("YYYY-MM") } });
  };

  return (
    <div className={`${mandatory ? "mandatory-field" : ""} relative`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem label={topLabel?.text}>
          {resetDates && (
            <button
              onClick={() => {
                handleChange({ target: { name, value: null } });
              }}
              type="button"
              className={`!text-primary-blue font-medium text-xs absolute right-0 reset !mt-0 ${
                value ? "" : "disabled !text-[#898a90]"
              }`}
            >
              <ResetIcon />
              <span className="!ml-[5px]">Reset</span>
            </button>
          )}
          <DatePicker
            value={value ? dayjs(value) : null}
            DialogProps={{
              className: "monthCalendar-calendar",
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            views={["month", "year"]}
            readOnly={readOnly}
            slots={{
              openPickerIcon: value ? CalendarBlueIcon : CalendarIcon,
              // switchViewIcon: () => null
            }}
            format="MMM-YYYY"
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
                placeholder: placeHolder,
              },
              popper: {
                className: "monthCalender-calender",
              },
            }}
            minDate={minDate && dayjs(minDate)}
            maxDate={maxDate && dayjs(maxDate)}
            onChange={onChangehandler}
            className={`MonthCalendar !border !border-solid !border-[#DDDFE8] !rounded-[4px] !w-[137px] ${className}`}
          />
        </DemoItem>
      </LocalizationProvider>
    </div>
  );
}
