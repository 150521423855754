const subscribe = (eventName, listener, element = document) => {
  element.addEventListener(eventName, listener);
};

const unsubscribe = (eventName, listener, element = document) => {
  element.removeEventListener(eventName, listener);
};

const publish = (customEvent, data = {}, element = document) => {
  if (!customEvent) return;
  const newCustomEvent = new CustomEvent(customEvent, {
    detail: data,
  });
  element.dispatchEvent(newCustomEvent);
};

export { publish, subscribe, unsubscribe };
