import React from "react";
import "./style.scss";

export default function Tag(props) {
  const { data } = props;
  return <p className={data?.toLowerCase() === "custom" ? "custom" : "default"}>{data}</p>;
}

export function InUse(props) {
  const { data } = props;
  return <span className={data === "Yes" ? "InUse-Yes" : "InUse-No"}>{data}</span>;
}
