import React from "react";
import TabWrapper from "../../Tabs";
import { noop } from "../../../../../../Utils/commonUtils";

function Tabs({
  tabs = [],
  tabClicked = noop,
  selected = "",
  wrapperClass = "",
  isLoading = false,
}) {
  return (
    <div className={wrapperClass}>
      {tabs?.length ? (
        <TabWrapper
          tabs={tabs}
          tabClicked={tabClicked}
          selected={selected || tabs?.[0]?.label}
          isRoundedCorner="rounded"
          isLoading={isLoading}
          tooltip={tabs?.map((item) => item?.tooltip) || []}
        />
      ) : null}
    </div>
  );
}

export default Tabs;
