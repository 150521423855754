import React from "react";
import { Tooltip } from "@material-ui/core";

function HoverText(props) {
  const { data, showHover = false, tooltipKey, tableRow, prefix = "", suffix = "" } = props;

  return (
    <div className="text-wrapper !text-[#000]">
      {showHover ? (
        <Tooltip className="!text-[#000]" title={tableRow[tooltipKey]} arrow placement="top">
          <p>{data !== undefined ? `${prefix}${data}${suffix}` : "- - -"}</p>
        </Tooltip>
      ) : (
        <p>{data !== undefined ? `${prefix}${data}${suffix}` : "- - -"}</p>
      )}
    </div>
  );
}

export default HoverText;
