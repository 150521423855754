const initialData = {};
const initAction = { type: "", payload: {} };

export default function StrapiReducer(state = initialData, action = initAction) {
  switch (action.type) {
    case "strapi-500":
    case "strapi-502":
    case "strapi-503":
      return { errorCode: action.payload.status, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
}
