const editFormData = {};
const initAction = { type: "", payload: {} };

export default function CkAutoReducer(state = editFormData, action = initAction) {
  switch (action.type) {
    case "CUSTOMER_BUCKET_NAME":
      return { ...state, customerBucketNameRead: action.payload };
    case "SIGNUP_ENABLED":
      return { ...state, signupEnabled: action.payload };
    case "CUSTOMER_EXTERNAL_ID_READ":
      return { ...state, customerExternalIDRead: action.payload };
    case "CUSTOMER_EXTERNAL_ID_WRITE":
      return { ...state, customerExternalIDWrite: action.payload };
    case "IS_CK_AUTO_ENABLED":
      return { ...state, isCkAuto: action.payload || false };
    case "AUTO_CUSTOMER_METADATA":
      return { ...state, metadata: action.payload };
    default:
      return state;
  }
}
