const RESPONSE = (accountIdList, mavUserList) => [
  {
    id: "",
    name: "",
    systemGenerated: false,
    new: true,
    users: [],
    accounts: [],
    accountIdList,
    mavUserList,
    operation: "ADD",
  },
];

export default RESPONSE;

export const initialState = {
  isLoading: true,
  id: "",
  open: false,
  isUserClicked: false,
  formUpdated: false,
};

export const userMAVMsgs = {
  left: {
    heading: "No Users to Add",
    subHeading: "All Users have been added to the MAV.",
  },
  right: {
    heading: "No Users Added",
    subHeading: "Selected Users will be shown here.",
  },
  leftHeading: "Choose Users to Add to MAV",
  rightHeading: "Users added to MAV",
};

export const accountIdMAVMsgs = {
  left: {
    heading: "No Account IDs to Add",
    subHeading: "All Account IDs have been added to the MAV.",
  },
  right: {
    heading: "No Account IDs Added",
    subHeading: "Selected Account IDs will be shown here.",
  },
  leftHeading: "Choose Accounts IDs to Add to MAV",
  rightHeading: "Account IDs added to MAV",
};

export const tagsMavMsgs = {
  left: {
    heading: "No MAVs to Add",
    subHeading: "All MAVs are been added to the Tags",
  },
  right: {
    heading: "No MAVs Added",
    subHeading: "Selected MAVs will be shown here.",
  },
  leftHeading: "Choose MAVs to add with the Tag",
  rightHeading: "MAVs added to Tags",
};

export const DROP_DOWN_VALUES = [0, 1, 2];

export const CUSTOMER_TRANSFER_LIST_CONFIG = {
  left: {
    heading: "No Account IDs",
    subHeading: "All Account IDs have been added.",
  },
  right: {
    heading: "No Account IDs Added",
    subHeading: "Selected Account IDs will be shown here.",
  },
  leftHeading: "Choose Accounts IDs to Add",
  rightHeading: "Account IDs added",
};
