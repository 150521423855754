import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { BTN_ARRAY_MODAL } from "../../../../Constants/Constant";
import useDetectOutsideClick from "../../../../CustomHooks/useDetectOutsideClick";

export default function CloneModal({
  header,
  data = {},
  handleApplyCancel,
  onModalChange,
  setSelectedRows,
  selectedRows,
  handleModalClose,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const modalRef = useRef(null);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allKeys = Object.keys(data);
      setSelectedRows(allKeys);
    }
  };

  useDetectOutsideClick(modalRef, (e) => {
    e.stopPropagation();
    handleModalClose(null);
  });

  useEffect(() => {
    if (selectedRows.length === Object.keys(data).length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, data]);

  return (
    <div className="clone_modal_wrapper" ref={modalRef}>
      <div className="clone_modal_block">
        <div className="clone_modal_header">
          <h4>{header} </h4>
        </div>
        <div className="clone_modal_body">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />}
              label="Select All"
            />
            {Object.keys(data).map((itemKey) => (
              <FormControlLabel
                key={itemKey}
                control={
                  <Checkbox
                    checked={selectedRows.includes(itemKey)}
                    onChange={() => onModalChange(itemKey)}
                  />
                }
                label={itemKey}
              />
            ))}
          </FormGroup>
        </div>
        <div className="clone_modal_footer bg-[#F0F6FF] pt-[11px] pb-[10px] px-[11px] flex justify-end ">
          {BTN_ARRAY_MODAL.map((item) => (
            <button
              key={item.label}
              type="button"
              className={item?.class}
              onClick={() => handleApplyCancel(item?.label)}
            >
              {item?.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
