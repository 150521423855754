/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SELECTED_DASHBOARD_ENUM } from "./configuration";
import { getApiFun, updateRoutes } from "../helper/utils";
import { getTabs } from "../../Reservations/ReservedInstances/helper/constants";

const useCudos = ({ pathname }) => {
  // State for dashboard name
  const url = new URLSearchParams(window.location.search);
  const selectedServiceFromUrl = url.get("selected") || undefined;
  const pathArray = window.location.pathname?.split("/");
  const dashboardName = pathArray[pathArray.length - 1];
  const tabs = getTabs()?.map((item) => item.title);
  const getInitDasboardSelected = () => {
    if (tabs.length) {
      if (selectedServiceFromUrl && tabs?.findIndex((tab) => tab === selectedServiceFromUrl) !== -1)
        return selectedServiceFromUrl;
      return tabs[0];
    }
    return dashboardName;
  };
  const navigate = useNavigate();
  const accountDate = useSelector((state) => state.AccountDateReducer) || {};
  const [isLoading, setIsLoading] = useState(true);
  const [route, setRoute] = useState(pathname);
  const [activeGranularity, setActiveGranularity] = useState("Monthly");
  const [activeChartType, setActiveChartType] = useState("stackedcolumn2d");
  const [selected, setSelected] = useState(
    SELECTED_DASHBOARD_ENUM(activeChartType)[getInitDasboardSelected()]?.name
  );
  const [dashboardConfig, setDashboardConfig] = useState(
    SELECTED_DASHBOARD_ENUM(activeChartType)[getInitDasboardSelected()]
  );
  const [granularityLoader, setGranularityLoader] = useState(false);

  const [isDropdownChanged, setIsDropdownChanged] = useState(false);

  const apiHandler = async (
    granularity = "MONTHLY",
    isGranularityUpdated = false,
    dashboardConfiguration = {}
  ) => {
    if (isGranularityUpdated) setGranularityLoader(true);
    else setIsLoading(true);
    try {
      const configObj = cloneDeep(dashboardConfiguration?.config);
      if (configObj) {
        const apiArr = [];
        Object.keys(configObj || {}).forEach((key) => {
          if (isGranularityUpdated) {
            if (configObj[key]?.isGranularityApplied) {
              apiArr.push(getApiFun(configObj, key, granularity, dashboardConfiguration?.name));
            }
          } else {
            apiArr.push(getApiFun(configObj, key, granularity, dashboardConfiguration?.name));
          }
        });
        const response = await Promise.all(apiArr);
        response.forEach((res) => {
          if (configObj[res.name]) {
            configObj[res.name].data = res?.data;
            configObj[res.name].tabArr = res?.tabArr;
            configObj[res.name].options = res?.options;
            configObj[res.name].selectedOption = res?.selectedOption;
          }
        });
        setDashboardConfig({ ...dashboardConfiguration, config: configObj });
      }
    } finally {
      setIsLoading(false);
      setGranularityLoader(false);
    }
  };
  const tabClicked = (selectedTab, index, e) => {
    if (!e.ctrlKey && selectedTab !== selected) {
      setSelected(selectedTab);
      setDashboardConfig(SELECTED_DASHBOARD_ENUM(activeChartType)[selectedTab]);
      const data = new URLSearchParams(window.location.search);
      const query = `month=${data.get("month")}&year=${data.get("year")}`;
      const currentRoute = `${pathname}?${query}&selected=${selectedTab}`;
      setRoute(currentRoute);
      apiHandler(activeGranularity, false, SELECTED_DASHBOARD_ENUM(activeChartType)[selectedTab]);
    }
  };
  const onChartTypeChange = async (chartType) => {
    setGranularityLoader(true);
    const updatedObjects = [];
    Object?.keys(dashboardConfig?.config)?.forEach((ele) => {
      if (dashboardConfig.config[ele]?.isGranularityApplied) {
        updatedObjects?.push({ ...dashboardConfig.config[ele], type: chartType });
      }
    });
    const dashboardConfigClone = cloneDeep(dashboardConfig.config);
    updatedObjects?.forEach((ele) => {
      dashboardConfigClone[ele.name] = ele;
    });
    setDashboardConfig({ ...dashboardConfig, config: dashboardConfigClone });
    setActiveChartType(chartType);
    setTimeout(() => setGranularityLoader(false), 1000);
  };

  const onGranulityChange = (item) => {
    apiHandler(item?.toUpperCase(), true, dashboardConfig);
    setActiveGranularity(item);
  };

  const updateConfig = (name, updatedValue) => {
    const data = cloneDeep(dashboardConfig?.config);
    data[name] = updatedValue;
    setDashboardConfig({ ...dashboardConfig, config: data });
  };

  const initCall = () => {
    const selectedDashbaord = getInitDasboardSelected();
    const config = cloneDeep(
      isDropdownChanged ? dashboardConfig : SELECTED_DASHBOARD_ENUM()[selectedDashbaord]
    );
    setIsLoading(true);
    setSelected(selectedDashbaord);
    if (!isDropdownChanged) {
      setActiveGranularity("Monthly");
      setActiveChartType("stackedcolumn2d");
    }
    setDashboardConfig(config);
    apiHandler(
      !isDropdownChanged ? "Monthly" : activeGranularity,
      false,
      config,
      selectedDashbaord
    );
    setIsDropdownChanged(false);
  };

  useEffect(() => {
    const currentRoute = updateRoutes(tabs, accountDate, pathname, navigate);
    if (url.get("month") && url.get("year") && currentRoute !== route) {
      setRoute(currentRoute);
      initCall();
    }
  }, [url.get("month"), url.get("year")]);

  const selectedDateProp = () => {
    setIsDropdownChanged(true);
  };

  return {
    isLoading,
    activeGranularity,
    dashboardConfig: cloneDeep(dashboardConfig?.config),
    selectedService: dashboardName,
    onGranulityChange,
    activeChartType,
    onChartTypeChange,
    selected,
    setSelected,
    tabClicked,
    granularityLoader,
    tabs,
    accountDate,
    updateConfig,
    apiKey: dashboardConfig?.name,
    selectedDateProp,
    showGranularitySidebar:
      SELECTED_DASHBOARD_ENUM(activeChartType)?.[selected]?.showGranularitySidebar,
  };
};
export default useCudos;
