import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import commonService from "../../Services/commonService";
import Loader from "../Common/Loader";
import PageTitle from "../Common/PageTitle";
import Routes from "../../Config/Routes";
import { updatePartnerFavicon } from "../Login/utils";
import { preloadImages } from "../../Utils/userUtils";
import "./style.scss";
import { CONTACT_US } from "../../Constants/Constant";

// icons
import { ReactComponent as ArrowRightIcon } from "../../Assets/icons/blue-arrow-filled-right.svg";
// import { ReactComponent as CrossIcon } from "../../Assets/icons/cross-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "../../Assets/icons/blue-arrow-filled-left.svg";
import { COMPLETE_STEP_TEXT, SAVINGDELIVERED_TEXT, renderButtons } from "./marketPlaceUtils";
import { getCurrencySymbol } from "../../Utils/commonUtils";

function MarketPlace() {
  const [partnerLogo, setPartnerLogo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [partnerDisplayName, setPartnerDisplayName] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const pageType = urlParams.get("type");
  const customerId = urlParams.get("customerId");
  const enableriOps = urlParams.get("enableriOps");

  const getInitialData = async () => {
    try {
      if (process.env.REACT_APP_BASE_URL !== window.location.origin) {
        // api call partner logo
        const result = await commonService.partnerCreateUser();
        const iconResponse = result?.data?.data?.logo;
        const updatedPartnerDisplayName =
          result?.data?.data?.displayName || result?.data?.data?.partnerName || "";
        if (iconResponse) {
          await preloadImages(iconResponse);
        }
        const favIcon = {
          data: {
            data: result?.data?.data?.favicon,
          },
        };
        updatePartnerFavicon(favIcon);
        setPartnerLogo(iconResponse);
        setPartnerDisplayName(updatedPartnerDisplayName);
      } else {
        updatePartnerFavicon();
      }
      setIsLoading(false);
    } catch (error) {
      updatePartnerFavicon();
      navigate(Routes.LOGIN);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PageTitle pageTitle="Verify Email" customPartnerDisplayName={partnerDisplayName} />
          <div className="marketPlace">
            <div className="header">
              <div className="header-logo">
                <img src={partnerLogo} alt="logo" />
              </div>
              {/* <div className="close_page_button">
                <button type="button" onClick="/">
                  <CrossIcon /> Close this page
                </button>
              </div> */}
            </div>
            <div className="section">
              <div className="thankyou-section">
                <p className="first-msg">
                  Thank you for choosing CloudKeeper-Auto, A Zero-touch, AI-powered platform for
                  automated AWS RI management
                </p>
                <div className="marketplace-heading">
                  <p>
                    <span>You have successfully subscribed to CloudKeeper-</span>
                    <span className="pink-text">Auto</span>
                  </p>
                  <p>via AWS Marketplace</p>
                </div>
                <div className="icon-div-section">
                  {enableriOps === "true" ? (
                    <p className="text-[16px] metropolis_family text-[#253E66]">
                      {SAVINGDELIVERED_TEXT}
                    </p>
                  ) : (
                    <p className="text-[13px] flex align-center justify-center">
                      <span className="px-[5px]">
                        <ArrowLeftIcon className="inline-block" />
                      </span>
                      <span className="metropolis_family inline-block	!pt-[1px] text-[#253E66]">
                        {COMPLETE_STEP_TEXT}
                      </span>
                      <span className="px-[5px]">
                        <ArrowRightIcon className="inline-block" />
                      </span>
                    </p>
                  )}
                  <div className="icon-containing-box">
                    <div className="content">
                      {renderButtons(pageType, enableriOps, customerId, navigate)}
                    </div>
                  </div>
                </div>
                <div className="contact-section" data-testid="talk-to-our-team">
                  Have Questions? <a href={CONTACT_US}>Talk to our team</a>
                </div>
              </div>
            </div>
            <div className="cloud-bg">
              <div className="cloud-section">
                <div className="info-content">
                  <h3>12+</h3>
                  <p>years on AWS</p>
                </div>
                <div className="info-content">
                  <h3>300+</h3>
                  <p>Active Customers</p>
                </div>
                <div className="info-content">
                  <h3>{getCurrencySymbol()}100 Mn+</h3>
                  <p>of Savings Delivered</p>
                </div>
                <div className="info-content">
                  <h3>20%</h3>
                  <p>Average Savings on Total Bill</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default MarketPlace;
