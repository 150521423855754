import React, { Fragment, useState, useRef } from "react";

import "./style.scss";
import { formatWithCommas, removeCommas } from "../../../../FormConfig/Form/service/helper";

function PpaInput(props) {
  const {
    onGetData,
    id,
    placeholder,
    name,
    type,
    value = "",
    errorMessage = "",
    disabled = false,
    errorClass = "",
    parentClass = "",
    filterError = false,
    affix = "",
    affixPosition = "left",
    regex = "",
    commaFormatting = false,
  } = props;
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const onChangeHandler = (event) => {
    const inputValue = event.target.value;
    let rawValue = inputValue;
    if (affix && inputValue.includes(affix)) {
      rawValue = inputValue.replace(affix, "");
    }
    if (commaFormatting) {
      rawValue = removeCommas(rawValue);
    }
    onGetData(event.target.name, rawValue);
    if (filterError) {
      setFocused(true);
    }
  };

  const handleError = () => {
    if (regex) {
      const regexValue = !!String(value)?.match(regex);
      return !regexValue;
    }
    return false;
  };

  const valueFormat = (inputValue) => {
    if (!inputValue) return "";
    const numericValue = inputValue;
    let formattedValue = numericValue.replace(regex, "");

    if (affix) {
      let caretPosition = inputRef.current?.selectionStart || 0;
      if (affixPosition === "right") {
        formattedValue = numericValue + affix;
        caretPosition =
          (commaFormatting ? formatWithCommas(formattedValue).length : formattedValue.length) - 1;
      } else {
        formattedValue = affix + numericValue;
        caretPosition = Math.min(
          caretPosition + affix.length,
          commaFormatting ? formatWithCommas(formattedValue).length : formattedValue.length
        );
      }
      inputRef.current?.setSelectionRange(caretPosition, caretPosition);
    }
    if (commaFormatting) {
      return formatWithCommas(formattedValue);
    }

    return formattedValue;
  };

  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        value={valueFormat(value)}
        onChange={onChangeHandler}
        onBlur={() => {
          setFocused(true);
          handleError();
        }}
        className={`${
          focused && errorMessage.length > 0 ? "error_box" : "input_box"
        } ${parentClass}`}
        ref={inputRef}
      />
      {focused && errorMessage.length > 0 && (
        <p className={`${errorClass} error_message`}>{errorMessage}</p>
      )}
    </>
  );
}

export default PpaInput;
