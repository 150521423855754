import React, { useState, useEffect, useRef } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import "./style.scss";

function CustomDropDown(props) {
  const { options, handler, reset, selected } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options?.[0] ?? "Select an option");

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    // Close the dropdown when clicking outside
    setSelectedOption(selected || options?.[0] || "Select an option");

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [reset]);

  useEffect(() => {
    // Close the dropdown when clicking outside
    setSelectedOption(selected);
  }, [selected]);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className="selected-option"
        aria-hidden
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {selectedOption}{" "}
        <ArrowForwardIosSharpIcon
          className={`expend ${isOpen ? "expend-close" : ""}`}
          sx={{ fontSize: "0.5rem" }}
        />
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option) => (
            <li
              aria-hidden
              onClick={() => {
                setSelectedOption(option);
                handler(option);
                setIsOpen(false);
              }}
              className={`text-[#151E33] ${option === selectedOption ? "selected-li" : ""}`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomDropDown;
