import React from "react";

import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import { ReactComponent as ExcelIcon } from "../../../Assets/icons/others/excel-icon.svg";
import { ReactComponent as DownloadWhite } from "../../../Assets/icons/others/download-outlineWhite.svg";
import { ReactComponent as ExcelLoader } from "../../../Assets/icons/others/excel-loader.svg";

export default function ExcelDownload({
  downloadHandler,
  hasData = false,
  styles = "",
  size = "medium",
  excelLoading = false,
}) {
  return (
    <Tooltip title="Download xlsx" arrow placement="top">
      <Button
        id="download"
        variant="outlined"
        onClick={() => downloadHandler()}
        className={`downloadxBtn ${styles} ${
          hasData || excelLoading ? "download-disabled-icon" : ""
        }`}
        data-testid="download-button"
        size={size}
        disabled={hasData || excelLoading}
      >
        <span className="xcelIcon">
          <ExcelIcon />
        </span>
        <span className="xcelDownloadIc">
          {excelLoading ? <ExcelLoader className="excel-loading-gif" /> : <DownloadWhite />}
        </span>
      </Button>
    </Tooltip>
  );
}
