import React from "react";
import moment from "moment";
import ListPopup from "../../../../Common/ListPopup";
import TooltipV2 from "../../../../Common/TooltipV2";
import { LinearProgressWithLabel } from "../../../../CK-Auto/Common/ProgressBar";
import { getLocalStorageUserData } from "../../../../../Utils/commonUtils";
import { ReactComponent as MessageIcon } from "../Credits/Icons/ic-message.svg";
import { ReactComponent as EyeActiveIcon } from "../../../../../Assets/icons/eye-active.svg";
import { tableHeaderMapper } from "../Cloudfront/util";

const renderYear = (value) => {
  switch (value) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    case 4:
    case 5:
      return "th";
    default:
      return "";
  }
};

export const renderYears = (number) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return "st";
  }
  if (number % 10 === 2 && number % 100 !== 12) {
    return "nd";
  }
  if (number % 10 === 3 && number % 100 !== 13) {
    return "rd";
  }
  return "th";
};

export const PPA_MEDIA_CONNECT_DATA_HEADER = [
  {
    label: "Tenure",
    key: "term",
  },
  {
    label: "Commitments",
    key: "commitment",
  },
];

const getActiveClass = (currentIndex, activeIndex) => {
  const getActiveIndex = +activeIndex;
  if (currentIndex === getActiveIndex) {
    return "text-[#0A3CA2] !font-medium !bg-[#F0F6FF] activeRow";
  }
  if (currentIndex > getActiveIndex) {
    return "text-[#898A90] font-normal !bg-[#FFFFFF]";
  }
  if (currentIndex < getActiveIndex) {
    return "text-[#000000] font-normal !bg-[#FFFFFF]";
  }
  return null;
};

const getActiveClassForProgress = (currentIndex, activeIndex) => {
  const getActiveIndex = +activeIndex;
  if (currentIndex === getActiveIndex) {
    return "green";
  }
  if (currentIndex > getActiveIndex) {
    return "disabled";
  }
  if (currentIndex < getActiveIndex) {
    return "grey";
  }
  return null;
};

const renderData = (row, item, index, activeIndex) => {
  if (item.key === "progress") {
    return (
      <LinearProgressWithLabel
        value={Math.random() * 100}
        progressClass={`w-[50px] ${getActiveClassForProgress(index + 1, activeIndex)}`}
      />
    );
  }
  if (item.key === "term") {
    const updateIndex = index + 1;
    return (
      <span>
        {updateIndex}
        <sup>{renderYear(updateIndex)}</sup> Year{" "}
        {updateIndex === activeIndex ? (
          <span className="Active_Chip !py-[2px] !px-[4px]">Active</span>
        ) : null}
        {row?.nullified ? (
          <span className="Active_Chip !py-[2px] !px-[4px] !text-[#898A90] !border-[#898A90] !bg-transparent">
            Nullified
          </span>
        ) : null}
      </span>
    );
  }
  return (
    (
      <div>
        {row?.prevCommitment ? (
          <>
            <span className={`${row?.prevCommitment ? "line-through" : ""}`}>
              {row?.prevCommitment} {row?.prevUnit?.split("_")?.[0]}
            </span>{" "}
          </>
        ) : null}
        <span className={`${row?.prevCommitment ? "font-medium text-black" : ""}`}>
          {row[item?.key]} {row?.unit?.split("_")?.[0]}
        </span>
      </div>
    ) || "- - -"
  );
};

export const renderTermLevelSummary = (tableDataConfig, activeTenure) => (
  <table className="TermLevelSummary_tooltip_style">
    <thead className="TermLevelSummary_tooltip_tableHead">
      <tr className="TermLevelSummary_tooltip_tableRow">
        {tableDataConfig?.headers?.map((item) => (
          <td className="TermLevelSummary_tooltip_tableData term_level_Header !text-left !text-black">
            {item?.label}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableDataConfig?.data?.map((columnItem, columnIndex) => (
        <tr className="">
          {tableDataConfig?.headers?.map((rowItem) => (
            <td
              className={`TermLevelSummary_tooltip_tableRow term_level-row !text-left ${getActiveClass(
                columnIndex + 1,
                activeTenure
              )} ${columnItem?.nullified ? "nullified-row" : ""}`}
            >
              {renderData(columnItem, rowItem, columnIndex, activeTenure)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const termLevelSummaryData = (year) => (
  <span className="termLevelSummaryYear">
    {year || "- - -"}
    <sup>{renderYears(year)}</sup>
  </span>
);

export const defaultColumnsAtEnd = [
  {
    Header: "Chargeback Type",
    accessor: "chargeBack",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "Chargeback Account ID",
    accessor: "chargeBackAccountId",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "End Date",
    accessor: "endDate",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Last Updated At",
    accessor: "lastUpdatedAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Last Updated By",
    accessor: "lastUpdatedBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
];

const name = (handlePreview) => ({
  Header: "Name",
  accessor: "name",
  sortedIcon: true,
  bodyClass: "whitespace-nowrap normal-case z-19 bg-white !text-left",
  headerClass: "whitespace-nowrap !z-20 !text-left",
  props: {
    defaultSelectedHeader: true,
    disabledMenuItem: true,
  },
  render: (item) =>
    item?.name && (
      <div className="flex items-center">
        {item?.name}
        <EyeActiveIcon
          className="ml-[10px] w-[14px] h-[14px] cursor-pointer"
          onClick={() => handlePreview(item)}
        />
      </div>
    ),
});

export const PPA_HEADERS = (getCommentHistory, handlePreview) => [
  {
    Header: "",
    accessor: "checkboxId",
    bodyClass: "text-center whitespace-nowrap normal-case !sticky left-0 !z-30 bg-white",
    headerClass: "whitespace-nowrap !z-30 text-center !sticky left-0",
    props: {
      doNotAddColumnSelector: true,
      hideOnRO: true,
    },
  },
  {
    Header: "CBU(s)",
    accessor: "cbuNames",
    sortedIcon: true,
    props: {
      shouldSort: true,
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
    component: ListPopup,
    bodyClass:
      "whitespace-nowrap normal-case !text-left !sticky left-[30px] !z-30 bg-white !px-[8px]",
    headerClass: "whitespace-nowrap !text-left !sticky left-[30px] !z-30 bg-white !px-[8px]",
  },
  {
    ...name(handlePreview),
  },
  {
    Header: "Region(s)",
    accessor: "regionList",
    component: ListPopup,
    props: {
      dataCount: 1,
      shouldSort: false,
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
  {
    Header: "Pricing(s)",
    accessor: "pricingList",
    component: ListPopup,
    props: {
      dataCount: 1,
      shouldSort: false,
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
  {
    Header: "Tenure",
    accessor: "tenure",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },
  {
    Header: "Term Level Summary",
    accessor: "termLevelSummary",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case z-18 !text-left",
    headerClass: "whitespace-nowrap !text-left",
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_term_level_summary_V2"
        displayText={() => (
          <div className="Edp_TooltipV2_Header">
            {item?.termLevelSummary > 0
              ? termLevelSummaryData(item?.termLevelSummary, item?.status)
              : "- - -"}
            {item?.status === "Active" && <span className="Active_Chip">{item?.status}</span>}
          </div>
        )}
      >
        {renderTermLevelSummary(
          {
            data: item?.commitmentList,
            headers: PPA_MEDIA_CONNECT_DATA_HEADER,
          },
          item?.termLevelSummary
        )}
      </TooltipV2>
    ),
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "number",
  },
  {
    Header: "Comments",
    accessor: "comments",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    render: (item) =>
      item?.commentsAvailable ? (
        <button type="button" className="w-full" onClick={() => getCommentHistory(item)}>
          <MessageIcon />
        </button>
      ) : (
        <div className="w-full text-center">- - -</div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Exclusion Preference",
    accessor: "exclusionPreference",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    searchable: true,
    props: {
      defaultSelectedHeader: true,
      disabledMenuItem: true,
    },
  },

  ...defaultColumnsAtEnd,
];

export const createPartnerPPAHeaders = (tabSelected, getCommentHistory, getPreviewData) => {
  switch (tabSelected) {
    case "CloudFront":
      return PPA_HEADERS(getCommentHistory, getPreviewData);
    case "MediaConnect":
      return PPA_HEADERS(getCommentHistory, getPreviewData);
    case "MediaTailor":
      return PPA_HEADERS(getCommentHistory, getPreviewData);
    case "Advanced Shield":
      return PPA_HEADERS(getCommentHistory, getPreviewData);
    case "MediaLive":
      return PPA_HEADERS(getCommentHistory, getPreviewData);
    default:
      return [];
  }
};

export const readOnlyTableHeaders = (tabSelected, headers) => {
  const updatedHeaders = headers?.map((header) => ({
    ...header,
    secondLabel: tableHeaderMapper[header?.label] || "",
    type: "table-input",
  }));

  return [
    {
      label: "",
      secondLabel: " ",
      value: "childRegions",
      type: "text",
    },
    ...updatedHeaders,
  ];
};

export const getPartnerInvoideDate = () => {
  const userData = getLocalStorageUserData();
  if (userData) {
    const {
      currentUser: { lastInvoiceDate },
    } = userData;
    return moment(lastInvoiceDate)?.format("DD-MMM-YYYY");
  }
  return moment();
};

export const transFormInitValues = (initvalues, selectedTab) => {
  const transformedValues = initvalues;
  transformedValues.cbu = initvalues?.cbuNames?.map((cbuName) => ({
    label: cbuName,
    value: cbuName,
    checked: true,
  }));

  transformedValues.region = initvalues?.regionList?.map((region) => ({
    label: region,
    value: region,
    checked: true,
  }));

  transformedValues.pricing = initvalues?.pricingList?.map((pricing) => ({
    label: pricing,
    value: pricing,
    checked: true,
  }));

  transformedValues.configureCommitments = {
    commitmentStartDate: initvalues?.startDate,
    tenure: initvalues?.tenure,
    shortfallCharge: initvalues?.shortfallCharge,
    tableData: initvalues?.commitments?.map((commitment) => ({
      year: commitment?.tenure,
      qty: commitment?.commitment,
      unit: {
        value: commitment?.unit,
        label: commitment?.unit?.replace("_", "/").replace(/YEAR/i, "Year"),
      },
    })),
    customerAccount: {
      value: initvalues?.chargeBackAccountId,
      label: initvalues?.chargeBackAccountId,
    },
    exclusionPreference: initvalues?.exclusionPreference,
  };

  transformedValues.configure = {
    isEditable: false,
    headers: readOnlyTableHeaders(selectedTab, initvalues?.pricingConfig),
    region: initvalues?.regionList?.map((region) => ({
      childRegions: region,
    })),
    aosSize: `${initvalues?.aos || "---"} `,
    configurationType: initvalues?.rateType || "discount",
    newRegion: true,
    newRegionConfig: { ...initvalues?.newRegionConfig?.config },
    values: Array.isArray(initvalues?.privatePricingConfigs)
      ? initvalues?.privatePricingConfigs?.map((config) => ({
          ...config?.config,
          childRegions: config?.region,
        }))
      : { ...initvalues?.privatePricingConfigs },

    tableData: initvalues?.regionList?.map((region) => ({
      childRegions: region,
    })),
  };

  transformedValues.configureWithTabs = {
    isEditable: false,
    headers: initvalues?.pricingConfig,
    region: initvalues?.regionList?.map((region) => ({
      childRegions: region,
    })),
    aosSize: `${initvalues?.aos || "- - -"} `,
    configurationType: "discount" || initvalues?.rateType,
    newRegion: true,
    newRegionConfig: { ...initvalues?.newRegionConfig?.config },
    values: Array.isArray(initvalues?.privatePricingConfigs)
      ? initvalues?.privatePricingConfigs?.map((config) => ({
          ...config?.config,
          childRegions: config?.region,
        }))
      : { ...initvalues?.privatePricingConfigs },
    tableData: initvalues?.regionList?.map((region) => ({
      childRegions: region,
    })),
  };

  return transformedValues;
};

export const transformPPAContentResponse = (data) =>
  data?.map((item) => ({
    ...item,
    startDate: item?.startDate ? moment(item?.startDate)?.format("DD-MMM-YYYY") : "",
    endDate: item?.endDate ? moment(item?.endDate)?.format("DD-MMM-YYYY") : "",
    createdAt: item?.createdAt ? moment(item?.createdAt)?.format("DD-MMM-YYYY, HH:mm") : "",
    lastUpdatedAt: item?.lastUpdatedAt
      ? moment(item?.lastUpdatedAt)?.format("DD-MMM-YYYY, HH:mm")
      : "",
  }));
export const billdeskMaxDate = () => moment("01-01-2099");

export const exclusionPreferenceValidation = {
  type: "radio",
  name: "exclusionPreference",
  placeHolder: "Exclusion Preference",
  className: "cld_cloudfront_radio_group exclusion-preference",
  buttonStyle: false,
  topLabel: {
    text: "Exclusion Preference",
    fontSize: "x-small",
    tooltipText:
      "Handle overlapping of discounted SKUs/Service by excluding the whole service or just selected SKUs",
    class: "title flex items-center mandatory",
  },
  label: "Exclusion Preference",
  childClassName: "pt-[5px] no_image",
  options: [
    { label: "Service", value: "Service" },
    { label: "SKU", value: "SKU" },
  ],
  validations: {
    rules: {
      required: true,
    },
    messages: {
      required: "This field is required",
    },
  },
};
