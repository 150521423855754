import React from "react";

export default function List({ heading, lists = [], ulClass, listClass, wrapperClass }) {
  return (
    <div className={wrapperClass}>
      <div className={heading?.class}>
        <p className={heading?.headerTextClass}>{heading?.name}</p>
        <p className={heading?.descriptionClass}>{heading?.description}</p>
      </div>
      <ul className={ulClass}>
        {lists?.map((list) => (
          <li key={Math.random()} className={listClass}>
            {list}
          </li>
        ))}
      </ul>
    </div>
  );
}
