/* eslint-disable complexity */
import React from "react";
import TextField from "@mui/material/TextField";
import Button from "./Button";

export default function input({
  type,
  name,
  handleChange,
  value = "",
  label,
  error,
  placeHolder,
  toggleIcon,
  toggleActionButton,
  toggleCallback,
  toggleActionBtnCallback,
  selectedVisibilityIcon,
  inputIcon,
  inputClass,
  floatingPlaceholder = false,
  suffix,
  prefixIcon,
  parentClass,
  multiline,
  rows,
  labelClass,
  showLabel,
  touched,
  onBlurHandler,
  disabled,
  disabledClass,
  filledStyle,
  autoComplete = "on",
  toolTip,
  maxLength = null,
  prefix,
  showInfoMessage = {},
  actionButtonConfig = {},
}) {
  const { isEnabled: isInfoMessageRequired, component: InfoBoxComponent } = showInfoMessage;
  const { buttonText = "", btnVariant = "primary", btnsize = "small" } = actionButtonConfig;

  return floatingPlaceholder ? (
    <>
      <TextField
        className={inputClass}
        value={value}
        type={type}
        name={name}
        label={placeHolder}
        variant="outlined"
        onChange={handleChange}
        error={touched && error}
        helperText={touched && error}
        multiline={multiline}
        rows={rows}
        onBlur={onBlurHandler}
      />
      {toggleIcon && (
        <span
          aria-hidden="true"
          className="showHide_password"
          onClick={() => toggleCallback(type, name)}
        >
          {selectedVisibilityIcon}
        </span>
      )}
    </>
  ) : (
    <div className={`input-field-container ${parentClass}`}>
      {showLabel && (
        <>
          <span className={`${labelClass && labelClass} text-[12px] text-black block`}>
            {prefixIcon && prefixIcon}
            {label}
          </span>
          {prefix && <span className={`${prefix?.style}`}>{prefix?.text}</span>}
        </>
      )}
      {isInfoMessageRequired && InfoBoxComponent}
      {inputIcon && (
        <div className={`icon ${showLabel && "!top-[31px]"}`}>
          <img src={inputIcon} alt={`${showLabel} Icon`} />
        </div>
      )}
      <input
        name={name}
        type={type}
        className={`${inputClass} ${
          value && `${filledStyle} filled`
        } !mt-[5px] !active:text-[#0a3ca2] ${touched && error && "error_box"} ${
          !inputIcon && "!pl-4"
        } ${disabledClass || ""}`}
        value={value}
        id={name}
        placeholder={disabledClass ? "" : placeHolder}
        onChange={handleChange}
        onBlur={onBlurHandler}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength || null}
      />
      {suffix && (
        <span className={`${suffix.style} ${touched && error && "text-red"}`}>
          {suffix.text}
          {suffix.icon}
        </span>
      )}
      {toolTip && <div className={toolTip.label}>{toolTip.component}</div>}
      {toggleIcon && (
        <span
          aria-hidden="true"
          className={`showHide_password ${showLabel && "!top-[24px] !right-2"} ${
            (disabled || !value) && "disabled"
          }`}
          onClick={() => !disabled && toggleCallback(type, name)}
        >
          {typeof selectedVisibilityIcon === "string" ? (
            <img src={selectedVisibilityIcon} alt="logo" />
          ) : (
            selectedVisibilityIcon
          )}
        </span>
      )}
      {toggleActionButton && (
        <span className="ck_action_button">
          <Button
            text={buttonText}
            variant={btnVariant}
            className="rounded-[3px] !font-bold ml-[10px] icon_btn"
            size={btnsize}
            disabled={disabled || !value || error}
            handleClick={() => toggleActionBtnCallback(type, name)}
          />
        </span>
      )}
      {touched && error && <p className={`error_message ${suffix?.errorStyle}`}>{error}</p>}
    </div>
  );
}
