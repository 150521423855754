import React from "react";
import cloneDeep from "lodash.clonedeep";
import axios from "axios";
import { getLocalStorageUserData, getToast } from "../../../../Utils/commonUtils";
import commonService from "../../../../Services/commonService";
import Routes from "../../../../Config/Routes";
import subStep1 from "./Assets/subStep1.png";
import subStep2 from "./Assets/subStep2.png";
import subStep3 from "./Assets/subStep3.png";
import subStep4 from "./Assets/subStep4.png";
import subStep5 from "./Assets/subStep5.png";
import subStep6 from "./Assets/subStep6.png";
import subStep7 from "./Assets/subStep7.png";
import subStep8 from "./Assets/subStep8.png";
import subStep9 from "./Assets/subStep9.png";
import subStep10 from "./Assets/subStep10.png";
import subStep11 from "./Assets/subStep11.png";
import subStep12 from "./Assets/subStep12.png";
import subStep13 from "./Assets/subStep13.png";
import subStep14 from "./Assets/subStep14.png";
import subStep15 from "./Assets/subStep15.png";
import subStep16 from "./Assets/subStep16.png";
import subStep17 from "./Assets/subStep17.png";
import subStep18 from "./Assets/subStep18.png";
import subStep19 from "./Assets/subStep19.png";
import subStep20 from "./Assets/subStep20.png";
import subStep21 from "./Assets/subStep21.png";
import subStep22 from "./Assets/subStep22.png";
import subStep23 from "./Assets/subStep23.png";
import subStep24 from "./Assets/subStep24.png";
import subStep25 from "./Assets/subStep25.png";
import subStep26 from "./Assets/subStep26.png";
import subStep27 from "./Assets/subStep27.png";
import subStep28 from "./Assets/subStep28.png";
import subStep29 from "./Assets/subStep29.png";
import subStep30 from "./Assets/subStep30.png";
import subStep31 from "./Assets/subStep31.png";
import subStep32 from "./Assets/subStep32.png";
import subStep33 from "./Assets/subStep33.png";
import subStep35 from "./Assets/subStep35.png";
import subStep36 from "./Assets/subStep36.png";
import subStep37 from "./Assets/subStep37.png";
import subStep39 from "./Assets/subStep39.png";
import subStep40 from "./Assets/subStep40.png";
import subStep41 from "./Assets/subStep41.png";
import subStep42 from "./Assets/subStep42.png";

export const config = [
  {
    heading: "Create Cost Export",
    subHeading: "Complete the necessary input fields as part of the process",
    status: "Pending",
    subSteps: [
      {
        id: 1,
        heading: [
          { label: "Login to your Azure Account and navigate to the " },
          {
            label: "Cost Export",
            linkURL:
              "https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/exports/open/exports/openedBy/AzurePortal",
          },
          {
            label: " section and and click “+Create” option.",
          },
        ],
        image: subStep1,
        carouselTooltipText: "Go to cost export",
        tooltip: {
          text: "On the Cost Export page, Click on “+Create”",
          positionClass: { top: "22.4%", left: "30.5%" },
          previousBtn: { text: "< Previous", class: "cursor-not-allowed", disabled: true },
          nextBtn: { text: "Next >", class: "", disabled: false },
          arrowClass: { top: "25px", left: "-4px" },
        },
      },
      {
        id: 2,
        heading: [
          {
            label:
              "After selecting “+Create,” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep2,
        carouselTooltipText: "Enter new cost export name",
        tooltip: {
          text: "Enter the name for the Cost Export",
          positionClass: { top: "80%", left: "38.6%" },
          arrowClass: { top: "-4px", left: "35px" },
          // borderClass: "w-[90px] h-[40px]",
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          copyItem: {
            text: "e.g,",
            copyText: "ck-cost-export",
          },
        },
      },
      {
        id: 3,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep3,
        carouselTooltipText: "Select export type",
        tooltip: {
          text: "Select the “Daily Export of Month-to-Date Cost” option as it is.",
          positionClass: { top: "89%", left: "42.2%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "35px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 4,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep4,
        carouselTooltipText: "Create new storage account",
        tooltip: {
          text: "Click “Create New” to set up a new storage account.",
          positionClass: { top: "54.2%", left: "30%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "25px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 5,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep5,
        carouselTooltipText: "Select a subscription",
        tooltip: {
          text: "Select the subscription in which you want to create the storage account in.",
          positionClass: { top: "36.4%", left: "37%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "unset", left: "35px", bottom: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 6,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep6,
        carouselTooltipText: "Select resource group",
        tooltip: {
          text: "Select the Resource Group from the drop-down",
          positionClass: { top: "69%", left: "40.7%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "22px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 7,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep7,
        carouselTooltipText: "Add storage account name",
        tooltip: {
          text: "Enter New Account Name.",
          positionClass: { top: "28%", left: "37.2%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "unset", left: "25px", bottom: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 8,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep8,
        carouselTooltipText: "Add container name",
        tooltip: {
          text: "Enter Container Name.",
          positionClass: { top: "90%", left: "38%", width: "249px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "35px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          copyItem: {
            text: "e.g,",
            copyText: "ck-lens-container",
          },
        },
      },
      {
        id: 9,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep9,
        carouselTooltipText: "Add directory name",
        tooltip: {
          text: "Enter Directory Name.",
          positionClass: { top: "40%", left: "40%", width: "279px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "unset", left: "28px", bottom: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          copyItem: {
            text: "e.g,",
            copyText: "cost_export/lens",
          },
        },
      },
      {
        id: 10,
        heading: [
          {
            label:
              "After selecting “+Create” a new window titled “New Export” will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep10,
        carouselTooltipText: `Click on${" "}"Create"`,
        tooltip: {
          text: "Click “Create,” and your new storage account will be generated.",
          positionClass: { top: "81.5%", left: "13.6%", width: "272px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 11,
        heading: [
          {
            label:
              "After being redirected to the Cost Export section, select the recently created cost export. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep11,
        carouselTooltipText: "Paste the copied storage account",
        tooltip: {
          text: "Copy the Storage Account Name and paste here:",
          positionClass: { top: "55.4%", left: "59.5%", width: "354px" },
          // "top-[14%] left-[34%]",
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "100px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Account Name",
            name: "storageAccountName",
            errorMessage: "Invalid input",
            regex: /^[a-z0-9]{3,24}$/,
            key: "storageAccountName",
          },
        },
      },
      {
        id: 12,
        heading: [
          {
            label:
              "After being redirected to the Cost Export section, select the recently created cost export. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep12,
        carouselTooltipText: "Paste the copied storage container name",
        tooltip: {
          text: "Copy the Storage Container Name and paste here:",
          positionClass: { top: "70.4%", left: "61.3%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "96px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Storage Container Name",
            name: "containerName",
            errorMessage: "Invalid input",
            regex: /^[a-z0-9](?:[a-z0-9]|-(?=[a-z0-9])){3,63}$/,
            key: "containerName",
          },
        },
      },
      {
        id: 13,
        heading: [
          {
            label:
              "After being redirected to the Cost Export section, select the recently created cost export. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep13,
        carouselTooltipText: "Paste the copied storage directory",
        tooltip: {
          text: "Copy the Storage Directory and paste here:",
          positionClass: { top: "76%", left: "61.3%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "97px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Storage Directory",
            name: "paths",
            errorMessage: "Invalid input",
            regex: /^.{0,}/,
            // /^.{3,}/ <--regex for minimum 2 char
            key: "paths",
          },
        },
      },
    ],
  },
  {
    heading: "Create a Role",
    subHeading: "Complete the necessary input fields as part of the process.",
    status: "Pending",
    subSteps: [
      {
        id: 1,
        heading: [
          { label: "Navigate to " },
          {
            label: "Subscriptions",
            linkURL: "https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2",
          },
          {
            label:
              " section and select the subscription where you previously created the storage account. Proceed by following the specified steps.",
          },
        ],
        image: subStep14,
        carouselTooltipText: "Select previously created subscription",
        tooltip: {
          text: "Select the subscription where you previously created the storage account.",
          positionClass: { top: "79%", left: "1.5%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "54px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 2,
        heading: [
          {
            label:
              "Upon clicking the recently created subscription, you will be redirected to its detailed page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep15,
        carouselTooltipText: "Paste the copied subscription id",
        tooltip: {
          text: "Copy the “Subscription ID” and paste here:",
          positionClass: { top: "75.4%", left: "37%", width: "394px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "90px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Subscription ID",
            name: "subscriptionId",
            errorMessage: "Invalid input",
            regex: /^.{0,}/,
            key: "subscriptionId",
          },
        },
      },
      {
        id: 3,
        heading: [
          {
            label:
              "Upon clicking the recently created subscription, you will be redirected to its detailed page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep16,
        carouselTooltipText: "Select Access Control (IAM)",
        tooltip: {
          text: "Select “Access Control (IAM)” from the left menu.",
          positionClass: { top: "45%", left: "21%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 4,
        heading: [
          {
            label:
              "Upon clicking the recently created subscription, you will be redirected to its detailed page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep17,
        carouselTooltipText: "Add a custom role",
        tooltip: {
          text: "After clicking on  “Access Control (IAM),” proceed to click on “+Add” and select “Add custom role.”",
          positionClass: { top: "47%", left: "45%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 5,
        heading: [
          {
            label:
              "Upon selecting “Access Control (IAM),” a new window will open to facilitate the creation of a custom role. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep18,
        carouselTooltipText: "Select JSON",
        tooltip: {
          text: "Click on “JSON”",
          positionClass: { top: "15.2%", left: "28.2%", width: "219px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 6,
        heading: [
          {
            label:
              "Upon selecting “Access Control (IAM),” a new window will open to facilitate the creation of a custom role. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep19,
        carouselTooltipText: "Edit JSON",
        tooltip: {
          text: "Click on “Edit”",
          positionClass: { top: "50.6%", left: "59.2%", width: "224px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-2px", left: "62px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 7,
        heading: [
          {
            label:
              "Upon selecting “Access Control (IAM),” a new window will open to facilitate the creation of a custom role. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep20,
        carouselTooltipText: "Copy the JSON code",
        tooltip: {
          text: "Copy the code shown below and paste on custom role page under JSON tab.",
          positionClass: { top: "-10%", left: "42%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "57%", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          copyContainer: `{
            "properties": {
            "roleName": "ck-read-cost-exports",
            "description": "",
            "assignableScopes": [
            "/subscriptions/<subscription-id>"
            ],
            "permissions": [
            {
            "actions": [
            "Microsoft.Storage/storageAccounts/blobServices/generateUserDelegationKey/action",
            "Microsoft.Storage/storageAccounts/blobServices/containers/read"
            ],
            "notActions": [],
            "dataActions": [
            "Microsoft.Storage/storageAccounts/blobServices/containers/blobs/read"
            ],
            "notDataActions": []
            }
            ]
            }}`,
        },
      },
      {
        id: 8,
        heading: [
          {
            label:
              "Upon selecting “Access Control (IAM),” a new window will open to facilitate the creation of a custom role. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep21,
        carouselTooltipText: "Save the code that has been pasted",
        tooltip: {
          text: "Click on “Save”",
          positionClass: { top: "55.6%", left: "59%", width: "224px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "65px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
    ],
  },
  {
    heading: "Create a Member",
    subHeading: "Complete the necessary input fields as part of the process",
    status: "Pending",
    subSteps: [
      {
        id: 1,
        heading: [
          { label: "Navigate to " },
          {
            label: "App Registration.",
            linkURL:
              "https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade",
          },
          {
            label: " Click “+ New Registration” option.",
          },
        ],
        image: subStep22,
        carouselTooltipText: "Navigate to app registration",
        tooltip: {
          text: "Click on “+ New Registration”",
          positionClass: { top: "13.5%", left: "14.4%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "33px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 2,
        heading: [
          {
            label:
              "Upon selecting “New Registration,” the subsequent page will be displayed. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep23,
        carouselTooltipText: "Enter name for the application",
        tooltip: {
          text: "Enter name",
          positionClass: { top: "54%", left: "3.4%", width: "315px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "35px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          copyItem: {
            text: "For e.g,",
            copyText: "ck-read-app",
          },
        },
      },
      {
        id: 3,
        heading: [
          {
            label:
              "Upon selecting “New Registration,” the subsequent page will be displayed. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep24,
        carouselTooltipText: "Choose supported account type",
        tooltip: {
          text: "Choose the option “Accounts in any organizational directory (Any Microsoft Entra ID tenant - Multitenant” and click “Register”",
          positionClass: { top: "68.4%", left: "42.1%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "23px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 4,
        heading: [
          {
            label:
              "Upon selecting “New Registration,” the subsequent page will be displayed. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep25,
        carouselTooltipText: "Register the new application",
        tooltip: {
          text: "Click on “Register” and new app will be registered.",
          positionClass: { top: "84%", left: "11.5%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "26px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 5,
        heading: [
          {
            label:
              "Upon selecting “Register,” you will be redirected to the recently created application page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep26,
        carouselTooltipText: "Paste the copied Application (Client) ID",
        tooltip: {
          text: "Copy the Application (Client) ID and paste here:",
          positionClass: { top: "74%", left: "47%", width: "414px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "42px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Application (Client) ID",
            name: "applicationId",
            errorMessage: "Invalid input",
            regex:
              /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
            key: "applicationId",
          },
        },
      },
      {
        id: 6,
        heading: [
          {
            label:
              "Upon selecting “Register,” you will be redirected to the recently created application page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep27,
        carouselTooltipText: "Paste the copied object ID",
        tooltip: {
          text: "Copy the Object ID and paste here:",
          positionClass: { top: "81%", left: "49.6%", width: "384px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "35px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Object ID",
            name: "objectId",
            errorMessage: "Invalid input",
            regex:
              /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
            key: "objectId",
          },
        },
      },
      {
        id: 7,
        heading: [
          {
            label:
              "Upon selecting “Register,” you will be redirected to the recently created application page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep28,
        carouselTooltipText: "Paste the copied Directory (Tenant) ID",
        tooltip: {
          text: "Copy the Directory (Tenant) ID and paste here:",
          positionClass: { top: "83.5%", left: "51%", width: "384px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "35px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Directory (Tenant) ID",
            name: "tenantId",
            errorMessage: "Invalid input",
            regex:
              /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
            key: "tenantId",
          },
        },
      },
      {
        id: 8,
        heading: [
          {
            label:
              "Upon selecting “Register,” you will be redirected to the recently created application page. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep29,
        carouselTooltipText: "Add client Credentials",
        tooltip: {
          text: "Enter Client Credentials.",
          positionClass: { top: "69.2%", left: "61.4%", width: "220px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "-4px", left: "172px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 9,
        heading: [
          {
            label:
              "Navigate to the “Certificates & Secrets” section on the same page by clicking on it in the left menu. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep30,
        carouselTooltipText: "Go to Certificate & Secrets",
        tooltip: {
          text: "Click on Certificates & Secrets.",
          positionClass: { top: "73.2%", left: "17%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "22px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 10,
        heading: [
          {
            label:
              "Navigate to the “Certificates & Secrets” section on the same page by clicking on it in the left menu. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep31,
        carouselTooltipText: "Select Client Secrets",
        tooltip: {
          text: "Select “Client Secrets”",
          positionClass: { top: "53.8%", left: "41.9%", width: "253px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "22px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 11,
        heading: [
          {
            label:
              "Navigate to the “Certificates & Secrets” section on the same page by clicking on it in the left menu. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep32,
        carouselTooltipText: `Click${" "}"+ New Client Secret"`,
        tooltip: {
          text: "Select “+New client secret,” and proceed to add the appropriate description and set the expiration date.",
          positionClass: { top: "70.3%", left: "36.9%" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "25px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 12,
        heading: [
          {
            label:
              "Navigate to the “Certificates & Secrets” section on the same page by clicking on it in the left menu. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep33,
        carouselTooltipText: "Paste the copied value",
        tooltip: {
          text: "Copy the Value and paste here:",
          positionClass: { top: "29%", left: "40.5%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "unset", left: "unset", right: "58px", bottom: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
          inputContainer: {
            label: "Value",
            name: "value",
            errorMessage: "Invalid input",
            regex: /^.{0,}/,
            key: "secretValue",
          },
        },
      },
      // {
      //   id: 13,
      //   heading: [
      //     {
      //       label:
      //         "Navigate to the “Certificates & Secrets” section on the same page by clicking on it in the left menu. Proceed by following the specified steps.",
      //       linkURL: "",
      //     },
      //   ],
      //   image: subStep33,
      //   tooltip: {
      //     text: "Copy the Value and paste here:",
      //     positionClass: { top: "24%", left: "34%" },
      //     // borderClass: "w-[90px] h-[40px]",
      //     arrowClass: { top: "unset", left: "unset", right: "55px", bottom: "-4px" },
      //     previousBtn: { text: "< Previous", class: "", disabled: false },
      //     nextBtn: { text: "Next >", class: "", disabled: false },
      //     inputContainer: {
      //       label: "Value",
      //       name: "value",
      //       errorMessage: "Invalid input",
      //       regex: /^[a-zA-Z]+(?:-[a-zA-Z]+)+$/,
      //       key: "value",
      //     },
      //   },
      // },
    ],
  },
  {
    heading: "Attach Role To Storage Account",
    subHeading: "",
    status: "Pending",
    subSteps: [
      {
        id: 1,
        heading: [
          { label: "Navigate to " },
          {
            label: "Storage Accounts",
            linkURL:
              "https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Storage%2FStorageAccounts",
          },
          {
            label: " and click on the storage account that you created while creating cost export.",
          },
        ],
        image: subStep35,
        carouselTooltipText: "Go to storage account",
        tooltip: {
          text: "After clicking on the created storage account, a new window will appear. Proceed by clicking on “Access Control (IAM).”",
          positionClass: { top: "51%", left: "43%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 2,
        heading: [
          { label: "Navigate to " },
          {
            label: "Storage Accounts",
            linkURL:
              "https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Storage%2FStorageAccounts",
          },
          {
            label: " and click on the storage account that you created while creating cost export.",
          },
        ],
        image: subStep36,
        carouselTooltipText: "Add role assignment",
        tooltip: {
          text: "Click on “+Add” and select “Add role assignment.”",
          positionClass: { top: "24%", left: "63%", width: "299px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 3,
        heading: [
          {
            label:
              "Upon clicking “add role assignment” a subsequent window will open. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep37,
        carouselTooltipText: "Choose the previously created role",
        tooltip: {
          text: "Choose the role previously created and proceed by clicking “Next.”",
          positionClass: { top: "80%", left: "31.7%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 4,
        heading: [
          {
            label:
              "Clicking on next, you will be redirected to the next adding role assigment. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep39,
        carouselTooltipText: "Assign access",
        tooltip: {
          text: "In the “Members” tab, assign access to “User, group, or service principal.”",
          positionClass: { top: "38%", left: "31.5%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 5,
        heading: [
          {
            label:
              "Clicking on next, you will be redirected to the next adding role assigment. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep40,
        carouselTooltipText: `Click on${" "}"+ select members" `,
        tooltip: {
          text: "Click on “+Select Members”",
          positionClass: { top: "52%", left: "32%", width: "354px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "45px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 6,
        heading: [
          {
            label:
              "Clicking on next, you will be redirected to the next adding role assigment. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep41,
        carouselTooltipText: "Choose the created app",
        tooltip: {
          text: "Choose the app created in the previous steps. Click on “Select” to confirm",
          positionClass: { top: "72%", right: "33%", width: "347px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "60%", left: "unset", right: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 7,
        heading: [
          {
            label:
              "Clicking on next, you will be redirected to the next adding role assigment. Proceed by following the specified steps.",
            linkURL: "",
          },
        ],
        image: subStep42,
        carouselTooltipText: "Confirm and proceed",
        tooltip: {
          text: "Click on “Review + Assign” to confirm",
          positionClass: { top: "83%", left: "13.5%", width: "347px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "38px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "", disabled: false },
        },
      },
      {
        id: 8,
        heading: [
          {
            label:
              "The onboarding summary section displays all the information you have submitted. Please click on “Confirm and Proceed” to finalize the onboarding process.",
            linkURL: "",
          },
        ],
        image: subStep42,
        carouselTooltipText: "Confirm and proceed",
        tooltip: {
          text: "Click on “Review + Assign” to confirm",
          positionClass: { top: "83%", left: "13.5%", width: "347px" },
          // borderClass: "w-[90px] h-[40px]",
          arrowClass: { top: "38px", left: "-4px" },
          previousBtn: { text: "< Previous", class: "", disabled: false },
          nextBtn: { text: "Next >", class: "cursor-not-allowed", disabled: true },
        },
      },
    ],
  },
];

export const stepperStatus = {
  inProgress: "In Progress",
  pending: "Pending",
  completed: "Completed",
};
export const payloadData = {
  storageAccountName: "",
  containerName: "",
  secretValue: "",
  tenantId: "",
  applicationId: "",
  paths: "",
  objectId: "",
  id: "",
  meta: "",
  subscriptionId: "",
};

export const onboardingSummaryData = [
  {
    label: "Account Name",
    value: null,
    key: "storageAccountName",
    disabled: false,
  },
  {
    label: "Storage Container",
    value: null,
    key: "containerName",
    disabled: false,
  },
  {
    label: "Storage Directory",
    value: null,
    key: "paths",
    disabled: false,
  },
  {
    label: "Subscription ID",
    value: null,
    key: "subscriptionId",
    disabled: false,
  },
  {
    label: "Application (Client) ID",
    value: null,
    key: "applicationId",
    disabled: false,
  },
  {
    label: "Object ID",
    value: null,
    key: "objectId",
    disabled: false,
  },
  {
    label: "Directory (Tenant) ID",
    value: null,
    key: "tenantId",
    disabled: false,
  },
  {
    label: "Secret Value",
    value: null,
    key: "secretValue",
    disabled: false,
  },
];

export const LandingPageContent = (key) => {
  const content = {
    startOnboarding: {
      headingText: "Get data insights today",
      description:
        "Congratulations on Signing up, you are one step away to get detailed data insights of your Azure usage. We only need to access your Azure usage report to analyse your data. Please proceed with onboarding",
      buttonText: "Click to Start Onboarding Process",
    },
    resumeOnboarding: {
      headingText: "Get data insights today",
      description:
        "Congratulations on Signing up, you are one step away to get detailed data insights of your Azure usage. We only need to access your Azure usage report to analyse your data. Please resume with onboarding",
      buttonText: "Continue Onboarding Process",
    },
  };
  return content?.[key];
};

export const onCopyHandler = (text) => {
  navigator.clipboard.writeText(text);
  getToast("success", "Data Copied To Clipboard");
};

export const getSubStepHeading = (subStepContent) => (
  <p>
    {subStepContent?.heading?.map((item) =>
      item?.linkURL ? (
        <span>
          <a href={item?.linkURL} target="_blank" rel="noreferrer">
            {item?.label}
          </a>
        </span>
      ) : (
        <span>{item?.label}</span>
      )
    )}
  </p>
);
export const updateMetaIndex = (onboardingMeta, metaIndexClone) => {
  const updatedMetaIndex = { ...metaIndexClone };

  if (onboardingMeta?.stepIndex > updatedMetaIndex?.stepIndex) {
    updatedMetaIndex.stepIndex = onboardingMeta?.stepIndex;
    updatedMetaIndex.subStepIndex = onboardingMeta?.subStepIndex;
  } else if (
    onboardingMeta?.stepIndex === updatedMetaIndex?.stepIndex &&
    onboardingMeta?.subStepIndex > updatedMetaIndex?.subStepIndex
  ) {
    updatedMetaIndex.subStepIndex = onboardingMeta?.subStepIndex;
  }

  return updatedMetaIndex;
};

export const getOnboardingPolicy = async (id, setOnboardingPolicy) => {
  try {
    const policy = await commonService.getOnboardingPolicy({
      onboardingDataType: "SUBSCRIPTION_POLICY",
      subscriptionId: id,
    });
    setOnboardingPolicy(
      JSON.stringify(JSON.parse(policy?.data?.data?.data || "{}"), null, 2) || {}
    );
  } catch (e) {
    // do nothing
  }
};

export const disableEditIconOnboardingSummary = (key, summaryData, setSummaryData) => {
  const updatedSummaryData = cloneDeep(summaryData);
  const updatedData = updatedSummaryData.map((item) => {
    if (item?.key === key) {
      return { ...item, disabled: true };
    }
    return { ...item, disabled: false };
  });
  setSummaryData(updatedData);
};

export const carouselHandler = (
  subStepId,
  metaIndex,
  stepIndex,
  setSubStepIndex,
  setErrorMessage,
  summaryData,
  setSummaryData
) => {
  if (
    (metaIndex.stepIndex === stepIndex && metaIndex?.subStepIndex >= subStepId) ||
    metaIndex?.stepIndex > stepIndex
  ) {
    setSubStepIndex(subStepId);
    disableEditIconOnboardingSummary("", summaryData, setSummaryData);
    setErrorMessage("");
  }
};

export const setActiveBarWidth = (configData, metaIndex, stepIndex, subStepIndex) => {
  let activeBarWidth = 0;
  const stepArea = 100 / configData.length;
  if (metaIndex?.stepIndex <= stepIndex && metaIndex?.subStepIndex <= subStepIndex) {
    if (stepIndex > 0) {
      const totalStepArea = stepArea * stepIndex;
      const subStepArea = stepArea / configData[stepIndex].subSteps.length;
      const totalSubStepArea = subStepArea * subStepIndex;
      activeBarWidth = `${totalStepArea + totalSubStepArea}%`;
    } else {
      const subStepArea = stepArea / configData[stepIndex].subSteps.length;
      activeBarWidth = `${subStepArea * subStepIndex}%`;
    }
  } else {
    const totalStepArea = stepArea * metaIndex.stepIndex;
    const subStepArea = stepArea / configData[metaIndex.stepIndex].subSteps.length;
    const totalSubStepArea = subStepArea * metaIndex.subStepIndex;
    activeBarWidth = `${totalStepArea + totalSubStepArea}%`;
  }
  return {
    width: activeBarWidth,
  };
};

export const returnPathArray = (pathArray) => {
  const updatedPathArray = cloneDeep(pathArray);
  const userData = getLocalStorageUserData();
  if (userData?.tokens?.projectCount) {
    updatedPathArray[0].path = Routes?.BILLINGSUMMARY;
  }
  return updatedPathArray;
};

export const setStatusOfStepper = (configData, setConfigData, stepIndex) => {
  const configDataClone = cloneDeep(configData);
  let step = stepIndex - 1;
  if (stepIndex === 0) {
    configDataClone[stepIndex].status =
      configDataClone?.[stepIndex]?.status === stepperStatus?.completed
        ? stepperStatus?.completed
        : stepperStatus?.inProgress;
  } else {
    configDataClone[stepIndex].status =
      configDataClone?.[stepIndex]?.status === stepperStatus?.completed
        ? stepperStatus?.completed
        : stepperStatus?.inProgress;
    while (step >= 0) {
      configDataClone[step].status = stepperStatus?.completed;
      step -= 1;
    }
  }
  setConfigData(configDataClone);
};

export const disableSummaryValidation = (metaIndex) =>
  metaIndex?.stepIndex !== config.length - 1 ||
  metaIndex?.subStepIndex !== config[config.length - 1].subSteps.length - 1;

export const previousClickhandler = (
  configData,
  stepIndex,
  setStepIndex,
  subStepIndex,
  setSubStepIndex
) => {
  if (subStepIndex < configData?.[stepIndex]?.subSteps?.length && subStepIndex !== 0) {
    setSubStepIndex(subStepIndex - 1);
  } else {
    setStepIndex(stepIndex - 1);
    setSubStepIndex(configData[stepIndex - 1].subSteps.length - 1);
  }
};

export const nextClickHandler = (
  configData,
  metaIndex,
  setMetaIndex,
  stepIndex,
  setStepIndex,
  subStepIndex,
  setSubStepIndex
) => {
  const metaIndexClone = { ...metaIndex };
  if (subStepIndex < configData[stepIndex].subSteps.length - 1) {
    if (metaIndexClone?.stepIndex <= stepIndex && metaIndexClone?.subStepIndex <= subStepIndex) {
      metaIndexClone.subStepIndex = subStepIndex + 1;
    }
    setSubStepIndex(subStepIndex + 1);
  } else {
    setStepIndex(stepIndex + 1);
    setSubStepIndex(0);
    if (metaIndexClone?.stepIndex <= stepIndex) {
      metaIndexClone.stepIndex = stepIndex + 1;
      if (metaIndexClone?.subStepIndex <= subStepIndex) {
        metaIndexClone.subStepIndex = 0;
      }
    }
  }
  setMetaIndex(metaIndexClone);
};

export const getProgressBar = (configData, metaIndex, stepIndex, subStepIndex) => (
  <div className="progress_bar">
    <div
      className="active_bar"
      style={setActiveBarWidth(configData, metaIndex, stepIndex, subStepIndex)}
    />
  </div>
);

export const getCarouselProgressBar = (
  metaIndex,
  stepIndex,
  subStepIndex,
  setSubStepIndex,
  setErrorMessage,
  summaryData,
  setSummaryData
) => (
  <div className="carousel_progress_bar">
    {config?.[stepIndex]?.subSteps?.map((item) => (
      <div
        className={`carousel_dot ${item?.id === subStepIndex + 1 ? "active" : ""} ${
          metaIndex.stepIndex === stepIndex && metaIndex?.subStepIndex < item.id - 1
            ? "not_visited"
            : ""
        }`}
        aria-hidden
        onClick={() =>
          carouselHandler(
            item.id - 1,
            metaIndex,
            stepIndex,
            setSubStepIndex,
            setErrorMessage,
            summaryData,
            setSummaryData
          )
        }
      >
        {item?.carouselTooltipText && (
          <div className="carousel_tooltip">
            <p>{item?.carouselTooltipText}</p>
          </div>
        )}
      </div>
    ))}
  </div>
);

export const getOnboardingPayload = (value, inputContent, updatedData, metaIndex) => {
  const data = updatedData;
  if (inputContent) {
    if (inputContent?.key === "paths") {
      data.paths = Array.isArray(value) ? [...value] : [value];
    } else {
      data[inputContent?.key] = value;
    }
  }
  data.meta = JSON.stringify(metaIndex);
  const payload = { ...updatedData };
  Object?.keys?.(payload)?.forEach((key) => {
    if (payload?.[key] === "" || payload?.[key]?.length === 0) {
      payload[key] = null;
    }
  });
  return payload;
};

export const updateSummaryData = (obj, updatedData, updatedSummaryData) => {
  const data = updatedData;
  Object?.keys?.(obj)?.forEach((key) => {
    if (Object?.hasOwn(data, key)) {
      data[key] = obj?.[key];
    }
    updatedSummaryData?.forEach((item) => {
      if (item?.key === key) {
        const newItem = item;
        newItem.value = obj?.[key];
      }
    });
  });
};

export const onboardingAxiosService = {
  get: (apiUrl) =>
    axios.get(apiUrl, {
      headers: {
        "X-OAuth-Token": sessionStorage.getItem("JWT"),
      },
    }),
  post: (apiUrl, payload) =>
    axios.post(apiUrl, payload, {
      headers: {
        "X-OAuth-Token": sessionStorage.getItem("JWT"),
      },
    }),
};

export const checkURLForToken = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const JWT = queryParams.get("code");
  if (JWT) {
    sessionStorage.setItem("JWT", JWT);
  }
};
