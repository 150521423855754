import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrentDashboard, getViewId } from "../utils";

export default function useCurrentDashboard() {
  const pathname = window.location?.pathname;
  const dashboardRoutes = useSelector((state) => state.sidebarReducer.dashboardRoutes);
  const dashboardPathname = pathname.split("/dashboard/")[1];
  const dashboard = useSelector((state) => state.dashbaordReducer.currentDashboard);
  const currentDashboard = useMemo(
    () =>
      dashboard && dashboardPathname === dashboard?.path
        ? dashboard
        : getCurrentDashboard(dashboardRoutes),
    [pathname]
  );
  const { dashboardId } = currentDashboard?.props || {};
  const viewId = getViewId(currentDashboard);
  return { currentDashboard, dashboardId, viewId };
}
