import React from "react";
import LabelInput from "../../../../FormConfig/Form/Components/labelInput";

const getCodecTabList = (data) =>
  Object.keys(data)?.map((item) => ({
    key: item,
    label: item?.replaceAll("_", " "),
    value: item,
  }));

export const getConfigObject = (outerTabs, regions, configureRate) => {
  const tabListNames = outerTabs?.map((item) => item?.key) || [];
  const configObject = {};
  const regionListNames = regions?.map((item) => item?.childRegions) || [];

  tabListNames?.forEach((item) => {
    if (configureRate?.[item]?.codecDetails) {
      configObject[item] = regionListNames?.map((innerItem) => ({
        region: innerItem,
        config: configureRate[item]?.config || configureRate[item]?.codecDetails,
        tabList: configureRate[item]?.tabs
          ? getCodecTabList(configureRate?.[item]?.codecDetails)
          : [],
      }));
    } else {
      configObject[item] = configureRate[item]?.map((innerItem) => {
        const isChildTabs = outerTabs?.find((obj) => obj.key === item);
        return {
          ...innerItem,
          tabList: isChildTabs?.tabs ? getCodecTabList(innerItem?.config) : [],
        };
      });
    }
  });

  return configObject;
};

export function splitString(string, separator, Component) {
  if (!separator || !Component) {
    return string;
  }
  return string.split(separator).map((part) => <Component key={`${part}`}>{part} </Component>);
}

export const getModalDataList = (data, excludeName) => {
  const regionList = {};
  const deleteCurrentList = data?.filter((item) => item?.childRegions !== excludeName);
  deleteCurrentList?.forEach((item) => {
    regionList[item?.childRegions] = false;
  });
  return regionList;
};

export const getKeys = (data, path = []) => {
  const keys = new Set();

  Object.entries(data).forEach(([key, value]) => {
    const newPath = [...path, key];
    if (newPath.length % 2 === 1) {
      keys.add(key);
    }
    if (typeof value === "object") {
      getKeys(value, newPath).forEach((item) => keys.add(item));
    }
  });

  return [...keys];
};

const calculateRowCount = (resolutionCounts, resolution, activeData, rowData, readOnly) => {
  let rowCount;

  if (readOnly) {
    const row =
      resolutionCounts[resolution] <= 3
        ? resolutionCounts[resolution]
        : resolutionCounts[resolution] - 2;

    const rowPairs = Math.ceil(row / 2);
    rowCount = row + rowPairs - 1;
  } else {
    rowCount =
      Object.keys(activeData?.Resolution?.[resolution]?.BitRate).length *
        Object.keys(rowData).length +
      3;
  }

  return rowCount;
};

// eslint-disable-next-line max-lines-per-function
export const renderOutputsRows = (data, handleInputChange, childTab, readOnly) => {
  const activeData = data?.[childTab?.value];
  if (!activeData) return null;

  let colKeys = [];

  if (childTab?.value === "AVC_Outputs") {
    const resolutions = Object.keys(activeData?.Resolution);

    const columnNames = Object.keys(
      activeData?.Resolution?.SD?.BitRate?.["<10Mbps"]?.Pipeline?.Single ||
        activeData?.Resolution?.SD?.BitRate?.["<10Mbps"]?.Pipeline?.Standard
    );

    colKeys = getKeys(activeData).filter(
      (key) => key !== "Resolution" && key !== "BitRate" && key !== "Pipeline"
    );

    const resolutionCounts = resolutions.reduce((acc, resolution) => {
      const bitRates = Object.keys(activeData?.Resolution?.[resolution]?.BitRate);
      let newCount = acc[resolution] || 0;
      bitRates.forEach((bitRateKey) => {
        const pipelines = Object.keys(activeData?.Resolution?.[resolution]?.BitRate?.[bitRateKey]);
        pipelines.forEach((pipelineKey) => {
          const rowData =
            activeData?.Resolution?.[resolution]?.BitRate?.[bitRateKey]?.[pipelineKey];
          newCount += Object.keys(rowData).length;
        });
      });
      return { ...acc, [resolution]: newCount };
    }, {});

    const rows = resolutions?.flatMap((resolution, resolutionIndex) => {
      const bitRates = Object.keys(activeData?.Resolution?.[resolution]?.BitRate);

      const resolutionRows = bitRates?.flatMap((bitRateKey, bitRateIndex) => {
        const pipelines = Object.keys(activeData?.Resolution?.[resolution]?.BitRate?.[bitRateKey]);
        const bitRateRows = pipelines?.flatMap((pipelineKey, pipelineIndex) => {
          const rowData =
            activeData?.Resolution?.[resolution]?.BitRate?.[bitRateKey]?.[pipelineKey];

          const rowKeys = Object.keys(rowData);

          resolutionCounts[resolution] += rowKeys.length;

          return rowKeys.map((rowKey, rowIndex) => {
            const cells = !readOnly
              ? columnNames?.map((columnName) => (
                  <td
                    className="p-0"
                    key={`${resolution}-${bitRateKey}-${pipelineKey}-${rowKey}-${columnName}`}
                  >
                    {typeof rowData[rowKey][columnName] === "string" ? (
                      <LabelInput
                        value={rowData?.[rowKey]?.[columnName]}
                        placeholderText=""
                        name=""
                        onChange={(event) =>
                          handleInputChange(
                            event.target.value,
                            columnName,
                            rowKey,
                            bitRateKey,
                            resolution,
                            childTab?.value
                          )
                        }
                        className="!w-[100%]"
                        inputContainerClass="!w-[100%] !max-w-full"
                        inputClass="text-center mb-0 p-[6px]"
                      />
                    ) : (
                      rowData?.[rowKey]?.[columnName]
                    )}
                  </td>
                ))
              : colKeys?.map((columnName) => (
                  <td
                    className="p-0"
                    key={`${resolution}-${bitRateKey}-${pipelineKey}-${rowKey}-${columnName}`}
                  >
                    {rowKey !== "" ? (
                      <LabelInput
                        value={rowData?.[rowKey]?.[columnName] || "- - -"}
                        placeholderText=""
                        name=""
                        onChange={(event) =>
                          handleInputChange(
                            event.target.value,
                            columnName,
                            rowKey,
                            bitRateKey,
                            resolution,
                            childTab?.value
                          )
                        }
                        className="!w-[100%]"
                        inputContainerClass="!w-[100%] !max-w-full"
                        inputClass="text-center mb-0 p-[6px]"
                      />
                    ) : null}
                  </td>
                ));

            if (bitRateIndex === 0 && pipelineIndex === 0 && rowIndex === 0) {
              const rowCount = calculateRowCount(
                resolutionCounts,
                resolution,
                activeData,
                rowData,
                readOnly
              );

              return (
                <tr className="here" key={`${resolution}-${bitRateKey}-${pipelineKey}-${rowKey}`}>
                  <td
                    className="!bg-[#F9F9F9] !text-center !font-semibold !px-[27px]"
                    rowSpan={rowCount}
                  >
                    {resolution}
                  </td>
                  <td
                    className="!bg-[#F9F9F9] !pl-[10px] !pr-[11px]"
                    rowSpan={Object.keys(rowData)?.length}
                  >
                    {bitRateKey}
                  </td>
                  <td className="!bg-[#F9F9F9]">{rowKey}</td>
                  {cells}
                </tr>
              );
            }
            if (pipelineIndex === 0 && rowIndex === 0) {
              return (
                <tr key={`${resolution}-${bitRateKey}-${pipelineKey}-${rowKey}`}>
                  <td
                    className="!bg-[#F9F9F9] !pl-[10px] !pr-[11px]"
                    rowSpan={Object.keys(rowData)?.length}
                  >
                    {splitString(bitRateKey, "_", "div")}
                  </td>
                  <td className="!bg-[#F9F9F9]">{rowKey}</td>
                  {cells}
                </tr>
              );
            }
            return (
              <tr
                className={!readOnly ? "!bg-[#F9F9F9]" : ""}
                key={`${resolution}-${bitRateKey}-${pipelineKey}-${rowKey}`}
              >
                <td className={`${!readOnly ? "!bg-[#F9F9F9]" : ""} !pl-[6px] !pr-[27px]`}>
                  {rowKey}
                </td>
                {cells}
              </tr>
            );
          });
        });

        return [
          ...bitRateRows,
          bitRateIndex !== bitRates.length - 1 && (
            <tr key={`${resolution}-${bitRateKey}-blank`} className="bitRate blank">
              <td colSpan={columnNames.length + bitRates.length - 1} />
            </tr>
          ),
        ];
      });

      return [
        ...resolutionRows,
        resolutionIndex !== resolutions.length - 1 && (
          <tr key={`${resolution}-blank`} className="blank">
            <td colSpan={columnNames.length + 3} />
          </tr>
        ),
      ];
    });

    return rows.flat();
  }
  if (childTab?.value === "HEVC_Outputs") {
    const resolutions = Object.keys(activeData?.Resolution);

    const columnNames = Object.keys(
      activeData?.Resolution?.SD?.FrameRate?.["<=30fps"]?.Pipeline?.Single ||
        activeData?.Resolution?.SD?.FrameRate?.["<=30fps"]?.Pipeline?.Standard
    );

    const rows = resolutions?.flatMap((resolution, resolutionIndex) => {
      const frameRates = Object.keys(activeData?.Resolution?.[resolution]?.FrameRate);

      const resolutionRows = frameRates?.flatMap((frameRateKey, frameRateIndex) => {
        const pipelines = Object.keys(
          activeData?.Resolution?.[resolution]?.FrameRate?.[frameRateKey]
        );
        const frameRateRows = pipelines?.flatMap((pipelineKey, pipelineIndex) => {
          const rowData =
            activeData?.Resolution?.[resolution]?.FrameRate?.[frameRateKey]?.[pipelineKey];
          const rowKeys = Object.keys(rowData);

          return rowKeys.map((rowKey, rowIndex) => {
            const cells = columnNames?.map((columnName) => (
              <td
                className="p-0"
                key={`${resolution}-${frameRateKey}-${pipelineKey}-${rowKey}-${columnName}`}
              >
                {typeof rowData[rowKey][columnName] === "string" ? (
                  <LabelInput
                    value={rowData?.[rowKey]?.[columnName]}
                    placeholderText=""
                    name=""
                    onChange={(event) =>
                      handleInputChange(
                        event.target.value,
                        columnName,
                        rowKey,
                        frameRateKey,
                        resolution,
                        childTab?.value
                      )
                    }
                    className="!w-[100%]"
                    inputContainerClass="!w-[100%] !max-w-full"
                    inputClass="text-center mb-0 p-[6px]"
                  />
                ) : (
                  rowData?.[rowKey]?.[columnName]
                )}
              </td>
            ));
            if (frameRateIndex === 0 && pipelineIndex === 0 && rowIndex === 0) {
              return (
                <tr key={`${resolution}-${frameRateKey}-${pipelineKey}-${rowKey}`}>
                  <td
                    className="!bg-[#F9F9F9] !text-center !font-semibold !px-[27px]"
                    rowSpan={
                      Object.keys(activeData?.Resolution?.[resolution]?.FrameRate).length *
                        Object.keys(rowData).length +
                      1
                    }
                  >
                    {resolution}
                  </td>
                  <td
                    className="!bg-[#F9F9F9] !pl-[10px] !pr-[11px]"
                    rowSpan={Object.keys(rowData)?.length}
                  >
                    {frameRateKey}
                  </td>
                  <td className="!bg-[#F9F9F9]">{rowKey}</td>
                  {cells}
                </tr>
              );
            }
            if (pipelineIndex === 0 && rowIndex === 0) {
              return (
                <tr key={`${resolution}-${frameRateKey}-${pipelineKey}-${rowKey}`}>
                  <td
                    className="!bg-[#F9F9F9] !pl-[10px] !pr-[11px]"
                    rowSpan={Object.keys(rowData)?.length}
                  >
                    {splitString(frameRateKey, "_", "div")}
                  </td>
                  <td className="!bg-[#F9F9F9]">{rowKey}</td>
                  {cells}
                </tr>
              );
            }
            return (
              <tr
                className="!bg-[#F9F9F9]"
                key={`${resolution}-${frameRateKey}-${pipelineKey}-${rowKey}`}
              >
                <td className="!bg-[#F9F9F9] !pl-[6px] !pr-[27px]">{rowKey}</td>
                {cells}
              </tr>
            );
          });
        });

        return [
          ...frameRateRows,
          frameRateIndex !== frameRates.length - 1 && (
            <tr key={`${resolution}-${frameRateKey}-blank`} className="frameRate blank">
              <td colSpan={columnNames.length + frameRates.length} />
            </tr>
          ),
        ];
      });

      return [
        ...resolutionRows,
        resolutionIndex !== resolutions.length - 1 && (
          <tr key={`${resolution}-blank`} className="blank">
            <td colSpan={columnNames.length + 3} />
          </tr>
        ),
      ];
    });

    return rows.flat();
  }
  if (childTab?.value === "Audio_Only") {
    return Object?.entries(activeData.Pipeline)?.map(([columnKey, columnData]) => (
      <tr key={columnKey} className="add-on-functionality">
        <td className="!bg-[#F9F9F9] !text-left !font-semibold">
          {splitString(columnKey, "_", "span")}
        </td>
        {typeof columnData?.Rate === "string" ? (
          <td className="p-0">
            <LabelInput
              value={columnData?.Rate}
              placeholderText=""
              name=""
              onChange={(event) =>
                handleInputChange(event.target.value, columnKey, "Rate", "", "", childTab?.value)
              }
              className="!w-[100%]"
              inputContainerClass="!w-[100%] !max-w-full"
              inputClass="text-center mb-0 p-[6px]"
            />
          </td>
        ) : (
          <td>{columnData?.Rate}</td>
        )}
      </tr>
    ));
  }

  return null;
};

export const renderStatmuxRows = (data, handleInputChange) => {
  const activeData = data?.Resolution;
  if (!activeData) return null;

  let prevResolution = null;
  const resolutions = Object.entries(activeData);
  let rows = [];
  resolutions.forEach(([resolution, resolutionData], index) => {
    const resolutionRows = Object.entries(resolutionData?.Codec)?.flatMap(([codec, codecData]) =>
      Object.entries(codecData?.FrameRate)?.map(([frameRate, frameRateData]) => {
        const row = (
          <tr key={`${resolution}-${codec}-${frameRate}`} className="statmux">
            {prevResolution !== resolution && (
              <td
                className="!bg-[#F9F9F9] !text-center !font-semibold !px-[27px]"
                rowSpan={Object.keys(resolutionData?.Codec)?.length}
              >
                {splitString(resolution, "__", "div")}
              </td>
            )}
            <td className="!bg-[#F9F9F9]">{codec}</td>
            <td className="!bg-[#F9F9F9]">{frameRate}</td>
            {typeof frameRateData?.Rate === "string" ? (
              <td className="p-0">
                <LabelInput
                  value={frameRateData?.Rate}
                  placeholderText=""
                  name=""
                  onChange={(event) =>
                    handleInputChange(event.target.value, frameRate, "Rate", codec, resolution)
                  }
                  className="!w-[100%]"
                  inputContainerClass="!w-[100%] !max-w-full"
                  inputClass="text-center mb-0 p-[6px]"
                />
              </td>
            ) : (
              <td>{frameRateData?.Rate}</td>
            )}
          </tr>
        );
        prevResolution = resolution;
        return row;
      })
    );
    if (index !== resolutions.length - 1) {
      rows = [
        ...rows,
        ...resolutionRows,
        <tr key={`${resolution}-blank`} className="blank">
          <td colSpan={4} />
        </tr>,
      ];
    } else {
      rows = [...rows, ...resolutionRows];
    }
  });
  return rows;
};

export const renderAddOnFunctionalityRows = (data, handleInputChange) => {
  const activeData = data;
  if (!activeData) return null;

  return Object?.entries(activeData)?.map(([columnKey, columnData]) => (
    <tr key={columnKey} className="add-on-functionality">
      <td className="!bg-[#F9F9F9] !text-left !font-semibold">
        {splitString(columnKey, "_", "span")}
      </td>
      {typeof columnData?.Rate === "string" ? (
        <td className="p-0">
          <LabelInput
            value={columnData?.Rate}
            placeholderText=""
            name=""
            onChange={(event) => handleInputChange(event.target.value, columnKey, "Rate")}
            className="!w-[100%]"
            inputContainerClass="!w-[100%] !max-w-full"
            inputClass="text-center mb-0 p-[6px]"
          />
        </td>
      ) : (
        <td>{columnData?.Rate}</td>
      )}
    </tr>
  ));
};

export const renderInputsRows = (data, handleInputChange, childTab, readOnly) => {
  const activeData = data?.[childTab?.value];
  if (!activeData) return null;

  const colKeys = getKeys(activeData).filter((key) => key !== "Resolution" && key !== "Pipeline");

  const resolutions = Object.keys(activeData?.Resolution);

  const rows = resolutions?.flatMap((resolution, resolutionIndex) => {
    const pipelines = Object.keys(activeData?.Resolution[resolution]?.Pipeline);
    return pipelines.map((pipeline, index) => {
      const rowSpan = index === 0 ? pipelines?.length : 0;
      const columnKeys = Object.keys(activeData?.Resolution[resolution]?.Pipeline[pipeline]);

      const isLastResolution = resolutionIndex === resolutions.length - 1;
      const isLastPipeline = index === pipelines.length - 1;

      const currentRow = (
        <tr key={`${resolution}-${pipeline}`}>
          {index === 0 && (
            <td
              className="!bg-[#F9F9F9] !text-center !px-[27px] !font-semibold !px-[27px]"
              rowSpan={rowSpan}
            >
              {resolution.split("__").map((part) => (
                <div key={`${part}`}>{part}</div>
              ))}
            </td>
          )}
          <td className="!bg-[#F9F9F9]">{pipeline}</td>
          {!readOnly
            ? columnKeys.map((columnKey) => (
                <td className="p-0" key={columnKey}>
                  {typeof activeData?.Resolution?.[resolution]?.Pipeline?.[pipeline]?.[
                    columnKey
                  ] === "string" ? (
                    <LabelInput
                      value={
                        activeData?.Resolution?.[resolution]?.Pipeline?.[pipeline]?.[columnKey]
                      }
                      placeholderText=""
                      name=""
                      onChange={(event) =>
                        handleInputChange(
                          event.target.value,
                          columnKey,
                          pipeline,
                          "",
                          resolution,
                          childTab?.value
                        )
                      }
                      className="!w-[100%]"
                      inputContainerClass="!w-[100%] !max-w-full"
                      inputClass="text-center mb-0 p-[6px]"
                    />
                  ) : (
                    activeData?.Resolution?.[resolution]?.Pipeline?.[pipeline]?.[columnKey]
                  )}
                </td>
              ))
            : colKeys.map((columnKey) => (
                <td className="p-0" key={columnKey}>
                  <LabelInput
                    value={
                      activeData?.Resolution?.[resolution]?.Pipeline?.[pipeline]?.[columnKey] ||
                      "- - -"
                    }
                    placeholderText=""
                    name=""
                    onChange={() => {}}
                    className="!w-[100%]"
                    inputContainerClass="!w-[100%] !max-w-full"
                    inputClass="text-center mb-0 p-[6px]"
                  />
                </td>
              ))}
        </tr>
      );

      if (!isLastResolution && isLastPipeline) {
        return [
          currentRow,
          <tr key={`${resolution}-blank`} className="blank">
            <td colSpan={columnKeys.length + 2} />
          </tr>,
        ];
      }
      return currentRow;
    });
  });

  return rows;
};
