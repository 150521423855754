import React from "react";
import Text from "../Components/Text";

export const dialogHeaderHTML = () => (
  <div className="flex items-center credit_ordering_modal">
    <Text text="Credit Ordering" className="label !text-black !text-[14px] items-center pl-0" />
    <Text
      text="(Drag & Drop to Update)"
      className="label text-[#898A90] !font-light !text-[12px] items-center !pl-[0]"
    />
  </div>
);

export default dialogHeaderHTML;
