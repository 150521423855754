import React from "react";
import FormInput from "./Input/index";
import RadioButtonGroup from "../../FormConfig/Form/Components/RadioButtonGroup";
import { ReactComponent as DeleteIcon } from "../../../Assets/icons/others/deleteicon.svg";
import { getErrorMessageInput, getRegex } from "./util";
import "../../CK-Lens/Common/graph-table-view/style.scss";

function EditTable({
  headers = [],
  tableData = [],
  tableWrapperClass = "",
  onTableInputChange = null,
  yearlyCommitmentCalculationRadioFormElement,
  handleRadioChange = null,
  yearlyCommitmentCalculationRadio,
  whiteTheme,
  tooltipStyle,
}) {
  const renderContent = (header, data, tableIndex, dataLength, previousItem = null) => {
    const { type } = header;
    let getError = "";
    let regex = null;
    if (type === "table-input") {
      regex = getRegex(header.value);
      getError = getErrorMessageInput(
        header.value,
        data[header.value],
        regex,
        null,
        header.value === "credits" ? data.commitment : null
      );
    }
    if (
      previousItem !== null &&
      header.value === "commitment" &&
      parseInt(previousItem[header.value], 10) > parseInt(data[header.value], 10)
    ) {
      getError = "Value should be greater than previous year";
    }
    switch (type) {
      case "table-input":
        return (
          <span>
            <FormInput
              name={header.value}
              value={data[header.value] || ""}
              type="text"
              onGetData={(name, value) => onTableInputChange(name, value, tableIndex, data, header)}
              placeholder=""
              errorClass="errorClass !absolute bottom-0 !text-left"
              regex={regex}
              parentClass={`table-radio-input ${header.bodyInputClass}`}
              errorMessage={getError}
              comparisonError={getError}
              filterError
              commaFormatting
            />
          </span>
        );
      case "deleteIcon":
        return (
          <div
            aria-hidden
            onClick={(name, value) =>
              dataLength > 1 && onTableInputChange(name, value, tableIndex, data, header)
            }
            className={`${dataLength > 1 ? "cursor-pointer" : "pointer-events-none"}`}
          >
            <DeleteIcon className="delete_icon" />
          </div>
        );
      default:
        return (
          <span>
            <div dangerouslySetInnerHTML={{ __html: data[header.value] }} />
          </span>
        );
    }
  };

  const renderTable = (tableHeaders = [], data = [], tableWrapper = "") => (
    <table className={tableWrapper}>
      <thead>
        <tr>
          {tableHeaders.map((header) => (
            <th className="private_pricing_header">
              <div className={header?.headerClass}>{header.label}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr>
            {tableHeaders.map((header) => (
              <td
                className={`private_pricing_td ${header?.bodyClass ? header?.bodyClass : ""}`}
                key={header.value}
              >
                {renderContent(
                  header,
                  item,
                  index,
                  data?.length,
                  index > 0 ? data[index - 1] : null
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <>
      <div className="rounded-[4px] border-[#DDDFE8] border border-solid w-fit">
        {renderTable(headers, tableData, tableWrapperClass)}
      </div>
      <div className="!w-full">
        <RadioButtonGroup
          key={yearlyCommitmentCalculationRadioFormElement.name}
          {...yearlyCommitmentCalculationRadioFormElement}
          handleChange={(e) => handleRadioChange(e)}
          value={yearlyCommitmentCalculationRadio}
          whiteTheme={whiteTheme}
          tooltipStyle={tooltipStyle}
        />
      </div>
    </>
  );
}

export default EditTable;
