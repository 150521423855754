import React from "react";

import Button from "@mui/material/Button";
import { ReactComponent as ExcelIconV2 } from "../../../Assets/icons/others/ic-xls-v2.svg";
import { ReactComponent as ExcelDisableIconV2 } from "../../../Assets/icons/excel-disabled.svg";
import { ReactComponent as DownloadIconV2 } from "../../../Assets/icons/others/download.svg";
import { ReactComponent as ExcelLoaderV2 } from "../../../Assets/icons/others/excel-loader-blue.svg";

export default function ExcelDownloadV2(props) {
  const { downloadHandler, hasData, excelLoading } = props;
  return (
    <div className="billing_console_header !mr-[3px]">
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          downloadHandler();
        }}
        className={`download_v2 download_button_style !px-[5px] !h-[30px] !font-[500] ${
          hasData ? "disable_v2" : ""
        } ${excelLoading ? "download_loading" : ""}`}
        disabled={hasData || excelLoading}
        id="download"
      >
        {hasData ? (
          <ExcelDisableIconV2 className="btn_icon_style download_excel_v2" />
        ) : (
          <ExcelIconV2 className="btn_icon_style download_excel_v2" />
        )}
        Download
        {excelLoading ? (
          <ExcelLoaderV2 className="excel-loading-gif mr_5 !ml-[5px] !mr-0" />
        ) : (
          <DownloadIconV2 className="btn_icon_style !ml-[5px] !mr-0" />
        )}
      </Button>
    </div>
  );
}
