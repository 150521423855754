const createWorker = (workerScript) => {
  const blob = new Blob([workerScript], { type: "application/javascript" });
  return new Worker(URL.createObjectURL(blob));
};

export const initiateWorker = async (workerScript, data) =>
  new Promise((resolve, reject) => {
    const worker = createWorker(workerScript);

    const serializableData = JSON.parse(JSON.stringify(data));

    worker.postMessage(serializableData);

    worker.onmessage = (e) => {
      resolve(e.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };
  });

export default createWorker;
