import React from "react";
import { Helmet } from "react-helmet";
import { getLocalStorageUserData, getPartnerDisplayName } from "../../../Utils/commonUtils";

export default function PageTitle({ pageTitle, customPartnerDisplayName = "" }) {
  const userData = getLocalStorageUserData();
  let pageHeading = "";
  const roleType = userData?.currentUser?.type;
  const partnerDisplayName = getPartnerDisplayName(userData);
  switch (roleType) {
    case "PARTNER":
    case "CUSTOMER":
      pageHeading = partnerDisplayName;
      break;
    case "CLOUDONOMICS":
      pageHeading = "Cloudonomic ™";
      break;
    default:
      if (process.env.REACT_APP_BASE_URL !== window.location.origin) {
        pageHeading = customPartnerDisplayName;
      } else {
        pageHeading = "Cloudonomic ™";
      }
      break;
  }

  return (
    <Helmet>
      <title>{`${pageHeading} | ${pageTitle}`}</title>
      <meta name="description" content={`${pageHeading} ${pageTitle}`} />
    </Helmet>
  );
}
