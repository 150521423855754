import React from "react";
import TooltipV2 from "../../../Common/TooltipV2";
import {
  PPA_MEDIA_CONNECT_DATA_HEADER,
  renderTermLevelSummary,
} from "../../../Dashboard/CustomerManagement/CreateCustomer/PrivatePricingAgreements/util";
import displayText from "./utils";
import "./style.scss";

function RenderHTML({ value, label, labelClass }) {
  return (
    <div className="render-tooltip-wrapper">
      <label className={`text-area-label text-black text-xs ${labelClass}`}>{label}</label>
      <TooltipV2
        parentClass="Edp_Tooltip_term_level_summary_V2"
        displayText={() => displayText(value)}
      >
        {renderTermLevelSummary(
          {
            data: value?.commitmentList,
            headers: PPA_MEDIA_CONNECT_DATA_HEADER,
          },
          value?.termLevelSummary
        )}
      </TooltipV2>
    </div>
  );
}

export default RenderHTML;
