import React from "react";
import { ReactComponent as MonthIcon } from "../../../Assets/icons/potential-saving-12-months.svg";
import { ReactComponent as DaysIcon } from "../../../Assets/icons/potential-saving-30-days.svg";
import { ReactComponent as StorageBlock } from "../../../Assets/icons/storage-blocks.svg";
import { getCurrencySymbol } from "../../../Utils/commonUtils";

export const SlackHeaders = [
  { key: "group", value: "Channel", name: "channel" },
  { key: "recipients", value: "Oauth Token", name: "token" },
  { key: "actions", value: "Actions" },
];

export const EmailHeaders = [
  { key: "group", value: "Notification Group", name: "notificationGroup" },
  { key: "recipients", value: "Recipients", name: "recipients" },
  { key: "actions", value: "Actions" },
];

export const BTN_ARRAY = [
  { label: "Cancel", class: "button outline-primary medium ml-[14px] rounded" },
  { label: "Apply", class: "button primary medium ml-[14px] rounded" },
];

export const GRANULARITY_MAP = {
  daily: "Days",
  monthly: "Months",
  weekly: "Weeks",
};

export const RESTRICT_ADMIN_ROUTES = ["OWNER", "ADMIN", "READ_ONLY"];

export const TABLE_VIEW = "Table view";
export const CHART_VIEW = "Chart view";

export const MODAL_TYPE = {
  INFORMATIVE: "Informative",
  DELETE: "Delete",
  CUSTOM: "Custom",
};

export const DUPLICATE_USER_HEADER = "Duplicate User Alert";
export const DUPLICATE_USER_BODY = "User Email Already Exists";
export const DUPLICATE_USER_DESCRIPTION =
  "The email address is already associated with another customer. You can proceed with creating the user, but please note that the password will be the same as the existing one.";
export const DUPLICATE_USER_BTN_TEXT = "Create";
export const FALSE = false;

export const DUPLICATE_USER = {
  heading: DUPLICATE_USER_HEADER,
  body: DUPLICATE_USER_BODY,
  type: MODAL_TYPE.CUSTOM,
  icon: <StorageBlock />,
  description: DUPLICATE_USER_DESCRIPTION,
  primaryBtnText: DUPLICATE_USER_BTN_TEXT,
  dialogClass: "duplicate-alert",
  // actions: FALSE,
};

export const CUSTOMER_ROLE_RESET = {
  role: "",
  roleLabel: "",
  type: "CUSTOMER",
  typeLabel: "CSTMR",
};

export const INFO_MESSAGE = {
  ACCOUNT_ONBOARDED: {
    title: "Onboarding Successful",
    description:
      "Thank you for onboarding your account with us. Please note that it may take 24-48 hours to calculate savings data for new accounts.",
  },
  READ_ONLY: {
    title: "Account Yet To be Onboarded. Please Contact your Owner/Admin",
    description: "",
  },
  NO_DATA: {
    description:
      "It appears that CloudKeeper did not make any purchases in the selected month for this particular category.",
  },
};

export const POTENTIAL_SAVING = {
  ACCOUNT_ONBOARDED:
    "Thank you for onboarding your account with us. Please note that it may take 24-48 hours to calculate savings data for new accounts.",
  NO_DATA: "No Data Available",
  READ_ONLY_ADMIN: "Account Yet To be Onboarded. Please Contact your Owner/Admin",
  CARD_DATA: [
    {
      title: "Monthly Savings",
      savings: "Calculating ...",
      Icon: <DaysIcon />,
      apiKey: "thirtyDays",
      tooltip: "Estimated Savings in next month",
      externalClass: "smallText",
    },
    {
      title: "Yearly Savings",
      savings: "Calculating ...",
      Icon: <MonthIcon />,
      apiKey: "twelveMonths",
      tooltip: "Estimated Savings in next 1 year",
      externalClass: "smallText",
    },
  ],
  GRAPH_HEADING: "Statistics of Potential Savings",
};

export const ERR_CODE = "ERR_CANCELED";

export const privatePricingTableHeaders = [
  { label: "Region Name", secondLabel: " ", value: "childRegions", type: "text-dropDown" },
  {
    label: `Active Resources ${getCurrencySymbol()}`,
    secondLabel: "",
    value: "http",
    type: "table-input",
  },
  {
    label: `Data Transfer Out to Internet ${getCurrencySymbol()}`,
    secondLabel: "",
    value: "https",
    type: "table-input",
  },
  {
    label: `Data Transfer Region to Region ${getCurrencySymbol()}`,
    secondLabel: "",
    value: "dtOut",
    type: "table-input",
  },
  {
    label: `Data Transfer Within Region ${getCurrencySymbol()}`,
    secondLabel: "",
    value: "dtwr",
    type: "table-input",
  },
];

export const privatePricingTableHeadersOld = [
  { label: "Region Name", secondLabel: " ", value: "childRegions", type: "text-dropDown" },
  { label: "HTTP Requests ($)", secondLabel: "(Per 10,000)", value: "http", type: "table-input" },
  { label: "HTTPS Requests ($)", secondLabel: "(Per 10,000)", value: "https", type: "table-input" },
  { label: "DT Out to Internet ($)", secondLabel: "(Per GB)", value: "dtOut", type: "table-input" },
];

export const newRegionTableHeaders = [
  { label: "Categories", value: "category", type: "" },
  { label: "AWS Public Pricing", value: "http", type: "table-radio" },
  { label: "Custom Private Pricing", value: "https", type: "table-radio-input" },
];

export const newRegionTableData = [
  {
    category: "HTTP Requests",
    Awspricing: true,
    customPricing: "",
    key: "http",
  },
  {
    category: "HTTPS Requests",
    Awspricing: true,
    customPricing: "",
    key: "https",
  },
  {
    category: "DT Out to Internet",
    Awspricing: true,
    customPricing: "",
    key: "dtOut",
  },
];

export const TOAST_MESSAGE_FOR_URL =
  "Incorrect URL requested. You remain on the current dashboard with default settings";
