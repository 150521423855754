import React from "react";
import { ReactComponent as CrossIcon } from "../../../../../Assets/icons/cross-icon.svg";
import "./style.scss";

export default function getMultiSelectChips(
  selectedItems,
  removeChipHandler,
  modifyLabelValue,
  readOnly
) {
  const updatedSelectedItem = selectedItems.filter((i) => i?.checked === true);
  const newArray = updatedSelectedItem?.slice(0, 2);
  const popupArray = updatedSelectedItem?.slice(2, selectedItems?.length);

  return updatedSelectedItem?.length > 2 ? (
    <>
      {newArray?.map((item, index) => (
        <div key={item?.value} className="chip">
          <span className="chip-text">{modifyLabelValue ? item?.label : item?.value}</span>
          {!item.disabled && !readOnly && (
            <span className="chip-icon">
              <CrossIcon onClick={(e) => removeChipHandler(e, item, index)} />
            </span>
          )}
        </div>
      ))}
      <div className="chip-counter">
        <span>+{updatedSelectedItem.length - 2}</span>
        <div className="popupList">
          {popupArray?.map((ele) => (
            <p key={ele?.value} className="popupListItem">
              {modifyLabelValue ? ele?.label : ele?.value}
            </p>
          ))}
        </div>
      </div>
    </>
  ) : (
    updatedSelectedItem?.map((item, index) => (
      <div key={item?.value} className="chip">
        <span className="chip-text">{modifyLabelValue ? item?.label : item?.value}</span>
        {!item.disabled && !readOnly && (
          <span className="chip-icon">
            <CrossIcon onClick={(e) => removeChipHandler(e, item, index)} />
          </span>
        )}
      </div>
    ))
  );
}
