import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card";

function Container({ data, onOrderUpdate, readOnly }) {
  const [cards, setCards] = useState(data);

  useEffect(() => {
    setCards(data);
  }, [data]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setCards((prevCards) => {
        const newCards = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        onOrderUpdate({ target: { name: "constructOrdering", value: newCards } });
        return newCards;
      });
    },
    [onOrderUpdate]
  );

  const renderCard = useCallback(
    (card, index) => (
      <Card
        key={card.order}
        index={index}
        id={card.order}
        text={card.text}
        moveCard={moveCard}
        readOnly={readOnly}
      />
    ),
    [moveCard]
  );

  return (
    <div className="flex flex-col gap-[2px]">{cards.map((card, i) => renderCard(card, i))}</div>
  );
}

export default Container;
