export default function ConfigDiscountsDataReducer(state = [], action = {}) {
  switch (action?.type) {
    case "CONFIG_DISCOUNT_TABLEDATA":
      return { ...state, config_tableData: action.payload };
    default:
      return state;
  }
}

// Removed Code
// import { ConfigureDiscountListing } from "../Components/Dashboard/ConfigDiscounts/mocks";

// const tableData = ConfigureDiscountListing;

// // eslint-disable-next-line default-param-last
// export default function ConfigDiscountsDataReducer(state = tableData, action) {
//   switch (action.type) {
//     case "CONFIG_DISCOUNT_TABLEDATA":
//       return { ...state, config_tableData: action.payload };
//     default:
//       return state;
//   }
// }
