import moment from "moment";
import workerScript from "./filterWorker";
import createWorker from "./createWorker";

const isEmptyRecord = (record) => record === undefined || record === null || record === "";

function handleEmptyRecords(valueA, valueB, ascending) {
  if (isEmptyRecord(valueA) && isEmptyRecord(valueB)) {
    return 0;
  }

  if (isEmptyRecord(valueA)) {
    return ascending ? -1 : 1;
  }

  if (isEmptyRecord(valueB)) {
    return ascending ? 1 : -1;
  }
  return null;
}

const isDateField = (sortBy) =>
  [
    "Date",
    "lastLogin",
    "signUpTimeStamp",
    "lastLoginTime",
    "accountCreationTime",
    "lastLoginTimeStamp",
  ]
    .map((field) => field.toLowerCase())
    .includes(sortBy?.toLowerCase());

const compareDates = (valueA, valueB, ascending, fallbackTimeFormat) => {
  const momentValueA = moment(valueA, fallbackTimeFormat);
  const momentValueB = moment(valueB, fallbackTimeFormat);
  const comparison = momentValueA.unix() - momentValueB.unix();
  return ascending ? comparison : -comparison;
};

export const sortedData = (
  sortBy,
  data,
  ascending = true,
  fallbackTimeFormat = "DD/MM/YYYY HH:mm A",
  headerObj = ""
) => {
  if (!Array.isArray(data)) {
    throw new Error("Data must be an array.");
  }

  if (!sortBy || typeof sortBy !== "string") {
    throw new Error("sortBy must be a valid string.");
  }

  if (headerObj?.searchLength) {
    return data.sort((a, b) => {
      const valueA = a[sortBy].length;
      const valueB = b[sortBy].length;
      return ascending ? valueA - valueB : valueB - valueA;
    });
  }

  return data.sort((a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy]; // Handle empty, null, and undefined values

    const emptyComparison = handleEmptyRecords(valueA, valueB, ascending);
    if (emptyComparison !== null) return emptyComparison;

    if (typeof valueA === "number" && typeof valueB === "number") {
      return ascending ? valueA - valueB : valueB - valueA;
    }
    if (isDateField(sortBy)) {
      return compareDates(valueA, valueB, ascending, fallbackTimeFormat);
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }
    if (typeof valueA === "boolean" && typeof valueB === "boolean") {
      return ascending ? valueA - valueB : valueB - valueA;
    }
    throw new Error(`Unsupported data types for sorting: ${typeof valueA} and ${typeof valueB}`);
  }); // return updatedTableData;
};

function isDate(input) {
  // Check if the date is in ISO 8601 format
  if (moment(input, moment.ISO_8601, true).isValid()) {
    return true;
  }
  // Check if the date is in the format "DD-MMM-YYYY"
  if (moment(input, "DD-MMM-YYYY", true).isValid()) {
    return true;
  }
  return false;
}

const dateFilter = (tableData, item, filters) => {
  const min = new Date(Math.min(...tableData.map((e) => new Date(e[[item]]))));
  const max = new Date(Math.max(...tableData.map((e) => new Date(e[[item]]))));
  const filterIndividual = {
    name: item,
    key: item,
    selectedDates: [min, max],
    min,
    max,
    type: "dates",
  };
  filters.push(filterIndividual);
};

export const stringFilter = (tableData, item, filters, value, allowCalendarFilter = false) => {
  if (isDate(value) && allowCalendarFilter) {
    dateFilter(tableData, item, filters);
  } else {
    const uniqueValue = [
      ...new Set(
        tableData.map((internalObj) => (internalObj[item] !== "" ? internalObj[item] : ""))
      ),
    ].filter((internalItem) => internalItem !== "");
    const filterIndividual = {
      name: item,
      key: item,
      values: uniqueValue.filter((singleUniqueValue) => singleUniqueValue !== undefined),
      value: [],
      filterKey: "Include Only",
      type: "string",
    };
    filters.push(filterIndividual);
  }
  return filters;
};

export const numberFilter = (tableData, item, filters) => {
  const quantityValues = tableData.map((singleRow) => singleRow[item]);
  const definedQuantityValues = quantityValues.filter((value) => value !== undefined);

  const max = Math.max(...definedQuantityValues).toFixed(2);
  const min = Math.min(...definedQuantityValues).toFixed(2);

  const filterIndividualNumber = {
    name: item,
    key: item,
    max,
    min,
    selectedValue: [min, max],
    type: "number",
  };
  filters.push(filterIndividualNumber);
};

export const objectFilter = (tableData, item, filters, typeFromHeader = false) => {
  if (
    (Array.isArray(tableData?.[0]?.[item]) &&
      tableData?.[0]?.[item]?.[0] &&
      typeof tableData?.[0]?.[item]?.[0] !== "object") ||
    typeFromHeader
  ) {
    const uniqueValue = [
      ...new Set(tableData.reduce((acc, obj) => acc.concat(obj[item]), [])),
    ].filter((internalItem) => internalItem !== "");
    const filterIndividual = {
      name: item,
      key: item,
      values: uniqueValue.filter((singleUniqueValue) => singleUniqueValue !== undefined),
      value: [],
      filterKey: "Include Only",
      type: "string",
    };
    filters.push(filterIndividual);
    // }
    return filters;
  }
  return false;
};

export const createTableFiltersUpdated = (
  tableData = [],
  isDriveFromWebWorker = false,
  allowCalendarFilter = false,
  tableConfig = {}
) => {
  if (isDriveFromWebWorker) {
    return new Promise((resolve, reject) => {
      const worker = createWorker(workerScript);

      const serializableData = JSON.parse(JSON.stringify(tableData));

      worker.postMessage({ tableData: serializableData });
      worker.onmessage = (e) => {
        resolve(e.data.filters);
        worker.terminate();
      };

      worker.onerror = (error) => {
        reject(error);
        worker.terminate();
      };
    });
  }

  const filters = [];
  if (tableConfig.filterFromheaders) {
    const filterKeys = tableConfig.headers || [];
    filterKeys.forEach((filterKey) => {
      switch (filterKey.filterType) {
        case "string":
          stringFilter(tableData, filterKey.accessor, filters, "", allowCalendarFilter);
          break;
        case "number":
          numberFilter(tableData, filterKey.accessor, filters);
          break;
        case "object":
          objectFilter(tableData, filterKey.accessor, filters, true);
          break;
        default:
      }
    });
  } else {
    const headers = tableData.length ? tableData[0] : [];
    if (headers) {
      Object.keys(headers).forEach((item) => {
        switch (typeof headers[item]) {
          case "string":
            stringFilter(tableData, item, filters, headers[item], allowCalendarFilter);
            break;
          case "number":
            numberFilter(tableData, item, filters);
            break;
          case "object":
            objectFilter(tableData, item, filters);
            break;
          default:
        }
      });
    }
  }

  return filters;
};

export const disableHandling = (filterSelected) => {
  const isFilterApplied = (item) => {
    if (item.type === "number") {
      return item.selectedValue[0] !== item.min || item.selectedValue[1] !== item.max;
    }
    if (item.type === "string") {
      return item.value.length > 0;
    }
    if (item.type === "dates") {
      return item.selectedDates[0] !== item.min || item.selectedDates[1] !== item.max;
    }
    return false;
  };

  const hasActiveFilters = (filters) =>
    filters.some((item) => {
      if (isFilterApplied(item)) {
        return true;
      }
      if (item.filters) {
        return hasActiveFilters(item.filters);
      }
      return false;
    });

  return !hasActiveFilters(filterSelected);
};

const areValuesNotEqual = (value1, value2) => value1?.toString() !== value2?.toString();

export const applynumberfiltersSingle = (internalItem, data, toFixValue = 1) => {
  if (
    (internalItem.selectedValue[0] <= internalItem.selectedValue[1] &&
      areValuesNotEqual(internalItem?.min, internalItem?.selectedValue[0])) ||
    areValuesNotEqual(internalItem?.max, internalItem?.selectedValue[1])
  ) {
    const newData = [];
    data.forEach((item) => {
      if (typeof item?.[internalItem?.name] === "number") {
        if (
          Number(item[internalItem?.name]?.toFixed(toFixValue)) >=
            Number(internalItem?.selectedValue?.[0])?.toFixed(toFixValue) &&
          Number(item[internalItem?.name]?.toFixed(toFixValue)) <=
            Number(internalItem?.selectedValue?.[1])?.toFixed(toFixValue)
        ) {
          newData.push(item);
        }
      }
    });
    return newData;
  }
  return data;
};
export const applyDatefiltersSingle = (internalItem, reducedData) => {
  const newData = [];
  reducedData.forEach((item) => {
    if (
      moment(item[internalItem.name])
        .startOf("day")
        .isBetween(
          moment(internalItem.selectedDates[0]).startOf("day"),
          moment(internalItem.selectedDates[1]).startOf("day"),
          "undefined",
          "[]"
        )
    ) {
      newData.push(item);
    }
  });
  return newData;
};

export const applyDateFilter = (newFilterDataObj, filters) => {
  const dateFilters = filters.filter((item) => item.type === "dates");
  const data = [...newFilterDataObj.tableData];
  let reducedData = [...data];
  dateFilters.forEach((internalItem) => {
    const dataNew = applyDatefiltersSingle(internalItem, reducedData);
    reducedData = dataNew;
  });
  return reducedData;
};
export const applynumberfilters = (FilterDataObj, filters, toFixValue = 1) => {
  const numberFilters = filters.filter((item) => item.type === "number");
  const data = [...FilterDataObj.tableData];
  let reducedData = [...data];
  const updateFilterCopy = filters.map((item) => {
    const obj = { ...item };
    if (item.filterKey === "Clear Filter") {
      obj.filterKey = "Include Only";
      obj.value = [];
    }
    return obj;
  });

  numberFilters.forEach((internalItem) => {
    const dataNew = applynumberfiltersSingle(internalItem, reducedData, toFixValue);
    reducedData = dataNew;
  });
  const newFilterDataObj = {
    filteredData: reducedData,
    tableData: reducedData,
    filters: updateFilterCopy,
  };
  const dateFilteredData = applyDateFilter(newFilterDataObj, filters);
  newFilterDataObj.filteredData = dateFilteredData;
  newFilterDataObj.tableData = dateFilteredData;
  return newFilterDataObj;
};

export const filterObjStructure = (item, internalItem) => ({
  name: item.name,
  filterKey: item.filterKey,
  key: item.key,
  type: item.type,
  value: item.values.filter((obj) => internalItem.value.indexOf(obj) === -1),
});

export const applyStringFilterIndividual = (data, filters, filtersCopy) => {
  const NewFIlters = [];
  filtersCopy.map((item) =>
    filters.map((internalItem) => {
      if (item.name === internalItem.name && internalItem.filterKey === "Exclude Only") {
        const newData = filterObjStructure(item, internalItem);
        return NewFIlters.push(newData);
      }
      if (item.name === internalItem.name) {
        return NewFIlters.push(internalItem);
      }
      return false;
    })
  );
  const newData = [];
  data?.forEach((singleRow) => {
    const filtersList = NewFIlters.map((singleFilter) => {
      if (typeof singleRow[singleFilter.key] === "object") {
        const valuesTosearchArr = singleFilter.value;
        const rowKeyValueArr = singleRow[singleFilter.key];
        return valuesTosearchArr.some((value) => rowKeyValueArr.includes(value));
      }
      return singleFilter.value.includes(singleRow[singleFilter.key]);
    });
    if (filtersList.filter((item) => item === true).length === filtersList.length) {
      newData.push(singleRow);
    }
  });
  return newData;
};

export const createFiltersForData = (FilteredData, selectedFilters) => {
  const updatedFilters = createTableFiltersUpdated(FilteredData);
  const newFilter = [...updatedFilters];
  selectedFilters.forEach((item, index) => {
    if (item?.value?.length) {
      newFilter.splice(
        index,
        1,
        selectedFilters.filter((singleFilter) => singleFilter.key === item.key)[0]
      );
    }
  });
  return newFilter;
};
export const applyFilters = (tableDataCopy, filtersCopy, filterSelected, toFixValue = 1) => {
  if (filterSelected?.length) {
    const filterSelectedList = filterSelected.filter(
      (data) => data.value && data.value.length > 0 && data.filterKey !== "Clear Filter"
    );
    const updateFilterCopy = filtersCopy.map((item) => {
      const obj = { ...item };
      obj.filterKey = "Include Only";
      obj.value = [];
      return obj;
    });
    const FilteredData = applyStringFilterIndividual(
      tableDataCopy,
      filterSelectedList,
      updateFilterCopy
    );

    const FilterDataObj = {
      filteredData: FilteredData,
      tableData: FilteredData,
      filters: filterSelected,
    };
    return applynumberfilters(FilterDataObj, filterSelected, toFixValue);
  }
  return null;
};
export const cancelFilterHandler = (filterSelected, selectedList) => {
  const filterSelectedUpdate = [...filterSelected];
  const objectToChange = selectedList[0];
  const newFilters = [];
  if (objectToChange) {
    filterSelectedUpdate.forEach((item) => {
      if (item.name === objectToChange.name) {
        newFilters.push(objectToChange);
      } else newFilters.push(item);
    });
  }
  return newFilters;
};
export const OnIncludeChange = (name, value, filterSelected, filters) => {
  filters.forEach((item, index) => {
    const itemClone = item;
    if (value === "Exclude Only") {
      if (itemClone.type === "string" && filterSelected[index].name === name) {
        itemClone.filterKey = value;
        itemClone.value = filters[index].values.filter((internalItem) =>
          filterSelected[index].value.includes(internalItem)
        );
      }
      // } else if (value === "Clear Filter") {
      //   if (itemClone.type === "string" && filterSelected[index].name === name) {
      //     itemClone.filterKey = value;
      //     itemClone.value = [];
      //   }
    } else if (itemClone.type === "string" && filterSelected[index].name === name) {
      itemClone.filterKey = value;
    }
  });
  return filters;
};

export const handleFilters = (value, name, filterSelected, filters) => {
  const updatedFilterSelected = [...filterSelected];
  if (value.includes("all")) {
    const allData = filters.filter((item) => name === item.name);
    allData[0].value = [];
    updatedFilterSelected.forEach((item) => {
      const updatedItem = item;
      if (updatedItem.name === name) {
        if (updatedItem.value.length === allData[0].values.length) {
          updatedItem.value = [];
        }
        if (value.length > allData[0].values.length) {
          updatedItem.value = [];
        } else {
          updatedItem.value = [];
          updatedItem.value = allData[0].values;
        }
      }
    });
  } else {
    updatedFilterSelected.forEach((item) => {
      if (item.name === name) {
        const itemClone = item;
        itemClone.value = value;
      }
    });
  }
  return updatedFilterSelected;
};

export const onRangeChange = (value, name, filterSelected) => {
  const arrayObj = filterSelected.map((item) => {
    if (item.name === name) {
      const itemCopy = { ...item };
      itemCopy.selectedValue = value;
      return itemCopy;
    }
    return item;
  });
  return arrayObj;
};

export const onDateChangeFilter = (startDate, endDate, name, filterSelected) => {
  const arrayObj = filterSelected.map((item) => {
    if (item.name === name) {
      const itemCopy = { ...item };
      itemCopy.selectedDates = [startDate, endDate];
      return itemCopy;
    }
    return item;
  });
  return arrayObj;
};
