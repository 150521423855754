import React, { useState, useEffect, useRef } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { FormControlLabel, Checkbox } from "@mui/material";
import { ReactComponent as SortIcon } from "../../../../../Assets/icons/sorting_v2.svg";
import { ReactComponent as FirstIcon } from "../../../../../Assets/icons/pagination-first.svg";
import { ReactComponent as LastIcon } from "../../../../../Assets/icons/pagination-last.svg";
import { ReactComponent as DropdownIcon } from "../../../../../Assets/icons/down-chevron.svg";
import SearchBar from "../SearchBar";
import Button from "../Button";
import useDetectOutsideClick from "../../../../../CustomHooks/useDetectOutsideClick";
import { noop } from "../../../../../Utils/commonUtils";
import "./style.scss";

// Table header component
function TableHeader({
  isPageSelected,
  headers = [],
  onSelectAll = noop,
  onSort = noop,
  showCheckBox = false,
  showSelectAllDropdown = false,
  openSelectAllDropdown = false,
  setOpenSelectAllDropdown = noop,
  selectedPageRow,
  currentRows,
}) {
  const [sortOrder, setSortOrder] = useState({});
  const ref = useRef();
  const handleSort = (accessor) => {
    const order = sortOrder?.[accessor] === "asc" ? "desc" : "asc";
    setSortOrder({ [accessor]: order });
    onSort(accessor, order);
  };

  const handleDropdownChange = (selected) => {
    onSelectAll(selected, selectedPageRow);
    setOpenSelectAllDropdown(false);
  };

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    if (openSelectAllDropdown) {
      setOpenSelectAllDropdown(!openSelectAllDropdown);
    }
  });

  return (
    <thead>
      <tr>
        {showCheckBox && showSelectAllDropdown && (
          <th
            ref={ref}
            className={`checkbox-header ${showSelectAllDropdown ? "show-selectAll-options" : ""} ${
              currentRows?.length === 0 ? "disabled-checkbox" : ""
            }`}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="selectAll"
                  id="selectAll"
                  checked={selectedPageRow?.length > 0}
                  indeterminate={
                    selectedPageRow?.length > 0 && selectedPageRow?.length < currentRows?.length
                  }
                  disableRipple
                  onChange={() => setOpenSelectAllDropdown(!openSelectAllDropdown)}
                />
              }
            />
            {showSelectAllDropdown && (
              <>
                <div
                  className="selectAll-dropdown-icon"
                  onClick={() => setOpenSelectAllDropdown(!openSelectAllDropdown)}
                  aria-hidden
                >
                  <DropdownIcon className={openSelectAllDropdown ? "rotate-180" : ""} />
                </div>
                {openSelectAllDropdown && (
                  <div className="option_container">
                    <div
                      role="button"
                      aria-hidden
                      onClick={() => handleDropdownChange("From this page")}
                      className="option mb-[3px] py-[4px] px-[8px]"
                    >
                      From this page
                    </div>
                    <div
                      role="button"
                      aria-hidden
                      onClick={() => handleDropdownChange("From all pages")}
                      className="option py-[4px] px-[8px]"
                    >
                      From all pages
                    </div>
                  </div>
                )}
              </>
            )}
          </th>
        )}
        {showCheckBox && !showSelectAllDropdown && (
          <th className="checkbox-header">
            <input type="checkbox" checked={isPageSelected} onChange={onSelectAll} />
          </th>
        )}
        {headers?.map((header) => (
          <th className="paginated-headers" key={header?.accessor}>
            <p
              onClick={() => handleSort(header?.accessor)}
              aria-hidden
              className="inline-block cursor-pointer"
            >
              {header?.name}
            </p>
            <span className="sorting-icons">
              {sortOrder[header?.accessor] ? (
                <SortIcon
                  className={`sorting_v2 ${
                    sortOrder[header?.accessor] === "asc" ? "up_icon" : "down_icon"
                  }`}
                  onClick={() => handleSort(header?.accessor)}
                />
              ) : (
                <SortIcon className="sorting_v2" onClick={() => handleSort(header?.accessor)} />
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}

// Table row component
function TableRow({ data, headers, selected, onSelect, showCheckBox }) {
  return (
    <tr>
      {showCheckBox && (
        <td>
          <input type="checkbox" checked={selected} onChange={onSelect} />
        </td>
      )}
      {headers?.map((header) => (
        <td key={header?.accessor}>{data[header?.accessor]}</td>
      ))}
    </tr>
  );
}

const handlePreviewClick = ({ filteredData, selectedRows, parent, updateModalData }) => {
  const result = filteredData
    ?.filter((item) => selectedRows?.includes(item?.id))
    .map((item) => item?.columnName);
  if (parent === "standard-data") {
    updateModalData(result?.join(", "));
  } else updateModalData();
};

const handleSearchChange = (e, { setSearchTerm, setSortOrder, setCurrentPage }) => {
  setSearchTerm(e.target.value);
  setSortOrder({});
  setCurrentPage(1); // Reset pagination when searching
};

const onHandleChange = (event, value, setCurrentPage) => {
  setCurrentPage(value);
};

const handleRowSelect = ({ selectedRows, id, setSelectedRows, handleChange }) => {
  const selectedIndex = selectedRows?.indexOf(id);
  let newSelectedRows = [];

  if (selectedIndex === -1) {
    newSelectedRows = [...selectedRows, id];
  } else {
    newSelectedRows = selectedRows?.filter((rowId) => rowId !== id);
  }

  setSelectedRows(newSelectedRows);
  handleChange({
    target: {
      name: "categoryColumnData",
      value: newSelectedRows,
    },
  });
};

const handleSelectAll = ({
  currentRows,
  selectedRows,
  setSelectedRows,
  handleChange,
  filteredData,
  selectedPageRow,
  selectedDropdownValue = "From this page",
}) => {
  let newSelectedRows = [];
  if (selectedDropdownValue === "From all pages") {
    if (selectedRows?.length) {
      newSelectedRows = selectedPageRow?.length > 0 ? [] : filteredData?.map((item) => item?.id);
      setSelectedRows(newSelectedRows);
    } else {
      newSelectedRows = filteredData?.map((item) => item?.id);
      setSelectedRows(newSelectedRows);
    }
  } else {
    const pageRows = currentRows.map((item) => item.id);
    if (selectedPageRow?.length > 0) {
      newSelectedRows = selectedRows?.filter((id) => !pageRows?.includes(id));
      setSelectedRows(newSelectedRows);
    } else {
      newSelectedRows = [...selectedRows, ...pageRows.filter((id) => !selectedRows?.includes(id))];
      setSelectedRows(newSelectedRows);
    }
  }
  handleChange({
    target: {
      name: "categoryColumnData",
      value: newSelectedRows,
    },
  });
};

const renderTable = ({
  searchTerm,
  setSearchTerm,
  setSortOrder,
  setCurrentPage,
  currentPage,
  pageNumbers,
  configDataClone,
  currentRows,
  selectedRows,
  setSelectedRows,
  handleChange,
  filteredData,
  handleSort,
  showCheckBox,
  isPageSelected,
  sortOrder,
  noDataMessage,
  modal,
  parent,
  updateModalData,
  showSelectAllDropdown,
  openSelectAllDropdown,
  setOpenSelectAllDropdown,
}) => (
  <div>
    <div className="search-and-pagination-wrapper">
      <div className="search-wrapper">
        <SearchBar
          searchTerm={searchTerm}
          onChangeHandler={(term) =>
            handleSearchChange(term, { setSearchTerm, setSortOrder, setCurrentPage })
          }
          placeholder="by Column Name"
          styleSearch=" !mt-0 !mx-0"
        />
      </div>
      <div className="pagination">
        <Stack spacing={5}>
          <Pagination
            page={currentPage}
            onChange={(event, value) => onHandleChange(event, value, setCurrentPage)}
            count={pageNumbers?.length}
            showFirstButton
            showLastButton
            renderItem={(item) => (
              <PaginationItem slots={{ first: FirstIcon, last: LastIcon }} {...item} />
            )}
          />
        </Stack>
      </div>
    </div>
    <table>
      <TableHeader
        headers={configDataClone?.headers}
        onSelectAll={(selectedDropdownValue, selectedPageRow) =>
          handleSelectAll({
            currentRows,
            selectedRows,
            setSelectedRows,
            handleChange,
            filteredData,
            selectedDropdownValue,
            selectedPageRow,
          })
        }
        onSort={handleSort}
        showCheckBox={showCheckBox}
        isPageSelected={isPageSelected}
        selectedPageRow={currentRows?.filter((row) => selectedRows?.includes(row?.id))}
        currentRows={currentRows}
        sortOrder={sortOrder}
        showSelectAllDropdown={showSelectAllDropdown}
        openSelectAllDropdown={openSelectAllDropdown}
        setOpenSelectAllDropdown={setOpenSelectAllDropdown}
      />
      {filteredData?.length ? (
        <tbody>
          {currentRows?.map((item) => (
            <TableRow
              key={item}
              data={item}
              headers={configDataClone?.headers}
              selected={selectedRows?.includes(item.id)}
              onSelect={() =>
                handleRowSelect({
                  selectedRows,
                  id: item.id,
                  setSelectedRows,
                  handleChange,
                  filteredData,
                })
              }
              showCheckBox={showCheckBox}
            />
          ))}
        </tbody>
      ) : (
        <tbody>
          <td colSpan="5" className="no-data">
            {noDataMessage?.searchResults}
          </td>
        </tbody>
      )}
    </table>
    {modal ? (
      <div className="footer-table">
        <Button
          text={modal?.btnText}
          className="btn-filled preview-btn"
          handleClick={() =>
            handlePreviewClick({ filteredData, selectedRows, parent, updateModalData })
          }
        />
      </div>
    ) : null}
  </div>
);

// Table component
export default function PaginationTable({
  configData,
  headingCount,
  modal,
  updateModalData,
  showCheckBox = false,
  parent,
  requiredCount,
  noDataMessage,
  handleChange,
  error,
  showSelectAllDropdown = false,
}) {
  const configDataClone = configData;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState(
    configDataClone?.data?.map((item) => item.id) || []
  );
  const [sortOrder, setSortOrder] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [openSelectAllDropdown, setOpenSelectAllDropdown] = useState(false);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Calculate total number of pageRows
  const totalPages = Math.ceil((filteredData?.length ?? 0) / rowsPerPage);

  // Generate an array of page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handleSort = (accessor, order, updateCurrentPage = true) => {
    const sortedData = [...filteredData]?.sort((a, b) => {
      if (order === "asc") {
        return a[accessor]?.localeCompare(b[accessor]);
      }
      return b[accessor]?.localeCompare(a[accessor]);
    });
    setSortOrder({ [accessor]: order });
    if (updateCurrentPage) {
      setCurrentPage(1);
    }
    setFilteredData(sortedData);
  };

  useEffect(() => {
    const dataWithSearch = configDataClone?.data?.filter((item) =>
      item?.columnName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const originalIds = new Set(filteredData?.map((obj) => obj.id));
    const updatedIds = new Set(dataWithSearch?.map((obj) => obj.id));

    // Find new IDs
    const newIds = [...updatedIds].filter((id) => !originalIds?.has(id));
    const removedIds = new Set([...originalIds]?.filter((id) => !updatedIds?.has(id)));

    if (newIds?.length) {
      setSelectedRows((prevSelectedRows) => [...new Set([...prevSelectedRows, ...newIds])]);
      handleChange({
        target: {
          name: "categoryColumnData",
          value: [...new Set([...selectedRows, ...newIds])],
        },
      });
    }
    if (removedIds.size) {
      setSelectedRows((prevSelectedRows) =>
        [...prevSelectedRows].filter((id) => !removedIds?.has(id))
      );
      handleChange({
        target: {
          name: "categoryColumnData",
          value: [...selectedRows].filter((id) => !removedIds?.has(id)),
        },
      });
      if (!newIds.length && !removedIds.size) {
        handleChange({
          target: {
            name: "categoryColumnData",
            value: configDataClone?.data.map((item) => item.id) || [],
          },
        });
      }
    }
    const columnName = Object?.keys(sortOrder)?.[0];
    if (columnName && dataWithSearch?.length === filteredData?.length) {
      handleSort(columnName, sortOrder?.[columnName], false);
    } else {
      setFilteredData(dataWithSearch);
    }
    if (currentPage > pageNumbers?.length) {
      setCurrentPage(1);
    }
  }, [configDataClone?.data]);

  useEffect(() => {
    if (filteredData?.length) {
      setCurrentRows(filteredData?.slice(indexOfFirstRow, indexOfLastRow));
    } else setCurrentRows([]);
  }, [filteredData, currentPage]);

  useEffect(() => {
    setFilteredData(
      configDataClone?.data?.filter((item) =>
        item?.columnName?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm]);

  const isPageSelected = currentRows?.every((row) => selectedRows?.includes(row?.id));

  return (
    <div className="pagination-table-wrapper">
      <button
        type="button"
        className={`header ${expanded ? "expanded" : "collapsed"}`}
        onClick={() => setExpanded(!expanded)}
      >
        <h3>
          {headingCount?.title}
          {requiredCount ? ` (${selectedRows?.length}/${configDataClone?.data?.length})` : ""}
        </h3>
        <p>{headingCount?.description}</p>
      </button>
      {expanded &&
        renderTable({
          searchTerm,
          setSearchTerm,
          setSortOrder,
          setCurrentPage,
          currentPage,
          pageNumbers,
          configDataClone,
          currentRows,
          selectedRows,
          setSelectedRows,
          handleChange,
          filteredData,
          handleSort,
          showCheckBox,
          isPageSelected,
          sortOrder,
          noDataMessage,
          modal,
          parent,
          updateModalData,
          openSelectAllDropdown,
          setOpenSelectAllDropdown,
          showSelectAllDropdown,
        })}
      {error && <p className="error_message pagination">{error}</p>}
    </div>
  );
}
