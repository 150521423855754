import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_URL,
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
//   environment: process.env.REACT_APP_ENVIRONMENT,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
