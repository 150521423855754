import { lazy } from "react";

const Dashboard = lazy(() => import("../Components/Dashboard"));

const PrivateRouteConfig = [
  {
    component: Dashboard,
    path: "/dashboard/*",
  },
];

export default PrivateRouteConfig;
