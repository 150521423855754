import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router";
// import ResendImg from "../../../Assets/images/resend-email.png";
// import logo from "../../../Assets/images/Cloudonomic-login.svg";
import commonService from "../../../Services/commonService";
import actions from "../../../Actions";
import { updatePartnerFavicon } from "../../Login/utils";
import Routes from "../../../Config/Routes";
import { calculateTime, getToast, isSignUpEnabled } from "../../../Utils/commonUtils";
import Loader from "../Loader";
import PublicFooter from "../../CK-Auto/Common/PublicFooter";
import { preloadImages } from "../../../Utils/userUtils";
import PageTitle from "../PageTitle";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../../Services/Strapi/urls";

// eslint-disable-next-line max-lines-per-function
function ResendEmail() {
  const [partnerLogo, setPartnerLogo] = useState("");
  const [timer, setTimer] = useState(0);
  const [allRightsFooter, setAllRightsFooter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCkAutoEnabled, setISCkAutoEnabled] = useState(false);
  const [partnerDisplayName, setPartnerDisplayName] = useState("");
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const myEmailParam = urlParams.get("email");
  const [strapiDataState, setStrapiDataState] = useState({});
  const navigate = useNavigate();

  const getInitialData = async (strapiData) => {
    try {
      if (process.env.REACT_APP_BASE_URL !== window.location.origin) {
        // api call partner logo
        const result = await commonService.partnerCreateUser();
        const SIGNUP_ENABLED = !!isSignUpEnabled(result?.data?.data);
        const updatedPartnerDisplayName =
          result?.data?.data?.displayName || result?.data?.data?.partnerName || "";
        dispatch(actions.CkAutoEnabledAction("SIGNUP_ENABLED", SIGNUP_ENABLED));
        const iconResponse = result?.data?.data?.logo;
        if (iconResponse) {
          await preloadImages(iconResponse);
        }

        const favIcon = {
          data: {
            data: result?.data?.data?.favicon,
          },
        };
        updatePartnerFavicon(favIcon);
        setISCkAutoEnabled(!!isSignUpEnabled(result?.data?.data));
        setPartnerLogo(iconResponse);
        setPartnerDisplayName(updatedPartnerDisplayName);
        setAllRightsFooter(
          `${updatedPartnerDisplayName} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`
        );
      } else {
        updatePartnerFavicon();
        setAllRightsFooter(
          `${strapiData?.pageFooter?.pretext} ${new Date().getFullYear()} ${
            strapiData?.pageFooter?.hypertext
          }`
        );
      }
      const payload = {
        email: myEmailParam,
      };
      const getTimer = await commonService.handleResendEmailTimer(payload);
      setTimer(getTimer?.data?.data?.timer || 0);
      setIsLoading(false);

      // verify api call
    } catch (error) {
      updatePartnerFavicon();
      navigate(Routes.LOGIN);
    }
  };

  const getStrapiData = async () => {
    setIsLoading(true);
    const data = await fetchStrapiData(strapiUrls.RESEND_EMAIL);
    setStrapiDataState(data);

    if (!myEmailParam) {
      navigate(Routes.LOGIN);
    } else {
      getInitialData(data);
    }
  };

  useEffect(() => {
    getStrapiData();
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        clearInterval(interval);
      }, 1000);
    }
    return () => {
      // clear interval on re-render to avoid memory leaks
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer]);

  const sendEmail = async () => {
    const payload = {
      email: myEmailParam,
    };
    setIsLoading(true);
    try {
      const message = await commonService.handleResendEmail(payload);
      getToast("success", message.data.data.message);

      const getTimer = await commonService.handleResendEmailTimer(payload);
      setTimer(getTimer?.data?.data?.timer || 0);
      setIsLoading(false);
    } catch (err) {
      const {
        response: { status },
      } = err;
      if (status === 701 || status === 401) {
        navigate(Routes.LOGIN);
      }
      setIsLoading(false);
    }
  };

  const mainLogo = `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`;

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PageTitle
        pageTitle={strapiDataState?.pageTitle}
        customPartnerDisplayName={partnerDisplayName}
        pageHeader={isCkAutoEnabled}
      />
      <div className={`resend-email-wrapper ${isCkAutoEnabled ? "ck-auto-wrapper" : ""}`}>
        <div className="logo-wrapper">
          <img src={`${partnerLogo || mainLogo}`} alt="logo" />
        </div>
        <div className="resend-body-wrapper">
          <div className="resend-heading">
            <p className="primary-heading">{strapiDataState?.title}</p>
            <p className="secondary-heading">{strapiDataState?.subtitle}</p>
          </div>
          <div className="image">
            <img
              src={`${strapiBasePath}${strapiDataState?.resendImage?.data?.attributes?.url}`}
              alt=""
            />
          </div>
          <div className="body-foter">
            <p>
              {strapiDataState?.description}{" "}
              <important className="bold-text">{myEmailParam}</important>{" "}
              {strapiDataState?.subdescription}
            </p>
          </div>
        </div>
        <div className="resend-footer">
          <button
            className={`resend-button ${timer > 0 ? "resend-disable" : null}`}
            type={strapiDataState?.Button?.type}
            disabled={timer > 0}
            onClick={() => sendEmail()}
          >
            {strapiDataState?.Button?.label}
          </button>
          {timer > 0 && (
            <div className="countdown ">
              {strapiDataState?.timerText}{" "}
              {moment(calculateTime(timer), "hh:mm:ss").format("mm:ss")}
            </div>
          )}
        </div>
        <PublicFooter
          show={isCkAutoEnabled}
          allRightsFooter={allRightsFooter}
          strapiData={strapiDataState?.publicFooter}
        />
      </div>
    </>
  );
}

export default ResendEmail;
