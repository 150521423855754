const initState = { allDashboardComments: {} };
const initAction = { type: "", payload: {} };

const updateCommentCount = (state, action) => {
  const {
    payload: { dashboardId, uniqueId, updatedCount },
  } = action;

  return {
    ...state.allDashboardComments[dashboardId].countMap,
    [uniqueId]: updatedCount,
  };
};

export default function commentsReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "SAVE_COMMENTS_COUNT":
      return { ...state, allDashboardComments: action.payload };
    case "UPDATE_COMMENT_COUNT":
      return {
        ...state,
        allDashboardComments: {
          [action.payload.dashboardId]: {
            ...state.allDashboardComments[action.payload.dashboardId],
            countMap: updateCommentCount(state, action),
          },
        },
      };
    default:
      return state;
  }
}
