import React from "react";
import "./style.scss";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { ReactComponent as ExcelIcon } from "../../../../Assets/icons/others/ic-xls.svg";
import { ReactComponent as DownloadWhite } from "../../../../Assets/icons/others/ic-download.svg";
import { downloadExcel } from "../../../../Utils/commonUtils";
import commonService from "../../../../Services/commonService";

import { ReactComponent as ExcelLoader } from "../../../../Assets/icons/others/excel-loader.svg";

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelLoading: false,
    };
  }

  downloadSheet = async (/* sheet */) => {
    const { downloadUrl } = this.props;

    this.setState({
      excelLoading: true,
    });
    try {
      const response = await commonService.downloadFile(downloadUrl);
      downloadExcel(response);
    } catch (err) {
      console.log(err);
      // console.log(err);
    } finally {
      this.setState({
        excelLoading: false,
      });
    }
  };

  render() {
    const { disableDownload, downloadBtnClass, className } = this.props;
    const { excelLoading } = this.state;
    return (
      <div className={`download-wrapper listing_component_download absolute ${className}`}>
        <Tooltip title="Download xlsx" arrow placement="top" TransitionComponent={Fade}>
          <Button
            className={`download-icon-btn ${
              disableDownload || excelLoading ? "disabled-button" : ""
            } ${downloadBtnClass}`}
            onClick={this.downloadSheet}
            disabled={excelLoading}
          >
            <span className="xcelIcon">
              <ExcelIcon />
            </span>
            <span className="xcelDownloadIc">
              {excelLoading ? <ExcelLoader /> : <DownloadWhite />}
            </span>
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default DownloadButton;
