import React from "react";
import { Tooltip } from "@mui/material";
import "./style.scss";

export function TabsV2({ children, disabled, isRoundedCorner, wrapperClass }) {
  return (
    <div
      className={`tabV2_wrapper ${disabled ? "disabled" : ""} ${
        isRoundedCorner ? "roundedWrapper" : ""
      } ${wrapperClass}`}
    >
      {children}
    </div>
  );
}

export function TabV2({
  customClass = "",
  customActiveClass = "",
  children,
  selectedTab,
  tab,
  index,
  tabs,
  tooltip,
  ...rest
}) {
  return (
    <Tooltip title={tooltip || ""} arrow placement="top">
      <div
        className={`tab-btn ${selectedTab === tab ? `active ${customActiveClass}` : ""} ${
          tabs[index + 1] === selectedTab ? "!border-r-0" : ""
        } ${tabs[index - 1] === selectedTab ? "!border-l-0" : ""} ${customClass}`}
        {...rest}
      >
        {children}
      </div>
    </Tooltip>
  );
}
