import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import "./style.scss";
// eslint-disable-next-line import/no-cycle
import { returnDate } from "../../Utils/commonUtils";

export default function SimpleAccordion({ data }) {
  return (
    <div>
      {data.map((item) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="customer_name_accordion">{item?.customerName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                {item?.sheetPaths?.map((internalItem) => (
                  <li>
                    <a
                      className="external_referer"
                      href={internalItem?.path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {!!internalItem?.date && returnDate(internalItem?.date)} <LaunchIcon />
                    </a>
                  </li>
                ))}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
