/* eslint-disable max-lines-per-function */
import React, { useCallback, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";

import { makeStyles, Tooltip } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ReactComponent as CloseIcons } from "../../../Assets/icons/ic-cancel.svg";
import "./style.scss";
import Button from "./Components/Button";
import { getCroppedImg } from "./Crop";
import { getCurrencySymbol } from "../../../Utils/commonUtils";

export default function EasyCrop({ open, handleClose, handleUpload, image, preview, useOriginal }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = async (imageSrc, croppedAreaPixel) => {
    try {
      const result = await getCroppedImg(imageSrc, croppedAreaPixel);
      setCroppedImage(result);
    } catch (e) {
      // do nothing
    }
  };

  const handleCrop = useCallback(
    (imageSrc, croppedAreaPixel) => {
      if (croppedImage) {
        handleUpload(croppedImage);
        handleClose();
      } else {
        showCroppedImage(imageSrc, croppedAreaPixel);
      }
    },
    [croppedImage]
  );

  const handleOriginalUpload = () => {
    handleUpload(useOriginal);
    handleClose();
  };

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
  }));

  const classes = useStyles();

  const renderCropper = () => (
    <div
      style={{
        display: image === null || croppedImage !== null ? "none" : "block",
      }}
    >
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        zoomSpeed={4}
        maxZoom={3}
        zoomWithScroll
        showGrid={false}
        aspect={3 / 1}
        onCropComplete={onCropComplete}
        onCropChange={setCrop}
        onZoomChange={setZoom}
      />
      <div className="controls absolute w-full bottom-[-14px]">
        <Tooltip placement="left" title="Zoom" arrow>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="zoom"
            onChange={(e, val) => setZoom(val)}
            className="range"
          />
        </Tooltip>
      </div>
    </div>
  );

  const renderCropPreview = () => (
    <div className="top-header flex items-start justify-between">
      <div className="logo-section flex items-center">
        <div className="right-image">{renderCropper()}</div>
      </div>
      <div className="info-section">
        <h2 className="text-[9px] font-bold">Header</h2>
        <p className="text-[8px] mb-1 mt-1">Description</p>
        <div className="invoice-shortcut">
          <div className="text-[8px] flex items-center justify-between">
            <span>AWS Service Charges</span>
            <div className="dashes-wrapper flex">
              <span>
                <span>{getCurrencySymbol()}22,116.94</span>
              </span>
            </div>
          </div>
          <div className="text-[8px] font-bold text-[#0A3CA2] flex items-center justify-between">
            <span>TTN Discounts (4.15% Savings)</span>
            <div className="dashes-wrapper flex">
              <span>
                <span>({getCurrencySymbol()}916.99)</span>
              </span>
            </div>
          </div>
          <div className="total-amount text-[9px] font-bold flex items-center justify-between">
            <span>TOTAL AMOUNT</span>
            <span>
              <span>{getCurrencySymbol()}21,198.95</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true)}
      aria-labelledby={`${preview ? "alert-upload-dialog-preview" : "alert-upload-dialog"}`}
      maxWidth="lg"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle className="relative upload-dialog-title !py-2" id="alert-dialog-title">
        Crop Image
        <CloseIcons
          onClick={() => handleClose(true)}
          className="absolute right-[-7px] top-[-10px] cursor-pointer "
        />
      </DialogTitle>
      <DialogContent
        className={`upload-dialog-body ${
          preview && "template-wrapper preview-template !h-[220px] !p-5 !w-[100%]"
        }`}
      >
        {preview && !croppedImage ? renderCropPreview() : renderCropper()}
        {croppedImage && (
          <div className="cropped-image-container">
            <img className="cropped-image" src={URL.createObjectURL(croppedImage)} alt="cropped" />
          </div>
        )}
      </DialogContent>
      <DialogActions className="upload-dialog-footer !pr-6 !pb-4">
        <Button
          className="rounded-[3px] text-xs bg-white "
          size="small"
          variant="outline-primary-nohover"
          handleClick={() => handleClose(true)}
          text="Cancel"
        />
        <Button
          className="rounded-[3px] text-xs !font-medium primary-hover"
          size="small"
          variant="primary"
          id="submit"
          text="Use Original"
          handleClick={() => handleOriginalUpload()}
        />
        <Button
          className="rounded-[3px] text-xs !font-medium primary-hover"
          size="small"
          variant="primary"
          id="submit"
          text={`${croppedImage ? "Save" : "Crop"}`}
          handleClick={() => handleCrop(image, croppedAreaPixels)}
        />
      </DialogActions>
    </Dialog>
  );
}
