import React, { useEffect, useState } from "react";
import MultiSelect from "../FormConfig/Form/Components/MultiSelect";
import renderFlowIcons, {
  renderLabel,
  getSortedList,
  resetList,
  fireHandleChange,
  resetItems,
} from "./utils";
import "./style.scss";

export default function CustomerLinkedAccId({
  name,
  handleChange,
  options = [],
  value = [],
  error,
  touched,
  resetClicked,
  noDataLabel,
  noDataLabelHeading,
  formLabel,
  formLabelSelected,
  label,
  V2 = false,
  disabled = false,
}) {
  const [leftSideItems, setLeftSideItems] = useState([]);
  const [leftSideSelectedItems, setLeftSideSelectedItems] = useState([]);

  const [rightSideItems, setRightSideItems] = useState([]);
  const [rightSideSelectedItems, setRightSideSelectedItems] = useState([]);

  const selectedLength = options.filter((item) => item.checked === true)?.length;

  useEffect(() => {
    if (resetClicked) {
      setRightSideItems([]);
      setLeftSideItems(options.map((item) => ({ ...item, checked: false })));
      setLeftSideSelectedItems([]);
      setRightSideSelectedItems([]);
    }
  }, [resetClicked]);

  useEffect(() => {
    const filteredArray = options.filter(
      (item) => !value.some((smallItem) => smallItem?.value === item?.value)
    );
    if (options.length) {
      setLeftSideItems(filteredArray.map((item) => ({ ...item, checked: false })));
    }
    if (value.length) {
      setRightSideItems(getSortedList(value));
    }
  }, [options]);

  const checkItems = (event, stateFn, listToEmpty) => {
    const {
      target: { value: targetValue },
    } = event;

    stateFn(targetValue);

    if (listToEmpty === "rightSide") {
      resetList(setRightSideItems, setRightSideSelectedItems, rightSideItems);
    } else {
      resetList(setLeftSideItems, setLeftSideSelectedItems, leftSideItems);
    }
  };

  // left side event handlers
  const addItemToRightSide = () => {
    if (leftSideSelectedItems?.length) {
      resetItems(leftSideItems, leftSideSelectedItems, setLeftSideItems, setLeftSideSelectedItems);
      const updatedRightSideItems = [...rightSideItems, ...leftSideSelectedItems];
      setRightSideItems(getSortedList(updatedRightSideItems));

      fireHandleChange(updatedRightSideItems, handleChange, name);
    }
  };
  // left side event handlers

  // right side event handlers
  const addItemToLeftSide = () => {
    if (rightSideSelectedItems?.length) {
      const updatedRightSideList = resetItems(
        rightSideItems,
        rightSideSelectedItems,
        setRightSideItems,
        setRightSideSelectedItems
      );

      setLeftSideItems(getSortedList([...leftSideItems, ...rightSideSelectedItems]));

      fireHandleChange(updatedRightSideList, handleChange, name);
    }
  };
  const resetSelection = (leftSide = false) => {
    if (leftSide) resetList(setLeftSideItems, setLeftSideSelectedItems, leftSideItems);
    else resetList(setRightSideItems, setRightSideSelectedItems, rightSideItems);
  };

  return (
    <div
      className={`${V2 ? "cld_customer_linked_acc_wrapper_v2" : ""} customer-linked-acc-wrapper`}
    >
      {renderLabel(label)}
      <div className="flex items-center gap-x-2">
        <div className={`multi-select ${disabled ? "list-disabled" : ""}`}>
          <MultiSelect
            searchEnabled
            options={leftSideItems}
            selected={leftSideSelectedItems}
            handleChange={(event) => checkItems(event, setLeftSideSelectedItems, "rightSide")}
            name={name}
            resetSelection={() => resetSelection(true)}
            label={formLabel || "Unassociated Accounts"}
            placeholder={`${leftSideItems.length} Linked Accounts`}
            disabled={disabled}
            noDataText={
              noDataLabel ? (
                <span className="leading-[18px]">{noDataLabel}</span>
              ) : (
                <span className="leading-[18px]">
                  Select IDs from
                  <br /> ‘Associated Accounts’ & push them
                  <br /> to ‘Unassociated Account’.
                </span>
              )
            }
            noDataLabel="No Accounts"
          />
        </div>
        <div className="flow-icon-wrapper flex flex-col gap-y-[5px]">
          {renderFlowIcons(
            leftSideSelectedItems,
            addItemToRightSide,
            rightSideSelectedItems,
            addItemToLeftSide
          )}
        </div>
        <div className={`multi-select ${disabled ? "list-disabled" : ""}`}>
          <MultiSelect
            searchEnabled
            options={rightSideItems}
            selected={rightSideSelectedItems}
            handleChange={(event) => checkItems(event, setRightSideSelectedItems, "leftSide")}
            label={`${formLabelSelected || "Associated Accounts"} ${selectedLength}`}
            name={name}
            resetSelection={() => resetSelection()}
            placeholder={`${rightSideItems.length} Associated Accounts`}
            disabled={disabled}
            noDataText={
              noDataLabel ? (
                <span className="leading-[18px]">{noDataLabel}</span>
              ) : (
                <span className="leading-[18px]">
                  {" "}
                  Select IDs from
                  <br /> ‘Unassociated Accounts’ & push them
                  <br /> to ‘Associated Account’.
                </span>
              )
            }
            noDataLabel={noDataLabelHeading || "No Accounts Added"}
          />
        </div>
      </div>
      {touched && error && <p className="error_message">{error}</p>}
    </div>
  );
}
