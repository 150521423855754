import React from "react";
import moment from "moment";
import { numberWithCommas, numberWithCommasandDecimal, noop } from "../../../../Utils/commonUtils";
import { DOLLAR_CHECKER, ROWS_NUMBER } from "./constant";
import { ReactComponent as SortIcon } from "../../../../Assets/icons/sorting_v2.svg";
import { ReactComponent as OldSortIcon } from "../../../../Assets/icons/sortIcon.svg";

import store from "../../../../store";

export const checkHandler = (Headers, columns = []) => {
  let found = [];
  found = columns.filter((item) => item.id in Headers);
  return found;
};

export const CostBreakupHeaders = (Headerconstant = [], Headers = {}) => {
  const updatedHeader = [];
  Headerconstant.forEach((item) => {
    const foundData = checkHandler(Headers, item.columns);
    if (item.id in Headers || !!foundData?.length) {
      const firstData = !!foundData && foundData[0];
      if (item.columns) {
        const updatedHeaderObj = {
          Header: item.Header,
          accessor: item.accessor,
          id: foundData?.length ? firstData.id : item.id,
          columns: foundData?.length ? foundData : item.columns,
          sortedIcon: item.sortedIcon,
        };
        updatedHeader.push(updatedHeaderObj);
      } else {
        updatedHeader.push(item);
      }
    }
  });
  return updatedHeader;
};

export const ReturnTotal = (key, data, total) => {
  const totalL = data.reduce((sum, row) => row[key] + sum, 0);
  const Total =
    typeof totalL === "number"
      ? `${
          total[key] - Number(totalL) > 0
            ? numberWithCommas((total[key] - Number(totalL)).toFixed(2))
            : "0.00"
        }`
      : null;
  return Total;
};

export const DollarChecker = (arr, column) => !!(!!arr && arr.includes(column.id));

export const renderFooter = (column, data, obj, isOthers = false) => {
  if (column.Footer === "Others") {
    return column.render("Footer");
  }
  if (isOthers) {
    if (DollarChecker(DOLLAR_CHECKER, column)) {
      return numberWithCommasandDecimal(obj?.[column?.id], 2);
    }
    return typeof obj?.[column?.id] === "number"
      ? numberWithCommas(obj[column.id].toFixed(2))
      : null;
  }
  if (DollarChecker(DOLLAR_CHECKER, column)) {
    return numberWithCommasandDecimal(
      Number(ReturnTotal(column.id, data, obj).replace(/[,$]+/g, "")),
      2
    );
  }
  return ReturnTotal(column.id, data, obj);
};

export const checkSelectedTabIncludesInTabs = (tabs, data) =>
  tabs?.length
    ? data?.get("selected") && tabs?.includes(data?.get("selected"))
    : data?.get("selected");

export const getRouteUrl = (navigate, accountDate, selected, baseUrl, tabs = []) => {
  const data = new URLSearchParams(window.location.search);
  let url = "";
  const query = localStorage.getItem("query");
  if (data.get("month") && data.get("year")) {
    const updatedUrl = checkSelectedTabIncludesInTabs(tabs, data)
      ? `month=${data.get("month")}&year=${data.get("year")}&selected=${data.get("selected")}`
      : `month=${data.get("month")}&year=${data.get("year")}&selected=${selected}`;
    url = `${baseUrl}?${updatedUrl}`;
    setTimeout(() => {
      navigate(url);
    }, 50);
    return url;
  }
  const updatedUrl = query
    ? `${query}&selected=${selected}`
    : `?month=${moment(accountDate.accountEndDate).format("MM")}&year=${moment(
        accountDate.accountEndDate
      ).format("YYYY")}&selected=${selected}`;
  url = `${baseUrl}${updatedUrl}`;
  setTimeout(() => {
    navigate(url, {
      month: moment(accountDate.accountEndDate).format("MM"),
      year: moment(accountDate.accountEndDate).format("YYYY"),
    });
  }, 50);
  return url;
};

export const sortHandler = (tableData, ascending, sortBy) => {
  const updatedTableData = [...tableData];
  updatedTableData.sort((a, b) => {
    if (typeof a[sortBy] === "number") {
      if (!ascending) {
        return a[sortBy] - b[sortBy];
      }
      return b[sortBy] - a[sortBy];
    }
    if (ascending) {
      return b[sortBy]?.toString().localeCompare(a[sortBy]?.toString(), undefined, {
        numeric: true,
        sensitivity: "base",
      });
    }
    return a[sortBy]?.toString().localeCompare(b[sortBy]?.toString(), undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
  return updatedTableData;
};

export const renderNumber = (column, total) => {
  if (total?.[column?.id] || total?.[column?.id] === 0) {
    if (DollarChecker(DOLLAR_CHECKER, column)) {
      return `${numberWithCommasandDecimal(total[column.id])}`;
    }
    return `${numberWithCommas(total[column.id].toFixed(2))}`;
  }
  return column.SecondaryFooter;
};

export function ReturnTdClass(rowIndex, cell = "", index = 0, stickyList = [], isV2 = false) {
  const cellColumnClass = !!cell.column && cell?.column.Header?.split(" ").join("_");
  let cellAlignmentClass = "";

  if (!!cell.column && cell?.column?.align) {
    if (cell.column.align === "Right") {
      cellAlignmentClass = "right-td-align";
    } else {
      cellAlignmentClass = "left-td-align";
    }
  } else {
    cellAlignmentClass = "";
  }
  const filterStickyList = stickyList.filter((item) => item?.id === cell?.column?.id);
  let stickyClass = "";
  if (index === 0) {
    stickyClass = "!sticky !left-0";
  }
  if (filterStickyList?.length) {
    stickyClass = filterStickyList[0].bodyClass;
  }
  return `${cellColumnClass} ${cell?.column?.customStyle}-${rowIndex % 2 === 0 ? "even" : "odd"} ${
    isV2 ? stickyClass : ""
  } ${cellAlignmentClass || ""} !z-9`;
}

export function ReturnThClass(cell = "", index = 0, stickyList = []) {
  const filterStickyList = stickyList.filter((item) => item?.id === cell?.id);
  let stickyClass = "!sticky top-[-1px] ";
  if (index === 0) {
    stickyClass += "!left-0 !z-10";
  }
  if (filterStickyList?.length) {
    stickyClass += `${filterStickyList[0].bodyClass} !z-10`;
  }
  if (cell?.headerClass) {
    stickyClass += `${cell.headerClass}`;
  }
  return stickyClass;
}

export function ReturnSortIcon(selectedSortHeader, ascending, v2) {
  let returnClass = "";
  if (selectedSortHeader) {
    returnClass = ascending ? "up_icon" : "down_icon";
  }
  return v2 ? <SortIcon className={`sorting_v2 ${returnClass}`} /> : <OldSortIcon />;
}
export const getMaxIndex = (data = []) => {
  let maxlength = 0;
  let maxIndex = 0;
  data?.forEach((item, index) => {
    const count = Object.keys(item);
    if (count.length > maxlength) {
      maxlength = count.length;
      maxIndex = index;
    }
  });
  return maxIndex;
};

export const getDateMonth = () => {
  const data = new URLSearchParams(window.location.search);
  const state = store.getState();
  const accountDate = state.AccountDateReducer;
  const query = new URLSearchParams(localStorage.getItem("query"));
  if (data.get("month") && data.get("year")) {
    return {
      month: data.get("month"),
      year: data.get("year"),
    };
  }
  if (query.get("month")) {
    return {
      month: query.get("month"),
      year: query.get("year"),
    };
  }
  return {
    month: moment(accountDate.accountEndDate).format("MM"),
    year: moment(accountDate.accountEndDate).format("YYYY"),
  };
};
export const getPayload = (service) => ({
  month: getDateMonth().month,
  year: getDateMonth().year,
  numberOfRows: ROWS_NUMBER,
  service,
});

export const getTotalAccountsPayload = (object = {}) => ({
  ...object,
  month: getDateMonth().month,
  year: getDateMonth().year,
});

const renderHeader = (mouseOverFilter, column, sortHandlerParent, summaryTable) => (
  <span
    className={`${
      mouseOverFilter && column?.sortedIcon ? "ck--multilevel-header-sorting-data" : "select-none"
    } ${column?.sortedIcon ? "cursor-pointer" : ""}`}
    onClick={() => (!column?.sortedIcon ? noop : sortHandlerParent(column?.id, summaryTable))}
    aria-hidden
  >
    {column?.render("Header")}
  </span>
);

export const getIconHeader = ({
  column,
  sortHandlerParent,
  summaryTable,
  selectedSortHeader,
  ascending,
  V2,
  lastColumnSticky,
  applyFiltersOnTable,
  renderTableFilters,
  mouseOverFilter,
}) => (
  <>
    {V2 && (
      <div
        className={`${
          lastColumnSticky && !column?.sortedIcon
            ? "justify-end"
            : "flex justify-between items-center"
        } ${column?.headerTextClass}`}
      >
        {renderHeader(mouseOverFilter, column, sortHandlerParent, summaryTable)}
        {!column?.hideSortingIcon && (
          <div className={`${mouseOverFilter ? "ck--multilevel-header-filter-block" : ""}`}>
            {applyFiltersOnTable && renderTableFilters(column)}
            {column?.sortedIcon && (
              <span
                onClick={() => sortHandlerParent(column?.id, summaryTable)}
                aria-hidden
                className="!mb-[2px] !ml-[10px] cursor-pointer"
              >
                {ReturnSortIcon(selectedSortHeader === column?.id, ascending, V2)}
              </span>
            )}
          </div>
        )}
      </div>
    )}
    {!V2 && (
      <>
        {renderHeader(mouseOverFilter, column, sortHandlerParent, summaryTable)}
        {column?.sortedIcon && (
          <span
            onClick={() => sortHandlerParent(column?.id, summaryTable)}
            aria-hidden
            className="cursor-pointer"
          >
            {ReturnSortIcon(selectedSortHeader === column?.id, ascending, V2)}
          </span>
        )}
      </>
    )}
  </>
);

export function ReturnSingleLevelTdClass(headerItem = "") {
  let cellAlignmentClass = "";

  if (!!headerItem && headerItem?.align) {
    if (headerItem.align === "Right") {
      cellAlignmentClass = "right-td-align";
    } else {
      cellAlignmentClass = "left-td-align";
    }
  } else {
    cellAlignmentClass = "";
  }
  return `${cellAlignmentClass || ""}`;
}

export const isLastColumnTotal = (array) => {
  if (array.length === 0) {
    return false;
  }
  const columnHeader = ["total", "total cost"];
  const lastObject = array[array.length - 1];
  return columnHeader?.includes(lastObject?.Header?.toLowerCase());
};
