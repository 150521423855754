import React from "react";
import "./style.scss";
// import serviceUnavailableLogo from "../../../Assets/icons/ic-503.png";

function MavNotFound(props) {
  const { errorMessage } = props;
  return (
    <div className="page-not-found errorPageNotFound">
      <div className="blurbg" />
      <div className="not-foundIcon">{/* <img src={serviceUnavailableLogo} alt="logo" /> */}</div>
      <div className="internalError-heading">
        <h3 className="error-heading">{errorMessage}</h3>
      </div>
      <div className="usefullLinks-container">
        <div className="internalError-texts">
          {/* <p>The server is temporarily busy. try again later!</p> */}
        </div>
        {/* <div className="error-button-wrapper">
          <a href="/dashboard/billingSummary" className="internalError-button">
            Back to home
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default MavNotFound;
