const initState = {
  modify: false,
};
const initAction = { type: "", payload: {} };

export default function ModifyRoutesReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "MODIFY_ROUTES":
      return { ...state, modify: action.payload };
    default:
      return state;
  }
}
