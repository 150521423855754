import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Fade, Tooltip } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { noop } from "../../../../Utils/commonUtils";
import { ReactComponent as InfoIcon } from "../../../../Assets/images/ic-info.svg";
import { ReactComponent as InfoIconOutlined } from "../../../../Assets/icons/info-outline.svg";

export default function CheckBoxComponent({
  name,
  className,
  value,
  label,
  handleChange,
  checked,
  toggleCallback = noop,
  disabled,
  info,
  tooltip = null,
}) {
  const onChangeHandler = (event) => {
    toggleCallback(event);
    handleChange({ target: { name, value: event.target.checked } });
  };

  const tooltipStyle = tooltip?.tooltipClass
    ? tooltip?.tooltipClass
    : {
        sx: {
          "& .MuiTooltip-tooltip": {
            color: "white",
            backgroundColor: "rgb(52, 61, 87) !important",
            boxShadow: "-5px 6px 7px #343D572B",
            borderRadius: "4px !important",
            lineHeight: "1.2",
            padding: "6px 7px",
            fontSize: "10px",
            maxWidth: "350px",
          },
          "& .MuiTooltip-arrow": {
            "&:before": {
              backgroundColor: "rgb(52, 61, 87) !important",
            },
          },
        },
      };

  return (
    <div className={`mui-checkbox ${className} flex items-center`}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={checked || false}
            id={value}
            disableRipple
            onChange={onChangeHandler}
            disabled={disabled}
          />
        }
        label={label}
      />
      {info && (
        <Tooltip className="multi-header-tooltip-class" title={info} arrow placement="top">
          <InfoIconOutlined class="infoIcon_style" className="h-[16px] w-[15px] ml-[-7px]" />
        </Tooltip>
      )}
      {tooltip && (
        <Tooltip
          title={tooltip?.text}
          // title="Apply minimum charge, Customer will pay this minimum amount or the result of calculation based on the agreement."
          arrow
          placement="bottom-start"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 400 }}
          PopperProps={tooltipStyle}
        >
          <span className="infoIcon">
            <InfoIcon />
          </span>
        </Tooltip>
      )}
    </div>
  );
}
