import cloneDeep from "lodash.clonedeep";
import store from "../../../../store";
import actions from "../../../../Actions";

export const getCurrentDashboard = (dashboardRoutes) => {
  const currentDashboardPathname = window.location.pathname.split("/dashboard/")[1];
  const currentDashboard = dashboardRoutes?.find((item) => item.path === currentDashboardPathname);
  store.dispatch(actions.DashboardActions("SET_CURRENT_DASHBOARD", currentDashboard));
  return currentDashboard;
};

const stringToArray = (str) => str?.toLowerCase()?.split(" ");

export const getViewId = (dashboard, viewIdIdentifier = null) => {
  let viewId;
  const { displayName } = dashboard?.props || {};
  if (viewIdIdentifier) {
    viewId = `${displayName} ${viewIdIdentifier}`;
  } else {
    viewId = displayName;
  }
  const displayNameArr = stringToArray(viewId) || [];
  displayNameArr.push("table");
  return displayNameArr.join("-");
};

const rowUniqueIdGetter = (id, combinations) => {
  let modifiedId = id;
  combinations.forEach((combination) => {
    modifiedId = `${modifiedId}##${combination}`;
  });
  return modifiedId;
};

const getRowCommentUniqueCombination = (allDashboardComments, dashboardId, viewId, tableData) => {
  const currentDashboardCommentsData = allDashboardComments[dashboardId] || {};
  const combinationArr = currentDashboardCommentsData.tables?.[viewId] || [];
  return combinationArr.map((combination) => tableData[combination]) || [];
};

export const getCurrentRowCommentData = (allDashboardComments, dashboardId, viewId, tableData) => {
  const combinations = getRowCommentUniqueCombination(
    allDashboardComments,
    dashboardId,
    viewId,
    tableData
  );
  const uniqueId = rowUniqueIdGetter(viewId, combinations);
  const count = allDashboardComments[dashboardId]?.countMap?.[uniqueId] || 0;
  return { count, uniqueId };
};

const getCommentData = (allDashboardComments, dashboardId, viewId, data) => {
  const { count } = getCurrentRowCommentData(
    allDashboardComments,
    dashboardId,
    viewId,
    cloneDeep(data)
  );
  const comments = count > 0 ? "Comments" : "No Comments";
  const commentsCount = count;
  return { comments, commentsCount };
};

export const modifyDataWithComments = (data, viewId) => {
  const dashboard = store?.getState()?.dashbaordReducer.currentDashboard;
  const { allDashboardComments } = store.getState().commentsReducer;
  const { dashboardId } = dashboard?.props || {};

  if (Array.isArray(data)) {
    const modifiedData = data.map((eachData) => {
      const { comments, commentsCount } = getCommentData(
        allDashboardComments,
        dashboardId,
        viewId,
        eachData
      );
      return { ...eachData, comments, commentsCount };
    });
    return modifiedData || [];
  }
  if (typeof data === "object" && !Array.isArray(data) && data !== null) {
    const { comments, commentsCount } = getCommentData(
      allDashboardComments,
      dashboardId,
      viewId,
      data
    );
    return { ...data, comments, commentsCount };
  }
  return data || {};
};
