import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import cloneDeep from "lodash.clonedeep";
import FormInput from "../../CK-Auto/Common/Form/Input";

function TableForm({ data, formInputFeilds, submitHandler, wrapperClass = "" }) {
  const [formValues, setFormValues] = useState(cloneDeep(data));

  useEffect(() => {
    setFormValues(cloneDeep(data));
  }, [data]);

  const handleChange = (name, value) => {
    const newValues = { ...formValues };
    newValues[name] = value;
    setFormValues(newValues);
  };

  const handleOnSubmit = async () => {
    if (submitHandler) {
      try {
        const payload = { ...formValues };
        await submitHandler(payload);
      } catch (e) {
        console.log("error", e.message);
      }
    }
  };

  const isFormValid = () => {
    const hasInvalidField = formInputFeilds?.some((row) => {
      const value = formValues?.[row?.name] || "";
      const hasEmptyValue = !value;
      if (hasEmptyValue) {
        return true;
      }
      if (row?.regex) {
        const regex = new RegExp(row?.regex);
        return !regex?.test(value);
      }
      return false;
    });
    return hasInvalidField;
  };

  return (
    <div className={`overflow-x-auto ${wrapperClass || ""}`}>
      <div className="inline-block min-w-full overflow-hidden border-solid border border-[#E6E6E6]">
        <table>
          <tbody>
            {formInputFeilds?.map?.((row) => (
              <tr key={row?.name}>
                <td>{row?.label}</td>
                <td>
                  <FormInput
                    name={row?.name}
                    value={formValues?.[row?.name] || ""}
                    type={row?.type}
                    parentClass={row?.parentClass}
                    onGetData={(name, value) => handleChange(name, value)}
                    errorClass={row?.errorClass}
                    regex={row?.regex}
                    errorMessage={row?.errorMessage}
                    disabled={row?.disabled}
                    focused
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="button-wrapper text-center w-full mt-[30px]">
        <Button className="w-[76%] !shadow-none" onClick={handleOnSubmit} disabled={isFormValid()}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default TableForm;
