const initState = {
  startDate: "",
  endDate: "",
  selectedRange: "",
};
const initAction = { type: "", payload: {} };

export default function computeHourlyReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "UPDATE_START_DATE":
      return { ...state, startDate: action.payload };
    case "UPDATE_END_DATE":
      return { ...state, endDate: action.payload };
    case "UPDATE_SELECTED_DATE_RANGE":
      return { ...state, selectedRange: action.payload };

    default:
      return state;
  }
}
