import { getCurrencySymbol } from "../../../../Utils/commonUtils";

export const updateStackedColumnChart = {};

export const returnTooltip = (props) => {
  let tooltip;
  if (props.tooltipHtml) {
    tooltip = props.tooltipHtml;
  } else {
    tooltip =
      props.numberPrefix === ""
        ? `<div class="tooltip_wrapper"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>${getCurrencySymbol()}$dataValue</b> </span>   $displayValue  </div>`
        : "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>";
  }
  return tooltip;
};

export const showValue = (show = undefined) => (show ? "1" : "0");

export const areaChartConfiguration = {
  type: "area2d",
  width: "100%",
  height: 550,
  dataFormat: "json",
  dataSource: {
    chart: {
      xAxisName: "",
      yAxisName: "",
      labelStep: "0",
      theme: "fusion",
      divLineDashed: "1",
      palettecolors: "#2498fe",
      numVDivLines: "28",
      xAxisNameFont: "Roboto",
      xAxisNameFontSize: "10",
      xAxisNameFontColor: "#000000",
      yAxisNameFont: "Roboto",
      yAxisNameFontSize: "10",
      yAxisNameFontColor: "#000000",
      plotSpacePercent: 60,
      yAxisValueFontColor: "#0a3ca2",
      yAxisValueFontSize: 10,
      labelFontColor: "#0a3ca2",
      labelFontSize: 12,
      showToolTipShadow: "1",
      tooltipPosition: "top",
      showxaxisline: "1",
      showyaxisline: "1",
      xaxislinecolor: "#cfdde5",
      yaxislinecolor: "#cfdde5",
      decimals: "2",
      plotToolText:
        // "<div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>State </span><b style='color:#000'>$seriesName</b></div>",
        `<div id='nameDiv' class='headingCont'>$label</div><div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>Cost:  </span><b style='color:#000'>${getCurrencySymbol()}$value</b>`,
    },
    data: [],
  },
};

export const barChartConfiguration = (props) => {
  const {
    plotSpacePercent = 60,
    height,
    maxLabelHeight,
    decimals = "2",
    chartBottomMargin,
    numVDivLines = "28",
    divLineDashed = "1",
    numbersuffix = "",
  } = props;
  return {
    type: "column2d",
    width: "100%",
    height: height || 300,
    dataFormat: "json",
    dataSource: {
      chart: {
        xAxisName: "",
        yAxisName: "",
        labelStep: "0",
        theme: "fusion",
        divLineDashed,
        palettecolors: "#2498fe",
        numVDivLines,
        xAxisNameFont: "Roboto",
        xAxisNameFontSize: "10",
        xAxisNameFontColor: "#000000",
        yAxisNameFont: "Roboto",
        yAxisNameFontSize: "10",
        yAxisNameFontColor: "#000000",
        plotSpacePercent,
        yAxisValueFontColor: "#0a3ca2",
        yAxisValueFontSize: 10,
        labelFontColor: "#0a3ca2",
        labelFontSize: 10,
        showToolTipShadow: "1",
        tooltipPosition: "top",
        showxaxisline: "1",
        decimals,
        showyaxisline: "1",
        xaxislinecolor: "#cfdde5",
        yaxislinecolor: "#cfdde5",
        maxLabelHeight: maxLabelHeight || undefined,
        chartBottomMargin,
        numbersuffix,
        plotToolText: props.isPercent
          ? "<div id='nameDiv' class='headingCont'>$label</div><div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>Percentage: </span><b style='color:#000'> $value %</b>"
          : "<div id='nameDiv' class='headingCont'>$label</div><div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>Percentage: </span><b style='color:#000'> $value</b>",
      },
      data: [],
    },
  };
};

export const lineChartConfiguration = (props) => ({
  type: "line",
  width: "100%",
  height: 300,
  dataFormat: "json",
  dataSource: {
    chart: {
      xAxisName: "",
      yAxisName: "",
      labelStep: "0",
      theme: "fusion",
      divLineDashed: "1",
      palettecolors: "#2498fe, #61dbfd, #ffa213",
      numVDivLines: "28",
      xAxisNameFont: "Roboto",
      xAxisNameFontSize: "10",
      xAxisNameFontColor: "#000000",
      yAxisNameFont: "Roboto",
      yAxisNameFontSize: "10",
      yAxisNameFontColor: "#000000",
      plotSpacePercent: 60,
      yAxisValueFontColor: "#0a3ca2",
      yAxisValueFontSize: 10,
      labelFontColor: "#0a3ca2",
      labelFontSize: 12,
      showToolTipShadow: "1",
      tooltipPosition: "top",
      showxaxisline: "1",
      showyaxisline: "1",
      xaxislinecolor: "#cfdde5",
      yaxislinecolor: "#cfdde5",
      plotToolText: props.isPercent
        ? "<div id='nameDiv' class='headingCont'>$label</div><div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>Percentage: </span><b style='color:#000'> $value %</b>"
        : `<div id='nameDiv' class='headingCont'>$label</div><div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>Percentage: </span><b style='color:#000'>${getCurrencySymbol()} $value</b>`,
    },
    data: [],
  },
});
export const groupChartConfiguration = (props) => {
  const { width = "100%", legendItemFont = "Roboto, sans-serif" } = props;
  return {
    type: "mscolumn2d",
    width,
    height: props.height ? props.height : 400,
    dataFormat: "json",
    dataSource: {
      chart: {
        xAxisName: "",
        yAxisName: "",
        showLabels: "",
        adjustDiv: 0,
        yAxisMinValue: "0.0",
        minimizeTendency: "1",
        setAdaptiveYMin: 1,
        labelStep: "0",
        drawCrossLine: "1",
        theme: "fusion",
        divLineDashed: props?.divLineDashed || "1",
        chartBottomMargin: "5",
        // divLineColor: "#A6BCC9",
        palettecolors:
          props?.palettecolors ||
          "#2498fe, #61dbfd,#ffa213, #a3dc28, #7af0ca, #f2cb00, #ff8080, #bb4dff, #f457e8, #1bca23, #0a87af, #0aaf83",

        numVDivLines: props?.numVDivLines || "9",
        crossLineColor: "#f2f5fa",
        divLineColor: props?.divLineColor || "#ccc",
        divLineDashLen: "5",
        divLineDashGap: "6",
        xAxisNameFont: "Roboto",
        xAxisNameFontSize: "10",
        xAxisNameFontColor: "#000000",
        yAxisNameFont: "Roboto",
        yAxisNameFontSize: "10",
        yAxisNameFontColor: "#000000",
        plotSpacePercent: 30,
        legendItemFontSize: 10,
        legendIconScale: ".96",
        legendItemFont,
        // legendBgColor: "rgba(207, 221, 229, 0.12)",
        legendBgAlpha: 20,
        legendItemFontBold: 0,
        legendItemFontColor: "#555b6c",
        legendItemHoverFontColor: "#0a3ca2",
        showValues: showValue(props?.show),
        valuefontcolor: "#0a3ca2",
        valuefontsize: "8",
        valuefontweight: "500",
        valuelettespacing: "-0.5px",
        yAxisValueFontColor: props?.yAxisValueFontColor || "#0a3ca2",
        yAxisValueFontSize: props?.yAxisValueFontSize || 11,
        labelFontColor: props?.labelFontColor || "#0a3ca2",
        labelFontSize: props?.labelFontSize || 10,
        showToolTipShadow: "1",
        tooltipPosition: "top",
        showxaxisline: "1",
        showyaxisline: "1",
        xaxislinecolor: props?.xaxislinecolor || "#cfdde5",
        yaxislinecolor: props?.yaxislinecolor || "#cfdde5",
        numberPrefix: `${getCurrencySymbol()}`,
        decimals: "2",
        interactiveLegend: "1",
        legendIconSides: props?.legendIconSides || 4,
        rotateLabels: props?.rotateLabels || 0,
        plotToolText:
          props.numberPrefix === ""
            ? "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>"
            : "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>",
        // plotToolText:
        //   "<div id='nameDiv' class='headingCont'>$label</div> <div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>$seriesName </span><b style='color:#000'>$ $value</b>",
      },

      categories: [
        {
          category: [],
        },
      ],
      dataset: [],
    },
  };
};
export const stackColumnChartConfiguration = (props) => {
  const {
    legendRight,
    legendCaption,
    graphLabel,
    hideScroll,
    numVDivLines = "28",
    plotSpacePercent = 50,
    height,
    maxLabelHeight,
    decimals = "2",
    legendIconSides = 4,
    divLineDashed = "1",
    divLineColor = "#A6BCC9",
    xaxislinecolor = "#cfdde5",
    yaxislinecolor = "#cfdde5",
    rotateLabels = 0,
    labelFontColor = "#0a3ca2",
    labelFontSize = 12,
    yAxisValueFontColor = "#0a3ca2",
    yAxisValueFontSize = 10,
    width,
    palettecolors = "#2498fe, #61dbfd,#ffa213, #a3dc28, #7af0ca, #f2cb00, #ff8080, #bb4dff, #f457e8, #1bca23, #0a87af, #0aaf83",
    numbersuffix = "",
    legendItemFont = "Roboto, sans-serif",
    labelDisplay,
  } = props;

  const config = {
    type: hideScroll ? "stackedcolumn2d" : "scrollstackedcolumn2d",
    width: width || "100%",
    height: height || 400,
    dataFormat: "json",
    dataSource: {
      chart: {
        xAxisName: "",
        showLegend: 0,
        // legendPosition: "bottom",
        yAxisName: "",
        drawCrossLine: "1",
        legendItemFontSize: 10,
        legendItemFont,
        crossLineColor: "#f2f5fa",
        labelDisplay: labelDisplay || "auto",
        // useEllipsesWhenOverflow: "0",
        chartBottomMargin: "5",
        divLineColor,
        labelStep: "0",
        theme: "fusion",
        divLineDashed,
        palettecolors,
        numVDivLines: numVDivLines || "28",
        xAxisNameFont: "Roboto",
        xAxisNameFontSize: "10",
        xAxisNameFontColor: "#000000",
        yAxisNameFont: "Roboto",
        yAxisNameFontSize: "10",
        yAxisNameFontColor: "#000000",
        // plotSpacePercent: 60,
        yAxisValueFontColor,
        yAxisValueFontSize,
        labelFontColor,
        labelFontSize,
        showToolTipShadow: "1",
        tooltipPosition: "bottom",
        showxaxisline: "1",
        showyaxisline: "1",
        xaxislinecolor,
        yaxislinecolor,
        decimals,
        numberPrefix: graphLabel || `${getCurrencySymbol()}`,
        plotSpacePercent,
        plotToolText: returnTooltip(props),
        interactiveLegend: "1",
        maxLabelHeight: maxLabelHeight || undefined,
        legendIconSides,
        rotateLabels,
        numbersuffix,
      },
      categories: [
        {
          category: [],
        },
        {
          seriesname: "Oil",
          data: [],
        },
      ],
    },
  };

  if (legendRight) {
    const rightObj = {
      legendPosition: "right-top",
      legendCaption,
      legendCaptionAlignment: "left",
      legendCaptionBold: "1",
      legendCaptionFontColor: "#000000",
    };
    config.dataSource.chart = { ...config.dataSource.chart, ...rightObj };
  }

  return config;
};

export const multipleLineChartConfiguration = (props) => {
  const {
    legendRight,
    legendCaption = "",
    divLineDashed = "1",
    numVDivLines = "28",
    divLineColor = "#A6BCC9",
    xaxislinecolor = "#cfdde5",
    yaxislinecolor = "#cfdde5",
    rotateLabels = 0,
    labelFontColor = "#0a3ca2",
    labelFontSize = 10,
    yAxisValueFontColor = "#0a3ca2",
    yAxisValueFontSize = 10,
    width = "100%",
    anchorRadius,
    plotToolText = props.numberPrefix === ""
      ? "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>"
      : "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>",

    palettecolors = "#2498fe, #61dbfd, #ffa213,#a3dc28, #7af0ca, #f2cb00, #ff8080, #bb4dff, #f457e8, #1bca23, #0a87af, #0aaf83",
    legendItemFont = "Source Sans Pro",
  } = props;

  const config = {
    type: "msline",
    width,
    height: props.height ? props.height : 400,
    dataFormat: "json",
    dataSource: {
      chart: {
        xAxisName: "",
        yAxisName: "",
        labelStep: "0",
        showLabels: "",
        theme: "fusion",
        divLineDashed,
        crossLineColor: "#f2f5fa",
        palettecolors,
        chartBottomMargin: "5",
        chartLeftMargin: "20",
        divLineColor,
        numVDivLines,
        xAxisNameFont: "Roboto",
        xAxisNameFontSize: "10",
        xAxisNameFontColor: "#000000",
        yAxisNameFont: "Roboto",
        yAxisNameFontSize: "10",
        yAxisNameFontColor: "#000000",
        yAxisMinValue: 0,
        plotSpacePercent: 60,
        legendItemFontSize: 10,
        legendIconScale: ".96",
        // legendBgColor: "rgba(207, 221, 229, 0.12)",
        legendBgAlpha: 20,
        legendItemFontBold: 0,
        legendItemFontColor: "#555b6c",
        legendItemHoverFontColor: "#0a3ca2",
        legendItemFont,
        yAxisValueFontColor,
        yAxisValueFontSize,
        labelFontColor,
        labelFontSize,
        showToolTipShadow: "1",
        tooltipPosition: "top",
        drawCrossLine: "1",
        showxaxisline: "1",
        showyaxisline: "1",
        numberPrefix: `${getCurrencySymbol()}`,
        xaxislinecolor,
        yaxislinecolor,
        decimals: "2",
        rotateLabels,
        anchorRadius: anchorRadius || "4",
        plotToolText,
        //   "<div class='tooltipListCont'> <div class='tooltipItem'><span class='tltipSquare'></span><span>$seriesName </span><b style='color:#000'>$ $value</b>",
      },
      categories: [
        {
          category: [],
        },
      ],
      dataset: [],
    },
  };
  if (legendRight) {
    const rightObj = {
      legendPosition: "right-top",
      legendCaption,
      legendCaptionAlignment: "left",
      legendCaptionBold: "1",
      legendCaptionFontColor: "#000000",
    };

    config.dataSource.chart = { ...config.dataSource.chart, ...rightObj };
  }
  return config;
};

export const stackBarChartConfiguration = (props) => ({
  type: "stackedbar2d",
  width: "100%",
  height: props.height ? props.height : 400,
  dataFormat: "json",
  dataSource: {
    chart: {
      xAxisName: "",
      yAxisName: "",
      labelStep: "0",
      drawCrossLine: "1",
      crossLineColor: "#f2f5fa",
      canvasRightPadding: "15",
      theme: "fusion",
      chartBottomMargin: "5",
      divLineColor: "#A6BCC9",
      divLineDashed: "1",
      palettecolors:
        "#2498fe, #61dbfd,#ffa213, #a3dc28, #7af0ca, #f2cb00, #ff8080, #bb4dff, #f457e8, #1bca23, #0a87af, #0aaf83",
      numVDivLines: "5",
      numDivLines: "16",
      xAxisNameFont: "Roboto",
      xAxisNameFontSize: "10",
      xAxisNameFontColor: "#000000",
      yAxisNameFont: "Roboto",
      yAxisNameFontSize: "10",
      yAxisNameFontColor: "#000000",
      plotSpacePercent: 40,
      legendItemFontSize: 10,
      legendIconScale: ".96",
      // legendBgColor: "rgba(207, 221, 229, 0.12)",
      legendBgAlpha: 20,
      legendItemFontBold: 0,
      legendItemFont: props?.legendItemFont || "Roboto, sans-serif",
      legendItemFontColor: "#555b6c",
      legendItemHoverFontColor: "#0a3ca2",
      yAxisValueFontColor: "#0a3ca2",
      yAxisValueFontSize: 10,
      labelFontColor: "#0a3ca2",
      labelFontSize: 12,
      showToolTipShadow: "1",
      tooltipPosition: "bottom",
      showxaxisline: "1",
      showyaxisline: "1",
      xaxislinecolor: "#cfdde5",
      yaxislinecolor: "#cfdde5",
      decimals: "2",
      numberPrefix: props.prefix ?? `${getCurrencySymbol()}`,
      plotToolText:
        "<div class=\"tooltip_wrapper\"> <div class='headingCont'> $label  </div>  <span> <label>$seriesName</label> <b>$dataValue</b> </span>   $displayValue  </div>",
    },
    categories: [
      {
        category: [
          {
            label: "2012",
          },
          {
            label: "2013",
          },
          {
            label: "2014",
          },
          {
            label: "2015",
          },
          {
            label: "2016",
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: "Facebook",
        data: [
          {
            value: "62",
          },
          {
            value: "64",
          },
          {
            value: "64",
          },
          {
            value: "66",
          },
          {
            value: "78",
          },
        ],
      },
      {
        seriesname: "Instagram",
        data: [
          {
            value: "16",
          },
          {
            value: "28",
          },
          {
            value: "34",
          },
          {
            value: "42",
          },
          {
            value: "54",
          },
        ],
      },

      {
        seriesname: "Twitter",
        data: [
          {
            value: "18",
          },
          {
            value: "19",
          },
          {
            value: "21",
          },
          {
            value: "21",
          },
          {
            value: "24",
          },
        ],
      },
    ],
  },
});

export const shankeyChartConfiguration = (props) => ({
  type: "sankey",
  width: "100%",
  height: props.height ? props.height : 400,
  dataFormat: "json",
  dataSource: {
    chart: {
      theme: "fusion",
      orientation: "horizontal",
      linkalpha: 30,
      linkhoveralpha: 60,
      nodelabelposition: "start",
      showLegend: 1,
      LegendPosition: "bottom",
      numberPrefix: `${getCurrencySymbol()}`,
      legendIconScale: ".96",
      legendBgAlpha: 20,
      legendItemFontBold: 0,
      legendItemFontColor: "#555b6c",
      legendItemHoverFontColor: "#0a3ca2",
      baseChartMessageFontSize: 6,
      labelFontColor: "#0a3ca2",
      nodeLabelFontSize: 12,
      drawCrossLine: "1",
      nodeLabelFontBold: 1,
      legendItemFontSize: 10,
    },
    nodes: [],
    links: [],
  },
});

export const doughNutChartConfiguration = (props) => ({
  type: "doughnut2d",
  renderAt: "chart-container",
  width: props?.width ? props.width : "136",
  height: props?.height ? props.height : "136",
  dataFormat: "json",
  dataSource: {
    chart: {
      caption: "",
      subCaption: "",
      numberPrefix: "",
      bgColor: "#ffffff",
      startingAngle: "310",
      showLegend: props?.showLegend ? props?.showLegend : "0",
      defaultCenterLabel: "",
      centerLabel: "",
      centerLabelBold: "0",
      showTooltip: "0",
      decimals: "0",
      theme: "fusion",
      skipOverlapLabels: "1",
      showLabels: props?.showLabels ? props?.showLabels : 0,
      showValues: props?.showValues ? props?.showValues : 0,
      palettecolors: props?.palettecolors ? props.palettecolors : "A3DC29, 2498FE",
      enableSlicing: 0,
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      pieRadius: props?.pieRadius ? props.pieRadius : 68,
      doughnutRadius: props?.doughnutRadius ? props.doughnutRadius : 136,
      labelFontSize: props?.labelFontSize ? props?.labelFontSize : "20px",
      isSmartLineSlanted: props?.isSmartLineSlanted ? props?.isSmartLineSlanted : 1,
      legendItemFontSize: props?.legendItemFontSize ? props?.legendItemFontSize : 16,
      manageLabelOverflow: 1,
      useEllipsesWhenOverflow: 1,
    },
    data: [],
  },
});
