import React, { useState, useEffect } from "react";
import transformData from "./Service/utils";
import { noop } from "../../../../Utils/commonUtils";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/billdesk-icons/delete-bucket-icon.svg";
import { ReactComponent as SelectIcon } from "../../../../Assets/icons/Vector.svg";
import ListSelectorNoData from "../../../FormConfig/Form/Components/ListSelector.js/noData";
import "./style.scss";

function SectionedItems({ name, options, value, topLabel, handleChange = noop }) {
  const [initialValue, setValues] = useState([]);
  const [groups, setGroups] = useState(transformData(initialValue));

  useEffect(() => {
    const selectedItems = (value?.options || options)?.filter((item) => item?.checked === true);
    setValues(selectedItems);
    setGroups(transformData(selectedItems));
  }, [options, value?.options]);

  const handleRemoveGroup = (groupName) => {
    const newInitialValue = initialValue?.filter((item) => item?.lastInvoiceDate !== groupName);
    setValues(newInitialValue);
    setGroups(transformData(newInitialValue));
    handleChange({ target: { name, value: { options: [...newInitialValue] } } });
  };

  const handleRemoveItem = (itemValue) => {
    const newInitialValue = initialValue?.filter((item) => item?.value !== itemValue);
    setValues(newInitialValue);
    setGroups(transformData(newInitialValue));
    handleChange({ target: { name, value: { options: [...newInitialValue] } } });
  };

  return (
    <div className="section-list-wrapper">
      {groups?.length ? (
        <div>
          <div className={`${topLabel?.class} ${topLabel?.fontSize}`}>{topLabel?.text}</div>
          <div className="section-item-list">
            {groups?.map((group) => (
              <div className="group">
                <div className="group-header">
                  <h4 className="group-title">
                    {group?.groupName} <span className="item-count">({group?.items?.length})</span>
                  </h4>
                  <button
                    type="button"
                    className="remove-group-btn"
                    onClick={() => handleRemoveGroup(group?.groupName)}
                  >
                    Remove
                  </button>
                </div>
                <ul className="item-list">
                  {group?.items?.map((item) => (
                    <li className="item">
                      {item?.label}
                      <button
                        type="button"
                        className="remove-item-btn"
                        onClick={() => handleRemoveItem(item?.value)}
                      >
                        <DeleteIcon />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <ListSelectorNoData message="Please Select Customer Name(s)" Icon={SelectIcon} />
      )}
    </div>
  );
}

export default SectionedItems;
