import React from "react";
import { termLevelSummaryData } from "../../../Dashboard/CustomerManagement/CreateCustomer/PrivatePricingAgreements/util";

const displayText = (value) => (
  <div className="Edp_TooltipV2_Header">
    {value?.termLevelSummary > 0 ? termLevelSummaryData(value?.termLevelSummary) : "- - -"}
    {value?.termLevelSummary > 0 && <span className="Active_Chip">Active</span>}
  </div>
);

export default displayText;
