import moment from "moment";
import { getLocalStorageUserData } from "../../../../../../Utils/commonUtils";

/**
 * The `formatDate` function in JavaScript uses the moment library to format a given date according to
 * a specified format string.
 * @param date - The `date` parameter is the date that you want to format. It can be a Date object, a
 * timestamp, or a string that can be parsed into a date.
 * @param [format=MMM-YYYY] - The `format` parameter in the `formatDate` function is a string that
 * specifies the desired format for the output date. By default, the format is set to "MMM-YYYY", which
 * represents the month abbreviation followed by the year (e.g., Jan-2022).
 */
export const formatDate = (date, format = "MMM-YYYY") => moment(date).format(format);

/**
 * The function `getPartnerInvoiceDate` retrieves the last invoice date of the current user from local
 * storage and formats it as "DD-MMM-YYYY".
 * @returns The function `getPartnerInvoiceDate` is returning the formatted date of the last invoice
 * date of the current user from local storage. If the user data is available in local storage, it
 * retrieves the last invoice date, formats it as "DD-MMM-YYYY" using moment.js library, and returns
 * the formatted date. If user data is not available, it returns the current date formatted by
 * moment.js
 */
export const getPartnerInvoideDate = () => {
  const userData = getLocalStorageUserData();
  if (userData) {
    const {
      currentUser: { lastInvoiceDate },
    } = userData;
    return moment(lastInvoiceDate)?.format("DD-MMM-YYYY");
  }
  return moment();
};

export const getLargerDate = (date1, date2, dateOperations = {}, format = "YYYY-MM-DD") => {
  const { value, operation, unit } = dateOperations;
  let momentDate1 = moment(date1);
  let momentDate2 = moment(date2);

  if (dateOperations) {
    if (operation === "add") {
      momentDate1 = momentDate1.add(value, unit);
      momentDate2 = momentDate2.add(value, unit);
    } else if (operation === "subtract") {
      momentDate1 = momentDate1.subtract(value, unit);
      momentDate2 = momentDate2.subtract(value, unit);
    }
  }

  if (momentDate1.isAfter(momentDate2)) {
    return momentDate1.format(format);
  }
  if (momentDate1.isBefore(momentDate2)) {
    return momentDate2.format(format);
  }
  return momentDate1.format(format);
};
