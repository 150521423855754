import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../../../../Assets/images/ic-info.svg";
import { ReactComponent as InfoDisabledIcon } from "../../../../../Assets/images/ic-info-disabled.svg";
import "./style.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

function FlatDiscountTooltip(props) {
  const { tableRow: data } = props;
  const showMore = useRef();

  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  return (
    <div className="list-popup-wrapper !inline auto-renew-tooltip-wrapper">
      <span className="display-text">{data?.name || ""}</span>
      <div
        className={`pop-up ml-[3px] mb-[3px] ${
          data?.state === "Active" ? "" : "pointer-events-none"
        }`}
      >
        <span
          ref={popoverAnchor}
          aria-owns="auto-renew-popover"
          aria-haspopup="true"
          onMouseEnter={data?.state === "Active" && popoverEnter}
          onMouseLeave={data?.state === "Active" && popoverLeave}
        >
          {data?.state === "Active" ? (
            <InfoIcon ref={showMore} className="show-more !w-[13px] !h-[13px] " />
          ) : (
            <InfoDisabledIcon ref={showMore} className="show-more !w-[13px] !h-[13px] " />
          )}
        </span>
        {data?.state === "Active" ? (
          <Popover
            id="auto-renew-popover"
            className={`${classes.popover} auto-renew-tooltip-popover 
        
            before:absolute bottom-6 before:bottom-[-24px] before:right-[4px] before:transform before:rotate-[135deg] before:left-auto`}
            classes={{
              paper: classes.popoverContent,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
          >
            <div className="flat-discount-description">
              <p className="font-medium text-sm  py-[7px] px-[10px]">Discount Description:</p>
              <div className="p-4 border-t border-solid border-[#DDDFE8] max-h-[90px] overflow-y-auto">
                <p className="flex text-xs font-normal  mb-[10px]">
                  {data?.description || "No Description Available"}
                </p>
              </div>
            </div>
          </Popover>
        ) : null}
      </div>
    </div>
  );
}

export default FlatDiscountTooltip;
