import { useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} ref
 * @param {function} handler
 * @param {boolean} hasEventTarget
   
 }} handler
 */
function useDetectOutsideClick(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        window.outerWidth - 15 <= event.clientX
      ) {
        // 15px is the scroller's width.
        // hence when user clicks on scroller, we would ignore that click.
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}

export default useDetectOutsideClick;
