import { useState, useEffect } from "react";
import isEqual from "lodash.isequal";

const returnCheckedItem = (option, checkboxes) =>
  checkboxes.find((item) => item.value === option.value);

const initializeOptions = (options, columnKeys, checkboxes) => {
  if (options?.length) {
    return options?.map((option) => ({
      ...option,
      column: returnCheckedItem(option, checkboxes)?.column || { ...columnKeys },
    }));
  }
  return [];
};

const changeStructure = (data) => data?.map((item) => ({ label: item?.label, value: item?.value }));

const useCheckboxes = (name, handleChange, columnKeys, options = []) => {
  const [checkboxes, setCheckboxes] = useState(() => initializeOptions(options, columnKeys, null));

  useEffect(() => {
    const initializedOptions = initializeOptions(options, columnKeys, checkboxes);
    setCheckboxes((prev) => {
      if (!isEqual(changeStructure(prev), changeStructure(initializedOptions))) {
        return initializedOptions;
      }
      return prev;
    });
  }, [options]);

  useEffect(() => {
    setTimeout(() => {
      if (options?.length) {
        handleChange({ target: { name, value: { options: checkboxes } } });
      }
    });
  }, [checkboxes]);

  const toggleAllCheckboxes = () => {
    const allChecked = checkboxes.every((option) =>
      option?.value === "CSSB"
        ? Object.entries(option?.column).every(([key, val]) => (key === "3_Years" ? !val : val))
        : Object.values(option?.column).every((val) => val)
    );

    const newCheckboxes = checkboxes.map((option) => {
      const newColumn = Object.keys(option?.column).reduce(
        (columnAcc, columnKey) => ({
          ...columnAcc,
          [columnKey]: option?.value === "CSSB" && columnKey === "3_Years" ? false : !allChecked,
        }),
        {}
      );

      return {
        ...option,
        column: newColumn,
      };
    });

    setCheckboxes(newCheckboxes);
  };

  const toggleCheckbox = (service, columnKey) => {
    setCheckboxes((prev) =>
      prev.map((option) =>
        option?.value === service
          ? {
              ...option,
              column: {
                ...option?.column,
                [columnKey]: !option?.column[columnKey],
              },
            }
          : option
      )
    );
  };

  return { checkboxes, toggleAllCheckboxes, toggleCheckbox };
};

export default useCheckboxes;
