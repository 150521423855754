import actions from "../Actions";
import commonService from "../Services/commonService";
import store from "../store";

export const isLensEnabledCustomer = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  return moduleList?.filter((item) => item?.name === "lens").length > 0;
};

export const hasTuner = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  return moduleList?.filter((item) => item?.name === "tuner").length > 0;
};

export const truncatedText = (text, charLimit = 20) =>
  text?.length > charLimit ? `${text.substring(0, charLimit)}...` : text;

// BELOW FUNCTIONS ARE USED TO CONTROL TUNER FEATURES FROM TCG

export const getEnabledTunerFeaturesByKey = (key, type = 0) => {
  const tabsArray = store.getState()?.TunerFeaturesReducer?.enabledFeatures?.[key]?.enabledFeatures;
  return type ? tabsArray.map((item) => item?.featureName) : tabsArray;
};

export const isTunerFeatureEnabled = (key) =>
  [key] in (store.getState().TunerFeaturesReducer.enabledFeatures || {});

export const getDashboardFeaturesByGroup = (menuData) => {
  const transformed = {};

  menuData.forEach((item) => {
    transformed[item.id] = {
      featureName: item.displayName,
      enabledFeatures:
        item.childDashboards && item.childDashboards.length > 0
          ? item.childDashboards.map((child) => ({
              featureName: child.displayName,
              featureKey: child.id,
            }))
          : [],
    };
  });

  return transformed;
};

export const getEnabledTunerFeatures = async (type) => {
  try {
    const response = await commonService.tuner.getEnabledTunerFeatures(type);
    const flattenedArray = getDashboardFeaturesByGroup(response?.data?.data);
    store.dispatch(
      actions.TunerFeaturesAction("TUNER_FEATURES", { enabledFeatures: flattenedArray })
    );
  } catch {
    //
  }
};
