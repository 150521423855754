import React from "react";
import internalErrorLogo from "../../../Assets/icons/others/broken.png";

export default function SomethingWentWrong({ redirecturl }) {
  return (
    <>
      <div className="blurbg" />
      <div className="error-logo-wrapper">{/* <img src={notCKFoundLogo} alt="" /> */}</div>
      <div className="not-foundIcon">
        <img src={internalErrorLogo} alt="" />
      </div>
      <div className="internalError-heading">
        <h3 className="error-heading">Oops, Something went wrong…</h3>
      </div>
      <div className="usefullLinks-container">
        <div className="internalError-texts">
          <p>This page didn’t load correctly, due to an error. Please try again later.</p>
        </div>
        <div className="error-button-wrapper">
          <a href={redirecturl} className="internalError-button">
            Go Back
          </a>
        </div>
      </div>
    </>
  );
}
