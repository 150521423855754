import React from "react";

export default function resetItems(
  listItems,
  selectedListItems,
  listItemsStateFn,
  selectedListItemsStateFn
) {
  const updatedRightSideList = listItems.filter(
    (item) => !selectedListItems.filter((ele) => ele.value === item.value)?.length
  );

  /**
   * remove checked from left side items
   * so that when they are moved to right side
   * it's checked property is reset
   */
  selectedListItems.forEach((item) => {
    const clone = item;
    clone.checked = false;
  });
  listItemsStateFn(updatedRightSideList);
  selectedListItemsStateFn([]);

  return updatedRightSideList;
}

export const labelTooltip = (data) => (
  <div className="Label-tooltip">
    <p>{data}</p>
  </div>
);

export const noDataLabelStructure = (noDataLabel, subHeading) =>
  noDataLabel ? (
    <span className="leading-[18px]">{noDataLabel}</span>
  ) : (
    <span className="leading-[18px]">
      <span className="text-[#696969] text-[11px]">{subHeading}</span>
    </span>
  );

export const isResetDisabled = (itemList, clonedItemList) =>
  itemList.length === clonedItemList.length;
