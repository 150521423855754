import React from "react";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
import { getCurrencySymbol } from "../../../Utils/commonUtils";
import { ReactComponent as ActiveArrow } from "../../../Assets/icons/external-link-dropdown.svg";
import CheckBoxComponent from "../../FormConfig/Form/Components/Checkbox";
import Routes from "../../../Config/Routes";

export const numberWithCommasandDecimal = (x = 0, toFixed = 2 /* , row = {} */) => {
  if (typeof x === "number") {
    if (x < 0) {
      const withoutNegative = x * -1;
      // eslint-disable-next-line eqeqeq
      if (x.toFixed(toFixed) == 0.0) {
        return `${getCurrencySymbol()}${withoutNegative
          .toFixed(toFixed)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      return `-${getCurrencySymbol()}${withoutNegative
        .toFixed(toFixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    return `${getCurrencySymbol()}${x
      .toFixed(toFixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return x;
};

export default function EDPDiscountTypeTooltip(item, key = "") {
  const data = item;
  return (data?.discountType === "EDP Discount" ||
    data?.discountType === "CloudFront Private Pricing") &&
    data?.commitments?.length > 0 ? (
    <div
      className={`specific-table-item-tooltip ${data?.termLevelSummary ? "" : "blue-info_icon"}`}
    >
      <span>{data?.termLevelSummary}</span>
      <div>
        <InfoIcon />
        <div className="tooltip-box">
          <table>
            {key === "EDP" ? (
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Commitments</th>
                  <th>Discount</th>
                  <th>Credits</th>
                </tr>
              </thead>
            ) : (
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Data Transfer</th>
                  <th>Data Transfer Unit Type</th>
                </tr>
              </thead>
            )}
            <tbody>
              {key === "EDP"
                ? data?.commitments?.map((commitment) => (
                    <tr key="">
                      <td>{commitment?.tenure}</td>
                      <td>{numberWithCommasandDecimal(Number(commitment?.commitment), 0)}</td>
                      <td>{`${commitment?.discount}%`}</td>
                      <td>{numberWithCommasandDecimal(Number(commitment?.credits), 0)}</td>
                    </tr>
                  ))
                : data?.commitments?.map((commitment) => (
                    <tr key="">
                      <td>{commitment?.tenure}</td>
                      {/* <td>{`${commitment?.qty}TB`}</td> */}
                      <td>{commitment?.qty}</td>
                      <td>{commitment?.unit}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div>{data?.discountType}</div>
  );
}

export const dicountToolip = (item) =>
  item?.discountType === "EDP Discount" && item?.commitments?.length > 0 ? (
    <div className="specific-table-item-tooltip">
      <span>{item?.commitments?.[0]?.discount}</span>
      <div>
        <InfoIcon />
        <div className="tooltip-box">
          <table>
            <thead>
              <tr>
                <th>Tenure</th>
                <th>Discount(%)</th>
              </tr>
            </thead>
            <tbody>
              {item?.commitments?.map((commitment) => (
                <tr key="">
                  <td>{commitment?.tenure}</td>
                  <td>{commitment?.discount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div>{item?.discountPercentage}</div>
  );

export const descriptionTooltip = (item, key = "") =>
  item?.[key] && (
    <div className="specific-table-item-tooltip">
      {/* {item?.name && <span>{item?.name}</span>} */}
      {item?.[key] && <span>{item?.[key]}</span>}
      {item.description && (
        <div>
          <InfoIcon />
          <div className="tooltip-box">
            <p className="tooltip-heading">{`${
              key === "templateApplied" ? "Template" : "Discount"
            } Description`}</p>
            <p className="tooltip-content">{item.description}</p>
          </div>
        </div>
      )}
    </div>
  );

export const addPercentageToFlatDiscount = (item) => {
  if (item?.discountApplication?.toLowerCase() === "flat") {
    return <div>{`${item?.discountApplicationDetail}%`}</div>;
  }
  return <div>{item?.discountApplicationDetail || "- - -"}</div>;
};

export const createTemplateList = (data, templateListConfig, onIconClick, EyeIcon) => {
  const templateData = templateListConfig;
  const optionsArr = [];

  data.forEach((item) => {
    const itemClone = {
      id: item.id,
      value: item.name,
      label: item.name,
      postImage: {
        icon: EyeIcon,
        onIconClick,
        props: { modalName: "cloneTemplate", modalId: item?.id },
      },
    };
    optionsArr.push(itemClone);
  });
  templateData.options = [...optionsArr];
  templateData.footer = (
    <div className="bg-[#F0F6FF] py-[10px] px-[10px] flex rounded-b-[3px] items-center justify-end border-t border-solid border-t-[#DDDFE8]">
      <a
        href={Routes.TEMPLATE_ON_DEMAND_DISCOUNTS}
        target="_blank"
        className="text-[#0A3CA2] text-xs whitespace-nowrap flex items-center font-semibold"
        rel="noreferrer"
      >
        Manage Discount Templates <ActiveArrow className="ml-[10px]" />
      </a>
    </div>
  );
  return templateData;
};

export const createAccountList = (data) => {
  let accountId = [];
  const optionsArr = [];

  data.forEach((item) => {
    const itemClone = {
      id: item.id,
      value: item.accountId,
      label: item.accountId,
    };
    optionsArr.push(itemClone);
  });
  accountId = optionsArr;

  return [...accountId];
};

export const edpFooter = (
  setCheckBox,
  checkBox,
  SubmitHandler,
  onCancelHandler,
  disabled = true
) => (
  <div className="flex justify-between items-center p-[20px] bg-[#F0F6FF] mx-[1rem] border-t border-t-[#DDDFE8] border-solid">
    <div>
      {" "}
      <CheckBoxComponent
        name="acknowledge"
        label="I acknowledge that I have reviewed the changes."
        handleChange={() => setCheckBox(!checkBox)}
        checked={checkBox}
      />
    </div>
    <div className="buttons flex gap-x-[10px]">
      <button
        type="submit"
        className="button small outline-primary !px-[21px] rounded !font-medium text-[12px]"
        id="submit"
        onClick={() => onCancelHandler()}
      >
        Cancel
      </button>
      <button
        type="button"
        className={`button primary small !px-[21px] rounded !font-medium text-[12px] ${
          disabled ? "disabled" : ""
        }`}
        onClick={() => SubmitHandler()}
        disabled={disabled}
      >
        Add Discount
      </button>
    </div>
  </div>
);

export const getRegex = (name) => {
  let regex = null;
  switch (name) {
    case "commitment":
      regex = /^(0*[1-9]\d*)$/;
      break;
    case "discount":
      regex = /^(0*(\d?\d(\.\d{1,2})?|99(\.99?)?))$/;
      break;
    case "credits":
      regex = /^(0*[1-9]\d*)$/;
      break;
    default:
      break;
  }
  return regex;
};

export const getErrorMessageInput = (
  name,
  value,
  regex,
  throwError = false,
  compareValue = null
) => {
  const errorMessage = "";
  if (value.length === 0 && name !== "credits") {
    return "This field is required";
  }
  if (compareValue && parseInt(value, 10) >= parseInt(compareValue, 10)) {
    return "Value should be lesser than Commitment";
  }
  if (name === "commitment" && throwError) {
    return "Value should be greater than previous year";
  }
  if (regex && value.length) {
    const regexValue = !!String(value)?.match(regex);
    return !regexValue ? `Please enter a valid ${name}` : "";
  }
  return errorMessage;
};
