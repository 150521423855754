import React from "react";

export default function HighLightText(props) {
  const { text = "", highlightText = "", customHighLight = false } = props;
  const index =
    String(text)
      ?.toLowerCase()
      ?.indexOf(highlightText?.toLowerCase() || "") || 0;
  const normalizedText = String(text) || "";
  return customHighLight && highlightText && index >= 0 ? (
    <>
      {normalizedText?.substring(0, index)}
      <span style={{ backgroundColor: "yellow" }}>
        {normalizedText?.substring(index, index + highlightText.length)}
      </span>
      {normalizedText?.substring(index + highlightText.length)}
    </>
  ) : (
    text
  );
}
