import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import calendarIcon from "../../../Assets/icons/calendarIcon.svg";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./style.scss";

function DateRangePick(props) {
  const {
    doubleDatepickerWithLabel = true,
    onDateChange,
    selectedValue,
    anchorEl,
    min,
    max,
    name,
    filtersCopyVariable,
    toggleHandler,
    opens = "",
  } = props;
  const [toggle, setToggle] = useState(false);
  const onCancelHandler = () => {
    setToggle(false);
    document.getElementsByClassName("MuiBackdrop-root")[0].click();
  };

  const handleEvent = (event, picker) => {
    onCancelHandler();
    onDateChange(
      picker.startDate._d,
      picker.endDate._d,
      name,

      filtersCopyVariable
    );
  };

  const handleClearSelection = (startDate, endDate) => {
    onCancelHandler();
    onDateChange(
      startDate,
      endDate,
      name,

      filtersCopyVariable
    );
  };

  let openPosition = window.innerWidth <= 1200 ? "left" : "right";
  if (opens) {
    openPosition = opens;
  }

  return (
    <DateRangePicker
      initialSettings={{
        startDate: moment(selectedValue[0]).format("MM/DD/YYYY"),
        endDate: moment(selectedValue[1]).format("MM/DD/YYYY"),
        minDate: min,
        maxDate: max,
        showDropdowns: true,
        ranges: {
          "7D": [moment(max).subtract(6, "days"), moment(max)],
          "3M": [moment(max).subtract(3, "month"), moment(max)],
          "6M": [moment(max).subtract(6, "month"), moment(max)],
          "1Y": [moment(max).subtract(1, "year"), moment(max)],
          MTD: [moment(max).startOf("month"), moment(max)],
          YTD: [moment(max).startOf("year"), moment(max)],
        },
        opens: openPosition,
        locale: "MMM,DD YYYY",
        alwaysShowCalendars: true,
        linkedCalendars: false,
        parentEl: ".dateRange_pickerBtn",
      }}
      onApply={handleEvent}
      onCancel={onCancelHandler}
      onShow={() => setToggle(!toggle)}
      onHide={() => setToggle(!toggle)}
    >
      <button
        className="dateRange_pickerBtn doubleCalendar"
        id={!anchorEl ? "dateRangePicker_Button" : ""}
        type="button"
      >
        <div className={`dateText ${doubleDatepickerWithLabel ? "date_label" : ""}`}>
          {moment(selectedValue[0]).format("MMM DD, YYYY")} -{" "}
          {moment(selectedValue[1]).format("MMM DD, YYYY")}
        </div>
        <div
          onClick={() => handleClearSelection(min, max)}
          className={!toggle ? `clear_selection ${opens}` : `clear_selection ${opens} show`}
          aria-hidden="true"
        >
          <p>Clear Selection</p>
          <RefreshIcon size="small" />
        </div>
        <label
          className={`addOn  ${doubleDatepickerWithLabel ? "date_label" : ""}`}
          onClick={toggleHandler}
          aria-hidden="true"
        >
          <img src={calendarIcon} alt="calender" />
        </label>
      </button>
    </DateRangePicker>
  );
}

export default DateRangePick;
