import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import Tabs from "../../Tabs";
import Chart from "../FusionChart";
import Loader from "../../../../../../Common/Loader";
import { getGraphDataNew } from "../../../helper/utils";
import ActionsDialog from "../../../../../../Common/Modal/ActionsDialog";
import Select from "../Select";

function ExpandedGraph(props) {
  const {
    configuration,
    selectedTab,
    activeGranularity,
    apiKey,
    selectedDropdownOption,
    showGraphModal,
    graph: { heading },
    setShowGraphModal,
  } = props;
  const [selected, setSelected] = useState(selectedTab);
  const [selectedOption, setSelectedOption] = useState(selectedDropdownOption);
  const [graphLoading, setGraphLoading] = useState(false);
  const [config, setConfig] = useState(configuration);

  const tabClicked = async (item, componentType = "tab") => {
    if (item?.label === selected) {
      return;
    }
    setGraphLoading(true);
    if (componentType === "tab") {
      setSelected(item?.label);
      configuration.selectedTab = item?.value;
    }
    if (componentType === "select") {
      setSelectedOption(item);
      configuration.selectedOption = item?.value;
    }
    const response = await configuration.serviceCall(
      apiKey,
      configuration?.name,
      activeGranularity?.toUpperCase(),
      configuration
    );
    const configClone = cloneDeep(config);
    configClone.data = response?.data;
    setConfig(configClone);
    setGraphLoading(false);
  };

  const renderHeading = () => (
    <div className="flex justify-between w-[calc(100%-35px)] items-center">
      <h2>{heading}</h2>
      <Select
        options={configuration?.options}
        handleChange={tabClicked}
        selected={selectedOption}
      />
    </div>
  );

  return (
    <ActionsDialog
      open={showGraphModal}
      data={{
        config: {
          heading: renderHeading(),
          type: "Custom",
          actions: false,
          dialogClass: "modal_table_container graph_modal",
          body: (
            <div className="expanded-graph w-[100%]">
              <Tabs
                tabs={configuration?.tabArr}
                tabClicked={tabClicked}
                selected={selected}
                wrapperClass="flex justify-center"
                isLoading={graphLoading}
              />
              {graphLoading ? (
                <div className="min-h-[346px] relative">
                  <Loader />
                </div>
              ) : (
                <Chart
                  {...props}
                  graph={getGraphDataNew(config, activeGranularity)}
                  updatedWidth="965"
                />
              )}
            </div>
          ),
        },
      }}
      handleClose={() => setShowGraphModal(false)}
      showCancelBtn={false}
      showCloseIcon
    />
  );
}

export default ExpandedGraph;
