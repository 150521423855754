import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { ReactComponent as DeleteModalIcon } from "../../../../Assets/icons/delete-modal.svg";
import { ReactComponent as MAVDeleteModalIcon } from "../../../../Assets/icons/mav-delete-model-icon.svg";
import Button from "../../../FormConfig/Form/Components/Button";
import "./style.scss";

export default function DeleteUserModal({
  open,
  data,
  handleClose,
  handleDelete,
  screen = "",
  deleteQuestion = "",
  keyname = "name",
  mav = false,
  dialogClass = "",
}) {
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-delete-dialog"
      id="delete_popup"
      maxWidth="xs"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      className={dialogClass}
    >
      <DialogTitle className="delete-dialog-title" id="alert-dialog-title">
        Delete {screen}
        {mav ? "" : "?"}
      </DialogTitle>
      <DialogContent className="delete-dialog-body">
        <DialogContentText id="alert-dialog-description">
          {mav ? <MAVDeleteModalIcon /> : <DeleteModalIcon />}
          {mav ? (
            <span className="!text-[18px] !font-[700] !text-center">
              {deleteQuestion ||
                `Delete ${screen.toLowerCase()} 
          "${data.lastName ? `${data.firstName} ${data.lastName}` : data[keyname]}" permanently?`}
            </span>
          ) : (
            deleteQuestion ||
            `Delete ${screen.toLowerCase()} 
    "${data?.lastName ? `${data.firstName} ${data.lastName}` : data[keyname]}" permanently?`
          )}
          {mav && <span>This action cannot be undone. Click “Yes” to confirm.</span>}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="delete-dialog-footer">
        <Button
          className="rounded-[3px] text-xs bg-white "
          size="small"
          variant="outline-primary-nohover"
          handleClick={() => handleClose()}
          text="Cancel"
        />
        <Button
          className="rounded-[3px] text-xs !font-medium primary-hover"
          size="small"
          variant="primary"
          id="submit"
          text={`${mav ? "Yes" : "Delete"}`}
          handleClick={() => {
            handleDelete();
            handleClose();
          }}
          type="submit"
        />
      </DialogActions>
    </Dialog>
  );
}
