import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import pageNotFoundIcon from "../../../Assets/images/pagenotfound/icon.png";
import PageTitle from "../../Common/PageTitle";
import withRouter from "../../Common/WithRouter";
import { getLocalStorageUserData, isEmpty } from "../../../Utils/commonUtils";
import InternalError503 from "../503_errorPage";
import MavNotFound from "../mav_userNotFound";
import fetchStrapiData from "../../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../../Services/Strapi/urls";

const PageNotFound = function ({ pageTitle }) {
  const [publicPage, setPublicPage] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [strapiDataState, setStrapiDataState] = useState({});

  const errorCode = useSelector((state) => state.ErrorReducer.errorCode);
  const errorMessage = useSelector((state) => state.ErrorReducer.errorMessage);

  const renderHtml = () => {
    setTimeout(() => {
      setShouldRender(true);
    }, 0);
  };

  useEffect(() => {
    const getStrapiData = async () => {
      const data = await fetchStrapiData(strapiUrls.PAGE_NOT_FOUND);
      setStrapiDataState(data);
    };

    getStrapiData();
  }, []);

  useEffect(() => {
    const userdata = getLocalStorageUserData();
    renderHtml();
    if (userdata) {
      setPublicPage(!publicPage);
    }
  }, [errorCode]);

  const renderDashboard = (code, message, strapiData) => {
    switch (code) {
      // case 500:
      //   return <InternalError />;
      case 503:
        return <InternalError503 strapiData={strapiData?.InternalError503} />;
      case 500:
      case 715:
      case 785:
        return <MavNotFound errorMessage={message} />;
      default:
        return <p data-testid="not-found-message">{strapiData?.defaultDashbaordText}</p>;
    }
  };

  return (
    shouldRender &&
    !isEmpty(strapiDataState) && (
      <div className={publicPage ? "page-not-found-wrapper" : "page-not-found-wrapper publicPage"}>
        <PageTitle pageTitle={pageTitle || strapiDataState?.pageTitle} />
        <div className="icon-wrapper">
          <img
            src={`${strapiBasePath}${strapiDataState?.image?.data?.attributes?.url}`}
            alt={strapiDataState?.pageTitle}
          />
        </div>
        {renderDashboard(errorCode, errorMessage, strapiDataState)}
      </div>
    )
  );
};

export default withRouter(PageNotFound);
