import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import urls from "../../../../../Services/axios/urls";
import AzureLoader from "./CommonComponent/AzureLoading";
import Routes from "../../../../../Config/Routes";
import { checkURLForToken, onboardingAxiosService } from "../util";
import "./style.scss";

function AzureSubscriptionActivation() {
  const { subscriptionId } = useParams();

  const navigate = useNavigate();
  checkURLForToken();
  const fetchData = async () => {
    const apiUrl = `${urls.CK_AZURE.GET_SUBSCRIPTION_ID}${subscriptionId}`;
    try {
      const response = await onboardingAxiosService.get(apiUrl);
      if (response?.data?.data?.transactionId) {
        sessionStorage.setItem("SSOTransactionID", response?.data?.data?.transactionId);
      }
      navigate(response?.data?.data?.redirectUrl);
    } catch (e) {
      navigate(Routes.AZURE_SUBSCRIPTION_ERROR);
    }
  };

  useEffect(() => {
    fetchData();
  }, [subscriptionId]);

  const title = "Thankyou for subscribing to CloudKeeper Lens";
  const message = "Please wait while we fetch details from Azure.";

  return <AzureLoader title={title} message={message} />;
}

export default AzureSubscriptionActivation;
