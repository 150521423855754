import { CircularProgress } from "@mui/material";
import React from "react";
import Icon from "@mui/material/Icon";

export default function Button({
  text,
  size = "medium",
  type = "button",
  handleClick,
  disabled = false,
  className,
  isLoading = false,
  variant,
  id,
  image,
  imageIcon,
  icon,
  label,
  imageIconinLast,
  disabledStyle,
}) {
  return (
    <button
      type={type}
      className={`button ${variant} ${disabled && (disabledStyle || "disabled")} ${
        disabledStyle && disabledStyle
      } ${size} ${className}`}
      onClick={handleClick}
      disabled={disabled}
      id={id}
    >
      {icon && <Icon>add_circle</Icon>}
      {imageIcon && imageIcon}
      {image && <img className={image.className} src={image.src} alt={image.alt} />}
      {label && <span className={label.className}>{label.text}</span>}
      {isLoading ? <CircularProgress /> : text}
      {imageIconinLast && imageIconinLast}
    </button>
  );
}
