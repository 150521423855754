/* eslint-disable complexity */
import React, { useRef, useState } from "react";
import { FormHelperText } from "@mui/material";
import isEqual from "lodash.isequal";
import Text from "./Text";
import { ReactComponent as CollapseIcon } from "../../../../Assets/icons/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../../Assets/icons/expand.svg";
import { ReactComponent as RefreshIcon } from "../../../../Assets/icons/ic-refresh-template.svg";
import useDetectOutsideClick from "../../../../CustomHooks/useDetectOutsideClick";
import { noop, filterList } from "../../../../Utils/commonUtils";
import SearchBar from "./SearchBar";

// eslint-disable-next-line max-lines-per-function
export default function BasicSelect({
  value = {},
  handleChange,
  name,
  error,
  className,
  placeHolder,
  options = [],
  topLabel,
  touched,
  onBlurHandler = noop,
  showSelectedIcon,
  enableFilter = null,
  footer = null,
  tooltipStyle,
  onRefresh,
  disabled = false,
  noDataMessage,
  optional = false,
  dropdownOpen = false,
  enableScroll = false,
  enableElipses = false,
  showTitle = false,
  overFlowYScroll = false,
}) {
  const [collapse, setCollapse] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const searchResult = options?.filter(filterList(searchTerm, "label"));

  const handleSelect = (_, option) => {
    if (!isEqual(value, option)) {
      handleChange({ target: { value: option, name }, eventType: "change" });
      setSearchTerm("");
    }
    setCollapse(true);
  };

  const ref = useRef();

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    if (!collapse) {
      setCollapse(true);
      onBlurHandler({ target: { value, name }, eventType: "blur" });
      setSearchTerm("");
    }
  });

  const onSearchHandler = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={`dropdown-wrapper ${className} ${disabled ? "disabled" : ""}`}>
      {topLabel?.text && (
        <Text
          text={topLabel.text}
          size={topLabel.fontSize}
          className={topLabel.class}
          type="primary"
          tooltipStyle={tooltipStyle}
          optional={optional}
        />
      )}
      {onRefresh ? (
        <div className="cursor-pointer refresh_template" onClick={onRefresh} aria-hidden>
          <RefreshIcon />
          <label className="text-[11px] font-normal">Refresh Template</label>
        </div>
      ) : null}
      <div ref={ref} className={`dropdown ${dropdownOpen || !collapse ? "dropdownOpen" : ""}`}>
        <div
          onClick={() => setCollapse(!collapse)}
          aria-hidden="true"
          className={`header ${value?.label && "filled"} ${!collapse && "dropdown"} ${
            touched && error && "error_box"
          } ${dropdownOpen ? "hidden" : ""}`}
        >
          <span className="truncate">
            {value.img && (
              <span className="inline-block mr-2 text-[23px]">
                <img src={value.img} alt={value.name} width="23px" />
              </span>
            )}
            {value.label || placeHolder}
          </span>
          {/* <img src= */}
          {dropdownOpen || collapse ? <ExpandIcon /> : <CollapseIcon />}
        </div>
        <div
          className={`body !p-0 ${
            dropdownOpen || !collapse ? "block visible" : "hidden invisible"
          }`}
        >
          {enableFilter && options.length > 5 && (
            <div className="sticky top-[0] z-[9]">
              <SearchBar
                placeholder={enableFilter?.placeHolder}
                searchTerm={searchTerm}
                onChangeHandler={onSearchHandler}
                className="text-xs"
                styleSearch="!mt-4"
                overridePlaceholder
              />
            </div>
          )}
          <div
            name={name}
            className={`option-wrapper ${enableScroll && "enable-scroll"}  ${
              overFlowYScroll ? "h-[50vh] overflow-y-scroll" : ""
            }`}
          >
            {searchResult?.length
              ? searchResult?.map((option, index) => {
                  const keyValue = `${option.value}_${index}`;
                  return (
                    <div
                      onClick={(e) => handleSelect(e, option)}
                      className={`option relative ${option?.disabled && "hidden"}  ${
                        showSelectedIcon && option.value === value.value && "showSelectedIcon"
                      } ${enableElipses && "enable-elipses"}`}
                      key={keyValue}
                      value={option.value}
                      aria-hidden="true"
                      title={(showTitle && option.label) || ""}
                    >
                      {option.img && (
                        <span className="inline-block mr-2 text-[23px]">
                          <img src={option.img} alt={option.name} width="23px" />
                        </span>
                      )}
                      {option.label}
                      {option?.postImage?.props?.default && (
                        <span className="default">Default</span>
                      )}
                      {option.postImage && (
                        <img
                          alt="post"
                          aria-hidden
                          src={option.postImage.icon}
                          onClick={(e) => {
                            e.stopPropagation();
                            option?.postImage?.onIconClick({
                              event: e,
                              props: option?.postImage?.props,
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })
              : noDataMessage && <p className="no_data_message"> {noDataMessage}</p>}
          </div>
          {footer && footer}
        </div>
        {touched && error && (
          <FormHelperText className="error_message" error={error} size="small">
            {error}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
