import React from "react";
import { Button } from "@mui/material";
import Draggable from "../../../../Common/Draggable";
import { ResetValueIcon } from "../../../../../Constants/icons";

function DialogBodyHtml({
  modalData,
  handleModalData,
  renderModalDisclaimer,
  reviewed,
  setReviewed,
  resetOrder,
  setOpen,
  setModalData,
  initialData,
  saveOrder,
}) {
  return (
    <>
      <div className="dialog_body_order">
        <Draggable listOrder={modalData || []} onCardUpdate={handleModalData} />
      </div>
      <div className="dialog_body_disclaimer">{renderModalDisclaimer}</div>
      <div className="footer_wrapper p-2 flex items-center justify-between">
        <section className="review_section_wrapper flex items-center">
          <label className="flex items-center gap-[10px]">
            <input
              type="checkbox"
              name="reviewed"
              value={reviewed}
              checked={reviewed === true}
              onChange={() => setReviewed(!reviewed)}
              className="!mb-[2px]"
            />
            <span className="checkbox_label">I have reviewed the changes</span>
          </label>
        </section>
        <section className="flex gap-2">
          <button type="button" className="reset_btn" onClick={resetOrder}>
            <ResetValueIcon />
            <span>Reset</span>
          </button>
          <Button
            onClick={() => {
              setOpen(false);
              setReviewed(false);
              setModalData(initialData.length ? initialData : modalData);
            }}
            variant="outlined"
            disableRipple
          >
            Cancel
          </Button>
          <Button disableRipple onClick={saveOrder} variant="filled" disabled={!reviewed}>
            Save
          </Button>
        </section>
      </div>
    </>
  );
}

export default DialogBodyHtml;
