import React, { useEffect, useRef } from "react";
import "../style.scss";
import Text from "./Text";
import InfoBox from "../../../CK-Lens/Common/InfoBox";

function AutoResizeTextbox(props) {
  const {
    handleChange,
    touched,
    error,
    className = "",
    labelText = "",
    labelClass,
    required = false,
    placeHolder = "",
    name,
    value = "",
    componentWrapperClass = "",
    detailedMsg = "",
  } = props;

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.textContent = value;
    }
  }, []);
  const onChangeHandler = (event) => {
    handleChange({
      ...event,
      target: {
        ...event.currentTarget,
        name,
        value: event.currentTarget.textContent,
      },
    });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <div className={componentWrapperClass}>
      <div className="flex justify-between items-center">
        {labelText && (
          <div className="flex">
            <Text text={labelText} labelClass={labelClass} className="mb-0" />
            {required && <Text text="*" labelClass="requiredClass" type="primary" />}
          </div>
        )}
        {detailedMsg && (
          <div className="autoResMsgInfo">
            <InfoBox message={detailedMsg} />
          </div>
        )}
      </div>
      <div
        className={`${className} auto-resize-wrapper ${
          touched && error && "auto-resize-wrapper-error"
        }`}
        contentEditable="true"
        role="textbox"
        id="editableContent"
        tabIndex="0"
        label=""
        onInput={(e) => onChangeHandler(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        data-text={placeHolder}
        name={name}
        placeholder={placeHolder}
        aria-labelledby="editableContentLabel"
        ref={inputRef}
      />
      {touched && error && <p className="error_message autoresize_error_message">{error}</p>}
    </div>
  );
}

export default AutoResizeTextbox;
